import React, { useContext, useEffect } from 'react';
import { blocks } from 'blocks';

import { usePointPosition } from 'hooks/usePointPosition';
import { useProperty } from 'hooks/useProperty';
import { useStoreSelector } from 'hooks/useStore';

import { CanvasDynamicContext } from './Canvas';

export const Pointer = () => {
  const copy = useStoreSelector((state) => state.graphic.copy);
  const drag = useStoreSelector((state) => (!blocks[state.graphic.drag?.element.type || '_']?.element.options.includes('element-sticker') || state.graphic.drag?.type === 'create' || copy) && state.graphic.drag);
  const layout = useStoreSelector((state) => state.graphic.layout.key);

  const canvas = useContext(CanvasDynamicContext);

  const getPosition = usePointPosition();

  const cursor = useProperty<[number, number]>([0, 0]);

  const status = drag && (drag.type !== 'move' || copy || (layout && drag.element.id !== layout));

  useEffect(() => {
    if (!status) {
      return () => {};
    }

    cursor.set([
      canvas.cursor[0],
      canvas.cursor[1],
    ]);

    const onMouseMove = (e: any) => {
      cursor.set([
        e.clientX,
        e.clientY,
      ]);
    };

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [status]);

  if (!status) {
    return null;
  }

  const position = getPosition(canvas.cursor, drag.frame.size, drag.frame.offset);

  position[0] = (position[0] + canvas.position[0] + drag.frame.size[0] / 2 + drag.frame.offset[0]) * canvas.zoom + canvas.screen[0] / 2;
  position[1] = (position[1] + canvas.position[1] + drag.frame.size[1] / 2 + drag.frame.offset[1]) * canvas.zoom + canvas.top + canvas.screen[1] / 2;

  return (
    <div
      className="element-pointer"
      style={{
        top: position[1],
        left: position[0],
        marginLeft: -drag.frame.offset[0] * canvas.zoom,
        marginTop: -drag.frame.offset[1] * canvas.zoom,
      }}
    >
      <div
        className="pointer-frame"
        style={{
          width: drag.frame.size[0],
          height: drag.frame.size[1],
          borderWidth: `${1 / canvas.zoom}px`,
          transform: `translate(-50%, -50%) scale(${canvas.zoom})`,
        }}
      />
    </div>
  );
};
