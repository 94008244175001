import { IConfig } from 'blocks';

import { extractPoints } from 'hooks/useConnector';

import { createCopier as copier } from 'blocks/factories/simple/Copier';

import { IPoint } from 'elements/Block/Connector';

interface ICopier {
  config: IConfig;
}

export const createCopier = ({ config }: ICopier) => copier({
  type: config.type,
  condition: (payload) => {
    const points = payload.target.points as IPoint[];

    const offset = [
      payload.position[0] - (payload.origin?.[0] ?? 0),
      payload.position[1] - (payload.origin?.[1] ?? 0),
    ];

    const vertices = extractPoints(points, payload.target.startFree, payload.target.endFree, payload.transition);

    if (!vertices[0].parent) {
      vertices[0].position = [
        vertices[0].position[0] + offset[0],
        vertices[0].position[1] + offset[1],
      ];
    }

    if (!vertices[vertices.length - 1].parent) {
      vertices[vertices.length - 1].position = [
        vertices[vertices.length - 1].position[0] + offset[0],
        vertices[vertices.length - 1].position[1] + offset[1],
      ];
    }

    payload.target = {
      ...payload.target,

      points: vertices,
    };

    payload.parent = '';
    payload.position[0] = 0;
    payload.position[1] = 0;

    return payload;
  },
});
