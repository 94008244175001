import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { IConnector } from './Cap';

export const Cap5: IConnector = {
  Start: () => null,
  End: ({ color, mask }: ICap) => (
    <>
      <circle cx="18" cy="8" r="5" fill={mask ? '#000' : 'transparent'} stroke={mask ? '#fff' : color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
      <path d="M14 8H22" stroke={mask ? '#fff' : color} fill="transparent" strokeWidth="1.8" strokeDasharray="100,0" />
      <path d="M18 4L18 12" stroke={mask ? '#fff' : color} fill="transparent" strokeWidth="1.8" strokeDasharray="100,0" />
    </>
  ),
};
