import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';

import { store } from 'store';

import { Loader } from 'components/Loader';
import { NotFound } from 'components/NotFound';

import { Alert } from 'elements/Alert';
import { Listener } from 'elements/Listener';

import Default from 'windows/Default';

import 'assets/css/app.css';
import 'assets/css/reset.css';
import 'assets/css/common.css';
import 'assets/css/primitives.css';
import 'assets/css/layouts.css';
import 'assets/css/media.css';

const appRoot = document.getElementById('app-root') as HTMLElement;
const root = ReactDOM.createRoot(appRoot);

const Qr = React.lazy(() => import('./windows/Qr'));
const SignIn = React.lazy(() => import('./windows/SignIn'));
const SignUp = React.lazy(() => import('./windows/SignUp'));
const Verification = React.lazy(() => import('./windows/Verification'));
const Restoration = React.lazy(() => import('./windows/Restoration'));
const RestorationSuccess = React.lazy(() => import('./windows/RestorationSuccess'));
const Resetting = React.lazy(() => import('./windows/Resetting'));
const Credentials = React.lazy(() => import('./windows/Credentials'));
const Home = React.lazy(() => import('./windows/Home'));
const Embed = React.lazy(() => import('./windows/Embed'));
const Diagram = React.lazy(() => import('./windows/Diagram'));
const Settings = React.lazy(() => import('./windows/Settings'));
const Verifying = React.lazy(() => import('./windows/Verifying'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Default />} />
          <Route path="/home/*" element={<Home />} />
          <Route path="/embed/:id/:ticket" element={<Embed />} />
          <Route path="/board/*" element={<Diagram />} />
          <Route path="/settings/*" element={<Settings />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/device" element={<Qr />} />
          <Route path="/device/:id" element={<Credentials />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verify" element={<Verification />} />
          <Route path="/verify/:token" element={<Verifying />} />
          <Route path="/restore" element={<Restoration />} />
          <Route path="/restore/:email" element={<RestorationSuccess />} />
          <Route path="/reset/:token" element={<Resetting />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
    <Alert />
    <Listener />
  </Provider>
);
