import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { Model } from 'blocks/factories/shape/Model';
import { createRender } from 'blocks/factories/simple/Render';
import { createPadding } from 'blocks/factories/simple/Padding';
import { createBlock, block } from 'blocks/factories/shape/Block';

import icon from './assets/icon.svg';

const { serialize } = require('svg-path-round-corners/dist/es6/serialize');
const { roundCorners } = require('svg-path-round-corners/dist/es6/index');

type IModel = Model<{}>;

export const config: IConfig = {
  title: 'Delay',
  search: 'flowchart shape delay',
  type: 'flowchart-shape-delay',
  group: ['Flowchart Shape'],
  memo: {
    key: 'flowchart-shape',
    styles: block.element.memo,
  },
  styles: block.element.styles,
};

const Render = createRender<IModel>({
  render: ({ width, height, element }) => {
    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - border, 0);
    const y = Math.max(height - border, 0);

    const a = Math.min(x - 6, y / 2.4) - offset;
    const b = a / 2.233555448;

    const points = [
      { c: 'M', x: offset, y: y },
      { c: 'L', x: offset, y: offset },
      { c: 'L', x: x - a, y: offset },
      { c: 'C', x1: x - b, y1: offset, x2: x, y2: b, x: x, y: y / 2 },
      { c: 'C', x1: x, y1: y - b, x2: x - b, y2: y, x: x - a, y: y },
      { c: 'Z' },
    ];

    return (
      <svg className="block-render" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path {...element.trigger} className="render-fill render-stroke render-hit" d={serialize(roundCorners(points, 6))} strokeLinejoin="round" strokeLinecap="round" strokeWidth={border} />
      </svg>
    );
  },
});

const Padding = createPadding<IModel>({
  size: [0, 1],
  padding: [1, 1, 0, 1],
  creator: ({ height }) => {
    const a = (14 / 64) * height - 6;

    return [0, 0, a, 0];
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  Render,
  Padding,
});

export const FlowchartShapeDelay : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: block.element.options,
    constraints: block.element.constraints,
    onCreate: block.creator(config, {
      width: 84,
      height: 64,
    }),
    onCopy: block.copier(config),
    title: config.title,
    size: [84, 64],
    icon,
    class: block.element.class,
  },
  button: block.button(config),
  tools: ['shape-flowchart', ...block.tools],
  toolbars: block.toolbars,
};
