import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import iconS from './assets/s.svg';
import iconM from './assets/m.svg';
import iconL from './assets/l.svg';

export const Tool = createTool('size', 'size', 'm', 'Size', 'Size', {
  s: iconS,
  m: iconM,
  l: iconL,
});

export const Size: ITool = {
  key: 'size',
  tool: Tool,
  options: ['only-edit'],
};
