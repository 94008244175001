import React from 'react';
import { deleteElements, getBlockContextLinks, getState, reparentWraps } from 'utils/store';
import { points } from 'utils/points';
import { blocks } from 'blocks';
import { ITool } from 'tools';

import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import icon from './assets/icon.svg';

const Help = () => (
  <>
    <div className="text-10">Delete (as wrapper)</div>
    <div className="text-9">This tool is a precise deletion tool within Exemplar that removes selected blocks without affecting any inner elements.</div>
    <div className="text-9">To make your workflow even more efficient, Exemplar offers a keyboard shortcut for triggering the Delete (as wrapper) tool. Simply press <b>CTRL</b> or <b>CMD</b> along with the <b>DELETE</b> or <b>BACKSPACE</b> key to instantly remove selected blocks.</div>
  </>
);

const Tool = () => {
  const dispatch = useStoreDispatch();

  const status = useStoreSelector((state) => {
    const selection = state.graphic.selection.elements;
    const links = state.diagram.links;

    const map = Object.fromEntries(selection.map((id) => [id, true]));

    return selection.every((id) => {
      const wrap = blocks[state.diagram.file[id]?.type]?.element.options.includes('element-wrap');
      const container = blocks[state.diagram.file[id]?.type]?.element.options.includes('element-container');
      const layout = blocks[state.diagram.file[id]?.type]?.element.options.includes('element-layout') || blocks[state.diagram.file[id]?.type]?.element.options.includes('element-wrap-grid');

      if (container) {
        return true;
      }

      return (
        links[id]?.every((child) => child === id || !(child in map))
        && (
          (wrap && !layout && state.diagram.childs[id]?.length > 0)
          || (layout && !state.diagram.file[id]?.parent)
        )
      );
    });
  });

  const onClick = () => {
    const selection = getState((state) => state.graphic.selection.elements);

    const elements = reparentWraps(selection);

    if (!elements) {
      return;
    }

    deleteElements(getBlockContextLinks(selection));
    dispatch(storeGraphic.setSelectionElements([]));
    dispatch(storeDiagram.setComputedProperties(elements));
  };

  if (!status) {
    return null;
  }

  return (
    <Hint message="Delete (as wrapper)" Help={Help} side="bottom" variant="default">
      <button type="button" className="button-icon v-1" onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt="Delete (as wrapper)" />
      </button>
    </Hint>
  );
};

export const DeleteWrap: ITool = {
  key: 'delete-wrap',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
