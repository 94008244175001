import { useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import { getState } from 'utils/store';
import { lines } from 'utils/lines';

import { ElementContext } from 'elements/Block/Element';

import { useProperty } from './useProperty';
import { useStoreSelector } from './useStore';
import { IPointControl } from './useConnector';

export const config = {
  padding: 6,
};

const setOffset = (visibility: boolean, vertices: IPointControl[], element: string, condition: () => { start: boolean, end: boolean } = () => ({ start: true, end: true })) => {
  const permission = condition();

  const start = {
    id: vertices[0].id,
    parent: vertices[0].parent,
    pivot: [
      vertices[1]._position[0] - vertices[0]._position[0],
      vertices[1]._position[1] - vertices[0]._position[1],
    ],
    side: vertices[0]._side,
    moving: vertices[0]._moving,
    active: permission.start && !!vertices[0].parent && vertices[0].type === 'parent',
  };

  const end = {
    id: vertices[vertices.length - 1].id,
    parent: vertices[vertices.length - 1].parent,
    pivot: [
      vertices[vertices.length - 2]._position[0] - vertices[vertices.length - 1]._position[0],
      vertices[vertices.length - 2]._position[1] - vertices[vertices.length - 1]._position[1],
    ],
    side: vertices[vertices.length - 1]._side,
    moving: vertices[vertices.length - 1]._moving,
    active: permission.end && !!vertices[vertices.length - 1].parent && vertices[vertices.length - 1].type === 'parent',
  };

  if (visibility && start.active && !start.moving) {
    lines.add({
      element,
      id: start.id,
      parent: start.parent,
      pivot: start.pivot,
      side: start.side,
      free: vertices[0]._free,
    });
  } else if (!start.moving) {
    lines.delete({
      element,
      id: start.id,
      parent: start.parent,
      side: start.side,
    });
  }

  if (visibility && end.active && !end.moving) {
    lines.add({
      element,
      id: end.id,
      parent: end.parent,
      pivot: end.pivot,
      side: end.side,
      free: vertices[vertices.length - 1]._free,
    });
  } else if (!end.moving) {
    lines.delete({
      element,
      id: end.id,
      parent: end.parent,
      side: end.side,
    });
  }
};

export const useConnectorPostprocessing = (points: IPointControl[], condition: () => { start: boolean, end: boolean } = () => ({ start: true, end: true })) => {
  const element = useContext(ElementContext);

  const status = useStoreSelector((state) => !!state.graphic.elements[element.id]);
  const closed = useStoreSelector((state) => !!state.graphic.options[element.id]?.closed);

  const render = useProperty({});
  const visibility = useProperty(status);

  useEffect(() => {
    visibility.set(status);
  }, [status]);

  useEffect(() => {
    const callback = () => {
      render.set({});
    };

    lines.render({
      element: element.id,
      id: points[0].id,
      callback,
    });

    lines.render({
      element: element.id,
      id: points[points.length - 1].id,
      callback,
    });

    return () => {
      lines.unrender({
        element: element.id,
        id: points[0].id,
      });

      lines.unrender({
        element: element.id,
        id: points[points.length - 1].id,
      });

      lines.delete({
        element: element.id,
        id: points[0].id,
        parent: points[0].parent,
        side: points[0]._side,
      });

      lines.delete({
        element: element.id,
        id: points[points.length - 1].id,
        parent: points[points.length - 1].parent,
        side: points[points.length - 1]._side,
      });
    };
  }, []);

  useLayoutEffect(() => {
    const options = getState((state) => state.graphic.options);

    const status = points.every(({ parent }) => {
      return !options[parent]?.closed;
    });

    if (!status) {
      return;
    }

    setOffset(visibility.value, points, element.id, condition);

    render.set({});
  }, [points, visibility.value, closed]);

  useLayoutEffect(() => {
    setOffset(visibility.value, points, element.id, condition);
  }, [closed]);

  return useMemo(() => {
    const vertices = points.map((point) => {
      const {
        _position,
        _parent,
        _moving,
        _side,
        _free,

        ...vertex
      } = point;

      vertex.position = _position;

      return vertex;
    });

    const start = lines.get({
      element: element.id,
      id: points[0].id,
      side: points[0]._side,
    });

    vertices[0].position = [
      vertices[0].position[0] + start[0] * config.padding,
      vertices[0].position[1] + start[1] * config.padding,
    ];

    const end = lines.get({
      element: element.id,
      id: points[points.length - 1].id,
      side: points[points.length - 1]._side,
    });

    vertices[vertices.length - 1].position = [
      vertices[vertices.length - 1].position[0] + end[0] * config.padding,
      vertices[vertices.length - 1].position[1] + end[1] * config.padding,
    ];

    return vertices;
  }, [points, render.value]);
};
