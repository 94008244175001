import React from 'react';

import { useAnimation } from 'hooks/useAnimation';

export const NotFound = () => {
  const animation = useAnimation('animation-scale');

  return (
    <div className="layout-alert">
      <svg className={animation.name} width={274} height={208} style={{ opacity: 0.3 }} viewBox="0 0 137 104" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M44.24 61.4H35.44V77H25.76V61.4H1.52V55.48L26.88 20.6H35.44V53.08H44.24V61.4ZM25.76 40.84L26.48 34.12H26.16L23.44 39.88L15.12 50.68L11.12 53.72L16.24 53.08H25.76V40.84ZM48.5906 48.92C48.5906 39.1067 50.2706 31.8267 53.6306 27.08C56.9906 22.28 61.8173 19.88 68.1106 19.88C74.8306 19.88 79.764 22.2533 82.9106 27C86.0573 31.6933 87.6306 39 87.6306 48.92C87.6306 58.7867 85.924 66.12 82.5106 70.92C79.1506 75.72 74.324 78.12 68.0306 78.12C61.364 78.12 56.4573 75.6133 53.3106 70.6C50.164 65.5333 48.5906 58.3067 48.5906 48.92ZM58.9906 48.92C58.9906 55.4267 59.684 60.4667 61.0706 64.04C62.5106 67.56 64.8573 69.32 68.1106 69.32C71.2573 69.32 73.5506 67.6933 74.9906 64.44C76.484 61.1867 77.2306 56.0133 77.2306 48.92C77.2306 42.4667 76.5373 37.48 75.1506 33.96C73.764 30.44 71.3906 28.68 68.0306 28.68C64.8306 28.68 62.5106 30.3333 61.0706 33.64C59.684 36.9467 58.9906 42.04 58.9906 48.92ZM135.021 61.4H126.221V77H116.541V61.4H92.3013V55.48L117.661 20.6H126.221V53.08H135.021V61.4ZM116.541 40.84L117.261 34.12H116.941L114.221 39.88L105.901 50.68L101.901 53.72L107.021 53.08H116.541V40.84Z" fill="#b7b7df" />
        <path d="M77.4124 47.6467C72.4813 46.7844 63.5189 46.7844 58.5878 47.6467C56.0142 48.0967 54.0967 50.0142 53.6467 52.5878C52.7844 57.5188 52.7844 66.4812 53.6467 71.4122C54.0967 73.9857 56.0142 75.9033 58.5878 76.3533C63.5188 77.2155 72.4812 77.2155 77.4123 76.3533C79.9858 75.9033 81.9033 73.9858 82.3533 71.4123C83.2156 66.4812 83.2156 57.5187 82.3533 52.5877C81.9033 50.0142 79.9858 48.0968 77.4124 47.6467Z" stroke="#b7b7df" strokeWidth="3" strokeLinejoin="round" />
        <path d="M55.1243 52.8461C55.4654 50.8953 56.8953 49.4654 58.8461 49.1243C63.6063 48.2919 72.3939 48.2919 77.154 49.1243C79.1047 49.4654 80.5346 50.8953 80.8757 52.846C81.7081 57.6061 81.7081 66.3937 80.8757 71.1539C80.5346 73.1047 79.1047 74.5346 77.1539 74.8757C72.3938 75.7081 63.6063 75.7081 58.8461 74.8757C56.8953 74.5346 55.4654 73.1047 55.1243 71.1539C54.2919 66.3937 54.2919 57.6063 55.1243 52.8461Z" fill="white" />
        <path d="M59.1494 58.4H66.6194V60.596H61.6154V63.53H66.1694V65.726H61.6154V68.804H66.7094V71H59.1494V58.4Z" fill="#b7b7df" />
        <path d="M70.5336 66.392L67.8336 62H70.6236L71.7936 63.926L72.2977 65.186L72.8377 63.926L74.0617 62H76.5997L73.8816 66.32L76.7796 71H74.0617L72.6756 68.84L72.1356 67.508L71.5597 68.84L70.1736 71H67.6177L70.5336 66.392Z" fill="#b7b7df" />
      </svg>
    </div>
  );
};
