import { useEffect, useRef } from 'react';
import { blocks } from 'blocks';
import { deleteElements, getState } from 'utils/store';
import { getFileDiff } from 'utils';

import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

import { useHotkey } from './useHotkey';
import { useProperty } from './useProperty';
import { useStoreDispatch, useStoreSelector } from './useStore';

interface ICommand {
  from: any,
  to: any,
}

export const skip = {
  elements: [] as string[],
};

export const useHistory = (active = false) => {
  const dispatch = useStoreDispatch();

  const component = useStoreSelector((state) => state.graphic.component.key);
  const creating = useStoreSelector((state) => !!state.graphic.drag || state.graphic.drop?.type === 'create');
  const draw = useStoreSelector((state) => !!state.graphic.draw || !!state.graphic.drop);

  const file = useStoreSelector((state) => state.diagram.file);
  const cloud = useStoreSelector((state) => state.diagram.cloud);
  const mode = useStoreSelector((state) => state.diagram.mode);

  const momento = useProperty<{[key:string]: any}>({});
  const modified = useProperty(false);

  const history = useProperty<{[key: string]: ICommand}[]>([]);
  const step = useProperty(0);
  const difference = useProperty<{[key: string]: {} | 'deleted'}>({});
  const save = useRef(() => {});
  const memorize = useRef(false);
  const loading = useRef(true);
  const memo = useRef<{[key: string]: ICommand[]}>({});

  useEffect(() => {
    momento.set(cloud);
  }, [cloud]);

  save.current = () => {
    if (Object.keys(difference.value).length === 0) {
      return;
    }

    const action: {[key: string]: ICommand} = {};

    Object.keys(difference.value).forEach((id) => {
      action[id] = {
        from: {},
        to: {},
      };

      if (!momento.value[id] || !file[id]) {
        action[id].from = momento.value[id] ?? {};
        action[id].to = file[id] ?? {};

        return;
      }

      ['type', 'loading', 'url', ...Object.keys(difference.value[id])].forEach((key) => {
        action[id].from[key] = momento.value[id]?.[key];
        action[id].to[key] = file[id]?.[key];
      });
    });

    Object.keys(action).forEach((id) => {
      if (action[id].from.loading || action[id].to.loading) {
        if (!(id in memo.current)) {
          memo.current[id] = [];
        }

        memo.current[id].push(action[id]);
      }

      if (action[id].to.loading === false) {
        memo.current[id]?.forEach((record) => {
          const from = { ...record.from };
          const to = { ...record.to };

          if (from.type) {
            if (from.loading) from.loading = false;
            if (!from.url) from.url = action[id].to.url;
          }

          if (to.type) {
            if (to.loading) to.loading = false;
            if (!to.url) to.url = action[id].to.url;
          }

          record.from = from;
          record.to = to;
        });

        delete memo.current[id];
      }
    });

    history.set((state) => {
      const value = [...state.slice(0, step.value), action];

      if (value.length <= 100) {
        step.set(value.length);
      } else {
        value.shift();
      }

      return value;
    });

    momento.set(file);
    difference.set({});
  };

  const onUndo = () => {
    if (step.value === 0 || component || mode !== 'edit' || memorize.current) {
      return;
    }

    const action = history.value[step.value - 1];

    Object.keys(action).forEach((id) => {
      const element = { ...action[id].from };

      Object.keys(action[id].to).forEach((key) => {
        if (!(key in element)) {
          element[key] = undefined;
        }
      });

      action[id].from = element;
    });

    modified.set(true);
    step.set((value) => value - 1);

    const file = getState((state) => state.diagram.file);
    const captured = getState((state) => state.diagram.captured);

    const deleted: string[] = [];
    const elements: string[] = [];

    Object.keys(action).forEach((id) => {
      if (captured[id]) {
        return;
      }

      if (!(id in file) && Object.values(action[id].to).findIndex((value) => !!value) >= 0) {
        return;
      }

      if (action[id].from.loading) {
        delete action[id].from.loading;
      }

      if (!action[id].from.url) {
        delete action[id].from.url;
      }

      if (Object.keys(action[id].from).length === 0 || !Object.values(action[id].from).find((value) => !!value)) {
        deleted.push(id);

        return;
      }

      elements.push(id);

      dispatch(storeDiagram.setElement({
        id,
        properties: { ...action[id].from },
        forced: true,
      }));
    });

    if (deleted.length > 0) deleteElements(deleted);
    if (elements.length > 0) dispatch(storeDiagram.setComputedProperties(elements));

    if (!draw) {
      dispatch(storeGraphic.setSelectionElements(Object.keys(action).filter((id) => !deleted.includes(id) && !blocks[action[id].from?.type]?.element.options.includes('element-wrapper'))));
    }
  };

  const onRedo = () => {
    if (step.value >= history.value.length || component || mode !== 'edit' || memorize.current) {
      return;
    }

    const action = history.value[step.value];

    Object.keys(action).forEach((id) => {
      const element = { ...action[id].to };

      Object.keys(action[id].from).forEach((key) => {
        if (!(key in element)) {
          element[key] = undefined;
        }
      });

      action[id].to = element;
    });

    modified.set(true);
    step.set((value) => value + 1);

    const file = getState((state) => state.diagram.file);
    const captured = getState((state) => state.diagram.captured);

    const deleted: string[] = [];
    const elements: string[] = [];

    Object.keys(action).forEach((id) => {
      if (captured[id]) {
        return;
      }

      if (!(id in file) && Object.values(action[id].from).findIndex((value) => !!value) >= 0) {
        return;
      }

      if (action[id].to.loading) {
        delete action[id].to.loading;
      }

      if (!action[id].to.url) {
        delete action[id].to.url;
      }

      if (Object.keys(action[id].to).length === 0 || !Object.values(action[id].to).find((value) => !!value)) {
        deleted.push(id);

        return;
      }

      elements.push(id);

      dispatch(storeDiagram.setElement({
        id,
        properties: { ...action[id].to },
        forced: true,
      }));
    });

    if (deleted.length > 0) deleteElements(deleted);
    if (elements.length > 0) dispatch(storeDiagram.setComputedProperties(elements));

    if (!draw) {
      dispatch(storeGraphic.setSelectionElements(Object.keys(action).filter((id) => !deleted.includes(id) && !blocks[action[id].to?.type]?.element.options.includes('element-wrapper'))));
    }
  };

  useHotkey({ code: 90, ctrl: true, prevent: true, shift: false, onPress: onUndo, isActive: () => !creating, deps: [step.value, history.value, !component, mode, creating, draw] });
  useHotkey({ code: 90, meta: true, prevent: true, shift: false, onPress: onUndo, isActive: () => !creating, deps: [step.value, history.value, !component, mode, creating, draw] });
  useHotkey({ code: 90, ctrl: true, prevent: true, shift: true, onPress: onRedo, isActive: () => !creating, deps: [step.value, history.value, !component, mode, creating, draw] });
  useHotkey({ code: 90, meta: true, prevent: true, shift: true, onPress: onRedo, isActive: () => !creating, deps: [step.value, history.value, !component, mode, creating, draw] });

  useEffect(() => {
    if (loading.current) {
      momento.set(file);

      const handler = setTimeout(() => {
        loading.current = false;
        history.set([]);
        step.set(0);
      }, 350);

      return () => {
        clearTimeout(handler);
      };
    }

    if (!active || modified.value) {
      momento.set(file);

      skip.elements = [];

      save.current();

      const handler = setTimeout(() => {
        modified.set(false);
      }, 150);

      return () => {
        clearTimeout(handler);
      };
    }

    const diff = getFileDiff(momento.value, file) ?? {};

    Object.keys(diff).forEach((id) => {
      if (skip.elements.includes(id)) {
        delete diff[id];
      }
    });

    skip.elements = [];

    difference.set((state) => {
      Object.keys(diff).forEach((id) => {
        if (!(id in state)) {
          state[id] = {};
        }

        if (state[id] === 'deleted' || diff[id] === 'deleted') {
          state[id] = 'deleted';
        } else {
          state[id] = {
            ...(state[id] as {}),
            ...(diff[id] as {}),
          };
        }
      });

      return state;
    });

    memorize.current = true;

    const handler = setTimeout(() => {
      save.current();
    }, 150);

    const cooldown = setTimeout(() => {
      memorize.current = false;
    }, 300);

    return () => {
      clearTimeout(handler);
      clearTimeout(cooldown);
    };
  }, [file]);
};
