import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { Model } from 'blocks/factories/shape/Model';
import { createRender } from 'blocks/factories/simple/Render';
import { createPadding } from 'blocks/factories/simple/Padding';
import { createBlock, block } from 'blocks/factories/shape/Block';

import icon from './assets/icon.svg';

const { serialize } = require('svg-path-round-corners/dist/es6/serialize');

type IModel = Model<{}>;

export const config: IConfig = {
  title: 'Stored Data',
  search: 'flowchart shape stored data',
  type: 'flowchart-shape-stored-data',
  group: ['Flowchart Shape'],
  memo: {
    key: 'flowchart-shape',
    styles: block.element.memo,
  },
  styles: block.element.styles,
};

const Render = createRender<IModel>({
  render: ({ width, height, element }) => {
    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - offset, 0);
    const y = Math.max(height - offset, 0);
    const b = 6;

    let a = (21 / 64) * y;

    if (a > Math.max(x * 0.68, x - 40)) {
      a = Math.max(x * 0.68, x - 40);
    }

    const points = [
      { c: 'M', x: x, y: y },
      { c: 'H', x: a / 2 + 2 },
      { c: 'C', x1: ((b / 21) * a), y1: y, x2: offset, y2: (49.6731 / 64) * y, x: offset, y: y / 2 },
      { c: 'C', x1: offset, y1: (14.3269 / 64) * y, x2: ((b / 21) * a), y2: offset, x: a / 2 + 2, y: offset },
      { c: 'L', x: x, y: offset },
      { c: 'C', x1: x - (b / 21) * a - 2, y1: offset, x2: x - a / 2 - 2, y2: (14.3269 / 64) * y, x: x - a / 2 - 2, y: y / 2 },
      { c: 'C', x1: x - a / 2 - 2, y1: (49.6731 / 64) * y, x2: x - (b / 21) * a - 2, y2: y, x: x, y: y },
    ];

    return (
      <svg className="block-render" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path {...element.trigger} className="render-fill render-stroke render-hit" d={serialize(points)} strokeLinejoin="round" strokeLinecap="round" strokeWidth={border} />
      </svg>
    );
  },
});

const Padding = createPadding<IModel>({
  size: [0, 1],
  padding: [1, 1, 0, 1],
  creator: ({ height }) => {
    const a = (21 / 64) * height;

    return [0, 0, a / 2 - 2, 0];
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  Render,
  Padding,
});

export const FlowchartShapeStoredData : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: block.element.options,
    constraints: block.element.constraints,
    onCreate: block.creator(config, {
      width: 64,
      height: 72,
    }),
    onCopy: block.copier(config),
    title: config.title,
    size: [64, 72],
    icon,
    class: block.element.class,
  },
  button: block.button(config),
  tools: ['shape-flowchart', ...block.tools],
  toolbars: block.toolbars,
};
