import { useContext, useEffect, useMemo } from 'react';
import { getPathSide, getVectorNormalized } from 'utils/graphic';
import { getState } from 'utils/store';
import { points } from 'utils/points';
import { blocks } from 'blocks';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';
import { useProperty } from './useProperty';
import { usePoints } from './usePoints';

export const config = {
  padding: 8,
  margin: 10,
};

export interface IPointControl extends IPoint {
  _position: number[];
  _parent: number[];
  _side: number[];

  _free: boolean;
  _moving: boolean;
}

export interface IMovedPoint {
  id: string,
  free: boolean,
  parent: string,
  position: number[],
  point: number[],
}

const getControlledPoints = (points: IPoint[], startFree: boolean, endFree: boolean) => {
  return points.map((point, index) => ({
    ...point,

    _position: point.position,
    _moving: false,
    _parent: [0, 0],
    _side: [0, 0],
    _free: (index === 0 && startFree) || (index === points.length - 1 && endFree),
  }));
};

const setOffsetPoints = (points: IPointControl[], offset: number[]) => {
  points.forEach((point) => {
    if (!point.parent && point.role === 'mayor') {
      point._position = [
        point.position[0] + offset[0],
        point.position[1] + offset[1],
      ];
    }
  });
};

const setConnectedPoint = (point: IPointControl) => {
  const file = getState((state) => state.diagram.file);

  const parent = points.elements[point.parent];

  if (!parent) {
    return;
  }

  const { size, position } = parent;

  const offset = [
    parent.offset[0],
    parent.offset[1],
  ];

  if (blocks[file[point.parent]?.type]?.element.options.includes('element-container') && (file[point.parent]?.elements ?? []).length > 0) {
    offset[0] = 0;
    offset[1] = 0;
  }

  point._position = [
    position[0] + offset[0],
    position[1] + offset[1],
  ];

  if (point.role === 'minor' && point.type === 'parent') {
    const side = getPathSide(point.position);
    const padding = [
      config.padding / 2,
      config.padding / 2,
    ];

    if (blocks[file[point.parent]?.type]?.element.options.includes('element-text')) {
      padding[0] = 2;
      padding[1] = 1;
    }

    point._side = side;
    point._position = [
      point._position[0] + size[0] / 2 + (parent.size[0] / 2) * side[0] + padding[0] * side[0],
      point._position[1] + size[1] / 2 + (parent.size[1] / 2) * side[1] + padding[1] * side[1],
    ];

    return;
  }

  if (point.type === 'join') {
    point._position = [
      point._position[0] + parent.size[0] * point.position[0],
      point._position[1] + parent.size[1] * point.position[1],
    ];

    return;
  }

  point._position = [
    point._position[0] + size[0] / 2,
    point._position[1] + size[1] / 2,
  ];
};

const setConnectedPoints = (vertices: IPointControl[]) => {
  vertices.forEach((point) => {
    setConnectedPoint(point);
  });
};

const getComputedPoints = (points: IPointControl[]) => {
  const computed = {
    pivots: [] as IPointControl[],
    points: [] as IPointControl[][],
  };

  points.forEach((point) => {
    if (point.role === 'minor' && point.type === 'default') {
      computed.points[computed.points.length - 1].push(point);
      return;
    }

    computed.pivots.push(point);
    computed.points.push([]);
  });

  return computed;
};

const setComputedPoins = (points: IPointControl[]) => {
  const computed = getComputedPoints(points);

  computed.pivots.slice(1).reduce((from, to, index) => {
    const pivot = [
      (from._position[0] + to._position[0]) / 2,
      (from._position[1] + to._position[1]) / 2,
    ];

    computed.points[index].forEach((point) => {
      point._position = [
        point.position[0] + pivot[0],
        point.position[1] + pivot[1],
      ];
    });

    return to;
  }, computed.pivots[0]);
};

const setParentPoints = (points: IPointControl[]) => {
  points.forEach((point) => {
    point._parent = point._position;
  });
};

const setPositionPoint = (points: IPointControl[], id: string, offset: number[]) => {
  const point = points.find((point) => point.id === id);

  if (!point) {
    return;
  }

  point._moving = true;
  point._position = [
    point._position[0] + offset[0],
    point._position[1] + offset[1],
  ];
};

const setSidePoint = (from: IPointControl, to: IPointControl, horizontal?: boolean, centered?: boolean) => {
  const options = getState((state) => state.graphic.options);

  const parent = points.elements[from.parent];

  if (!parent) {
    return;
  }

  if (Array.isArray(options[from.parent]['connector-centered']) && options[from.parent]['connector-centered'].includes(from.id)) {
    return;
  }

  const ratio = from._moving ? 1 : parent.size[0] / parent.size[1];
  const direction = [
    to._position[0] - from._parent[0],
    to._position[1] - from._parent[1],
  ];

  const side = getPathSide(centered ? [0, 0] : from.position, [
    horizontal === true ? 0 : (to._position[0] - from._parent[0]) / ratio,
    horizontal === false ? 0 : (to._position[1] - from._parent[1]),
  ]);

  if (horizontal === true && side[1] === 0) {
    side[0] = 0;
    side[1] = 1;
  }

  from._side = side;

  from._position = [
    from._parent[0] + (parent.size[0] / 2) * side[0] + config.padding * side[0],
    from._parent[1] + (parent.size[1] / 2) * side[1] + config.padding * side[1],
  ];

  if (from._free) {
    from._position = [
      side[0] !== 0 ? from._position[0] : Math.max(from._position[0] - parent.size[0] / 2 + config.margin, Math.min(from._position[0] + parent.size[0] / 2 - config.margin, to._position[0])),
      side[1] !== 0 ? from._position[1] : Math.max(from._position[1] - parent.size[1] / 2 + config.margin, Math.min(from._position[1] + parent.size[1] / 2 - config.margin, to._position[1])),
    ];
  } else if (centered) {
    const normal = getVectorNormalized(direction);

    from._position = [
      from._parent[0] + (parent.size[0] / 2) * side[0] + (normal[0] * ((parent.size[1] / 2) / (normal[1] || 1))) * side[1] + (config.padding / 1.4) * normal[0],
      from._parent[1] + (parent.size[1] / 2) * side[1] + (normal[1] * ((parent.size[0] / 2) / (normal[0] || 1))) * side[0] + (config.padding / 1.4) * normal[1],
    ];
  }
};

const setSidePoints = (vertices: IPointControl[], horizontal?: boolean, except?: string, centered?: boolean) => {
  const start = 0;
  const end = vertices.length - 1;

  if (vertices[start].parent && vertices[start].type === 'parent' && vertices[start].id !== except) {
    setSidePoint(vertices[start], vertices[start + 1], horizontal, centered);
  }

  if (vertices[end].parent && vertices[end].type === 'parent' && vertices[end].id !== except) {
    setSidePoint(vertices[end], vertices[end - 1], horizontal === undefined ? undefined : (end % 2 === 0 ? horizontal : !horizontal), centered);
  }
};

const getNextId = (points: IPoint[]) => {
  return points.reduce((id, point) => {
    return Math.max(id, +point.id + 1);
  }, +points[0].id).toString();
};

export const insertPoint = (points: IPoint[], index: number, position: number[], startFree: boolean, endFree: boolean) => {
  if (!points || index < 1 || index > points.length) {
    return points;
  }

  const vertices = points.map((point) => ({
    ...point,
  }));

  vertices.splice(index, 0, {
    id: getNextId(points),
    role: 'minor',
    type: 'default',
    position: [0, 0],
    parent: '',
  });

  const to = getControlledPoints(vertices, startFree, endFree);

  setConnectedPoints(to);

  const computed = getComputedPoints(to);

  computed.pivots.slice(1).reduce((from, to, i) => {
    const pivot = [
      (from._position[0] + to._position[0]) / 2,
      (from._position[1] + to._position[1]) / 2,
    ];

    computed.points[i].forEach((point) => {
      if (point.id === vertices[index].id) {
        vertices[index].position = [
          position[0] - pivot[0],
          position[1] - pivot[1],
        ];
      }
    });

    return to;
  }, computed.pivots[0]);

  return vertices;
};

const getOutputControlPoints = (points: IPoint[], startFree: boolean, endFree: boolean) => {
  const from = getControlledPoints(points, startFree, endFree);

  setConnectedPoints(from);
  setComputedPoins(from);
  setParentPoints(from);

  return from;
};

const buildPoints = (points: IPointControl[]) => {
  const computed = getComputedPoints(points);

  computed.pivots.slice(1).reduce((from, to, index) => {
    const pivot = [
      (from._position[0] + to._position[0]) / 2,
      (from._position[1] + to._position[1]) / 2,
    ];

    computed.points[index].forEach((point) => {
      point.position = [
        point._position[0] - pivot[0],
        point._position[1] - pivot[1],
      ];
    });

    return to;
  }, computed.pivots[0]);

  return points.map((point) => {
    const {
      _side,
      _moving,
      _parent,
      _position,
      _free,

      ...vertex
    } = point;

    if (vertex.role === 'mayor' && !vertex.parent) {
      vertex.position = _position;
    }

    return vertex;
  });
};

export const extractPoints = (points: IPoint[], startFree: boolean, endFree: boolean, transition: Record<string, string> = {}) => {
  const from = getOutputControlPoints(points, startFree, endFree);

  const to = from.map((point) => ({ ...point }));

  to.forEach((point) => {
    if (point.parent in transition) {
      point.parent = transition[point.parent];

      return;
    }

    if (point.parent) {
      point.parent = '';
      point.type = 'default';
    }
  });

  setParentPoints(to);

  return buildPoints(to);
};

export const rebuildPoints = (points: IPoint[], startFree: boolean, endFree: boolean, edit: IMovedPoint[]) => {
  const from = getOutputControlPoints(points, startFree, endFree);

  const to = from.map((point) => ({ ...point }));

  edit.forEach((edit) => {
    const match = to.find((point) => point.id === edit.id);

    if (!match) {
      return;
    }

    match.parent = edit.parent;
    match.position = edit.position;
    match.type = edit.parent ? (edit.free ? 'join' : 'parent') : 'default';
    match._position = edit.point;

    if (match.parent) {
      setConnectedPoint(match);
    }

    match._parent = match._position;
  });

  return buildPoints(to);
};

export const useConnector = (offset: { id: string; value: number[]}) => {
  const element = useContext(ElementContext);

  const drag = useStoreSelector((state) => !!state.graphic.drag);
  const vertices = useStoreSelector((state) => state.diagram.file[element.id].points as IPoint[]);
  const horizontal = useStoreSelector((state) => (state.diagram.file[element.id].style === 'elbow' ? state.diagram.file[element.id].horizontal : undefined));
  const startFree = useStoreSelector((state) => state.diagram.file[element.id].startFree);
  const endFree = useStoreSelector((state) => state.diagram.file[element.id].endFree);
  const style = useStoreSelector((state) => state.diagram.file[element.id].style);
  const centered = useStoreSelector((state) => blocks[state.diagram.file[element.id].type]?.element.options.includes('connector-centered'));

  const rerender = useStoreSelector((state) => !!(
    blocks[state.diagram.file[vertices[0].parent]?.type]?.element.options.includes('connector-double-render')
    || blocks[state.diagram.file[vertices[vertices.length - 1].parent]?.type]?.element.options.includes('connector-double-render')
  ));

  const wrap = usePoints();
  const connection = usePoints();

  const render = useProperty({});

  const connector = useMemo(() => {
    return {
      points: getControlledPoints(vertices, startFree, endFree),
      moveable: !!vertices.find((point) => {
        return !point.parent && point.role === 'mayor';
      }),
      parents: vertices.filter((point) => {
        return point.parent;
      }).map((point) => {
        const parent = {
          type: 'middle' as 'start' | 'middle' | 'end',
          id: point.parent,
        };

        if (vertices[0].id === point.id) {
          parent.type = 'start';
        }

        if (vertices[vertices.length - 1].id === point.id) {
          parent.type = 'end';
        }

        return parent;
      }),
    };
  }, [vertices, startFree, endFree, render.value]);

  // Listen to Element
  useEffect(() => {
    if (!connector.moveable) {
      return () => {};
    }

    wrap.subscribe(element.id);

    return () => {
      wrap.unsubscribe(element.id);
    };
  }, [connector.moveable]);

  // Rerender if Connector appear on element with Double-Render Option
  useEffect(() => {
    if (rerender) {
      render.set({});
    }
  }, []);

  // Listen to Parents
  useEffect(() => {
    connector.parents.forEach((parent) => {
      connection.subscribe(parent.id, parent.type);
    });

    return () => {
      connector.parents.forEach((parent) => {
        connection.unsubscribe(parent.id);
      });
    };
  }, [connector.parents]);

  // Update Points after Parent's Change
  useEffect(() => {
    if (!drag) {
      element.updatePoints();
    }
  }, [connection.value, drag]);

  const preprocessed = useMemo(() => {
    const shift = points.elements[element.id]?.offset ?? [0, 0];
    const vertices = connector.points.map((point) => ({ ...point }));

    setOffsetPoints(vertices, shift);
    setConnectedPoints(vertices);
    setComputedPoins(vertices);
    setParentPoints(vertices);
    setSidePoints(vertices, vertices.length > 2 ? horizontal : undefined, undefined, centered && style !== 'elbow');

    return vertices;
  }, [connector.points, horizontal, wrap.value, connection.value]);

  return useMemo(() => {
    const vertices = preprocessed.map((point) => ({ ...point }));

    if (offset.id) {
      setPositionPoint(vertices, offset.id, offset.value);
      setSidePoints(vertices, vertices.length > 2 ? horizontal : undefined, offset.id, centered && style !== 'elbow');
    }

    return vertices;
  }, [preprocessed, offset.id, offset.value[0], offset.value[1]]);
};
