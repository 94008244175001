import React from 'react';
import { ITool } from 'tools';
import { blocks } from 'blocks';
import { getMemoStyle } from 'utils/store';

import { storeDiagram } from 'store/diagram';

import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';
import { useMemoPropertiesSetter } from 'hooks/useMemoPropertiesSetter';

import { Hint } from 'components/Hint';

import { IPoint } from 'elements/Block/Connector';

import icon from './assets/icon.svg';

export const Tool = () => {
  const dispatch = useStoreDispatch();

  const selection = useStoreSelector((state) => state.graphic.selection.elements);
  const file = useStoreSelector((state) => state.diagram.file);
  const drop = useStoreSelector((state) => state.graphic.drop);

  const setMemo = useMemoPropertiesSetter();

  const onClick = () => {
    if (selection.length === 0) {
      const start = getMemoStyle(blocks[drop?.block ?? '_']?.element.memo ?? '', 'start') ?? 0;
      const end = getMemoStyle(blocks[drop?.block ?? '_']?.element.memo ?? '', 'end') ?? 1;

      setMemo(blocks[drop?.block ?? '_']?.element.memo ?? '', 'start', end);
      setMemo(blocks[drop?.block ?? '_']?.element.memo ?? '', 'end', start);

      return;
    }

    selection.forEach((id) => {
      const points = (file[id].points as IPoint[])?.map((point) => ({ ...point }));

      if (!points) {
        return;
      }

      const horizontal = points.length % 2 === 0 ? !file[id].horizontal : file[id].horizontal;

      dispatch(storeDiagram.setElement({
        id,
        properties: {
          horizontal,
          points: points.map((point: IPoint, index) => ({
            ...point,

            parent: points[points.length - 1 - index].parent,
            role: points[points.length - 1 - index].role,
            type: points[points.length - 1 - index].type,
            position: points[points.length - 1 - index].position,
          })),
        },
      }));
    });
  };

  return (
    <Hint message="Reverse" side="bottom" variant="default">
      <button type="button" className="button-icon v-1" onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt="Reverse" />
      </button>
    </Hint>
  );
};

export const ConnectorReverse: ITool = {
  key: 'connector-reverse',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
