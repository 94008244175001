import React, { memo, useLayoutEffect } from 'react';
import { IBlock, IConfig, blocks } from 'blocks';

import { useWrap } from 'hooks/useWrap';
import { useTextCreator } from 'hooks/useTextCreator';
import { useArrangementWrap } from 'hooks/useArrangementWrap';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';
import { useClickOnSelection } from 'hooks/useClickOnSelection';

import { Wrap } from 'elements/Block/Wrap';
import { Frame } from 'elements/Wrap/Frame';
import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';
import design from 'blocks/factories/assets/Simple.module.css';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Quote',
  search: 'information shape quote text',
  type: 'information-shape-quote',
  group: ['Information'],
  memo: {
    key: 'information-quote',
    styles: [
      'color',
      'size',
      'placing',
    ],
  },
  styles: [
    'color',
    'size',
    'placing',
  ],
};

interface IModel {
  color: string;
  size: 's' | 'm' | 'l';
  placing: 'left' | 'right';
  lockKey: string;
  lock: boolean;
}

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const wrap = useWrap();
    const arrangement = useArrangementWrap();
    const create = useTextCreator();

    const inline = blocks[wrap.type]?.element.options.includes('element-text');

    useEnterOnSelection({
      onPress: create('enter'),
      mode: 'edit',
    });

    useClickOnSelection({
      onClick: create('click'),
      mode: 'edit',
    });

    // Update Points on Property Change
    useLayoutEffect(() => {
      element.updatePoints();
    }, [properties.size, properties.placing]);

    return (
      <Shape
        classElement={style.element}
        classProperties={`${design[`color-${properties.color}`]} ${style[`size-${properties.size}`]} ${style[`placing-${properties.placing}`]}`.appendWhen(inline, style.inline)}
      >
        <Arrangement {...arrangement} />
        <Selection />
        <Frame />

        <div
          className={'block-content'.appendWhen(!wrap.status, style.empty)}
          {...(element.focused ? (!element.selected ? {} : { onMouseDown: element.trigger.onMouseDown }) : {})}
        >

          <div className="block-render" />
          <Wrap {...wrap.attributes} />

        </div>
      </Shape>
    );
  },
});

export const InformationShapeQuote : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-wrap', 'element-wrap-inline'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
        placing: [
          'top',
          'bottom',
          'top-left',
          'top-right',
          'left-top',
          'left-bottom',
          'bottom-left',
          'bottom-right',
          'right-top',
          'right-bottom',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        size: 'm',
        color: 'dark',
        placing: 'right',
        lockKey: '',
        lock: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [32, 32],
    icon,
    class: {
      title: 'Quote',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit', 'block-core'],
    type: 'drag',
  },
  tools: [
    'size',
    'placing',
    'color',
    'lock',
    'delete-wrap',
    'delete',
  ],
  toolbars: [

  ],
};
