import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { storeGraphic } from 'store/graphic';
import { getBlockContextContainers, getState } from 'utils/store';
import { Emitter } from 'utils/events';
import { points } from 'utils/points';
import { blocks } from 'blocks';

import { storeDiagram } from 'store/diagram';

import { CanvasDynamicContext } from 'elements/Canvas';

import { useElement } from './useElement';
import { useProperty } from './useProperty';
import { usePointPosition } from './usePointPosition';
import { useStoreDispatch, useStoreSelector } from './useStore';

export interface IWrap {
  ref: HTMLDivElement | null;
  link: (node: HTMLDivElement) => void;
}

export interface ITrigger {
  onMouseDown: ((e: React.MouseEvent) => void) | undefined;
  onClick: ((e: React.MouseEvent) => void) | undefined;
}

interface IControl {
  wrap: IWrap;
  trigger: ITrigger;
}

export const useBlockControl = (id: string, wrapped: boolean, selected: boolean): IControl => {
  const dispatch = useStoreDispatch();

  const component = useStoreSelector((state) => state.graphic.component.key);
  const drag = useStoreSelector((state) => state.graphic.drag);
  const drop = useStoreSelector((state) => !!state.graphic.drop);
  const mode = useStoreSelector((state) => state.diagram.mode);
  const copy = useStoreSelector((state) => state.graphic.copy);
  const type = useStoreSelector((state) => state.diagram.file[id].type);
  const lock = useStoreSelector((state) => state.diagram.captured[id] || state.diagram.file[id].lock);
  const mobile = useStoreSelector((state) => state.settings.mobile);
  const connector = useStoreSelector(() => blocks[type]?.element.options.includes('element-connector'));
  const container = useStoreSelector(() => blocks[type]?.element.options.includes('element-container'));
  const empty = useStoreSelector((state) => container && (state.diagram.file[id].elements ?? []).length === 0);
  const moving = useStoreSelector((state) => !container || (state.diagram.file[id]?.elements ?? []).every((id: string) => {
    return (
      !state.diagram.file[id]?.parent
      && !blocks[state.diagram.file[id]?.type]?.element.options.includes('element-container')
    );
  }));

  const canvas = useContext(CanvasDynamicContext);
  const wrap = useElement<HTMLDivElement>();

  const origin = useRef([0, 0]);
  const cursor = useRef([0, 0]);
  const click = useRef(false);
  const press = useRef(false);
  const hold = useRef(false);

  const render = useProperty({});

  const getPosition = usePointPosition();

  const onClick = (e: React.MouseEvent) => {
    if (e.button !== 0 || (!click.current && !mobile && mode === 'edit')) {
      return;
    }

    press.current = false;
    hold.current = false;

    render.set({});

    const selected = getState((state) => state.graphic.selection.elements.includes(id));
    const group = getState((state) => state.graphic.selection.group);

    if (!selected || group) {
      Emitter.emit('element-click', { id });
    }
  };

  const onDoubleClick = (e: React.MouseEvent) => {
    if (e.button !== 0 || mode !== 'edit' || mobile || !click.current || lock) {
      return;
    }

    const selected = getState((state) => state.graphic.selection.elements.includes(id));
    const group = getState((state) => state.graphic.selection.group);

    if (selected && !group) {
      Emitter.emit('element-click-selected', { id });
    }
  };

  const onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    if (e.button !== 0 || mobile || mode === 'view' || component) {
      return;
    }

    click.current = true;

    if ((wrapped && !selected) || lock) {
      return;
    }

    const parent = getState((state) => state.diagram.file[id].parent);
    const sticky = getState((state) => state.diagram.file[id].sticky);
    const point = [e.clientX, e.clientY];

    press.current = true;
    origin.current = point;
    cursor.current = point;

    render.set({});

    if (!parent && !sticky) {
      const elements = container ? getState((state) => state.diagram.file[id]?.elements) : undefined;

      Emitter.emit('element-capture', {
        element: id,
        elements,
      });
    }
  };

  const onMouseUp = (e: MouseEvent) => {
    if (hold.current && !copy) {
      Emitter.emit('element-relese', {
        element: id,
      });

      if (container) {
        const border = getState((state) => state.diagram.file[id].border);

        const offset = [
          (cursor.current[0] - origin.current[0]) / canvas.zoom,
          (cursor.current[1] - origin.current[1]) / canvas.zoom,
        ];

        dispatch(storeDiagram.setElement({
          id,
          properties: {
            border: [
              Number.isFinite(border?.[0]) ? border[0] + offset[0] : null,
              Number.isFinite(border?.[1]) ? border[1] + offset[1] : null,
              Number.isFinite(border?.[2]) ? border[2] + offset[0] : null,
              Number.isFinite(border?.[3]) ? border[3] + offset[1] : null,
            ],
          },
        }));

        if (moving && !empty) {
          points.update({
            id,
          });
        }
      }
    } else {
      Emitter.emit('element-relese', {
        element: id,
        cancel: true,
      });
    }

    click.current = true;
    hold.current = false;
    press.current = false;

    render.set({});
  };

  const onMouseMove = (e: MouseEvent) => {
    if (mobile || mode === 'view' || component || lock) {
      onMouseUp(e);

      return;
    }

    const drag = getState((state) => state.graphic.drag);

    const frame = {
      size: drag?.frame.size || [0, 0],
      offset: drag?.frame.offset || [0, 0],
    };

    if (click.current) {
      if (Math.abs(e.clientX - cursor.current[0]) < 2 && Math.abs(e.clientY - cursor.current[1]) < 2) {
        return;
      }

      const position = getPosition([e.clientX, e.clientY], [0, 0], [0, 0], [0, 0], true);
      const point = points.elements[id]?.position || [0, 0];

      frame.offset = [point[0] - position[0], point[1] - position[1]];
      frame.size = points.elements[id]?.size || [0, 0];

      dispatch(storeGraphic.setDrag({
        type: 'move',
        path: points.path(id),
        childs: getBlockContextContainers([id]),
        element: {
          id,
          type,
        },
        frame: {
          size: frame.size,
          offset: [
            (-frame.offset[0] - frame.size[0] / 2),
            (-frame.offset[1] - frame.size[1] / 2),
          ],
        },
      }));
    }

    cursor.current = getPosition([e.clientX, e.clientY], frame.size, frame.offset, [0, 0], click.current);

    cursor.current[0] = (cursor.current[0] + canvas.position[0] + frame.size[0] / 2 + frame.offset[0]) * canvas.zoom + canvas.screen[0] / 2;
    cursor.current[1] = (cursor.current[1] + canvas.position[1] + frame.size[1] / 2 + frame.offset[1]) * canvas.zoom + canvas.top + canvas.screen[1] / 2;

    if (click.current) {
      origin.current = cursor.current;

      click.current = false;
      hold.current = true;
    }

    render.set({});

    Emitter.emit('element-move', {
      offset: [
        (cursor.current[0] - origin.current[0]) / canvas.zoom,
        (cursor.current[1] - origin.current[1]) / canvas.zoom,
      ],
      element: id,
    });
  };

  useEffect(() => {
    if (!press.current || mobile || mode === 'view' || component || lock) {
      return () => {};
    }

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [press.current, mode === 'view' || component || lock, !copy]);

  useEffect(() => {
    if (!wrap.ref || mobile) {
      return;
    }

    if (!hold.current || copy || drag?.element.id !== id || mode === 'view' || component || lock) {
      wrap.ref.style.transform = '';
      wrap.ref.style.zIndex = '';

      if (!empty) {
        points.move({
          id,
          offset: [0, 0],
        });
      }

      if (connector) {
        points.update({
          id,
        });
      }

      return;
    }

    const offset = [
      (cursor.current[0] - origin.current[0]) / canvas.zoom,
      (cursor.current[1] - origin.current[1]) / canvas.zoom,
    ] as [number, number];

    if ((!container || empty) && !connector) {
      wrap.ref.style.transform = `translate3d(${offset[0]}px, ${offset[1]}px, 0)`;
      wrap.ref.style.zIndex = container ? '17' : '22';
    }

    points.move({
      id,
      offset,
      silent: !moving,
    });
  }, [(!wrap.ref || !hold.current || copy || drag?.element.id !== id || lock) ? undefined : render.value, container, connector, moving, empty]);

  useEffect(() => {
    return () => {
      const copy = getState((state) => state.graphic.copy);

      if (hold.current && !copy) {
        Emitter.emit('element-relese', {
          element: id,
        });
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (copy && hold.current) {
      points.move({
        id,
        offset: [0, 0],
      });
    }
  }, [copy]);

  return {
    wrap,
    trigger: useMemo(() => ({
      onDoubleClick: drop ? undefined : onDoubleClick,
      onClick: drop ? undefined : onClick,
      onMouseDown: (drop || mode === 'view' || component) ? undefined : onMouseDown,
    }), [drop, mode === 'view' || component, lock, wrapped && !selected]),
  };
};
