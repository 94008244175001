import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { storeDiagram } from 'store/diagram';
import { getState } from 'utils/store';
import { Emitter } from 'utils/events';
import { points } from 'utils/points';
import { blocks } from 'blocks';

import { useProperty } from './useProperty';
import { useStoreDispatch, useStoreSelector } from './useStore';

export const useBlockOffset = (id: string, wrap: HTMLDivElement | null) => {
  const dispatch = useStoreDispatch();

  const connector = useStoreSelector((state) => blocks[state.diagram.file[id].type]?.element.options.includes('element-connector'));
  const container = useStoreSelector((state) => blocks[state.diagram.file[id].type]?.element.options.includes('element-container'));
  const parent = useStoreSelector((state) => !!(state.diagram.file[id].parent || state.diagram.file[id].sticky || (container && !state.graphic.selection.elements.includes(id))));
  const empty = useStoreSelector((state) => container && (state.diagram.file[id].elements ?? []).length === 0);
  const dragging = useStoreSelector((state) => state.graphic.drag?.element.id);
  const lock = useStoreSelector((state) => state.diagram.captured[id] || state.diagram.file[id].lock);
  const moving = useStoreSelector((state) => !container || (state.diagram.file[id]?.elements ?? []).every((id: string) => {
    return (
      !state.diagram.file[id]?.parent
      && !blocks[state.diagram.file[id]?.type]?.element.options.includes('element-container')
    );
  }));

  const component = useStoreSelector((state) => state.graphic.component.key);
  const drag = useStoreSelector((state) => state.graphic.drag);
  const mode = useStoreSelector((state) => state.diagram.mode);
  const copy = useStoreSelector((state) => state.graphic.copy);

  const status = useRef(false);
  const offset = useRef([0, 0]);

  const render = useProperty({});
  const dragged = useProperty(dragging);

  const selected = useStoreSelector((state) => !!([...state.graphic.selection.elements, dragged.value ?? '']).find((element) => {
    if (element === id) {
      return true;
    }

    if (blocks[state.diagram.file[element]?.type]?.element.options.includes('element-container')) {
      return (state.diagram.file[element]?.elements ?? []).includes(id);
    }

    return false;
  }));

  useEffect(() => {
    dragged.set(dragging);
  }, [dragging]);

  useEffect(() => {
    if (parent || lock) {
      return () => {};
    }

    const onElementCapture = ({ element, elements }: { element: string, elements?: string[] }) => {
      if ((element === id || !selected) && !elements?.includes(id)) {
        return;
      }

      status.current = true;

      render.set({});
    };

    Emitter.on('element-capture', onElementCapture);

    return () => {
      Emitter.off('element-capture', onElementCapture);
    };
  }, [parent || lock, selected]);

  useEffect(() => {
    if (!selected || parent || lock) {
      status.current = false;
      offset.current = [0, 0];

      return () => {};
    }

    const onElementMove = ({ element, offset: vector }: { element: string, offset: [number, number] }) => {
      if (element === id || !status.current) {
        return;
      }

      offset.current = vector;

      render.set({});
    };

    const onElementRelese = ({ element, cancel }: { element: string, cancel?: boolean }) => {
      if (element === id || !status.current) {
        return;
      }

      const position = getState((state) => state.diagram.file[id].position);
      const border = getState((state) => state.diagram.file[id].border);
      const vertices = getState((state) => state.diagram.file[id].points);

      status.current = false;

      if (cancel) {
        return;
      }

      if (connector) {
        const dynamic = vertices?.find((point: any) => {
          return !point.parent && point.role === 'mayor';
        });

        if (!dynamic) {
          points.move({
            id,
            offset: [0, 0],
          });

          return;
        }

        dispatch(storeDiagram.setElement({
          id,
          properties: {
            position: [0, 0],
            parent: '',
            points: vertices?.map((point: any) => {
              if (!point.parent && point.role === 'mayor') {
                return {
                  ...point,

                  position: [
                    point.position[0] + offset.current[0],
                    point.position[1] + offset.current[1],
                  ],
                };
              }

              return point;
            }),
          },
        }));

        return;
      }

      if (container) {
        dispatch(storeDiagram.setElement({
          id,
          properties: {
            border: [
              Number.isFinite(border?.[0]) ? border[0] + offset.current[0] : null,
              Number.isFinite(border?.[1]) ? border[1] + offset.current[1] : null,
              Number.isFinite(border?.[2]) ? border[2] + offset.current[0] : null,
              Number.isFinite(border?.[3]) ? border[3] + offset.current[1] : null,
            ],
          },
        }));

        if (empty) {
          dispatch(storeDiagram.setElement({
            id,
            properties: {
              position: [
                position[0] + offset.current[0],
                position[1] + offset.current[1],
              ],
            },
          }));
        }

        return;
      }

      render.set({});

      dispatch(storeDiagram.setElement({
        id,
        properties: {
          position: [
            position[0] + offset.current[0],
            position[1] + offset.current[1],
          ],
        },
      }));
    };

    Emitter.on('element-move', onElementMove);
    Emitter.on('element-relese', onElementRelese);

    return () => {
      Emitter.off('element-move', onElementMove);
      Emitter.off('element-relese', onElementRelese);
    };
  }, [!selected || parent || lock]);

  useLayoutEffect(() => {
    if (!wrap) {
      return;
    }

    if (!status.current || copy || !drag || mode === 'view' || component || !selected || parent || lock) {
      wrap.style.transform = '';
      wrap.style.zIndex = '';

      if (!moving && !empty) {
        points.move({
          id,
          offset: [0, 0],
        });
      }

      if (container && !empty) {
        points.update({
          id,
        });
      }

      return;
    }

    if ((!container || empty) && !connector) {
      wrap.style.transform = `translate3d(${offset.current[0]}px, ${offset.current[1]}px, 0)`;
      wrap.style.zIndex = container ? '17' : '22';
    }

    points.move({
      id,
      offset: offset.current as [number, number],
      silent: !moving,
    });
  }, [(!status.current || copy || !drag || mode === 'view' || component || !selected || parent || lock) || render.value, container, connector, empty]);

  useLayoutEffect(() => {
    if (copy && status.current) {
      points.move({
        id,
        offset: [0, 0],
      });
    }
  }, [copy]);
};
