import React, { FC } from 'react';
import ReactQuill from 'react-quill';

import { useEditable } from 'hooks/useEditable';
import { useEditableBlur } from 'hooks/useEditableBlur';
import { useEditableBindings } from 'hooks/useEditableBindings';
import { useEditableComponent } from 'hooks/useEditableComponent';

import { IComponent, config } from '.';

import style from '../../assets/style.module.css';

export const Edit: FC<IComponent> = ({ value, property }) => {
  const bindings = useEditableBindings({
    key: config.key,
    shift: false,
  });

  const onBlur = useEditableBlur({
    property,
    key: config.key,
  });

  const input = useEditable({
    value,
    bindings,
    formats: [
      'paragraph',
      'bold',
      'link',
      'italic',
      'underline',
      'strike',
      'background',
    ],
    onBlur: (value) => onBlur(value, input),
  });

  useEditableComponent(input);

  return (
    <ReactQuill
      {...input.attributes}
      className={style.input}
      placeholder="Stereotype"
    />
  );
};
