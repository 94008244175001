import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import icon from './assets/icon.svg';

const Tool = createTool('cisco', '_', 'icon', 'Icon', 'Icon', {
  icon,
});

export const Cisco: ITool = {
  key: 'cisco',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
