import React, { useMemo } from 'react';
import { useComponentSkip } from './useComponentSkip';
import { IInput } from './useEditable';

interface IBindings {
  [key: string]: (input: IInput) => boolean | undefined;
}

interface IEditableBindings {
  key: string;
  shift?: boolean;
  onPrev?: (input: IInput) => boolean | void;
  onNext?: (input: IInput) => boolean | void;
  onShiftEnter?: (input: IInput) => boolean | void;
}

export const useEditableBindings = ({ key, shift, onPrev, onNext, onShiftEnter }: IEditableBindings) => {
  const skip = useComponentSkip(key);

  return useMemo((): IBindings => {
    const prev = (input: IInput) => {
      if (input.mistake.current || input.validate()) {
        input.blur();
        skip.prev();
      }
    };

    const next = (input: IInput) => {
      if (input.mistake.current || input.validate()) {
        input.blur();
        skip.next();
      }
    };

    const blur = (input: IInput) => {
      if (input.mistake.current && !input.validate()) {
        input.reset();
      }

      if (input.mistake.current || input.validate()) {
        input.blur();
      }
    };

    return {
      escape: (input: IInput) => {
        blur(input);

        return false;
      },
      enter: (input: IInput) => {
        if (shift) {
          return true;
        }

        blur(input);

        return false;
      },
      shiftEnter: (input: IInput) => {
        if (shift !== undefined && !shift) {
          return true;
        }

        if (onShiftEnter) {
          return !!onShiftEnter(input);
        }

        blur(input);

        return false;
      },
      prev: (input: IInput) => {
        if (onPrev) {
          return !!onPrev(input);
        }

        prev(input);

        return false;
      },
      next: (input: IInput) => {
        if (onNext) {
          return !!onNext(input);
        }

        next(input);

        return false;
      },
    };
  }, []);
};
