import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import iconCap0 from './assets/cap-0.svg';
import iconCap1 from './assets/cap-1.svg';
import iconCap2 from './assets/cap-2.svg';
import iconCap3 from './assets/cap-3.svg';
import iconCap4 from './assets/cap-4.svg';
import iconCap5 from './assets/cap-5.svg';
import iconCap6 from './assets/cap-6.svg';
import iconCap7 from './assets/cap-7.svg';
import iconCap8 from './assets/cap-8.svg';
import iconCap9 from './assets/cap-9.svg';
import iconCap10 from './assets/cap-10.svg';

export const Tool = createTool('connector-general-start', 'start', 0, 'Start', 'Start', {
  0: iconCap0,
  1: iconCap1,
  2: iconCap2,
  3: iconCap3,
  4: iconCap4,
  5: iconCap5,
  6: iconCap6,
  7: iconCap7,
  8: iconCap8,
  9: iconCap9,
  10: iconCap10,
});

export const ConnectorGeneralStart: ITool = {
  key: 'connector-general-start',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
