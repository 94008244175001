import { useContext } from 'react';
import { store } from 'store';

import { CanvasDynamicContext } from 'elements/Canvas';

export const usePointPosition = () => {
  const canvas = useContext(CanvasDynamicContext);

  return (cursor: number[], size: number[], offset: number[], shift: number[] = [0, 0], actual = false) => {
    const state = store.getState();

    const snap = state.graphic.snap;

    const frame = {
      position: canvas.position,
      screen: canvas.screen,
      zoom: canvas.zoom,
      top: canvas.top,
    };

    const position = [
      (cursor[0] - frame.screen[0] / 2) / frame.zoom - frame.position[0] - size[0] / 2 - offset[0],
      (cursor[1] - frame.top - frame.screen[1] / 2) / frame.zoom - frame.position[1] - size[1] / 2 - offset[1],
    ];

    if (snap && !actual) {
      position[0] = Math.round((position[0] - 24 + 10 + shift[0]) / 24) * 24 + 24 - 10 - shift[0];
      position[1] = Math.round((position[1] - 24 + 10 + shift[1]) / 24) * 24 + 24 - 10 - shift[1];
    }

    return position;
  };
};
