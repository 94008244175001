import React, { memo, useLayoutEffect } from 'react';
import { Emitter } from 'utils/events';
import { points as lines } from 'utils/points';
import { IConfig } from 'blocks';

import { useConnector } from 'hooks/useConnector';
import { useStoreSelector } from 'hooks/useStore';
import { useConnectorStyle } from 'hooks/useConnectorStyle';
import { IMask, useConnectorMasks } from 'hooks/useConnectorMasks';
import { IElbows, useConnectorElbow } from 'hooks/useConnectorElbow';
import { ILevers, useConnectorSmooth } from 'hooks/useConnectorSmooth';
import { ICap, useConnectorCap } from 'hooks/useConnectorCap';
import { useConnectorStroke } from 'hooks/useConnectorStroke';
import { IPicture, useConnectorPicture } from 'hooks/useConnectorPicture';
import { IVertex, useConnectorCreator } from 'hooks/useConnectorCreator';
import { useConnectorDefault } from 'hooks/useConnectorDefault';
import { IResizer, useResizerConnector } from 'hooks/useResizerConnector';
import { useResizerConnectorCreator } from 'hooks/useResizerConnectorCreator';
import { useConnectorPostprocessing } from 'hooks/useConnectorPostprocessing';

import design from 'blocks/factories/assets/Simple.module.css';
import { createBlock as block } from 'blocks/factories/simple/Block';

import { Frame } from 'elements/Wrap/Frame';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Connector, IPoint } from 'elements/Block/Connector';
import { IElementContext } from 'elements/Block/Element';

import { IModel } from './Model';

import style from '../assets/Connector.module.css';

interface IFlow<T extends IModel> {
  properties: Extract<T & { parent: string; position: [number, number] }, Record<string, any>>;
  element: IElementContext;
  resizer: IResizer,
}

interface IBlock<T extends IModel> {
  config: IConfig;
  colors?: {[key: string]: string};
  caps: {
    Start: Record<number, React.ComponentType<ICap>>,
    End: Record<number, React.ComponentType<ICap>>,
  };
  flow?: (payload: IFlow<T>) => {
    points: IPoint[];
    dots: number[][];
    elbows: IElbows;
    levers: ILevers;
    picture: IPicture;
    path: string;
    stroke: Record<string, string | number>;
    creator: {
      attributes: {};
    };
    masks: IMask[];
    vertices: IVertex[];
  }
}

const preferences = {
  color: {
    dark: '#3A3D4E',
    yellow: '#FFCF35',
    orange: '#FF935C',
    red: '#FD3063',
    green: '#68CC95',
    sky: '#72A5F1',
    blue: '#5474D1',
    purple: '#AE72DE',
    grey: '#CACADE',
  },
  flow: ({ resizer }: IFlow<IModel>) => {
    const connector = useConnector(resizer.offset);
    const points = useConnectorPostprocessing(connector);

    const dots = useConnectorDefault(points);
    const elbows = useConnectorElbow(points, connector);
    const levers = useConnectorSmooth(points, connector);

    const picture = useConnectorPicture(points, levers.handles);
    const path = useConnectorStyle(elbows.points ?? dots, levers.handles, picture.position, picture.padding);

    const stroke = useConnectorStroke();
    const creator = useResizerConnectorCreator(elbows.axis);
    const masks = useConnectorMasks(connector, picture.padding);
    const vertices = useConnectorCreator(points, elbows.points ?? dots, levers.points);

    return {
      points,
      dots,
      elbows,
      levers,
      picture,
      path,
      stroke,
      creator,
      masks,
      vertices,
    };
  },
};

export const createBlock = <T extends IModel, > ({
  config,
  colors = preferences.color,
  caps,
  flow = preferences.flow,
}: IBlock<T>) => block<T>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const selecting = useStoreSelector((state) => state.graphic.selection.elements.length > 1);

    const resizer = useResizerConnector();

    const {
      points,
      dots,
      elbows,
      levers,
      picture,
      path,
      stroke,
      creator,
      masks,
      vertices,
    } = flow({
      resizer,
      properties,
      element,
    });

    const Cap = useConnectorCap(caps.Start, caps.End);

    useLayoutEffect(() => {
      element.updatePoints();

      setTimeout(() => {
        element.updatePoints();

        Emitter.emit('set-bounds');
      }, 5);
    }, [properties.points, properties.style]);

    useLayoutEffect(() => {
      lines.draw({
        id: element.id,
      });
    }, [picture]);

    return (
      <Connector
        path={elbows.points ?? levers.points?.flat(1) ?? dots}
        position={picture.position}
        classElement={style.element}
        classProperties={`${design[`color-${properties.color}`]}`}
      >
        <div className="block-render" style={picture.margin}>
          <svg width={picture.width} height={picture.height} viewBox={`0 0 ${picture.width} ${picture.height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <marker id={`start.${element.id}`} markerWidth="12" markerHeight="8" viewBox="0 0 24 16" refX="1" refY="8" orient="auto" markerUnits="strokeWidth">
                <Cap.Start color={colors[properties.color]} />
              </marker>
              <marker id={`end.${element.id}`} markerWidth="12" markerHeight="8" viewBox="0 0 24 16" refX="23" refY="8" orient="auto" markerUnits="strokeWidth">
                <Cap.End color={colors[properties.color]} />
              </marker>
              <marker id={`mask.start.${element.id}`} markerWidth="12" markerHeight="8" viewBox="0 0 24 16" refX="1" refY="8" orient="auto" markerUnits="strokeWidth">
                <Cap.Start color={colors[properties.color]} mask />
              </marker>
              <marker id={`mask.end.${element.id}`} markerWidth="12" markerHeight="8" viewBox="0 0 24 16" refX="23" refY="8" orient="auto" markerUnits="strokeWidth">
                <Cap.End color={colors[properties.color]} mask />
              </marker>
              <mask id={`mask.${element.id}`} maskUnits="userSpaceOnUse">
                <rect x="0" y="0" width={picture.width} height={picture.height} fill="#fff" />
                <path d={path} strokeWidth={stroke.strokeWidth} fill="none" stroke="none" markerStart={`url(#mask.start.${element.id})`} markerEnd={`url(#mask.end.${element.id})`} />
                {masks.map((mask) => (
                  <rect rx="6" key={mask.id} x={mask.position[0] - picture.position[0]} y={mask.position[1] - picture.position[1]} width={mask.size[0]} height={mask.size[1]} fill="#000" />
                ))}
              </mask>
            </defs>
            <path d={path} strokeLinejoin="round" strokeLinecap="round" stroke={colors[properties.color]} markerStart={`url(#start.${element.id})`} markerEnd={`url(#end.${element.id})`} mask={`url(#mask.${element.id})`} {...stroke} />
          </svg>
        </div>
        <div className="block-render" style={picture.margin}>
          <svg width={picture.width} height={picture.height} viewBox={`0 0 ${picture.width} ${picture.height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d={path} strokeWidth={20} stroke="none" {...element.trigger} className="render-hit-stroke" />
          </svg>
        </div>

        <Selection status={selecting || properties.lock} />
        <Frame disabled={!element.selected || properties.lock || !selecting} />
        <Resizer.Controller {...resizer.attributes} status={resizer.visibility && !selecting} points={points} position={picture.position} />
        <Resizer.Creator {...creator.attributes} status={resizer.visibility && !selecting && !resizer.status} points={vertices} position={picture.position} />

      </Connector>
    );
  },
});
