import { useContext } from 'react';
import { getCacheProperty } from 'utils/store';

import { ElementContext } from 'elements/Block/Element';

import { useOption } from './useOption';
import { useStoreSelector } from './useStore';

export const useClosed = (joins: string[]) => {
  const element = useContext(ElementContext);

  const closed = useStoreSelector((state) => {
    return joins.length > 0 && joins.every((id) => {
      const collapsed = getCacheProperty(state, id, 'collapsed') ?? false;
      const collapsable = !state.graphic.options[id]?.collapsed;
      const closed = state.graphic.options[id]?.closed;

      return closed || (collapsed && collapsable);
    });
  });

  useOption({
    id: element.id,
    key: 'closed',
    value: closed,
    deps: [closed],
  });

  return closed;
};
