import React, { useContext } from 'react';
import { blocks } from 'blocks';

import { withCondition } from 'hocs/core/withCondition';

import { useStoreSelector } from 'hooks/useStore';

import { ElementContext } from 'elements/Block/Element';

export const Frame = withCondition(() => {
  const element = useContext(ElementContext);

  const cancel = useStoreSelector((state) => !!state.graphic.drop || blocks[state.graphic.drag?.element.type ?? '_']?.element.options.includes('element-sticker'));
  const status = useStoreSelector((state) => !!(state.graphic.layout.status || state.graphic.drag));
  const forced = useStoreSelector((state) => status && state.graphic.layout.key === element.id);

  if (cancel || (!status && !element.selected)) {
    return null;
  }

  return (
    <div
      className={
        'block-layout'
          .appendWhen(forced, 'hover')
          .appendWhen(element.selected, 'selected')
      }
      {...element.trigger}
    />
  );
}, () => {
  return useStoreSelector((state) => state.diagram.mode === 'edit');
});
