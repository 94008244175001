import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { IConnector } from './Cap';

export const Cap9: IConnector = {
  Start: ({ color, mask }: ICap) => (
    <circle cx="4" cy="4" r="4" transform="matrix(-1 0 0 1 9 4)" fill={mask ? '#000' : 'transparent'} stroke={mask ? '#fff' : color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
  End: ({ color, mask }: ICap) => (
    <circle cx="19" cy="8" r="4" fill={mask ? '#000' : 'transparent'} stroke={mask ? '#fff' : color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
};
