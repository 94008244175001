import { useMemo } from 'react';
import { blocks } from 'blocks';
import { points } from 'utils/points';
import { getState } from 'utils/store';

import { IPointControl } from './useConnector';

export interface IMask {
  id: string;
  position: number[];
  size: number[];
}

export const config = {
  padding: 4,
};

export const useConnectorMasks = (vertices: IPointControl[], margin: number[]) => {
  return useMemo(() => {
    const file = getState((state) => state.diagram.file);

    const masks = new Set();

    return vertices.filter((point) => {
      return (
        point.parent
        && point.role === 'minor'
        && point.type === 'parent'
        && !blocks[file[point.parent]?.type]?.element.options.includes('element-container')
      );
    }).map((point) => {
      const element = points.elements[point.parent];

      if (!element) {
        return undefined;
      }

      const padding = [
        config.padding,
        config.padding,
      ];

      if (blocks[file[point.parent]?.type]?.element.options.includes('element-text')) {
        padding[0] = 0;
        padding[1] = -config.padding / 2;
      } else if (point._side[0] !== 0 || point._side[1] !== 0) {
        padding[0] = 0;
        padding[1] = 0;
      }

      return {
        id: point.parent,
        position: [
          element.position[0] + element.offset[0] + margin[0] - padding[0],
          element.position[1] + element.offset[1] + margin[1] - padding[1],
        ],
        size: [
          element.size[0] + padding[0] * 2,
          element.size[1] + padding[1] * 2,
        ],
      };
    }).filter((mask) => {
      if (!mask || masks.has(mask.id)) {
        return false;
      }

      masks.add(mask.id);

      return true;
    }) as IMask[];
  }, [vertices]);
};
