import { blocks } from 'blocks';

import { useStoreSelector } from './useStore';

export const useConstraintStatus = (property: string) => {
  return useStoreSelector((state) => {
    return state.graphic.selection.elements.every((id) => {
      return state.graphic.options[id]?.[property] !== 'disable' && !blocks[state.diagram.file[id]?.type]?.element.constraints.disable?.[property];
    });
  });
};
