import React, { useContext } from 'react';
import { AppDispatch } from 'store';

import { IMode } from 'store/diagram';

import { ElementContext } from 'elements/Block/Element';

import { useHotkey } from './useHotkey';
import { useStoreDispatch, useStoreSelector } from './useStore';

interface IEnterOnSelection {
  onPress: (dispatch: AppDispatch) => void;
  mode?: IMode;
  deps?: React.DependencyList;
}

export const useEnterOnSelection = ({ onPress, mode, deps = [] }: IEnterOnSelection) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  const active = useStoreSelector((state) => !(state.diagram.captured[element.id] || state.diagram.file[element.id]?.lock || state.graphic.options[element.id]?.invisible) && (!mode || state.diagram.mode === mode) && state.graphic.selection.elements.length === 1 && state.graphic.selection.elements[0] === element.id && !state.graphic.component.key && !state.graphic.drag && !state.graphic.drop && !state.graphic.layout.key && !state.graphic.copy);

  useHotkey({
    key: 'Enter',
    shift: false,
    alt: false,
    meta: false,
    ctrl: false,
    isActive: () => active,
    onPress: () => onPress(dispatch),
    deps: [active, ...deps],
    prevent: true,
  });
};
