import { ITool } from 'tools';
import { createTool } from 'tools/ToolBasic';

import { storeDiagram } from 'store/diagram';

import icon from './assets/icon.svg';

const Tool = createTool({
  key: 'random',
  def: false,
  title: 'Regenerate',
  alt: 'Regenerate',
  icon,
  getActive: () => false,
  onClick: (selection, dispatch) => {
    selection.forEach((id) => {
      dispatch(storeDiagram.setElement({
        id,
        properties: {
          random: Math.random(),
        },
      }));
    });
  },
});

export const Random: ITool = {
  key: 'random',
  tool: Tool,
  options: ['only-edit'],
};
