import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import iconElbow from './assets/elbow.svg';
import iconSmooth from './assets/smooth.svg';
import iconDefault from './assets/default.svg';

export const Tool = createTool('line-style', 'style', 'default', 'Type', 'Type', {
  elbow: iconElbow,
  smooth: iconSmooth,
  default: iconDefault,
});

export const LineStyle: ITool = {
  key: 'line-style',
  tool: Tool,
  options: ['only-edit'],
};
