import { storage as localStorage } from 'utils/storage';

export interface ISession {
  access: string;
  refresh: string;
}

export interface IUser {
  id: string;
  email: string;
}

const session: ISession = {
  access: '',
  refresh: '',
};

const user: IUser = {
  id: '',
  email: '',
};

const storage = localStorage.getItem('session');

if (storage) {
  const payload = JSON.parse(storage);

  session.access = payload.access;
  session.refresh = payload.refresh;
}

export default {
  getSession: () => {
    return session;
  },

  getUser: () => {
    return user;
  },

  setSession: (payload: ISession) => {
    session.access = payload.access;
    session.refresh = payload.refresh;

    localStorage.setItem('session', JSON.stringify({
      access: payload.access,
      refresh: payload.refresh,
    }));
  },

  removeSession: () => {
    session.access = '';
    session.refresh = '';

    localStorage.removeItem('session');
  },

  setUser: (payload: IUser) => {
    user.id = payload.id;
    user.email = payload.email;
  },

  removeUser: () => {
    user.id = '';
    user.email = '';
  },
};
