import { ICopy } from 'blocks';
import { store } from 'store';

import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

interface ICopierConfig {
  type: string;
  component?: string;
  condition?: (payload: ICopy) => ICopy;
}

export const createCopier = (config: ICopierConfig) => {
  return (payload: ICopy) => {
    const { file, transition, target, position, parent, preventComponentStatus, auto } = config.condition?.(payload) ?? payload;

    const id = transition[target.id];

    file[id] = {
      ...target,

      id,
      type: config.type,
      position: auto ? target.position : position,
      parent,
    };

    store.dispatch(storeDiagram.addElement({
      element: file[id],
    }));

    if (!auto) {
      store.dispatch(storeGraphic.setSelectionElements([id]));

      if (config.component && auto === undefined) {
        store.dispatch(storeGraphic.setComponentKey(config.component));

        if (preventComponentStatus) {
          store.dispatch(storeGraphic.setComponentPreventStatus());
        }
      }
    }

    return id;
  };
};
