import { ITool } from 'tools';
import { createTool } from 'tools/ToolLink';

import icon from './assets/icon.svg';

const Tool = createTool('attributes', 'Attributes', 'Attributes', icon);

export const LinkAttributes: ITool = {
  key: 'link-attributes',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
