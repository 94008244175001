import React from 'react';
import { ITool } from 'tools';

import { storeCache } from 'store/cache';
import { storeDiagram } from 'store/diagram';

import { useCacheProperty } from 'hooks/useCacheProperty';
import { useConstraintStatus } from 'hooks/useConstraintStatus';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import icon from './assets/icon.svg';

const Tool = () => {
  const dispatch = useStoreDispatch();

  const selection = useStoreSelector((state) => state.graphic.selection.elements);
  const diagram = useStoreSelector((state) => state.diagram.diagram);
  const mode = useStoreSelector((state) => state.diagram.mode);

  const active = useConstraintStatus('collapsed');
  const value = useCacheProperty('collapsed', selection[0]);

  const onClick = () => {
    selection.forEach((id) => {
      if (mode === 'edit') {
        dispatch(storeDiagram.setElement({
          id,
          properties: {
            collapsed: !value,
          },
        }));
      }

      dispatch(storeCache.setElement({
        id,
        diagram: diagram.id,
        properties: {
          collapsed: !value,
        },
      }));
    });
  };

  if (!active) {
    return null;
  }

  return (
    <Hint message="Collapsed" side="bottom" variant="default">
      <button type="button" className={'button-icon v-1'.appendWhen(value, 'active')} onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt="Collapsed" />
      </button>
    </Hint>
  );
};

export const Collapsed: ITool = {
  key: 'collapsed',
  tool: Tool,
  options: [],
};
