import { getState, setMemoizedStyles } from 'utils/store';

import { storeCache } from 'store/cache';

import { useProperty } from './useProperty';
import { useStoreDispatch, useStoreSelector } from './useStore';

export type IMemoPropertiesSetter = <T>(element: string | undefined, property: string, value: T) => void;

export const useMemoPropertiesSetter = () : IMemoPropertiesSetter => {
  const dispatch = useStoreDispatch();

  const id = useStoreSelector((state) => state.diagram.diagram.id);

  const render = useProperty({});

  return <T> (element: string | undefined, property: string, value: T) => {
    if (!element) {
      return;
    }

    const group = getState((state) => state.cache.groups[id]?.[element]) ?? {};

    dispatch(storeCache.setGroup({
      id,
      data: {
        [element]: {
          ...group,

          [property]: value,
        },
      },
    }));

    setMemoizedStyles(property, value);

    render.set({});
  };
};
