import { useEffect } from 'react';
import { storeGraphic } from 'store/graphic';

import { useStoreDispatch, useStoreSelector } from './useStore';
import { useElement } from './useElement';

export const useLayout = (id: string, active = false) => {
  const dispatch = useStoreDispatch();

  const element = useElement<HTMLDivElement>();

  const deactivated = useStoreSelector((state) => !state.graphic.copy || !state.graphic.layout.status);

  const onMouseEnter = (e: any) => {
    if (!active) {
      return;
    }

    e.currentTarget.classList.add('hover');
    dispatch(storeGraphic.setLayoutKey(id));
  };

  const onMouseLeave = (e: any) => {
    e.currentTarget.classList.remove('hover');
    dispatch(storeGraphic.setLayoutKey(''));
  };

  useEffect(() => {
    if (element.ref && deactivated) {
      element.ref.classList.remove('hover');
    }
  }, [deactivated]);

  return {
    ref: element.link,
    onMouseEnter,
    onMouseLeave,
  };
};
