import { useCallback, useMemo } from 'react';
import { useProperty } from './useProperty';

export interface IElement<T> {
  ref: T | null;
  link: (node: T) => void;
}

export const useElement = <T> (): IElement<T> => {
  const ref = useProperty<T | null>(null);
  const link = useCallback((node: T) => {
    ref.set(node);
  }, []);

  return useMemo(() => ({
    ref: ref.value,
    link,
  }), [ref.value, link]);
};
