import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { useResizer } from 'hooks/useResizer';
import { useArrangementElement } from 'hooks/useArrangementElement';

import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';
import design from 'blocks/factories/assets/Simple.module.css';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Fork',
  search: 'fork uml unified modeling language',
  type: 'uml-activity-fork',
  group: ['UML'],
  memo: {
    key: 'uml-activity-shape',
    styles: [
      'color',
    ],
  },
  styles: [
    'color',
  ],
};

interface IModel {
  color: string;
  width?: number;
  realWidth?: number;
  vertical: boolean;
  lockKey: string;
  lock: boolean;
}

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const arrangement = useArrangementElement({
      prevent: [
        'tl',
        'tr',
        'bl',
        'br',
        'cl',
        'ct',
        'cr',
        'cb',
      ],
    });

    const resier = useResizer<HTMLImageElement>({
      strict: true,
      realWidth: properties.realWidth,
      minWidth: 16,
    });

    return (
      <Shape
        classElement={style.element}
        classProperties={`${design[`color-${properties.color}`]}`}
      >
        <Arrangement {...arrangement} />

        <div className={'block-render'.append(style.render)} />
        <Selection status={!resier.status} />

        <div
          className={'block-content'.appendWhen(properties.vertical, style.vertical)}
          {...element.trigger}
          {...resier.wrap}
        />

        <Resizer.Width {...resier.attributes} status={resier.visibility} />
      </Shape>
    );
  },
});

export const UMLActivityFork : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['container-title-none'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        color: 'dark',
        width: 64,
        realWidth: 64,
        vertical: false,
        lockKey: '',
        lock: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [64, 8],
    icon,
    class: {
      title: 'Fork',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [
    'vertical',
    'color',
    'lock',
    'delete',
  ],
  toolbars: [],
};
