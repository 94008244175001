import React from 'react';
import { ITool } from 'tools';

import { storeDiagram } from 'store/diagram';

import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import icon from './assets/icon.svg';

const Tool = () => {
  const dispatch = useStoreDispatch();

  const selection = useStoreSelector((state) => state.graphic.selection.elements);
  const element = useStoreSelector((state) => state.diagram.file[selection[0]]);

  const shown = useStoreSelector((state) => {
    return selection.every((id) => {
      return state.diagram.file[id].points[0]?.parent;
    });
  });

  const value = element.freeFinish ?? false;

  const onClick = () => {
    selection.forEach((id) => {
      dispatch(storeDiagram.setElement({
        id,
        properties: { freeFinish: !value },
      }));
    });
  };

  if (!shown) {
    return null;
  }

  return (
    <Hint message="Center end" side="bottom" variant="default">
      <button type="button" className={`button-icon v-1${!value ? ' active' : ''}`} onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt="Center end" />
      </button>
    </Hint>
  );
};

export const LinePointCenteredFinish: ITool = {
  key: 'line-point-center-finish',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
