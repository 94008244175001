import { DependencyList, useEffect } from 'react';

import { storeGraphic } from 'store/graphic';

import { useStoreDispatch } from './useStore';

interface IOption {
  id: string;
  key: string;
  value: any;
  deps?: DependencyList;
}

export const useOption = ({ id, key, value, deps = [] } : IOption) => {
  const dispatch = useStoreDispatch();

  useEffect(() => {
    dispatch(storeGraphic.setOptions({
      id,
      options: {
        [key]: value,
      },
    }));
  }, [...deps]);
};
