import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { IConnector } from './Cap';

export const Cap4: IConnector = {
  Start: ({ color, mask }: ICap) => (
    <path d="M12 12L1 8L12 4L7 8L12 12Z" stroke={mask ? '#fff' : color} fill={mask ? 'transparent' : color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
  End: ({ color, mask }: ICap) => (
    <path d="M12 12L23 8L12 4L17 8L12 12Z" stroke={mask ? '#fff' : color} fill={mask ? 'transparent' : color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
};
