import { ITool } from 'tools';
import { blocks } from 'blocks';
import { getMemoStyle, getState } from 'utils/store';

import { createTool } from 'tools/ToolBasic';

import icon from './assets/icon.svg';

export const Tool = createTool<string>({
  key: 'style',
  def: 'pen',
  title: 'Highlighter',
  alt: 'Highlighter',
  getValue: () => 'highlighter',
  getActive: () => {
    const draw = getState((state) => state.graphic.draw);

    return getMemoStyle(blocks[draw ?? '_']?.element.memo ?? '', 'style') === 'highlighter';
  },
  onMemo: (memo) => {
    const draw = getState((state) => state.graphic.draw);

    memo(blocks[draw ?? '_']?.element.memo ?? '', 'style', 'highlighter');
  },
  icon,
});

export const MarkerHighlighter: ITool = {
  key: 'marker-highlighter',
  tool: Tool,
  options: ['only-draw'],
};
