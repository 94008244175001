import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDiagram, getDiagramMember, getFile } from 'utils/models';
import { Emitter } from 'utils/events';
import security from 'utils/security';
import errors from 'utils/errors';

import { storeCache } from 'store/cache';
import { storeCollection } from 'store/collection';
import { IDiagram, IRole, storeDiagram } from 'store/diagram';

import { api, url } from '.';
import { serviceAlert } from './alert';

interface ICallback<T = boolean> {
  callback?: (payload?: T) => void;
}

export const service = {
  get: async (id: string, ticket?: string) => {
    const config = { headers: {} };

    if (ticket) {
      config.headers = { ticket };
    }

    const response = await api.get(`/diagrams/diagram/${id}`, config);

    const file = response.data.file;
    const user = security.getUser();
    const client = response.data.diagram.members.find((member: {user: string}) => member.user === user.id);

    if (user.id && user.email && ticket && !client) {
      const email = response.data.diagram.members.find((member: {email: string}) => member.email === user.email);

      if (email) {
        const response = await api.post('/diagrams/ticket', {
          diagram: id,
        }, config);

        if (response.data.members.find((member: {user: string}) => member.user === user.id)) {
          return {
            diagram: response.data,
            file,
          };
        }
      }
    }

    return response.data;
  },
  getTemplate: async (id: string) => {
    const response = await api.get(`/diagrams/template/${id}`);

    return {
      diagram: response.data.diagram,
      file: response.data.file,
    };
  },
};

export const serviceDiagram = {
  get: createAsyncThunk('diagram/get', async (payload: { id: string, ticket?: string } & ICallback<{ id: string, ticket: string }>, thunk) => {
    try {
      const response = await service.get(payload.id, payload.ticket);

      const diagram = getDiagram(response.diagram);

      thunk.dispatch(storeDiagram.setDiagram(diagram));

      if (payload.callback) {
        payload.callback({
          id: diagram.id,
          ticket: diagram.ticket,
        });
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {}, [400, 403, 404]);

      thunk.dispatch(storeDiagram.setFailedStatus(true));
      thunk.dispatch(storeDiagram.setLoadingStatus(false));
    }
  }),

  copy: createAsyncThunk('diagram/copy', async (payload: { id: string, ticket?: string } & ICallback<{ status: boolean }>, thunk) => {
    try {
      const clone = await service.get(payload.id, payload.ticket);

      const diagram = getDiagram(clone.diagram);
      const file = getFile(clone.file);

      await thunk.dispatch(serviceDiagram.create({
        name: `${diagram.name} (copy)`,
        extra: {},
        callback: (diagram) => {
          if (diagram) {
            payload.id = diagram.id;
            payload.id = diagram.id;
          }
        },
      }));

      Object.entries(file).forEach(([id, properties]) => {
        if (properties.type === 'general-image') {
          thunk.dispatch(serviceDiagram.duplicate({
            id: payload.id,
            url: `${url}/diagrams/asset/${diagram.id}/${diagram.ticket}/${properties.url}`,
            callback: (payload) => {
              if (!payload || !payload.url) {
                thunk.dispatch(storeDiagram.setElement({
                  id,
                  properties: {
                    url: '',
                    loading: false,
                  },
                }));

                return;
              }

              thunk.dispatch(storeDiagram.setElement({
                id,
                properties: {
                  url: payload.url,
                  loading: false,
                },
              }));
            },
          }));
        }
      });

      Object.entries(file).forEach(([id, properties]) => {
        if (properties.type === 'general-image') {
          file[id].loading = true;
          file[id].url = '';
        }
      });

      thunk.dispatch(storeDiagram.setFile(file));

      if (payload.callback) {
        payload.callback({
          status: true,
        });
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {}, [400, 403, 404]);

      thunk.dispatch(storeDiagram.setFailedStatus(true));
      thunk.dispatch(storeDiagram.setLoadingStatus(false));
    }
  }),

  delete: createAsyncThunk('diagram/delete', async (payload: { id: string } & ICallback, thunk) => {
    try {
      await api.delete(`/diagrams/diagram/${payload.id}`);

      thunk.dispatch(storeCollection.removeDiagram(payload.id));

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        404: 'Diagram is not found',
        403: 'You do not have permission',
      });
    }
  }),

  create: createAsyncThunk('diagram/create', async (payload: { name: string, extra: { [key: string] : any } } & ICallback<IDiagram>, thunk) => {
    try {
      const response = await api.post('/diagrams/diagram', {
        name: payload.name,
        ...payload.extra,
      });

      const diagram = getDiagram(response.data.diagram);

      thunk.dispatch(storeCache.initDiagram({ id: diagram.id }));
      thunk.dispatch(storeDiagram.setDiagram(diagram));

      thunk.dispatch(storeCache.setDiagram({
        id: 'new',
        data: {
          aws: undefined,
          azure: undefined,
          google: undefined,
          vmware: undefined,
          cisco: undefined,
          kubernetes: undefined,
        },
      }));

      Emitter.emit('diagram-creation', {
        id: diagram.id,
        ticket: diagram.ticket,
      });

      if (payload.callback) {
        payload.callback(diagram);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        404: 'Diagram is not found',
      });
    }
  }),

  join: createAsyncThunk('diagram/join', async (payload: { token: string } & ICallback<string>, thunk) => {
    try {
      const response = await api.post('/diagrams/join', {
        token: payload.token,
      });

      if (payload.callback) {
        payload.callback(response.data.id);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      thunk.dispatch(storeDiagram.setFailed());

      errors(e, thunk, {}, [400, 403, 404]);
    }
  }),

  setFavorite: createAsyncThunk('diagram/setFavorite', async (payload: { id: string, favorite: boolean } & ICallback, thunk) => {
    try {
      await api.put('/diagrams/favorite', {
        diagram: payload.id,
        favorite: payload.favorite,
      });

      thunk.dispatch(storeCollection.updateDiagram({
        id: payload.id,
        favorite: payload.favorite,
      }));

      thunk.dispatch(storeDiagram.updateDiagram({
        id: payload.id,
        favorite: payload.favorite,
      }));

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        404: 'Diagram is not found',
      });
    }
  }),

  setTemplate: createAsyncThunk('diagram/setTemplate', async (payload: { id: string, template: boolean } & ICallback, thunk) => {
    try {
      if (!payload.id) {
        await thunk.dispatch(serviceDiagram.create({
          name: 'Untitled',
          extra: {
            template: payload.template,
          },
        }));
      } else {
        const response = await api.put('/diagrams/template', {
          diagram: payload.id,
          template: payload.template,
        });

        thunk.dispatch(storeCollection.updateDiagram({
          id: payload.id,
          template: response.data.template,
        }));

        thunk.dispatch(storeDiagram.updateDiagram({
          id: payload.id,
          template: response.data.template,
        }));
      }

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        404: 'Diagram is not found',
      });
    }
  }),

  resetLink: createAsyncThunk('diagram/resetLink', async (payload: { id: string } & ICallback<string>, thunk) => {
    try {
      const response = await api.put('/diagrams/ticket', {
        diagram: payload.id,
      });

      thunk.dispatch(storeCollection.updateDiagram({
        id: payload.id,
        ticket: response.data.ticket,
      }));

      thunk.dispatch(storeDiagram.updateDiagram({
        id: payload.id,
        ticket: response.data.ticket,
      }));

      if (payload.callback) {
        payload.callback(response.data.ticket);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        403: 'You do not have permission',
        404: 'Diagram in not found',
      });
    }
  }),

  shareDiagram: createAsyncThunk('diagram/shareDiagram', async (payload: { id: string, email: string, role: IRole } & ICallback, thunk) => {
    try {
      const response = await api.post('/diagrams/share', {
        location: `${window.location.origin}/board/join`,
        email: payload.email,
        diagram: payload.id,
        role: payload.role,
      });

      thunk.dispatch(storeCollection.updateDiagram({
        id: payload.id,
        members: response.data.members.map((member: any) => getDiagramMember(member)),
      }));

      thunk.dispatch(storeDiagram.updateDiagram({
        id: payload.id,
        members: response.data.members.map((member: any) => getDiagramMember(member)),
      }));

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        403: 'You do not have permission',
        404: 'Diagram in not found',
        409: 'This member has already been added',
      });
    }
  }),

  setMemberRole: createAsyncThunk('diagram/setMemberRole', async (payload: { id: string, user: string, role: IRole } & ICallback, thunk) => {
    try {
      const response = await api.put('/diagrams/share', {
        diagram: payload.id,
        user: payload.user,
        role: payload.role,
      });

      thunk.dispatch(storeCollection.updateDiagram({
        id: payload.id,
        members: response.data.members.map((member: any) => getDiagramMember(member)),
      }));

      thunk.dispatch(storeDiagram.updateDiagram({
        id: payload.id,
        members: response.data.members.map((member: any) => getDiagramMember(member)),
      }));

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        403: 'You do not have permission',
        404: 'Member in not found',
      });
    }
  }),

  removeMember: createAsyncThunk('diagram/removeMember', async (payload: { id: string, user: string } & ICallback, thunk) => {
    try {
      const response = await api.delete('/diagrams/share', {
        data: {
          diagram: payload.id,
          user: payload.user,
        },
      });

      thunk.dispatch(storeCollection.updateDiagram({
        id: payload.id,
        members: response.data.members.map((member: any) => getDiagramMember(member)),
      }));

      thunk.dispatch(storeDiagram.updateDiagram({
        id: payload.id,
        members: response.data.members.map((member: any) => getDiagramMember(member)),
      }));

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        403: 'You do not have permission',
        404: 'Member in not found',
      });
    }
  }),

  reshareDiagram: createAsyncThunk('diagram/reshareDiagram', async (payload: { id: string, email: string } & ICallback, thunk) => {
    try {
      await api.post('/diagrams/reshare', {
        location: `${window.location.origin}/board/join`,
        email: payload.email,
        diagram: payload.id,
      });

      thunk.dispatch(serviceAlert.showAlert({
        message: 'The letter has been resent',
        type: 'default',
      }));

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        403: 'You do not have permission',
        404: 'Member in not found',
        409: 'This member has already been added',
      });
    }
  }),

  updateName: createAsyncThunk('diagram/updateName', async (payload: { id: string, name: string } & ICallback, thunk) => {
    try {
      if (!payload.id) {
        await thunk.dispatch(serviceDiagram.create({
          name: payload.name,
          extra: {},
        }));
      } else {
        const response = await api.put('/diagrams/name', {
          diagram: payload.id,
          name: payload.name,
        });

        thunk.dispatch(storeDiagram.updateDiagram({
          id: payload.id,
          name: response.data.name,
        }));
      }

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        404: 'Diagram is not found',
      });
    }
  }),

  upload: createAsyncThunk('diagram/upload', async (payload: { id: string, blob: Blob } & ICallback<{ url: string }>, thunk) => {
    try {
      const form = new FormData();

      form.append('blob', payload.blob, 'image.png');

      if (payload.blob.size > 3 * 1024 * 1024) {
        thunk.dispatch(serviceAlert.showAlert({
          type: 'error',
          message: 'Maximum file size is 3 MB',
        }));

        if (payload.callback) {
          payload.callback();
        }

        return;
      }

      const response = await api.post(`/diagrams/asset/${payload.id}/upload`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (payload.callback) {
        payload.callback({
          url: response.data.file,
        });
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        404: 'Image could not be loaded',
      });
    }
  }),

  duplicate: createAsyncThunk('diagram/duplicate', async (payload: { id: string, url: string } & ICallback<{ url: string }>, thunk) => {
    try {
      const response = await api.post(`${payload.url}/duplicate/${payload.id}`);

      if (payload.callback) {
        payload.callback({
          url: response.data.file,
        });
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        404: 'Image could not be loaded',
      });
    }
  }),
};
