import React, { useContext } from 'react';
import { points } from 'utils/points';
import { blocks } from 'blocks';

import { useLayout } from 'hooks/useLayout';
import { config } from 'hooks/useResizerBorder';
import { useDragBlock } from 'hooks/useDragBlock';
import { useStoreSelector } from 'hooks/useStore';

import { ElementContext } from './Element';

export const Title = () => {
  const element = useContext(ElementContext);

  const drag = useStoreSelector((state) => state.graphic.drag);
  const layout = useStoreSelector((state) => state.graphic.layout.status);
  const header = useStoreSelector((state) => state.diagram.childs[element.id]?.[0]);
  const title = useStoreSelector((state) => state.diagram.links[element.id]?.includes(state.graphic.drag?.element.id || '_'));
  const space = useStoreSelector((state) => state.diagram.file[element.id].space);
  const lock = useStoreSelector((state) => ((state.diagram.file[element.id]?.lock || state.diagram.captured[element.id]) && !header) || ((state.diagram.file[header]?.lock || state.diagram.captured[header]) && !layout));
  const placing = useStoreSelector((state) => state.diagram.file[element.id].placing);
  const create = useStoreSelector((state) => state.graphic.drag?.type === 'create' || state.graphic.copy);
  const nest = useStoreSelector((state) => (
    drag
    && (
      !header
      || (
        blocks[state.diagram.file[state.diagram.roots[state.diagram.roots[header]]]?.type]?.element.options.includes('element-wrap')
        && !blocks[state.diagram.file[state.diagram.roots[state.diagram.roots[header]]]?.type]?.element.options.includes('element-wrapable')
        && !state.diagram.file[state.diagram.roots[header]]?.lock
        && !state.diagram.captured[state.diagram.roots[header]]
      )
    )
    && !blocks[drag.element.type]?.element.options.includes('container-title-none')
  ));

  const frame = useLayout(element.id, true);
  const block = useDragBlock(header);

  const bar = [...(points.elements[header]?.size || [32, 32])];
  if (header && space) {
    bar[0] += config.space[0] * 2;
    bar[1] += config.space[1] * 2;
  }

  const style: React.CSSProperties = {};

  style.width = bar[0];
  style.height = bar[1];

  if (header) {
    if (['top', 'top-left', 'top-right', 'right-top', 'left-top'].includes(placing)) style.top = 0;
    if (['bottom', 'bottom-left', 'bottom-right', 'right-bottom', 'left-bottom'].includes(placing)) style.bottom = 0;
    if (['left', 'left-top', 'left-top', 'top-left', 'bottom-left'].includes(placing)) style.left = 0;
    if (['right', 'right-top', 'right-top', 'top-right', 'bottom-right'].includes(placing)) style.right = 0;

    if (['top', 'bottom'].includes(placing)) {
      style.left = '50%';
      style.marginLeft = -bar[0] / 2;
    } else if (['left', 'right'].includes(placing)) {
      style.top = '50%';
      style.marginTop = -bar[1] / 2;
    }
  } else {
    style.top = 0;
    style.left = 0;
  }

  const arrangement = {
    onMouseUp: (e: React.MouseEvent<HTMLDivElement>) => {
      block.onDragUp(e, [0, 0]);
    },
  };

  if (drag && !lock && !title && ((!layout && header && !create && !blocks[drag.element.type]?.element.options.includes('container-title-none')) || (layout && nest))) {
    return (
      <div
        className={'block-title'.appendWhen(!header, 'empty')}
        style={style}
        {...frame}
        {...arrangement}
      />
    );
  }

  return null;
};
