import { useContext } from 'react';

import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';

export const useJoins = (type: 'from' | 'to', types: string[], condition: (join: [string, string]) => boolean = () => true) => {
  const element = useContext(ElementContext);

  const joins = useStoreSelector((state) => {
    return (state.diagram.joins[type][element.id] || []).map((join) => {
      return join.split('.') as [string, string];
    }).filter((join) => {
      return (types.length === 0 || types.includes(state.diagram.file[join[0]]?.type)) && condition(join);
    }).map((join) => {
      return join.join('.');
    }).join(',');
  });

  return {
    joins: joins ? joins.split(',').map((join) => join.split('.')[0]) : [],
    connectors: joins ? joins.split(',').map((join) => join.split('.')[1]) : [],
  };
};
