import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { Model } from 'blocks/factories/shape/Model';
import { createRender } from 'blocks/factories/simple/Render';
import { createPadding } from 'blocks/factories/simple/Padding';
import { createBlock, block } from 'blocks/factories/shape/Block';

import icon from './assets/icon.svg';

const { serialize } = require('svg-path-round-corners/dist/es6/serialize');
const { roundCorners } = require('svg-path-round-corners/dist/es6/index');

type IModel = Model<{}>;

export const config: IConfig = {
  title: 'Node',
  search: 'shape node uml unified modeling language',
  type: 'uml-deployment-shape-node',
  group: ['UML Deployment Shape'],
  memo: {
    key: 'uml-deployment-shape',
    styles: block.element.memo,
  },
  styles: block.element.styles,
};

const Render = createRender<IModel>({
  render: ({ width, height, element }) => {
    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - border, 0);
    const y = Math.max(height - border, 0);
    const o = 12;

    const points = [
      { c: 'M', x: offset, y: offset + o },
      { c: 'H', x: x },
      { c: 'V', y: y + o },
      { c: 'M', x: offset, y: offset + o },
      { c: 'V', y: y + o },
      { c: 'H', x: x },
      { c: 'M', x: offset, y: offset + o },
      { c: 'L', x: offset + o, y: offset },
      { c: 'H', x: x + o },
      { c: 'V', y: y },
      { c: 'L', x: x, y: y + o },
      { c: 'M', x: x - 1.5, y: offset + o + 2 },
      { c: 'L', x: x + o - 1.5, y: offset + 2 },
    ];

    return (
      <div className="block-render" style={{ marginTop: -o }}>
        <svg width={width + o} height={height + o} viewBox={`0 0 ${width + o} ${height + o}`} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path {...element.trigger} className="render-fill render-stroke render-hit" strokeWidth={border} strokeLinecap="round" d={serialize(roundCorners(points, 6))} />
        </svg>
      </div>
    );
  },
});

const Padding = createPadding<IModel>({
  size: [0, 0],
  padding: [1, 1, 1, 1],
  creator: () => {
    return [0, 0, 0, 0];
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  Render,
  Padding,
});

export const UMLDeploymentShapeNode : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: block.element.options,
    constraints: {
      prevent: {
        ...block.element.constraints.prevent,

        render: [7, 8],
      },
    },
    onCreate: block.creator(config, {
      width: 64,
      height: 64,
    }),
    onCopy: block.copier(config),
    title: config.title,
    size: [64, 64],
    icon,
    class: block.element.class,
  },
  button: block.button(config),
  tools: block.tools,
  toolbars: block.toolbars,
};
