import { ITool } from 'tools';
import { createTool } from 'tools/ToolBasic';

import icon from './assets/icon.svg';

const Tool = createTool({
  key: 'italic',
  def: false,
  title: 'Italic',
  alt: 'Italic',
  icon,
});

export const Italic: ITool = {
  key: 'italic',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
