import React, { useContext, useEffect, useRef } from 'react';
import { getBlockContextContainers, getState } from 'utils/store';
import { Emitter } from 'utils/events';
import { points } from 'utils/points';
import { blocks } from 'blocks';

import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

import { CanvasDynamicContext } from 'elements/Canvas';
import { ElementContext } from 'elements/Block/Element';
import { IArrangement, IPoint } from 'elements/Block/Arrangement';

import { useStoreDispatch, useStoreSelector } from './useStore';
import { usePointPosition } from './usePointPosition';
import { useDropFreely } from './useDropFreely';
import { useDropSnaply } from './useDropSnaply';
import { useProperty } from './useProperty';
import { useHotkey } from './useHotkey';

interface IContainer {
  nesting: boolean;
  status: boolean;
  cap: number[];
  disabled: boolean;
}

export const useArrangementContainer = (): IArrangement & IContainer => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);
  const canvas = useContext(CanvasDynamicContext);

  const drop = useStoreSelector((state) => state.graphic.drop);
  const drag = useStoreSelector((state) => state.graphic.drag);
  const moving = useStoreSelector((state) => drag?.element.id === element.id || (drag && state.graphic.selection.elements.includes(element.id)));
  const layout = useStoreSelector((state) => state.graphic.layout.status && !moving);
  const component = useStoreSelector((state) => !state.graphic.component.key);
  const elements = useStoreSelector((state) => state.diagram.file[element.id]?.elements) ?? [];
  const hidden = useStoreSelector((state) => state.diagram.file[element.id]?.hidden);
  const free = useStoreSelector((state) => (state.graphic.drop && state.graphic.layout.status) || (blocks[state.graphic.drag?.element.type ?? '_']?.element.options.includes('parent-free')) || (!!state.graphic.drop?.handle && blocks[state.diagram.file[state.graphic.drop.id]?.type]?.element.constraints.free?.handle === state.graphic.drop.handle));
  const create = useStoreSelector((state) => state.graphic.drag?.type === 'create' || state.graphic.copy);
  const header = useStoreSelector((state) => state.diagram.links[element.id]?.includes(state.graphic.drag?.element.id || '_'));

  const getPosition = usePointPosition();

  const freely = useDropFreely();
  const snaply = useDropSnaply();

  const space = useHotkey({ code: 32, isActive: () => component && !moving, deps: [component && !moving] });
  const sticker = blocks[drag?.element.type ?? '_']?.element.options.includes('element-sticker');
  const dragger = (create ? '' : drag?.element.id) ?? '';

  const active = useProperty(false);
  const nesting = useProperty(false);
  const dragging = useRef('');
  const render = useProperty({});
  const cap = useRef<number[]>([]);
  const start = useRef<string[]>([]);

  const getCapBounds = (items: string[]) => {
    const file = getState((state) => state.diagram.file);

    return items.filter((id) => elements.includes(id)).reduce((frame, id) => {
      const element = points.elements[id];

      if (!element) {
        return frame;
      }

      const border = (file[id]?.elements ?? []).length > 0 && file[id]?.border;

      const offset = [
        element.offset[0],
        element.offset[1],
      ];

      const position = [
        element.position[0],
        element.position[1],
      ];

      const size = [
        element.size[0],
        element.size[1],
      ];

      const box = [
        Math.min(position[0], (border?.[0] ?? Infinity) + offset[0]),
        Math.min(position[1], (border?.[1] ?? Infinity) + offset[1]),
        Math.max(position[0] + size[0], (border?.[2] ?? -Infinity) + offset[0]),
        Math.max(position[1] + size[1], (border?.[3] ?? -Infinity) + offset[1]),
      ];

      if (!border) {
        box[0] += offset[0];
        box[1] += offset[1];
        box[2] += offset[0];
        box[3] += offset[1];
      }

      if (frame.length === 0) {
        return box;
      }

      return [
        Math.min(frame[0], box[0]),
        Math.min(frame[1], box[1]),
        Math.max(frame[2], box[2]),
        Math.max(frame[3], box[3]),
      ];
    }, [] as number[]);
  };

  const getItems = (dragger: string, excludeParent = false) => {
    if (dragger === '') {
      return [''];
    }

    const selection = getState((state) => state.graphic.selection.elements);
    const links = getState((state) => state.diagram.links);
    const file = getState((state) => state.diagram.file);

    const items = new Set<string>();

    [...(file[dragger]?.parent ? [] : selection), dragger].forEach((id) => {
      if (
        (id !== dragger && file[id]?.parent)
        || blocks[file[id]?.type]?.element.options.includes('container-none')
        || blocks[file[id]?.type]?.element.options.includes('container-child-none')
      ) {
        return;
      }

      (links[id] ?? []).forEach((id) => {
        if (id === dragger || !excludeParent || !file[id]?.parent) {
          items.add(id);
        }
      });
    });

    return Array.from(items);
  };

  const insertList = (list: string[]) => {
    dispatch(storeDiagram.setElement({
      id: element.id,
      properties: {
        elements: list,
      },
      // skip: true,
    }));
  };

  const insert = (id: string) => {
    const items = getItems(id, true);
    const elements = getState((state) => state.diagram.file[element.id]?.elements) ?? [];

    if (!elements.includes(id)) {
      insertList([...elements, ...items]);
    }
  };

  const remove = (list: string[]) => {
    const border = getState((state) => state.diagram.file[element.id]?.border);

    const position = points.elements[element.id].position;
    const size = points.elements[element.id].size;

    dispatch(storeDiagram.setElement({
      id: element.id,
      properties: {
        position: list.length === 0 ? [...position] : [0, 0],
        width: list.length === 0 ? size[0] : 0,
        height: list.length === 0 ? size[1] : 0,
        elements: list,
        border: [
          Number.isFinite(border?.[0]) && list.length === 0 ? position[0] : border?.[0],
          Number.isFinite(border?.[1]) && list.length === 0 ? position[1] : border?.[1],
          Number.isFinite(border?.[2]) && list.length === 0 ? (position[0] + size[0]) : border?.[2],
          Number.isFinite(border?.[3]) && list.length === 0 ? (position[1] + size[1]) : border?.[3],
        ],
      },
      // skip: true,
    }));
  };

  const insertAll = (item: string, condition: (id: string) => boolean) => {
    const include = getItems(item, true).filter((id) => condition(id));
    const elements = getState((state) => state.diagram.file[element.id]?.elements) ?? [];

    const list = Array.from(new Set([...elements, ...include]));

    if (list.length === elements.length) {
      return false;
    }

    insertList(list);

    return true;
  };

  const removeAll = (item: string, condition: (id: string) => boolean) => {
    const exept = getBlockContextContainers(getItems(item).filter((id) => condition(id)));
    const list = elements.filter((element: string) => !exept.includes(element));

    if (list.length === elements.length) {
      return false;
    }

    remove(list);

    return true;
  };

  const nest = () => {
    if (!create) {
      cap.current = [];
    }

    if (!drag) {
      return;
    }

    if (create) {
      dispatch(storeGraphic.setDrag({
        ...drag,

        container: Array.from(new Set([...(drag.container ?? []), element.id])),
      }));

      return;
    }

    insert(dragger);
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>, direction: number[]) => {
    if (e.button !== 0) {
      return;
    }

    if (free) {
      freely.onDropDown(e);
    } else if (drop) {
      snaply.onDropDown(e, direction);
    }
  };

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement>, direction: number[]) => {
    if (drag && !sticker) {
      Emitter.emit('drag-up', {
        id: '',
        event: e,
        auto: space ? false : undefined,
        container: create ? element.id : undefined,
      });

      if (active.value) {
        nest();
      }
    } else if (free) {
      if (drop) {
        freely.onDropUp(e);
      } else if (drag) {
        freely.onDragUp(e);
      }
    } else if (drop) {
      snaply.onDropUp(e, direction);
    }
  };

  const onMouseEnter = () => {
    if (layout && drag && active.value) {
      nesting.set(true);
      dragging.current = dragger;
    }
  };

  const onMouseLeave = () => {
    if (layout && drag && active.value) {
      nesting.set(false);
      dragging.current = '';
    }
  };

  useEffect(() => {
    if (!active.value) {
      return;
    }

    if (drag && layout) {
      const elements = getState((state) => state.diagram.file[element.id]?.elements) ?? [];
      const status = removeAll(dragger, (id) => {
        return elements.includes(id);
      });

      if (status && elements.includes(drag.element.id)) {
        const items = getItems(dragger, true);
        const bounds = getCapBounds(items);

        if (bounds.length === 4) {
          cap.current = bounds;

          return;
        }

        const position = getPosition(canvas.cursor, drag.frame.size, drag.frame.offset);

        cap.current = [
          position[0],
          position[1],
          position[0] + drag.frame.size[0],
          position[1] + drag.frame.size[1],
        ];
      }

      return;
    }

    if (drag && nesting.value) {
      nest();
    }
  }, [!!layout && !!drag]);

  useEffect(() => {
    if (!drag && nesting.value) {
      nest();
    }
  }, [drag, nesting.value]);

  useEffect(() => {
    if (!drag) {
      active.set(false);
    } else if (!layout) {
      active.set(true);
    }
  }, [!drag, !layout]);

  useEffect(() => {
    if (!drag) {
      if (dragging.current) {
        insert(dragging.current);
      }

      nesting.set(false);
      dragging.current = '';
      cap.current = [];
    } else if (elements.includes(dragger)) {
      nesting.set(true);
      dragging.current = dragger;
    }
  }, [drag]);

  useEffect(() => {
    if (!drag) {
      start.current = elements;
    }
  }, [drag, elements]);

  useEffect(() => {
    if (!drag || !create || !nesting.value || layout) {
      return () => {};
    }

    const onMove = () => {
      const position = getPosition(canvas.cursor, drag.frame.size, drag.frame.offset);

      cap.current = [
        position[0],
        position[1],
        position[0] + drag.frame.size[0],
        position[1] + drag.frame.size[1],
      ];

      render.set({});
    };

    onMove();

    window.addEventListener('mousemove', onMove);

    return () => {
      window.removeEventListener('mousemove', onMove);
    };
  }, [!drag || !create || !nesting.value || layout]);

  useEffect(() => {
    if (!drag) {
      return;
    }

    if (create) {
      removeAll(dragger, (id) => {
        return (elements.includes(id) || cap.current.length > 0);
      });

      cap.current = [];
      render.set({});
      dragging.current = '';
      nesting.set(false);
    }

    if (!create && ((drag.container ?? []).includes(element.id) || cap.current.length > 0)) {
      cap.current = [];
      nesting.set(false);
      dragging.current = '';
      render.set({});

      dispatch(storeGraphic.setDrag({
        ...drag,

        container: (drag.container ?? []).filter((id) => id !== element.id),
      }));
    }
  }, [create]);

  useEffect(() => {
    if (!nesting.value && drag && create && (drag.container ?? []).includes(element.id)) {
      dispatch(storeGraphic.setDrag({
        ...drag,

        container: (drag.container ?? []).filter((id) => id !== element.id),
      }));
    }
  }, [!nesting.value && drag && create && (drag.container ?? []).includes(element.id)]);

  useEffect(() => {
    if (drag && create) {
      const status = insertAll(drag.element.id, (id) => {
        return start.current.includes(id);
      });

      if (status) {
        return;
      }
    }

    if (drag) {
      removeAll(drag.element.id, (id) => {
        return !start.current.includes(id) && elements.includes(id);
      });
    }
  }, [create]);

  const preventing: IPoint[] = [];

  preventing.push('tl');
  preventing.push('tr');
  preventing.push('bl');
  preventing.push('br');

  if (drag) {
    preventing.push('cl');
    preventing.push('ct');
    preventing.push('cr');
    preventing.push('cb');
  }

  if (header || drop) {
    preventing.push('cc');
  }

  return {
    layout: 'new',
    container: !drop && !header && !sticker,
    backdrop: header,
    prevent: preventing,
    nesting: drag && (nesting.value || elements.includes(dragger)),
    free,
    cap: cap.current,
    disabled: (
      blocks[drag?.element.type ?? '']?.element.options.includes('container-none')
      || drag?.childs.includes(element.id)
      || hidden
    ),
    status: (drag && layout) || !!drop || header || sticker,

    onMouseUp,
    onMouseDown,
    onMouseEnter: (layout && drag) ? onMouseEnter : undefined,
    onMouseLeave: (layout && drag) ? onMouseLeave : undefined,
  };
};
