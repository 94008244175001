import { useContext } from 'react';

import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';

export const usePathFilter = (joins: string[], filter: string[]) => {
  const element = useContext(ElementContext);

  const path = useStoreSelector((state) => {
    const links = joins.filter((id) => {
      return filter.includes(state.diagram.file[id]?.type);
    });

    const pathes = links.map((join) => {
      return state.graphic.options[join]?.path;
    }).filter((path) => {
      return path;
    }).map((path) => {
      return path.split(',');
    }).flat(1);

    const path = new Set([...links, ...pathes]);

    path.delete(element.id);

    return Array.from(path).filter((id) => {
      return filter.includes(state.diagram.file[id]?.type);
    }).join(',');
  });

  return path.split(',');
};
