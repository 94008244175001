import React from 'react';
import { ITool } from 'tools';

import { createFormatTool } from 'tools/ToolFormat';

import icon from './assets/icon.svg';

const Tool = createFormatTool({
  icon,
  title: 'Bold',
  format: 'bold',
});

export const FormatBold: ITool = {
  key: 'format-bold',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
