import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAlert {
  type: 'error' | 'default';
  message: string;
}

export interface ISpecificAlert extends IAlert {
  id: number;
}

interface State extends ISpecificAlert {
  active: boolean;
}

const defaultState: State = {
  id: 0,
  active: false,
  type: 'default',
  message: '',
};

const slice = createSlice({
  name: 'alert',
  initialState: defaultState,
  reducers: {
    showAlert: (state, action: PayloadAction<ISpecificAlert>) => {
      state.id = action.payload.id;
      state.type = action.payload.type;
      state.message = action.payload.message;

      state.active = true;
    },
    hideAlert: (state) => {
      state.active = false;
    },
  },
});

export const storeAlert = {
  showAlert: slice.actions.showAlert,
  hideAlert: slice.actions.hideAlert,
};

export default slice.reducer;
