import React from 'react';
import { ITool } from 'tools';

import { storeGraphic } from 'store/graphic';

import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import icon from './assets/icon.svg';

const Tool = () => {
  const dispatch = useStoreDispatch();

  const failed = useStoreSelector((state) => state.graphic.component.failed);

  const onClick = () => {
    if (failed) {
      dispatch(storeGraphic.setComponentFailed(false));

      return;
    }

    dispatch(storeGraphic.setComponentKey(''));
  };

  return (
    <Hint message="Back" side="bottom" variant="default">
      <button type="button" className="button-icon v-1" onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt="Back" />
      </button>
    </Hint>
  );
};

export const LinkBack: ITool = {
  key: 'link-back',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
