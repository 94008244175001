import { useContext } from 'react';
import { getState } from 'utils/store';
import { blocks } from 'blocks';

import { OptionSecondElementClick } from 'store/settings';

import { config } from 'blocks/General/Text';

import { CanvasDynamicContext } from 'elements/Canvas';
import { ElementContext } from 'elements/Block/Element';

import { useTextSelector } from './useTextSelector';
import { useStoreSelector } from './useStore';

export const useTextCreator = () => {
  const element = useContext(ElementContext);
  const canvas = useContext(CanvasDynamicContext);

  const lock = useStoreSelector((state) => !!state.diagram.captured[element.id] || state.diagram.file[element.id]?.lock || state.graphic.options[element.id]?.invisible);

  const select = useTextSelector();

  if (lock) {
    return () => () => {};
  }

  return (type: 'click' | 'enter') => () => {
    const selected = select(type)();
    const click = getState((state) => state.settings.app.secondElementClick);

    if (selected || (type === 'click' && !click.includes(OptionSecondElementClick.TextCreating))) {
      return;
    }

    const file = { ...getState((state) => state.diagram.file) };

    blocks[config.type].element.onCreate({
      file,
      position: [0, 0],
      parent: element.id,
      auto: canvas?.space ? false : undefined,
      preventComponentStatus: true,
    });
  };
};
