import { useContext } from 'react';
import { getState } from 'utils/store';
import { blocks } from 'blocks';

import { storeDiagram } from 'store/diagram';
import { OptionSecondElementClick } from 'store/settings';

import { config as grid } from 'blocks/Core/Wrapper';
import { config as text } from 'blocks/General/Text';

import { CanvasDynamicContext } from 'elements/Canvas';
import { ElementContext } from 'elements/Block/Element';

import { useStoreDispatch, useStoreSelector } from './useStore';

export const useTextCreatorWithWrapper = ({ placing = 'bottom', properties = {} }: { placing?: 'left' | 'top' | 'right' | 'bottom', properties?: Record<string, any> } = {}) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);
  const canvas = useContext(CanvasDynamicContext);

  const lock = useStoreSelector((state) => !!state.diagram.captured[element.id] || state.diagram.file[element.id]?.lock || state.graphic.options[element.id]?.invisible);

  if (lock) {
    return () => () => {};
  }

  return (type: 'click' | 'enter') => () => {
    const click = getState((state) => state.settings.app.secondElementClick);

    if (type === 'click' && !click.includes(OptionSecondElementClick.TextCreating)) {
      return;
    }

    const file = { ...getState((state) => state.diagram.file) };
    const parent = getState((state) => state.diagram.file[element.id].parent);
    const position = getState((state) => state.diagram.file[element.id].position);

    if (blocks[file[parent]?.type]?.element.options.includes('element-wrap')) {
      return;
    }

    const wrapper = blocks[grid.type].element.onCreate({
      file,
      parent,
      position,
      auto: true,
      properties: {
        placing,
      },
    });

    dispatch(storeDiagram.setElement({
      id: element.id,
      properties: {
        parent: wrapper,
        position: [0, 0],
      },
    }));

    blocks[text.type].element.onCreate({
      file,
      position: [0, 1],
      parent: wrapper,
      auto: canvas?.space ? false : undefined,
      preventComponentStatus: true,
      properties,
    });
  };
};
