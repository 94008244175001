import { ITool } from 'tools';
import { blocks } from 'blocks';
import { getState } from 'utils/store';
import { createTool } from 'tools/ToolBasic';

import icon from './assets/icon.svg';

const Tool = createTool({
  key: 'space',
  def: false,
  title: 'White space',
  alt: 'White space',
  icon,
  getStatus: () => {
    return getState((state) => {
      const selection = state.graphic.selection.elements;

      return !selection.every((id) => {
        if (blocks[state.diagram.file[id]?.type]?.element.options.includes('element-container')) {
          return !(state.diagram.childs[id]?.length > 0);
        }

        return true;
      });
    });
  },
});

export const Space: ITool = {
  key: 'space',
  tool: Tool,
  options: ['only-edit'],
};
