import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import icon from './assets/icon.svg';

const Tool = createTool('icon', '_', 'icon', 'Graphics', 'Graphics', {
  icon,
});

export const Icon: ITool = {
  key: 'icon',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
