import React, { useContext } from 'react';

import { withCondition } from 'hocs/core/withCondition';

import { useLayout } from 'hooks/useLayout';
import { useStoreSelector } from 'hooks/useStore';

import { ElementContext } from './Element';

const config = {
  direction: {
    cc: [0, 0],
    ct: [0, -1],
    cb: [0, 1],
    cl: [-1, 0],
    cr: [1, 0],
    tl: [-1, -1],
    tr: [1, -1],
    bl: [-1, 1],
    br: [1, 1],
  },
};

export type IPoint = 'cc' | 'ct' | 'cb' | 'cl' | 'cr'| 'tl'| 'tr'| 'bl'| 'br';

export interface IArrangement {
  layout: string;
  free?: boolean;
  prevent?: IPoint[];
  container?: boolean;
  backdrop?: boolean;
  disabled?: boolean;
  onCenter?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLDivElement>, direction: number[]) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>, direction: number[]) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const Arrangement = withCondition(({
  layout,
  free = false,
  prevent = [],
  container = false,
  backdrop = false,
  disabled = false,
  onCenter,
  onMouseUp = () => {},
  onMouseDown = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
} : IArrangement) => {
  const element = useContext(ElementContext);

  const block = useLayout(layout, !container);

  const drag = useStoreSelector((state) => !!state.graphic.drag);
  const drop = useStoreSelector((state) => !!state.graphic.drop);
  const lock = useStoreSelector((state) => !!state.diagram.file[element.id]?.lock || !!state.diagram.captured[element.id]);

  if ((!drag && !drop) || disabled || lock) {
    return null;
  }

  return (
    <div className={'block-arrangement'.appendWhen(backdrop, 'with-backdrop')} {...block}>
      {free && <div className="free" onMouseUp={(e) => onMouseUp(e, [0, 0])} onMouseDown={(e) => onMouseDown(e, [0, 0])} />}

      {!free && !prevent.includes('cc') && (
        <div
          className="cc"
          onMouseUp={(e) => (onCenter ? onCenter(e) : onMouseUp(e, config.direction.cc))}
          onMouseDown={(e) => (onCenter ? onCenter(e) : onMouseDown(e, config.direction.cc))}
          onMouseEnter={(e) => { onMouseEnter(e); e.currentTarget.classList.add('hover'); }}
          onMouseLeave={(e) => { onMouseLeave(e); e.currentTarget.classList.remove('hover'); }}
        />
      )}

      {!free && !prevent.includes('ct') && <div className="ct" onMouseUp={(e) => onMouseUp(e, config.direction.ct)} onMouseDown={(e) => onMouseDown(e, config.direction.ct)} onMouseEnter={(e) => e.currentTarget.classList.add('hover')} onMouseLeave={(e) => e.currentTarget.classList.remove('hover')} />}
      {!free && !prevent.includes('cb') && <div className="cb" onMouseUp={(e) => onMouseUp(e, config.direction.cb)} onMouseDown={(e) => onMouseDown(e, config.direction.cb)} onMouseEnter={(e) => e.currentTarget.classList.add('hover')} onMouseLeave={(e) => e.currentTarget.classList.remove('hover')} />}
      {!free && !prevent.includes('cl') && <div className="cl" onMouseUp={(e) => onMouseUp(e, config.direction.cl)} onMouseDown={(e) => onMouseDown(e, config.direction.cl)} onMouseEnter={(e) => e.currentTarget.classList.add('hover')} onMouseLeave={(e) => e.currentTarget.classList.remove('hover')} />}
      {!free && !prevent.includes('cr') && <div className="cr" onMouseUp={(e) => onMouseUp(e, config.direction.cr)} onMouseDown={(e) => onMouseDown(e, config.direction.cr)} onMouseEnter={(e) => e.currentTarget.classList.add('hover')} onMouseLeave={(e) => e.currentTarget.classList.remove('hover')} />}
      {!free && !prevent.includes('tl') && <div className="tl" onMouseUp={(e) => onMouseUp(e, config.direction.tl)} onMouseDown={(e) => onMouseDown(e, config.direction.tl)} onMouseEnter={(e) => e.currentTarget.classList.add('hover')} onMouseLeave={(e) => e.currentTarget.classList.remove('hover')} />}
      {!free && !prevent.includes('tr') && <div className="tr" onMouseUp={(e) => onMouseUp(e, config.direction.tr)} onMouseDown={(e) => onMouseDown(e, config.direction.tr)} onMouseEnter={(e) => e.currentTarget.classList.add('hover')} onMouseLeave={(e) => e.currentTarget.classList.remove('hover')} />}
      {!free && !prevent.includes('bl') && <div className="bl" onMouseUp={(e) => onMouseUp(e, config.direction.bl)} onMouseDown={(e) => onMouseDown(e, config.direction.bl)} onMouseEnter={(e) => e.currentTarget.classList.add('hover')} onMouseLeave={(e) => e.currentTarget.classList.remove('hover')} />}
      {!free && !prevent.includes('br') && <div className="br" onMouseUp={(e) => onMouseUp(e, config.direction.br)} onMouseDown={(e) => onMouseDown(e, config.direction.br)} onMouseEnter={(e) => e.currentTarget.classList.add('hover')} onMouseLeave={(e) => e.currentTarget.classList.remove('hover')} />}
    </div>
  );
}, () => {
  return useStoreSelector((state) => state.diagram.mode === 'edit');
});
