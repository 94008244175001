import { IConfig } from 'blocks';
import { setLatest } from 'utils/store';

import { createCreator as creator } from 'blocks/factories/simple/Creator';

export interface IModel {
  file: string;
  width: number;
  height: number;
  realWidth: number;
  realHeight: number;
  title: string;
  lockKey: string;
  lock: boolean;
}

interface ICreator {
  config: IConfig;
  tab: string;
  size?: number[];
}

export const createCreator = ({ config, tab, size }: ICreator) => creator<IModel>({
  type: config.type,
  memo: config.memo.key,
  styles: config.styles,
  properties: {
    file: '',
    title: '',
    height: size?.[0] ?? 48,
    width: size?.[1] ?? 48,
    realHeight: size?.[0] ?? 48,
    realWidth: size?.[1] ?? 48,
    lockKey: '',
    lock: false,
  },
  condition: (payload) => {
    if (payload.properties?.file && payload.properties?.slug && payload.properties?.title) {
      setLatest(tab)({
        file: payload.properties?.file,
        slug: payload.properties?.slug,
        title: payload.properties?.title,
      });
    }

    if (payload.properties?.slug) {
      payload.properties = {
        ...payload.properties,
      };

      delete payload.properties.slug;
    }

    if (payload.properties?.title) {
      payload.properties = {
        ...payload.properties,

        title: `<p class="ql-align-center">${payload.properties.title}</p>`,
      };
    }

    return payload;
  },
});
