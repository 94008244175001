import { ITool } from 'tools';
import { createTool } from 'tools/ToolBasic';

import icon from './assets/icon.svg';

const Tool = createTool({
  key: 'vertical',
  def: false,
  title: 'Rotate',
  alt: 'vertical',
  icon,
});

export const Vertical: ITool = {
  key: 'vertical',
  tool: Tool,
  options: ['only-edit'],
};
