import { useContext, useEffect, useLayoutEffect } from 'react';
import { Emitter } from 'utils/events';
import { points } from 'utils/points';

import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

import { ElementContext } from 'elements/Block/Element';

import { useElement } from './useElement';
import { useProperty } from './useProperty';
import { useStoreDispatch, useStoreSelector } from './useStore';
import { useBorderBlock } from './useBorderBlock';

export const config = {
  minWidth: 48,
  minHeight: 48,
  space: [10, 10],
  margin: {
    s: 9,
    m: 23,
    l: 39,
  } as Record<string, number>,
};

interface IResizer {
  minWidth?: number;
  minHeight?: number;
  heading?: number[];
  spacing?: number[];
  cap: number[];
}

export const useResizerBorder = <T extends HTMLElement = HTMLDivElement> (payload: IResizer) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  const border = useStoreSelector((state) => state.diagram.file[element.id].border);
  const space = useStoreSelector((state) => state.diagram.file[element.id].space);
  const width = useStoreSelector((state) => state.diagram.file[element.id].width);
  const height = useStoreSelector((state) => state.diagram.file[element.id].height);
  const position = useStoreSelector((state) => state.diagram.file[element.id].position);
  const padding = useStoreSelector((state) => state.diagram.file[element.id].padding) ?? 'm';
  const lock = useStoreSelector((state) => !!state.diagram.captured[element.id] || state.diagram.file[element.id].lock);
  const selection = useStoreSelector((state) => state.graphic.selection.elements);
  const drag = useStoreSelector((state) => state.graphic.drag?.element.id);

  const wrap = useElement<T>();

  const dragging = useProperty(drag);
  const moving = useStoreSelector((state) => !!(dragging.value || drag) && !![(dragging.value || drag), ...state.graphic.selection.elements].find((id) => id === element.id));
  const empty = useStoreSelector((state) => (state.diagram.file[element.id]?.elements ?? []).length === 0);

  const active = useProperty(false);
  const offset = useProperty<[number, number]>([0, 0]);
  const size = useProperty<number[]>([]);

  const block = useBorderBlock({
    cap: payload.cap,
    heading: payload.heading ?? [0, 0],
    space: space ? (payload.spacing ?? config.space) : [0, 0],
    padding: config.margin[padding],
    size: [
      payload.minWidth ?? config.minWidth,
      payload.minHeight ?? config.minHeight,
    ],
  });

  const onStart = () => {
    active.set(true);
    dispatch(storeGraphic.setResizing(element.id));
  };

  const onEnd = () => {
    size.set([]);
    active.set(false);
    dispatch(storeGraphic.setResizing());
  };

  const onUpdate = (shift: [number, number, number, number]) => {
    if (!wrap.ref) {
      return;
    }

    const frame = [
      Math.min(block.base[0], block.limit[0], block.position[0] + (shift[0] || 0)),
      Math.min(block.base[1], block.limit[1], block.position[1] + (shift[1] || 0)),
      Math.max(block.base[2], (!Number.isNaN(shift[0]) && !empty && Number.isFinite(border?.[2]) && border[2]) || block.base[2], Math.max(block.limit[2], block.position[0] + block.size[0] + (shift[2] || 0)) + Math.min((!Number.isNaN(shift[0]) && !empty && shift[0]) || 0, 0)),
      Math.max(block.base[3], (!Number.isNaN(shift[1]) && !empty && Number.isFinite(border?.[3]) && border[3]) || block.base[3], Math.max(block.limit[3], block.position[1] + block.size[1] + (shift[3] || 0)) + Math.min((!Number.isNaN(shift[1]) && !empty && shift[1]) || 0, 0)),
    ];

    offset.set([
      frame[0] - block.position[0],
      frame[1] - block.position[1],
    ]);

    size.set([
      frame[2] - frame[0],
      frame[3] - frame[1],
    ]);
  };

  const onDrop = (shift: [number, number, number, number]) => {
    const size = points.elements[element.id].size;
    const position = points.elements[element.id].position;

    dispatch(storeDiagram.setElement({
      id: element.id,
      properties: {
        width: size[0],
        height: size[1],
        position: [
          position[0],
          position[1],
        ],
        border: [
          Number.isNaN(shift[0]) ? border?.[0] : Math.min(block.limit[0], block.position[0] + block.size[0] - (payload.minWidth ?? config.minWidth), block.position[0] + shift[0]),
          Number.isNaN(shift[1]) ? border?.[1] : Math.min(block.limit[1], block.position[1] + block.size[1] - (payload.minHeight ?? config.minHeight), block.position[1] + shift[1]),
          Number.isNaN(shift[2]) ? border?.[2] : Math.max(block.limit[2], block.position[0] + (payload.minWidth ?? config.minWidth), block.position[0] + block.size[0] + shift[2]),
          Number.isNaN(shift[3]) ? border?.[3] : Math.max(block.limit[3], block.position[1] + (payload.minHeight ?? config.minHeight), block.position[1] + block.size[1] + shift[3]),
        ],
      },
    }));
  };

  const onDelete = (index?: number) => {
    dispatch(storeDiagram.setElement({
      id: element.id,
      properties: {
        border: [
          index === 0 ? undefined : border?.[0],
          index === 1 ? undefined : border?.[1],
          index === 2 ? undefined : border?.[2],
          index === 3 ? undefined : border?.[3],
        ],
      },
    }));
  };

  useEffect(() => {
    dragging.set(drag);
  }, [drag]);

  useLayoutEffect(() => {
    if (element.wrap.ref && !moving) {
      element.wrap.ref.style.marginLeft = '';
      element.wrap.ref.style.marginTop = '';

      element.updatePoints();
    }
  }, [padding, border]);

  useLayoutEffect(() => {
    if (element.wrap.ref && empty) {
      element.wrap.ref.style.marginLeft = '';
      element.wrap.ref.style.marginTop = '';

      element.updatePoints();
    }
  }, [height, width, position]);

  useLayoutEffect(() => {
    element.updatePoints(!drag || (drag !== element.id && !selection.includes(element.id)));
  }, [block.size[0], block.size[1], block.position[0], block.position[1]]);

  useEffect(() => {
    if (!drag || (drag !== element.id && !selection.includes(element.id))) {
      points.move({
        id: element.id,
        offset: [0, 0],
      });

      Emitter.emit('set-bounds');
    }
  }, [!drag || (drag !== element.id && !selection.includes(element.id))]);

  useLayoutEffect(() => {
    if (element.wrap.ref && active.value) {
      element.wrap.ref.style.marginLeft = `${offset.value[0]}px`;
      element.wrap.ref.style.marginTop = `${offset.value[1]}px`;

      element.updatePoints();
    }
  }, [offset.value]);

  return {
    position: block.position,
    visibility: element.selected && !lock,
    status: active.value && !lock,
    wrap: {
      ref: wrap.link,
      style: (size.value.length > 0) ? {
        width: size.value[0],
        height: size.value[1],
      } : {
        width: block.size[0],
        height: block.size[1],
      },
    },
    attributes: {
      id: element.id,

      onUpdate,
      onDrop,
      onDelete,

      onStart,
      onEnd,
    },
  };
};
