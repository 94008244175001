import { useContext, useMemo } from 'react';
import { getCacheProperty, getState } from 'utils/store';

import { useStoreSelector } from 'hooks/useStore';

import { ElementContext } from 'elements/Block/Element';
import { useOption } from 'hooks/useOption';

export const useClosed = () => {
  const element = useContext(ElementContext);

  const closed = useStoreSelector((state) => {
    const joins = (state.diagram.joins.from[element.id] ?? []).filter((id) => {
      return state.diagram.file[id.split('.')[1] ?? '']?.type === 'information-connector';
    });

    return joins.length > 0 && joins.every((id) => {
      const key = id.split('.')[0];

      const collapsed = getCacheProperty(state, key, 'collapsed') ?? false;
      const collapsable = !state.graphic.options[key]?.collapsed;
      const closed = state.graphic.options[key]?.closed;

      return closed || (collapsed && collapsable);
    });
  });

  useOption({
    id: element.id,
    key: 'closed',
    value: closed,
    deps: [closed],
  });

  return closed;
};
