import React, { useContext } from 'react';
import { getPathSideCode } from 'utils/graphic';
import { getState } from 'utils/store';
import { blocks } from 'blocks';

import { ElementContext } from 'elements/Block/Element';

export interface INeighbor {
  id: string;
  side: 'L' | 'T' | 'R' | 'B';
}

export const useNeighborElement = (): (direction: number[]) => INeighbor => {
  const context = useContext(ElementContext);

  return (direction: number[]) => {
    const drag = getState((state) => state.graphic.drag);

    const element = {
      id: context.id,
      direction,
      side: '',
    };

    if (blocks[drag?.element.type ?? '_']?.element.options.includes('element-layout')) {
      element.id = '';
    }

    if (Math.abs(direction[0]) === Math.abs(direction[1])) {
      element.id = '';
    }

    return {
      id: element.id,
      side: getPathSideCode(element.direction),
    };
  };
};
