import React, { useContext, useMemo } from 'react';
import { renderPathDefault } from 'utils/graphic';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';

export interface ICap {
  color: string;
  mask?: boolean;
}

export const useConnectorCap = (Start: Record<number, React.ComponentType<ICap>>, End: Record<number, React.ComponentType<ICap>>) => {
  const element = useContext(ElementContext);

  const start = useStoreSelector((state) => state.diagram.file[element.id].start as number);
  const end = useStoreSelector((state) => state.diagram.file[element.id].end as number);

  const Cap = {
    Start: Start[start],
    End: End[end],
  };

  if (!Cap.Start) {
    Cap.Start = Start[0];
  }

  if (!Cap.End) {
    Cap.End = End[0];
  }

  return Cap;
};
