import React, { FC, ReactNode, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { getState } from 'utils/store';
import { Emitter } from 'utils/events';
import { points } from 'utils/points';

import { storeDiagram } from 'store/diagram';

import { useOption } from 'hooks/useOption';
import { useProperty } from 'hooks/useProperty';
import { useConnectorParents } from 'hooks/useConnectorParents';
import { useConnectorInvisible } from 'hooks/useConnectorInvisible';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { CanvasStaticContext } from 'elements/Canvas';

import { ElementContext } from './Element';
import { Captured } from './Captured';

export interface IPoint {
  id: string;
  parent: string;
  role: 'mayor' | 'minor';
  type: 'default' | 'parent' | 'join';
  position: number[];
}

export interface IConnector {
  children: ReactNode;

  classElement: string;
  classProperties?: string;
  position: number[];
  path: number[][];
}

export const Connector: FC<IConnector> = ({ children, classElement, classProperties, position, path }) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);
  const canvas = useContext(CanvasStaticContext);

  const mode = useStoreSelector((state) => state.diagram.mode);
  const copy = useStoreSelector((state) => state.graphic.copy);
  const drag = useStoreSelector((state) => state.graphic.drag);
  const layout = useStoreSelector((state) => state.graphic.layout.key);
  const moveable = useStoreSelector((state) => !!state.diagram.file[element.id].points?.find((point: IPoint) => !point.parent));
  const loading = useStoreSelector((state) => !state.graphic.elements[element.id] || !(state.diagram.file[element.id].points ?? []).every((point: IPoint) => {
    return !point.parent || (!!state.graphic.elements[point.parent] && !state.graphic.options[point.parent]?.closed);
  }));

  const connector = useRef(path);
  const hidden = useProperty(loading);
  const hiding = useProperty(loading);

  connector.current = path;

  const parents = useConnectorParents();
  const invisible = useConnectorInvisible();

  useOption({
    id: element.id,
    key: 'closed',
    value: loading,
    deps: [loading],
  });

  // Set Hidden
  useEffect(() => {
    hidden.set(loading);
  }, [loading]);

  // Initialize points
  useLayoutEffect(() => {
    if (!canvas.loading) {
      element.updatePoints();
    }
  }, [canvas.loading]);

  // Set Hidden Transition
  useLayoutEffect(() => {
    if (!hidden.value) {
      points.update({
        id: element.id,
      });

      setTimeout(() => {
        hiding.set(false);
      }, 300);
    }
  }, [hidden.value]);

  // Set Shape Points
  useLayoutEffect(() => {
    points.shape({
      id: element.id,
      forced: true,
      getter: () => {
        return connector.current as ([number, number][]);
      },
    });

    return () => {
      points.unshape({
        id: element.id,
      });
    };
  }, [element.id]);

  // Listen to Move Event
  useEffect(() => {
    if (!moveable) {
      return () => {};
    }

    const onMove = (payload: { id: string, position: [number, number], parent: string }) => {
      if (payload.id !== element.id) {
        return;
      }

      const vertices = getState((state) => state.diagram.file[element.id].points);
      const offset = points.elements[element.id]?.offset ?? [0, 0];

      dispatch(storeDiagram.setElement({
        id: payload.id,
        properties: {
          position: [0, 0],
          parent: '',
          points: vertices?.map((point: IPoint) => {
            if (!point.parent && point.role === 'mayor') {
              return {
                ...point,

                position: [
                  point.position[0] + offset[0],
                  point.position[1] + offset[1],
                ],
              };
            }

            return point;
          }),
        },
      }));
    };

    Emitter.on('move-element', onMove);

    return () => {
      Emitter.off('move-element', onMove);
    };
  }, [element.id, moveable, position[0], position[1]]);

  return (
    <div
      className={
        `diagram-element element-connector ${classElement}`
          .append(classProperties)
          .appendWhen(element.grabbed && !copy, 'grabbed')
          .appendWhen(element.selected, 'selected')
          .appendWhen(hidden.value, 'hidden')
          .appendWhen(hiding.value, 'hiding')
          .appendWhen(parents.has(drag?.element.id ?? '') && layout && !copy, 'frame')
          .appendWhen(element.locked, 'locked')
          .appendWhen(element.captured, 'captured')
          .appendWhen(element.highlighted, 'highlighted')
          .appendWhen(invisible, 'invisible')
          .appendWhen(element.focused || (mode === 'view' && element.highlighted), 'focused')
      }
      ref={element.wrap.link}
      style={{
        position: 'absolute',
        left: position[0],
        top: position[1],
      }}
    >
      {!loading && (
        <>
          {children}
          <Captured />
        </>
      )}
    </div>
  );
};
