import { ITool } from 'tools';
import { blocks } from 'blocks';
import { createTool } from 'tools/ToolTab';

import iconS from './assets/s.svg';
import iconM from './assets/m.svg';
import iconL from './assets/l.svg';

const Tool = createTool('padding', 'padding', 'm', 'Padding', 'Padding', {
  s: iconS,
  m: iconM,
  l: iconL,
}, (state) => {
  const selection = state.graphic.selection.elements;

  return !selection.every((id) => {
    if (blocks[state.diagram.file[id]?.type]?.element.options.includes('element-container')) {
      return !((state.diagram.file[id]?.elements ?? []).length > 0);
    }

    return (
      !blocks[state.diagram.file[id]?.type]?.element.options.includes('element-layout')
      && !(
        blocks[state.diagram.file[id]?.type]?.element.options.includes('element-wrap')
        && state.diagram.childs[id]?.length > 0
      )
    );
  });
});

export const Padding: ITool = {
  key: 'padding',
  tool: Tool,
  options: ['only-edit'],
};
