import React, { FC, ReactNode, useContext, useEffect, useLayoutEffect } from 'react';
import { Emitter } from 'utils/events';
import { points } from 'utils/points';

import { storeDiagram } from 'store/diagram';

import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { ElementContext } from './Element';
import { Captured } from './Captured';

export interface ILayout {
  children: ReactNode;

  classElement: string;
  classProperties?: string;
}

export const Layout: FC<ILayout> = ({ children, classElement, classProperties }) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  const copy = useStoreSelector((state) => state.graphic.copy);
  const parent = useStoreSelector((state) => state.diagram.file[element.id].parent);
  const position = useStoreSelector((state) => state.diagram.file[element.id].position);
  const frame = useStoreSelector((state) => !!(state.graphic.drag?.element.id === element.id && state.graphic.layout.key));

  const loading = !points.elements[element.id]?.size;

  // Set Shape Points
  useLayoutEffect(() => {
    points.shape({
      id: element.id,
    });

    return () => {
      points.unshape({
        id: element.id,
      });
    };
  }, []);

  // Update Frame Points on Position Change
  useLayoutEffect(() => {
    if (!parent) {
      element.updatePoints();
    }
  }, [position[0], position[1]]);

  // Listen to Move Event
  useEffect(() => {
    const onMove = (payload: any) => {
      if (payload.id !== element.id) {
        return;
      }

      dispatch(storeDiagram.setElement({
        id: payload.id,
        properties: {
          position: payload.position,
          parent: payload.parent,
        },
      }));
    };

    Emitter.on('move-element', onMove);

    return () => {
      Emitter.off('move-element', onMove);
    };
  }, [element.id]);

  return (
    <div
      className={
        `diagram-element element-layout ${classElement}`
          .append(classProperties)
          .appendWhen(element.grabbed && !copy, 'grabbed')
          .appendWhen(element.selected, 'selected')
          .appendWhen(loading, 'hidden')
          .appendWhen(element.captured, 'captured')
          .appendWhen(frame && !copy, 'frame')
          .appendWhen(element.locked, 'locked')
          .appendWhen(element.containered, 'containered')
          .appendWhen(element.invisible, 'invisible')
      }
      ref={element.wrap.link}
      style={{
        position: parent ? undefined : 'absolute',
        left: parent ? undefined : position[0],
        top: parent ? undefined : position[1],
      }}
    >
      {children}
      <Captured />
    </div>
  );
};
