import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { Model } from 'blocks/factories/shape/Model';
import { createRender } from 'blocks/factories/simple/Render';
import { createPadding } from 'blocks/factories/simple/Padding';
import { createBlock, block } from 'blocks/factories/shape/Block';

import icon from './assets/icon.svg';

type IModel = Model<{}>;

export const config: IConfig = {
  title: 'Connector',
  search: 'flowchart shape connector',
  type: 'flowchart-shape-connector',
  group: ['Flowchart Shape'],
  memo: {
    key: 'flowchart-shape',
    styles: block.element.memo,
  },
  styles: block.element.styles,
};

const Render = createRender<IModel>({
  render: ({ width, height, element, properties }) => {
    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - border, 0);
    const y = Math.max(height - border, 0);

    const points = [
      [x / 2 + offset, y / 2 + offset],
      [x / 2, y / 2],
    ];

    return (
      <svg className="block-render" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse {...element.trigger} className={'render-fill render-stroke'.append(properties.lock ? 'render-hit-stroke' : 'render-hit')} cx={points[0][0]} cy={points[0][1]} rx={points[1][0]} ry={points[1][1]} strokeLinecap="round" strokeWidth={border} />
      </svg>
    );
  },
});

const Padding = createPadding<IModel>({
  size: [0, 0],
  padding: [1, 1, 1, 1],
  creator: () => {
    return [10, 10, 10, 10];
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  Render,
  Padding,
});

export const FlowchartShapeConnector : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: block.element.options,
    constraints: block.element.constraints,
    onCreate: block.creator(config, {
      width: 64,
      height: 64,
    }),
    onCopy: block.copier(config),
    title: config.title,
    size: [64, 64],
    icon,
    class: block.element.class,
  },
  button: block.button(config),
  tools: ['shape-flowchart', ...block.tools],
  toolbars: block.toolbars,
};
