import React, { FC } from 'react';
import ReactDOM from 'react-dom';

import { IAlert } from 'store/alert';
import { OptionAnimation } from 'store/settings';

import { serviceAlert } from 'services/alert';

import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';
import { useAnimation } from 'hooks/useAnimation';

const root = document.getElementById('alert-root') as HTMLElement;

const Message: FC<IAlert> = ({ type, message }) => {
  const dipatch = useStoreDispatch();

  const status = useStoreSelector((state) => state.settings.app.animation.includes(OptionAnimation.Notification));

  const animation = useAnimation(status ? 'animation-scale' : '');

  const onHide = () => {
    dipatch(serviceAlert.hideAlert());
  };

  return (
    <button type="button" onClick={onHide} className={`element-alert-message ${type} ${animation.name}`}>
      {message}
    </button>
  );
};

export const Alert = () => {
  const alert = useStoreSelector((state) => state.alert);

  return ReactDOM.createPortal(
    (
      <div className={`element-alert${alert.active ? ' active' : ''}`}>
        {Object.values([alert.id]).map((id) => (
          <Message key={id} message={alert.message} type={alert.type} />
        ))}
      </div>
    ),
    root
  );
};
