import React, { FC, ReactNode, useContext } from 'react';

import { IElement } from 'hooks/useElement';
import { usePointsElement } from 'hooks/usePointsElement';

import { ElementContext } from 'elements/Block/Element';

interface ICell {
  children?: ReactNode;
  element: IElement<HTMLDivElement>
  point: number[];
}

export const Cell: FC<ICell> = ({ children, point, element: wrap }) => {
  const element = useContext(ElementContext);

  usePointsElement({
    id: `${element.id}.${point[0]}-${point[1]}`,
    parent: element.id,
    ref: wrap.ref,
  });

  return (
    <div
      ref={wrap.link}
      className="block-wrap"
    >
      {children}
    </div>
  );
};
