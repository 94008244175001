import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDiagram, getTemplate } from 'utils/models';
import errors from 'utils/errors';

import { ITemplates } from 'tabs/Template';

import { api } from '.';

interface ICallback<T = boolean> {
  callback?: (payload?: T) => void;
}

export const serviceCollection = {
  getCollection: createAsyncThunk('collection/get', async (_, thunk) => {
    try {
      const response = await api.get('/diagrams/collection');

      const boards = response.data.diagrams.map((diagram: any) => {
        return getDiagram(diagram);
      });

      const templates = response.data.templates.map((template: any) => {
        return getTemplate(template);
      });

      return {
        boards,
        templates,
      };
    } catch (e) {
      errors(e, thunk);

      return thunk.rejectWithValue([]);
    }
  }),
  getTemplates: createAsyncThunk('collection/getTemplates', async (payload: ICallback<ITemplates>, thunk) => {
    try {
      const response = await api.get('/diagrams/collection/templates');

      if (payload.callback) {
        payload.callback({
          saved: response.data.saved.map((template: any) => getTemplate(template)),
          provided: response.data.provided.map((template: any) => getTemplate(template)),
        });
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk);
    }
  }),
};
