import { useEffect } from 'react';

import security, { ISession } from 'utils/security';
import { serviceAccount } from 'services/account';

import { IUser } from 'store/account';

import { useProperty } from './useProperty';
import { useStoreDispatch, useStoreSelector } from './useStore';

export type IAuthStatus = 'loading' | 'authorized' | 'unverified' | 'unauthorized';

const getStatus = (user: IUser, active: boolean, session: ISession) : IAuthStatus => {
  if (!session.access) {
    return 'unauthorized';
  }

  if (user.id && !active) {
    return 'unverified';
  }

  if (user.id) {
    return 'authorized';
  }

  return 'loading';
};

export const getLink = (status: IAuthStatus, saveLocation: boolean = false) => {
  if (status === 'authorized') {
    return '/home/recent';
  }

  if (status === 'unauthorized') {
    if (saveLocation) {
      const path = encodeURIComponent(`${window.location.pathname}${window.location.search}`);

      return `/signin${path.length > 3 ? `?from=${path}` : ''}`;
    }

    return '/signin';
  }

  if (status === 'unverified') {
    return '/verify';
  }

  return '/';
};

export const useAuth = () => {
  const dispatch = useStoreDispatch();

  const user = useStoreSelector((state) => state.account.user);
  const active = useStoreSelector((state) => state.account.active);

  const session = security.getSession();

  const status = useProperty(getStatus(user, active, session));

  // Process user access
  useEffect(() => {
    if (user.id) {
      return;
    }

    if (session.access) {
      dispatch(serviceAccount.setUser({
        onAuthorized: () => status.set('authorized'),
        onUnauthorized: () => status.set('unauthorized'),
        onUnverified: () => status.set('unverified'),
      }));
    }
  }, []);

  return status.value;
};
