import React, { useEffect } from 'react';
import { getState } from 'utils/store';
import { Emitter } from 'utils/events';

import { storeSettings } from 'store/settings';

import { useSessionListener, useVerificationListener } from 'hooks/useEvent';
import { useStoreDispatch } from 'hooks/useStore';

export const Listener = () => {
  const dispatch = useStoreDispatch();

  useSessionListener(() => window.location.reload());
  useVerificationListener(() => window.location.reload());

  // Reporting System
  useEffect(() => {
    const send = (origin: string) => {
      const diagram = getState((state) => state.diagram.diagram);
      const user = getState((state) => state.account.user);

      const payload: Record<string, string> = {
        'feedback-origin': origin,
        'user-id': user.id,
        'user-email': user.email,
        'user-name': user.name,
      };

      if (diagram.id) {
        payload.board = diagram.id;
      }

      (window as any).appziSettings.data = payload;
      (window as any).appzi?.openSurvey('86fc0784-f06b-4c5f-9637-eca0bfff347e');
    };

    Emitter.on('send-feedback', send);

    return () => {
      Emitter.off('send-feedback', send);
    };
  }, []);

  // Listen to online status
  useEffect(() => {
    const onOffline = () => {
      dispatch(storeSettings.setOffline(true));
    };

    const onOnline = () => {
      dispatch(storeSettings.setOffline(false));
    };

    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener('online', onOnline);
    };
  }, []);

  // Detect mobile device
  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    const isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });

    dispatch(storeSettings.setMobile(isMobile));
  }, []);

  return null;
};
