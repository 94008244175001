import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import { getQuillFormat } from 'utils';
import { Emitter } from 'utils/events';

import { useProperty } from './useProperty';
import { useStoreSelector } from './useStore';

const Delta = ReactQuill.Quill.import('delta');

export interface IInput {
  html: string;
  blur: () => void;
  hasFocus: () => boolean;
  focus: () => void;
  reset: () => void;
  validate: () => boolean;
  mistake: React.MutableRefObject<string>;
}

export interface IEditableResult extends IInput {
  set: (value: string) => void;
  ref: any | null;
  default: string;
  value: React.MutableRefObject<string>;
  error: string;
  html: string;
  attributes: any;
}

export interface IEditable {
  value: string;
  formats: string[];
  bindings: { [key: string]: (input: IInput) => boolean | undefined };
  validator?: (value: string) => string,
  modifier?: (value: string) => string;
  onBlur?: (value: string) => void;
  deps?: any[];
}

export const useEditable = ({
  value,
  formats,
  bindings,
  validator = () => '',
  modifier = (value) => value,
  onBlur = () => {},
  deps = [],
}: IEditable) : IEditableResult => {
  const preventable = useStoreSelector((state) => state.graphic.component.tab === 'format-link');

  const ref = useProperty<ReactQuill | null>(null);
  const field = useRef(value);
  const html = useProperty(value);
  const mistake = useRef('');
  const input = useRef<IInput | null>(null);
  const error = useProperty('');

  const link = useCallback((node: ReactQuill) => {
    if (node) {
      ref.set(node);

      node.editor?.clipboard.addMatcher(Node.TEXT_NODE, (_, delta) => {
        return delta.compose(new Delta().retain(delta.length(), { background: '' }));
      });
    }
  }, []);

  const optimize = (value: string) => modifier((value || '').replace(/^(<p><br.?.?><\/p>)+|(<p><br.?.?><\/p>)+$/gmi, '').trim());

  const set = (value: string) => {
    field.current = value;
    html.set(field.current);

    if (error.value) {
      const result = validator(optimize(value));

      error.set(result);
      mistake.current = result;
    }
  };

  // Initialize
  useEffect(() => {
    const document = window as any;

    const blur = (e: any) => {
      if ((e?.relatedTarget as HTMLElement)?.classList.contains('ql-clipboard')) {
        return;
      }

      document.content = undefined;

      if (preventable) {
        document.content = document.editor;
      } else if (onBlur) {
        onBlur(optimize(field.current));
      }

      document.editor = undefined;
      Emitter.emit('editor-change');
    };

    const focus = () => {
      document.content = undefined;
      document.editor = ref.value?.editor;
      Emitter.emit('editor-change');
    };

    const onSelectionChange = (range: ReactQuill.Range) => {
      if (document.editor === ref.value?.editor) {
        setTimeout(() => {
          Emitter.emit('selection-change', { ...getQuillFormat(ref.value), length: range?.length || 0 });
        });
      }
    };

    const onTextChange = () => {
      if (document.editor === ref.value?.editor) {
        setTimeout(() => {
          Emitter.emit('selection-change', { ...getQuillFormat(ref.value), length: -1 });
        });
      }
    };

    ref.value?.editor?.root.setAttribute('spellcheck', 'false');
    ref.value?.editor?.root.setAttribute('autocapitalize', 'off');
    ref.value?.editor?.root.setAttribute('autocomplete', 'off');
    ref.value?.editor?.root.setAttribute('autocorrect', 'off');

    ref.value?.editor?.root.addEventListener('blur', blur);
    ref.value?.editor?.root.addEventListener('focus', focus);
    ref.value?.editor?.on('selection-change', onSelectionChange);
    ref.value?.editor?.on('text-change', onTextChange);

    return () => {
      ref.value?.editor?.root.removeEventListener('blur', blur);
      ref.value?.editor?.root.removeEventListener('focus', focus);
      ref.value?.editor?.off('selection-change', onSelectionChange);
      ref.value?.editor?.off('text-change', onTextChange);
    };
  }, [ref.value, preventable, ...deps]);

  // Creat history
  useEffect(() => {
    ref.value?.editor?.getModule('history')?.clear();
  }, [ref.value]);

  // Listen to Formatting Instuctions
  useEffect(() => {
    const onFormat = (payload: {[key: string]: boolean}) => {
      if ((window as any).editor === ref.value?.editor || (window as any).content === ref.value?.editor) {
        Object.entries(payload).forEach(([key, value]) => {
          if (key === 'link') {
            const range = ref.value?.editor?.getSelection();

            if (range) {
              if (range.length === 0) {
                const leaf = ref.value?.editor?.getLeaf(range.index) || [0, 0];

                ref.value?.editor?.formatText(range.index - leaf[1], leaf[0].domNode.length, key, value);
              } else {
                ref.value?.editor?.formatText(range.index, range.length, key, value);
              }
            } else {
              ref.value?.editor?.format(key, value);
            }
          } else {
            ref.value?.editor?.format(key, value);
          }
        });
      }
    };

    Emitter.on('selection-format', onFormat);

    return () => {
      Emitter.off('selection-format', onFormat);
    };
  }, [ref.value]);

  input.current = {
    html: field.current,
    mistake,
    hasFocus: () => {
      return document.activeElement === ref.value?.editor?.root;
    },
    focus: () => {
      ref.value?.focus();
      ref.value?.editor?.setSelection(1000000, 0);
    },
    blur: () => {
      ref.value?.blur();
    },
    reset: () => {
      field.current = value;
      mistake.current = '';
      html.set(value);
      error.set('');
    },
    validate: () => {
      const result = validator(optimize(field.current));

      error.set(result);
      mistake.current = result;

      return !result;
    },
  };

  return {
    ...input.current,
    set,
    error: error.value,
    default: value,
    value: field,
    ref: ref.value,
    html: html.value,
    attributes: {
      ref: link,
      value: html.value,
      modules: useMemo(() => ({
        toolbar: false,
        clipboard: {
          matchVisual: false,
        },
        keyboard: {
          bindings: {
            bold: {
              key: 'B',
              shortKey: true,
              handler: () => {
                Emitter.emit('format-emit', { format: 'bold', value: true });
              },
            },
            italic: {
              key: 'I',
              shortKey: true,
              handler: () => {
                Emitter.emit('format-emit', { format: 'italic', value: true });
              },
            },
            underline: {
              key: 'U',
              shortKey: true,
              handler: () => {
                Emitter.emit('format-emit', { format: 'underline', value: true });
              },
            },
            strike: {
              key: 'X',
              shortKey: true,
              shiftKey: true,
              handler: () => {
                Emitter.emit('format-emit', { format: 'strike', value: true });
              },
            },
            escape: {
              key: 'Escape',
              handler: () => {
                if (bindings.escape && input.current) {
                  return bindings.escape(input.current);
                }

                return true;
              },
            },
            enter: {
              key: 13,
              handler: () => {
                if (bindings.enter && input.current) {
                  return bindings.enter(input.current);
                }

                return true;
              },
            },
            shiftEnter: {
              key: 13,
              shiftKey: true,
              handler: () => {
                if (bindings.shiftEnter && input.current) {
                  return bindings.shiftEnter(input.current);
                }

                return true;
              },
            },
            tab: bindings.next && {
              key: 9,
              handler: () => {
                if (input.current) {
                  return bindings.next(input.current);
                }

                return true;
              },
            },
            shiftTab: bindings.prev && {
              key: 9,
              shiftKey: true,
              handler: () => {
                if (input.current) {
                  return bindings.prev(input.current);
                }

                return true;
              },
            },
          },
        },
      }), []),
      formats: useMemo(() => formats, []),
      onChange: useCallback((value: string) => {
        set(value);
      }, [ref.value, error.value]),
    },
  };
};
