import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { Model } from 'blocks/factories/shape/Model';
import { createRender } from 'blocks/factories/simple/Render';
import { createPadding } from 'blocks/factories/simple/Padding';
import { createBlock, block } from 'blocks/factories/shape/Block';

import icon from './assets/icon.svg';

const { serialize } = require('svg-path-round-corners/dist/es6/serialize');
const { roundCorners } = require('svg-path-round-corners/dist/es6/index');

type IModel = Model<{}>;

export const config: IConfig = {
  title: 'Hexagon',
  search: 'basic shape hexagon',
  type: 'basic-shape-hexagon',
  group: ['Basic', 'Basic Shape'],
  memo: {
    key: 'basic-shape',
    styles: block.element.memo,
  },
  styles: block.element.styles,
};

const Render = createRender<IModel>({
  render: ({ width, height, element }) => {
    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - offset, 0);
    const y = Math.max(height - offset, 0);

    const points = [
      { c: 'M', x: (64 / 64) * x, y: (32 / 64) * y },
      { c: 'L', x: (48 / 64) * x, y: (64 / 64) * y },
      { c: 'H', x: offset + (16 / 64) * x },
      { c: 'L', x: offset + (0 / 64) * x, y: (32 / 64) * y },
      { c: 'L', x: offset + (16 / 64) * x, y: offset + (0 / 64) * y },
      { c: 'H', x: (48 / 64) * x },
      { c: 'Z' },
    ];

    return (
      <svg className="block-render" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path {...element.trigger} className="render-fill render-stroke render-hit" d={serialize(roundCorners(points, 6))} strokeLinejoin="round" strokeLinecap="round" strokeWidth={border} />
      </svg>
    );
  },
});

const Padding = createPadding<IModel>({
  size: [0, 0],
  padding: [1, 1, 1, 1],
  creator: () => {
    return [10, 10, 10, 10];
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  Render,
  Padding,
});

export const BasicShapeHexagon : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: block.element.options,
    constraints: block.element.constraints,
    onCreate: block.creator(config, {
      width: 72,
      height: 64,
    }),
    onCopy: block.copier(config),
    title: config.title,
    size: [72, 64],
    icon,
    class: block.element.class,
  },
  button: block.button(config),
  tools: ['shape-basic', ...block.tools],
  toolbars: block.toolbars,
};
