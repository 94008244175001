import React, { useEffect, useRef, useState } from 'react';
import { Emitter } from 'utils/events';
import { ITab } from 'tabs';

import { storeGraphic } from 'store/graphic';

import { useHotkey } from 'hooks/useHotkey';
import { useProperty } from 'hooks/useProperty';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import style from './assets/style.module.css';
import iconBack from './assets/back.svg';

export const colors: {[key: string]: string} = {
  'rgba(75, 75, 75, 0.35)': '#3A3D4E',
  'rgba(237, 196, 49, 0.35)': '#FFCF35',
  'rgba(237, 49, 94, 0.35)': '#FD3063',
  'rgba(56, 206, 116, 0.35)': '#68CC95',
  'rgba(49, 136, 237, 0.35)': '#72A5F1',
  'rgba(173, 65, 211, 0.35)': '#AE72DE',
  'rgba(203, 203, 207, 0.35)': '#CACADE',
};

const Tab = () => {
  const dispatch = useStoreDispatch();

  const selection = useStoreSelector((state) => state.graphic.selection.elements.length === 1);
  const [status, setStatus] = useState<string>(() => {
    try {
      return ((window as any).editor?.getFormat() || {}).background;
    } catch (e) {
      return '';
    }
  });
  const visible = useProperty(!!(window as any).editor || selection);
  const empty = useRef(true);

  const shift = useHotkey({ shift: true });

  const onBack = () => {
    dispatch(storeGraphic.setComponentTab(''));
  };

  const onClick = (color: string) => {
    if (empty) {
      setStatus(color);
    }

    Emitter.emit('selection-format', { background: color });

    if (!shift) {
      onBack();
    }
  };

  // Listen to Editor Change
  useEffect(() => {
    const onChange = () => {
      visible.set(!!(window as any).editor || selection);
    };

    Emitter.on('editor-change', onChange);

    return () => {
      Emitter.off('editor-change', onChange);
    };
  }, []);

  // Listen to Selection Change
  useEffect(() => {
    const onSelect = (payload: {[key: string]: string}) => {
      setStatus(payload.background);
      empty.current = !(payload.length || 0);
    };

    Emitter.on('selection-change', onSelect);

    return () => {
      Emitter.off('selection-change', onSelect);
    };
  }, []);

  return (
    <div className="group-icon">
      <Hint message="Back" side="bottom" variant="default">
        <button type="button" className="button-icon v-1" onClick={onBack} onMouseDown={(e) => e.preventDefault()}>
          <img src={iconBack} width={28} height={28} alt="Back" />
        </button>
      </Hint>

      <div className="icon-separator" />

      {Object.entries(colors).map(([key, color]) => (
        <button key={key} type="button" className={`button-icon v-1${status === key ? ' active' : ''}`} onClick={() => onClick(key)} onMouseDown={(e) => e.preventDefault()}>
          <div className={style.icon} style={{ background: color }} />
        </button>
      ))}
    </div>
  );
};

export const FormatHighlight: ITab = {
  key: 'format-highlight',
  tab: Tab,
};
