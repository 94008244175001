import { useContext, useMemo } from 'react';
import { blocks } from 'blocks';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';
import { IPointControl } from './useConnector';

export const config = {
  padding: 24,
  sharp: 1.35,
  approximation: 4,
};

interface INormalized {
  normalized: number[];
}

export interface ILever {
  left: number[];
  right: number[];
}

export interface ILevers {
  points: number[][][] | undefined;
  handles: ILever[];
}

const getNormalized = (point: number[]): INormalized => ({
  normalized: [
    point[0],
    point[1],
  ],
});

const getPoint = (a: INormalized, b: INormalized) => {
  const point = {
    direction: [0, 0],
    length: 0,
    normalized: [0, 0],
  };

  point.direction = [
    b.normalized[0] - a.normalized[0],
    b.normalized[1] - a.normalized[1],
  ];

  point.length = (point.direction[0] ** 2 + point.direction[1] ** 2) ** (1 / 2);

  point.normalized = [
    point.direction[0] / (point.length || 1),
    point.direction[1] / (point.length || 1),
  ];

  return point;
};

export const useConnectorSmooth = (points: IPoint[], vertices: IPointControl[]) => {
  const element = useContext(ElementContext);

  const style = useStoreSelector((state) => state.diagram.file[element.id].style);
  const centered = useStoreSelector((state) => blocks[state.diagram.file[element.id].type]?.element.options.includes('connector-centered'));

  return useMemo(() => {
    if (style !== 'smooth') {
      return {
        points: undefined,
        handles: points.map((point) => ({
          left: [
            point.position[0],
            point.position[1],
          ],
          right: [
            point.position[0],
            point.position[1],
          ],
        })),
      };
    }

    const preprocess = points.map((point, index) => {
      if (index > 0 && index < points.length - 1 && index % 2 === 0) {
        return {
          left: [0, 0],
          right: [0, 0],
        };
      }

      const before = points[index - 1] ?? point;
      const after = points[index + 1] ?? point;

      const prev = getNormalized(before.position);
      const next = getNormalized(after.position);
      const current = getNormalized(point.position);

      const backward = getPoint(current, prev);
      const forward = getPoint(current, next);
      const tangent = getPoint(forward, backward);

      if (!centered && before === point && !vertices[index]._moving) {
        return {
          left: [
            current.normalized[0],
            current.normalized[1],
          ],
          right: [
            current.normalized[0] + vertices[index]._side[0] * (forward.length / (2 * config.sharp)),
            current.normalized[1] + vertices[index]._side[1] * (forward.length / (2 * config.sharp)),
          ],
        };
      }

      if (!centered && (before === point || after === point) && vertices[index]._moving) {
        return {
          left: [
            current.normalized[0],
            current.normalized[1],
          ],
          right: [
            current.normalized[0],
            current.normalized[1],
          ],
        };
      }

      if (!centered && after === point) {
        return {
          left: [
            current.normalized[0] + vertices[index]._side[0] * (backward.length / (2 * config.sharp)),
            current.normalized[1] + vertices[index]._side[1] * (backward.length / (2 * config.sharp)),
          ],
          right: [
            current.normalized[0],
            current.normalized[1],
          ],
        };
      }

      return {
        left: [
          current.normalized[0] + tangent.normalized[0] * (backward.length / (2 * config.sharp)),
          current.normalized[1] + tangent.normalized[1] * (backward.length / (2 * config.sharp)),
        ],
        right: [
          current.normalized[0] - tangent.normalized[0] * (forward.length / (2 * config.sharp)),
          current.normalized[1] - tangent.normalized[1] * (forward.length / (2 * config.sharp)),
        ],
      };
    });

    const handles = preprocess.map((handle, index) => {
      if (index === 0 || index >= points.length - 1 || index % 2 !== 0) {
        return handle;
      }

      const prev = getNormalized(preprocess[index - 1].right);
      const next = getNormalized(preprocess[index + 1].left);
      const current = getNormalized(points[index].position);

      const backward = getPoint(current, prev);
      const forward = getPoint(current, next);
      const tangent = getPoint(forward, backward);

      return {
        left: [
          current.normalized[0] + tangent.normalized[0] * (backward.length / (2 * config.sharp)),
          current.normalized[1] + tangent.normalized[1] * (backward.length / (2 * config.sharp)),
        ],
        right: [
          current.normalized[0] - tangent.normalized[0] * (forward.length / (2 * config.sharp)),
          current.normalized[1] - tangent.normalized[1] * (forward.length / (2 * config.sharp)),
        ],
      };
    });

    return {
      points: points.map((_, index) => {
        if (index === points.length - 1) {
          return [
            [
              points[index].position[0],
              points[index].position[1],
            ],
          ];
        }

        const a = [
          points[index].position[0],
          points[index].position[1],
        ];

        const b = [
          handles[index].right[0],
          handles[index].right[1],
        ];

        const c = [
          handles[index + 1].left[0],
          handles[index + 1].left[1],
        ];

        const d = [
          points[index + 1].position[0],
          points[index + 1].position[1],
        ];

        return Array(config.approximation).fill(0).map((_, i) => {
          const t = i * (1 / config.approximation);

          return [
            ((1 - t) ** 3) * a[0] + 3 * ((1 - t) ** 2) * t * b[0] + 3 * (1 - t) * (t ** 2) * c[0] + (t ** 3) * d[0],
            ((1 - t) ** 3) * a[1] + 3 * ((1 - t) ** 2) * t * b[1] + 3 * (1 - t) * (t ** 2) * c[1] + (t ** 3) * d[1],
          ];
        });
      }),
      handles,
    };
  }, [points, style]);
};
