import { useContext, MouseEvent } from 'react';
import { Emitter } from 'utils/events';
import { getState } from 'utils/store';
import { points } from 'utils/points';

import { ElementContext } from 'elements/Block/Element';
import { usePointPosition } from './usePointPosition';

export const useDropFreely = (point?: [number, number]) => {
  const element = useContext(ElementContext);
  const parent = `${element.id}${point ? `.${point[0]}-${point[1]}` : ''}`;

  const getPosition = usePointPosition();

  const getPoint = (e: MouseEvent<HTMLDivElement>) => {
    const cursor = getPosition([e.clientX, e.clientY], [0, 0], [0, 0]);
    const wrap = points.elements[parent];

    return [
      (cursor[0] - wrap.position[0]) / wrap.size[0],
      (cursor[1] - wrap.position[1]) / wrap.size[1],
    ];
  };

  return {
    onDropDown: (e: MouseEvent<HTMLDivElement>) => {
      const drop = getState((state) => state.graphic.drop);

      if (drop?.type !== 'create') {
        return;
      }

      const position = getPoint(e);

      Emitter.emit('drop-down', {
        id: parent,
        position,
        event: e,
      });
    },
    onDropUp: (e: MouseEvent<HTMLDivElement>) => {
      const drop = getState((state) => state.graphic.drop);

      if (drop?.type !== 'move') {
        return;
      }

      const position = getPoint(e);

      Emitter.emit('drop-up', {
        id: parent,
        position,
        event: e,
      });
    },
    onDragUp: (e: MouseEvent<HTMLDivElement>) => {
      const position = getPoint(e);

      Emitter.emit('drag-up', {
        id: parent,
        position,
        event: e,
      });
    },
  };
};
