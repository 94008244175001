import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import iconCap0 from './assets/cap-0.svg';

export const Tool = createTool('connector-information-start', 'start', 0, 'Start', 'Start', {
  0: iconCap0,
});

export const ConnectorInformationStart: ITool = {
  key: 'connector-information-start',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
