import { useContext } from 'react';
import { store } from 'store';

import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

import { ElementContext } from 'elements/Block/Element';

import { IEditableResult } from './useEditable';
import { useStoreDispatch } from './useStore';

export interface IEditableBlur {
  key: string;
  property: string;
  onBefore?: (value: string, input: IEditableResult) => boolean;
}

export const useEditableBlur = ({ key, property, onBefore = () => true }: IEditableBlur) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  return (value: string, input: IEditableResult) => {
    if (onBefore && !onBefore(value, input)) {
      return;
    }

    if (!input.validate()) {
      dispatch(storeGraphic.setComponentFailed(true));

      return;
    }

    const state = store.getState();

    const selection = state.graphic.selection.elements;

    dispatch(storeGraphic.setComponentFailed(false));

    if (selection.length === 1) {
      setTimeout(() => {
        dispatch(storeGraphic.setComponentKeySafely({
          next: '',
          prev: key,
          element: element.id,
        }));
      }, 100);
    }

    if (value === input.default) {
      return;
    }

    const properties: Record<string, any> = {};

    properties[property] = value;

    dispatch(storeDiagram.setElement({
      id: element.id,
      properties,
    }));
  };
};
