import React, { FC, useContext } from 'react';
import { deleteElements, getBlockContextElements, getBlockContextPathes, getState } from 'utils/store';
import optimizers from 'utils/optimizers';
import ReactQuill from 'react-quill';
import { blocks } from 'blocks';

import { storeGraphic } from 'store/graphic';
import { OptionEmptyTextBlur } from 'store/settings';

import { useEditable } from 'hooks/useEditable';
import { useLineCounting } from 'hooks/useLineCounting';
import { useEditableBlur } from 'hooks/useEditableBlur';
import { useEditableBindings } from 'hooks/useEditableBindings';
import { useEditableComponent } from 'hooks/useEditableComponent';
import { useTabGridText } from 'hooks/useTabGridText';
import { useStoreDispatch } from 'hooks/useStore';

import { ElementContext } from 'elements/Block/Element';

import { IComponent, config } from '.';

import style from '../../assets/style.module.css';

export const Edit: FC<IComponent> = ({ value: field, property, placeholder }) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);
  const onTab = useTabGridText();

  const bindings = useEditableBindings({
    key: config.key,
    shift: true,
    onShiftEnter: (input) => {
      input.blur();
      onTab(input.html, 'B', true);
    },
  });

  const onBlur = useEditableBlur({
    property,
    key: config.key,
    onBefore: (value) => {
      if (optimizers.noHtml(value).trim() === '') {
        const blur = getState((state) => state.settings.app.emptyTextBlur);
        const def = optimizers.noHtml(field).trim();
        const deletable = getState((state) => {
          const parent = state.diagram.file[element.id]?.parent;

          if (!parent) {
            return true;
          }

          if (blocks[state.diagram.file[parent]?.type].element.options.includes('element-wrap-grid')) {
            return !!state.diagram.childs[parent]?.find((id) => !!state.diagram.file[id]?.deletable);
          }

          return state.diagram.file[parent]?.deletable;
        });

        if ((def || deletable) && blur.includes(OptionEmptyTextBlur.ParentRemoving)) {
          deleteElements(getBlockContextElements(getBlockContextPathes([element.id])));
          dispatch(storeGraphic.setSelectionElements([]));
        } else if (blur.includes(OptionEmptyTextBlur.TextRemoving) || (!def && blur.includes(OptionEmptyTextBlur.ParentRemoving))) {
          deleteElements(getBlockContextElements([element.id]));
          dispatch(storeGraphic.setSelectionElements([]));
        }

        return false;
      }

      return true;
    },
  });

  delete bindings.prev;
  delete bindings.next;

  const input = useEditable({
    value: field,
    bindings,
    formats: [
      'paragraph',
      'bold',
      'italic',
      'underline',
      'strike',
      'background',
      'indent',
    ],
    onBlur: (value) => onBlur(value, input),
  });

  useEditableComponent(input);
  useLineCounting(input);

  return (
    <ReactQuill
      {...input.attributes}
      className={`component-text ${style.input}`}
      placeholder={placeholder}
    />
  );
};
