import { ITool } from 'tools';
import { createTool } from 'tools/ToolLink';

import icon from './assets/icon.svg';

const Tool = createTool('methods', 'Methods', 'Methods', icon);

export const LinkMethods: ITool = {
  key: 'link-methods',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
