import sanitizeHtml, { IOptions } from 'sanitize-html';
import { stripTag } from 'utils';

export default {
  default(value: string) {
    return (value || '').trim();
  },
  noSpaces(value: string) {
    return (value || '').replace(/^\s*| |\s*$/g, '').replace(/\s*$/gm, '');
  },
  noParagraphs(value: string) {
    return `<p>${(value || '').replace(/<p>|<\/p>|<br.?.?>/gmi, '').trim()}</p>`;
  },
  withMultiLines(value: string) {
    return sanitizeHtml(stripTag((value || '')), {
      allowedAttributes: {},
      nestingLimit: 1,
      allowedTags: ['div', 'p', 'br'],
    });
  },
  withHtml(config: IOptions) {
    return (value: string) => {
      return sanitizeHtml((value || ''), config);
    };
  },
  withTrimmedHtml(config: IOptions) {
    return (value: string) => {
      return sanitizeHtml((value || '').replace(/(^(\s|&nbsp;)+)|(\s|&nbsp;)+$/g, ''), config);
    };
  },
  noHtml(value: string) {
    return (value || '').replace(/(<([^>]+)>)/ig, '');
  },
  withSpaces(value: string) {
    return (value || '').replace(/&nbsp;/g, ' ');
  },
  noParagraphDuplication(value: string) {
    const rows = value.split('</p>');
    const duplicates = new Set<string>();
    const context = [] as string[];

    rows.forEach((row) => {
      const line = row.replace(/<p.*?>/, '');

      if (duplicates.has(line)) {
        return;
      }

      duplicates.add(line);
      context.push(row);
    });

    return context.join('</p>');
  },
  withParagraphStart(start: string[], value: string) {
    const rows = value.split('</p>');

    return rows.filter((row) => {
      return !start.every((term) => {
        return !this.noHtml(row).trim().startsWith(term);
      });
    }).join('</p>');
  },
};
