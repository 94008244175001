import React from 'react';
import { ITool } from 'tools';
import { createTool } from 'tools/ToolBasic';

import { storeDiagram } from 'store/diagram';

import icon from './assets/icon.svg';

const Help = () => (
  <>
    <div className="text-10">Safe mode</div>
    <div className="text-9">
      Safe Mode is a tool within UML Class Diagrams that prevents the creation of duplicate blocks. When enabled, it ensures that each block in your diagram is unique by blocking the addition of duplicates.
      <br />
      <br />
      This feature helps maintain diagram clarity and efficiency by eliminating redundancy.
    </div>
  </>
);

const Tool = createTool({
  key: 'safe',
  def: false,
  title: 'Safe mode',
  alt: 'Safe mode',
  Help,
  icon,
  onClick: (selection, dispatch, value) => {
    selection.forEach((id) => {
      dispatch(storeDiagram.setElement({
        id,
        properties: { safe: !value },
        skip: true,
      }));
    });

    window.getSelection()?.focusNode?.parentElement?.focus();
  },
});

export const Safe: ITool = {
  key: 'safe',
  tool: Tool,
  options: ['only-edit'],
};
