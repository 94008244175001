import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAlert, storeAlert } from 'store/alert';

let timer = 0;

export const serviceAlert = {
  showAlert: createAsyncThunk('alert/show', async (data: IAlert, thunk) => {
    window.clearTimeout(timer);

    timer = window.setTimeout(() => {
      thunk.dispatch(serviceAlert.hideAlert());
    }, 10000);

    thunk.dispatch(storeAlert.showAlert({
      message: data.message,
      type: data.type,
      id: timer,
    }));
  }),

  hideAlert: createAsyncThunk('alert/hide', async (_, thunk) => {
    window.clearTimeout(timer);
    thunk.dispatch(storeAlert.hideAlert());
  }),
};
