import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { IConnector } from './Cap';

export const Cap8: IConnector = {
  Start: ({ color, mask }: ICap) => (
    <path d="M1 13L9 8L1 3L1 13Z" strokeWidth="2" fill={mask ? '#fff' : color} stroke={mask ? '#fff' : color} strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
  End: ({ color, mask }: ICap) => (
    <path d="M23 13L15 8L23 3L23 13Z" strokeWidth="2" fill={mask ? '#fff' : color} stroke={mask ? '#fff' : color} strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
};
