import React from 'react';
import { deleteElements, getBlockContextElements } from 'utils/store';
import { ITool } from 'tools';
import { store } from 'store';

import { storeGraphic } from 'store/graphic';

import { useStoreDispatch } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import icon from './assets/icon.svg';

const Tool = () => {
  const dispatch = useStoreDispatch();

  const onClick = () => {
    const state = store.getState();

    const selection = state.graphic.selection.elements;

    deleteElements(getBlockContextElements(selection));
    dispatch(storeGraphic.setSelectionElements([]));
  };

  return (
    <Hint message="Delete" side="bottom" variant="default">
      <button type="button" className="button-icon v-1" onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt="Delete" />
      </button>
    </Hint>
  );
};

export const Delete: ITool = {
  key: 'delete',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
