import React, { memo } from 'react';
import { IBlock } from 'blocks';

import { useCacheProperty } from 'hooks/useCacheProperty';

import { Model } from 'blocks/factories/container/Model';
import { createBlock } from 'blocks/factories/container/Block';
import { createRender } from 'blocks/factories/simple/Render';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';

import { Button } from 'elements/Block/Button';

import style from './assets/style.module.css';
import icon from './assets/icon.svg';
import iconWhite from './assets/icon-white.svg';

const { serialize } = require('svg-path-round-corners/dist/es6/serialize');
const { roundCorners } = require('svg-path-round-corners/dist/es6/index');

type IModel = Model<{}>;

export const config = {
  title: 'Node',
  search: 'container node uml unified modeling language',
  type: 'uml-deployment-container-node',
  group: ['UML'],
  memo: {
    key: 'uml-deployment-shape',
    styles: [
      'color',
      'placing',
      'space',
      'render',
      'padding',
    ],
  },
  styles: [
    'color',
    'placing',
    'space',
    'render',
    'padding',
  ],
};

const Render = createRender<IModel>({
  render: ({ width, height, properties, element }) => {
    const hidden = useCacheProperty('hidden') ?? false;

    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - border, 0);
    const y = Math.max(height - border, 0);
    const o = 12;

    const points = [
      { c: 'M', x: offset, y: offset + o },
      { c: 'H', x: x },
      { c: 'V', y: y + o },
      { c: 'M', x: offset, y: offset + o },
      { c: 'V', y: y + o },
      { c: 'H', x: x },
      { c: 'M', x: offset, y: offset + o },
      { c: 'L', x: offset + o, y: offset },
      { c: 'H', x: x + o },
      { c: 'V', y: y },
      { c: 'L', x: x, y: y + o },
      { c: 'M', x: x - 1.5, y: offset + o + 2 },
      { c: 'L', x: x + o - 1.5, y: offset + 2 },
    ];

    return (
      <div className="block-render" style={{ marginTop: -o }}>
        <svg width={width + o} height={height + o} viewBox={`0 0 ${width + o} ${height + o}`} fill="none" xmlns="http://www.w3.org/2000/svg">
          {hidden && (
            <defs>
              <pattern id={`${element.id}-pattern`} patternUnits="userSpaceOnUse" width="24" height="24" viewBox="0 0 8 8">
                <path className="render-stroke-color render-opacity" d="M-1,1 l4,-4 M0,8 l8,-8 M6,10 l4,-4" strokeOpacity={0.075} strokeWidth={4} />
              </pattern>
            </defs>
          )}

          <path className="render-fill-stroke render-stroke" fillOpacity={0.1} strokeOpacity={properties.render === 7 ? 0.1 : 1} strokeWidth={border} strokeLinecap="round" d={serialize(roundCorners(points, 6))} />

          {hidden && (
            <>
              <rect x={offset + 4} y={offset + 4 + o} width={Math.max(x - 8, 0)} height={Math.max(y - 8, 0)} strokeLinecap="round" rx="6" fill={`url(#${element.id}-pattern)`} />
              <svg x={(width - 62) / 2} y={(height - 30) / 2 + 4 + o} width="62" height="30" viewBox="0 0 62 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="render-stroke-color" d="M2.5 2C4.43193 4.41491 6.62093 6.51562 9 8.29424M59.5 2C57.5127 4.37196 55.3281 6.42336 53 8.16053M9 8.29424L2 20.5M9 8.29424C13.337 11.5366 18.3057 13.7087 23.5 14.7625M23.5 14.7625L21.5 27.5M23.5 14.7625C28.4126 15.7593 33.5271 15.7558 38.5 14.7119M38.5 14.7119L41 28M38.5 14.7119C43.6065 13.6399 48.5638 11.4707 53 8.16053M53 8.16053L60 20" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </>
          )}
        </svg>
      </div>
    );
  },
});

const Block = createBlock({
  config,
  Render,
  classElement: style.element,
  classFrame: (properties) => {
    if ((properties.elements ?? []).length === 0) {
      return style.frame;
    }

    return undefined;
  },
});

export const UMLDeploymentContainerNode : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-container', 'element-wrap', 'child-none', 'container-title-none'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
        render: [
          6,
          8,
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        color: 'dark',
        render: 0,
        padding: 'm',
        elements: [],
        placing: 'top',
        width: 120,
        height: 100,
        space: true,
        lockKey: '',
        lock: false,
        hidden: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [120, 100],
    icon,
    class: {
      title: 'Node',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: [
      'only-edit',
    ],
    type: 'drag',
  },
  tools: [
    'padding',
    'space',
    'placing',
    'render',
    'color',
    'hidden',
    'lock',
    'delete-wrap',
    'delete',
  ],
  toolbars: [

  ],
};
