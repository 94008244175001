import React, { useEffect, useState } from 'react';

export const useUpdateEffect = (effect: () => void, deps?: React.DependencyList | undefined) => {
  const [mount, setMount] = useState(false);

  useEffect(() => {
    if (mount) {
      effect();
    }
  }, deps);

  useEffect(() => {
    setMount(true);
  }, []);
};
