import React, { useContext, useMemo } from 'react';
import { getPath, getState } from 'utils/store';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';

export const useConnectorParents = () => {
  const element = useContext(ElementContext);

  const childs = useStoreSelector((state) => state.diagram.childs);
  const points = useStoreSelector((state) => state.diagram.file[element.id].points as IPoint[]);

  return useMemo(() => {
    const file = getState((state) => state.diagram.file);

    const path = getPath(points[0].parent, { file }) ?? [];
    const parents = new Set<string>(path);

    points.forEach((point) => {
      if (!point.parent || parents.size === 0) {
        return;
      }

      const path = getPath(point.parent, { file });
      const links = new Set<string>(path);

      Array.from(parents).forEach((id) => {
        if (!links.has(id)) {
          parents.delete(id);
        }
      });
    });

    return parents;
  }, [points, childs]);
};
