import { ITool } from 'tools';
import { blocks } from 'blocks';
import { getMemoStyle, getState } from 'utils/store';

import { createTool } from 'tools/ToolBasic';

import icon from './assets/icon.svg';

export const Tool = createTool<string>({
  key: 'style',
  def: 'pen',
  title: 'Pen',
  alt: 'Pen',
  getValue: () => 'pen',
  getActive: () => {
    const draw = getState((state) => state.graphic.draw);

    return (getMemoStyle(blocks[draw ?? '_']?.element.memo ?? '', 'style') || 'pen') === 'pen';
  },
  onMemo: (memo) => {
    const draw = getState((state) => state.graphic.draw);

    memo(blocks[draw ?? '_']?.element.memo ?? '', 'style', 'pen');
  },
  icon,
});

export const MarkerPen: ITool = {
  key: 'marker-pen',
  tool: Tool,
  options: ['only-draw'],
};
