import React, { memo } from 'react';
import { IBlock } from 'blocks';
import { getState } from 'utils/store';

import { useConnector } from 'hooks/useConnector';
import { useConnectorElbow } from 'hooks/useConnectorElbow';
import { useConnectorStyle } from 'hooks/useConnectorStyle';
import { useConnectorMasks } from 'hooks/useConnectorMasks';
import { useConnectorSmooth } from 'hooks/useConnectorSmooth';
import { useConnectorStroke } from 'hooks/useConnectorStroke';
import { useConnectorDefault } from 'hooks/useConnectorDefault';
import { useConnectorPicture } from 'hooks/useConnectorPicture';
import { useConnectorCreator } from 'hooks/useConnectorCreator';
import { useConnectorDirections } from 'hooks/useConnectorDirections';
import { useResizerConnectorCreator } from 'hooks/useResizerConnectorCreator';
import { useConnectorPostprocessing } from 'hooks/useConnectorPostprocessing';

import { Model } from 'blocks/factories/connector/Model';
import { createBlock } from 'blocks/factories/connector/Block';
import { createCopier } from 'blocks/factories/connector/Copier';
import { createCreator } from 'blocks/factories/connector/Creator';

import { Button } from 'elements/Block/Button';
import { IPoint } from 'elements/Block/Connector';

import { End, Start } from './components/Cap';

import icon from './assets/icon.svg';
import iconWhite from './assets/icon-white.svg';

export const config = {
  title: 'Connector',
  search: 'arrow line connector uml unified modeling language',
  type: 'uml-use-case-connector',
  group: ['UML'],
  memo: {
    key: 'connector-uml-use-case',
    styles: [
      'size',
      'color',
      'style',
      'stroke',
      'start',
      'end',
      'startFree',
      'endFree',
    ],
  },
  styles: [
    'size',
    'color',
    'style',
    'stroke',
    'start',
    'end',
    'startFree',
    'endFree',
  ],
};

type IModel = Model<{}>;

const Block = createBlock<IModel>({
  config,
  caps: {
    Start,
    End,
  },
  flow: ({ resizer, element }) => {
    const handles = getState((state) => state.diagram.file[element.id]?.points);
    const style = getState((state) => state.diagram.file[element.id]?.style);

    const connector = useConnector(resizer.offset);
    const points = useConnectorPostprocessing(connector, () => {
      const start = handles?.[0] as IPoint;
      const end = handles?.[(handles ?? []).length - 1] as IPoint;

      return {
        start: !start?.parent || start?.type !== 'parent' || style === 'elbow',
        end: !end?.parent || end?.type !== 'parent' || style === 'elbow',
      };
    });

    const dots = useConnectorDefault(points);
    const elbows = useConnectorElbow(points, connector);
    const levers = useConnectorSmooth(points, connector);

    const picture = useConnectorPicture(points, levers.handles);
    const path = useConnectorStyle(elbows.points ?? dots, levers.handles, picture.position, picture.padding);

    const stroke = useConnectorStroke();
    const creator = useResizerConnectorCreator(elbows.axis);
    const masks = useConnectorMasks(connector, picture.padding);
    const vertices = useConnectorCreator(points, elbows.points ?? dots, levers.points);

    useConnectorDirections(points);

    return {
      points,
      dots,
      elbows,
      levers,
      picture,
      path,
      stroke,
      creator,
      masks,
      vertices,
    };
  },
});

const Help = () => (
  <>
    <div className="text-10">Connector (UML Use Case Diagram)</div>
    <div className="text-9">The Connector (UML Use Case Diagram)  is a specialized connector block tailored for UML use case diagrams within Exemplar.</div>
    <div className="text-8">Key Features:</div>
    <ul>
      <li className="text-9"><b>Designed for UML Use Case Diagrams</b>: This connector is purpose-built to enhance the creation and representation of UML use case diagrams in Exemplar.</li>
      <li className="text-9"><b>Control Point Behavior:</b>: Unlike standard connectors, the control points of the Connector (UML Use Case Diagram) are unique. They do not shift and do not impact the offset of other arrows connected to the same side of a block.</li>
      <li className="text-9"><b>Connection to Entire Block</b>: Instead of connecting directly to the center of a block&apos;s side, the control points of this connector are linked to the center of the entire block. This distinctive feature results in a specialized path view, offering a unique visual representation of connections in your UML use case diagrams.</li>
    </ul>
    <div className="text-9">The Connector (UML Use Case Diagram) brings a fresh perspective to the creation of UML use case diagrams. Its control point behavior and connection approach provide users with a unique and visually appealing way to represent relationships within their use case diagrams, adding a touch of elegance to their work.</div>
  </>
);

export const UMLUseCaseConnector : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    Help,
    memo: config.memo.key,
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: [
      'element-connector',
      'connector-centered',
      'parent-none',
      'child-none',
      'container-none',
      'container-title-none',
      'no-selection-view-mode',
      'lock-delete',
    ],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      config,
      properties: {
        end: 0,
      },
    }),
    onCopy: createCopier({
      config,
    }),
    title: config.title,
    size: [64, 64],
    icon,
    class: {
      title: 'UML Use Case Connector',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: [
      'only-edit',
    ],
    type: 'connector-uml-use-case',
  },
  tools: [
    'connector-uml-use-case-start',
    'connector-uml-use-case-end',
    'size',
    'line-style',
    'line-stroke',
    'color',
    'connector-start-free',
    'connector-end-free',
    'connector-reverse',
    'lock',
    'delete',
  ],
  toolbars: [

  ],
};
