import React, { useMemo, useState } from 'react';

interface IPopupElement<T> {
  data?: {
    get?: () => (T | void);
    dependencies?: React.DependencyList;
  };
  active?: boolean;
}

export const usePopup = <T> ({ data: { get = () => {}, dependencies = [] } = {}, active = true } : IPopupElement<T> = {}) => {
  const [status, setStatus] = useState(false);
  const data = useMemo<T>(get as () => T, dependencies);

  return {
    data,
    active,
    status,
    open: () => active && setStatus(true),
    close: () => setStatus(false),
  };
};
