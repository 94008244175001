import { ITool } from 'tools';
import { blocks } from 'blocks';
import { createTool } from 'tools/ToolTab';

const icons: Record<string, string> = {};

Object.keys(blocks).forEach((id) => {
  if (blocks[id].element.icon) {
    icons[id] = blocks[id].element.icon;
  }
});

const Tool = createTool('shape-uml-class', 'type', '', 'Shape', 'Shape', icons);

export const ShapeUmlClass: ITool = {
  key: 'shape-uml-class',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
