import { useContext } from 'react';
import { getState } from 'utils/store';

import { storeGraphic } from 'store/graphic';
import { OptionSecondElementClick } from 'store/settings';

import { config } from 'blocks/General/Text';

import { ElementContext } from 'elements/Block/Element';

import { useStoreDispatch } from './useStore';

export const useTextSelector = () => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  return (type: 'click' | 'enter') => () => {
    const childs = getState((state) => state.diagram.childs[element.id]) || [];
    const click = getState((state) => state.settings.app.secondElementClick);

    if (childs.length > 0) {
      if (type !== 'click' || click.includes(OptionSecondElementClick.TextFocusing)) {
        const root = getState((state) => state.diagram.roots[element.id]);
        const type = getState((state) => state.diagram.file[root]?.type);

        if (type === config.type) {
          dispatch(storeGraphic.setSelectionElements([root]));
          dispatch(storeGraphic.setComponentKey('text'));
          dispatch(storeGraphic.setComponentPreventStatus());
        }
      }

      return true;
    }

    return false;
  };
};
