import { useEffect, useRef } from 'react';
import { blocks } from 'blocks';
import { getNextId } from 'utils';
import { deleteElements, getState } from 'utils/store';

import { IPoint } from 'elements/Block/Connector';

import { useProperty } from './useProperty';

export const useConnectorsRebind = () => {
  const deleting = useProperty(false);
  const deleted = useRef<string[]>([]);

  const rebind = (parent: string, connector: string, file: Record<string, any>) => {
    const [id, handle] = connector.split('.');

    if (file[id].lock) {
      return;
    }

    blocks[file[id].type].element.onCreate({
      file,
      position: [0, 0],
      parent: '',
      auto: true,
      preventComponentStatus: true,
      properties: {
        ...file[id],

        points: file[id].points.map((point: IPoint) => {
          if (point.role === 'mayor' && point.id === handle) {
            return {
              ...point,

              parent,
            };
          }

          return point;
        }),
      },
    });
  };

  const insert = (parent: string, connector: string, file: Record<string, any>) => {
    const [id, handle] = connector.split('.');

    if (file[id].lock) {
      return;
    }

    blocks[file[id].type].element.onCreate({
      file,
      position: [0, 0],
      parent: '',
      auto: true,
      preventComponentStatus: true,
      properties: {
        ...file[id],

        points: file[id].points.map((point: IPoint) => {
          if (point.role === 'mayor' && point.id === handle) {
            return {
              ...point,

              parent,
            };
          }

          return point;
        }),
      },
    });

    blocks[file[id].type].element.onCreate({
      file,
      position: [0, 0],
      parent: '',
      auto: true,
      preventComponentStatus: true,
      properties: {
        ...file[id],

        points: file[id].points.map((point: IPoint) => {
          if (point.role === 'mayor' && point.id !== handle) {
            return {
              ...point,

              parent,
            };
          }

          return point;
        }),
      },
    });
  };

  // Replace Connectors
  useEffect(() => {
    deleting.set(false);

    if (!deleting.value || deleted.current.length === 0) {
      return;
    }

    deleteElements(deleted.current);

    deleted.current = [];
  }, [deleting.value]);

  useEffect(() => {
    return () => {
      if (deleted.current.length === 0) {
        return;
      }

      deleteElements(deleted.current);

      deleted.current = [];
    };
  }, []);

  return (target: string, connectors: string[], payload?: Record<string, any>) => {
    if (!connectors) {
      return;
    }

    const file = payload ?? { ...getState((state) => state.diagram.file) };
    const to = target || (+getNextId(file) + Math.max(connectors.length, 2) + (payload ? 1 : 0)).toString();

    connectors.forEach((connector) => {
      if (connectors.length === 1) {
        insert(to, connector, file);

        return;
      }

      rebind(to, connector, file);
    });

    deleted.current = connectors.map((connector) => {
      return connector.split('.')[0];
    });

    deleting.set(true);
  };
};
