import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { IConnector } from './Cap';

export const Cap1: IConnector = {
  Start: ({ color, mask }: ICap) => (
    <path d="M9 13L1 8L9 3" stroke={mask ? '#fff' : color} fill="transparent" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
  End: ({ color, mask }: ICap) => (
    <path d="M15 13L23 8L15 3" stroke={mask ? '#fff' : color} fill="transparent" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
};
