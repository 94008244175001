import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { Model } from 'blocks/factories/shape/Model';
import { createRender } from 'blocks/factories/simple/Render';
import { createPadding } from 'blocks/factories/simple/Padding';
import { createBlock, block } from 'blocks/factories/shape/Block';

import icon from './assets/icon.svg';

const { serialize } = require('svg-path-round-corners/dist/es6/serialize');
const { roundCorners } = require('svg-path-round-corners/dist/es6/index');

type IModel = Model<{}>;

export const config: IConfig = {
  title: 'Multiple Documents',
  search: 'flowchart-shape-multiple-documents',
  type: 'flowchart-shape-multiple-documents',
  group: ['Flowchart Shape'],
  memo: {
    key: 'flowchart-shape',
    styles: block.element.memo,
  },
  styles: block.element.styles,
};

const Render = createRender<IModel>({
  render: ({ width, height, element }) => {
    const border = 2;
    const offset = border / 2;

    const b = 8;
    const x = Math.max(width - offset, 0);
    const y = Math.max(height - offset, 0);

    const a = Math.min(x, y * 3.2) / 64;

    const points = [
      { c: 'M', x: b + offset, y: b + offset },
      { c: 'L', x: b + x, y: b + offset },
      { c: 'L', x: b + x, y: b + y },
      { c: 'C', x1: b + (59 / 64) * x, y1: b + y - a * 5, x2: b + (53 / 64) * x, y2: b + y - a * 8, x: b + (46 / 64) * x, y: b + y - a * 8 },
      { c: 'C', x1: b + (35 / 64) * x, y1: b + y - a * 8, x2: b + (30 / 64) * x, y2: b + y, x: b + (20 / 64) * x, y: b + y },
      { c: 'C', x1: b + (11 / 64) * x, y1: b + y, x2: b + (5 / 64) * x, y2: b + y - a * 4, x: b + offset, y: b + y - a * 10 },
      { c: 'L', x: b + offset, y: b + offset },
      { c: 'L', x: b + offset, y: b + offset },
      { c: 'M', x: offset + b, y: y - a * 10 },
      { c: 'C', x1: offset + b, y1: y - a * 10, x2: offset + 1, y2: y - a * 10 - b + 2, x: offset, y: y - a * 10 - b },
      { c: 'L', x: offset, y: offset },
      { c: 'L', x: x, y: offset },
      { c: 'L', x: x, y: offset + b },
    ];

    return (
      <div className="block-render" style={{ marginLeft: -b, marginTop: -b }}>
        <svg width={width + b} height={height + b} viewBox={`0 0 ${width + b} ${height + b}`} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path {...element.trigger} className="render-fill render-stroke render-hit" d={serialize(roundCorners(points, 6))} strokeLinejoin="round" strokeLinecap="round" strokeWidth={border} />
        </svg>
      </div>
    );
  },
});

const Padding = createPadding<IModel>({
  size: [1, 0],
  padding: [1, 1, 1, 0],
  creator: ({ width }) => {
    const a = Math.min(width / 8, width - 14);

    return [0, 0, 0, a];
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  Render,
  Padding,
});

export const FlowchartShapeMultipleDocuments : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: block.element.options,
    constraints: {
      prevent: {
        ...block.element.constraints.prevent,

        render: [7, 8],
      },
    },
    onCreate: block.creator(config, {
      width: 64,
      height: 64,
    }),
    onCopy: block.copier(config),
    title: config.title,
    size: [64, 64],
    icon,
    class: block.element.class,
  },
  button: block.button(config),
  tools: ['shape-flowchart', ...block.tools],
  toolbars: block.toolbars,
};
