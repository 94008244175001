import { useContext, useEffect, useLayoutEffect } from 'react';

import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';

export const useEditableComponent = <T extends { ref: any, focus: any, html: any, error: any}> (input: T, deps: any[] = []) => {
  const selection = useStoreSelector((state) => state.graphic.selection.elements.length);

  const element = useContext(ElementContext);

  // Set focus on enter
  useEffect(() => {
    if (input.ref && selection === 1) {
      input.focus();
    }
  }, [input.ref]);

  // Update Frame Points on Change
  useLayoutEffect(() => {
    if (input.ref) {
      element.updatePoints();
    }
  }, [input.html, input.error, input.ref]);

  // Update Frame Points on Destroy
  useEffect(() => {
    return () => {
      element.updatePoints();
    };
  }, [...deps]);
};
