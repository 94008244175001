import React, { useContext } from 'react';

import { withCondition } from 'hocs/core/withCondition';

import { ElementContext } from 'elements/Block/Element';

interface IFrame {
  disabled?: boolean;
}

export const Frame = withCondition(({ disabled = false }: IFrame) => {
  const element = useContext(ElementContext);

  return (
    <div
      className={'block-layout'.appendWhen(disabled, 'disabled')}
      {...element.trigger}
    />
  );
});
