/* eslint-disable no-plusplus */
import { useContext, useEffect } from 'react';
import { storage as localStorage } from 'utils/storage';
import { getBlobFromHTML, getNextId, setLiterals } from 'utils';
import { deleteElements, filterProperties, getBlockContextContainers, getBlockContextElements, getGroupStyles, getMemoizedStyles, getState, uploadPicture } from 'utils/store';
import { points } from 'utils/points';
import { blocks } from 'blocks';

import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

import { config as text } from 'blocks/General/Text';
import { config as image } from 'blocks/General/Image';

import { serviceAlert } from 'services/alert';

import { IPoint } from 'elements/Block/Connector';
import { CanvasDynamicContext } from 'elements/Canvas';

import { useHotkey } from './useHotkey';
import { useProperty } from './useProperty';
import { useStoreDispatch, useStoreSelector } from './useStore';
import { extractPoints } from './useConnector';

interface IClipboard {
  styles: {[key: string]: any},
  elements: {[key: string]: any},
}

const config = {
  styles: new Set([
    'color',
    'space',
    'fill',
    'style',
    'shape',
    'stroke',
    'size',
    'start',
    'end',
    'safe',
    'startFree',
    'endFree',
    'alignVertical',
    'alignHorizontal',
    'italic',
    'render',
    'padding',
    'placing',
    'width',
    'height',
  ]),
};

export const storage = {
  styles: {} as {
    [key: string]: any;
  },
};

export const useClipboard = () => {
  const dispatch = useStoreDispatch();

  const selection = useStoreSelector((state) => state.graphic.selection.elements);
  const block = useStoreSelector((state) => state.diagram.mode !== 'edit' || state.graphic.component.key || state.graphic.drag || state.graphic.drop);
  const creating = useStoreSelector((state) => !!state.graphic.drag || !!state.graphic.drop || !!state.graphic.draw);

  const canvas = useContext(CanvasDynamicContext);

  const createTextBlock = (value: string, position: number[], doc: {[x: string]: any}, id: string, ticket: string) => {
    const target = {
      color: 'dark',
      size: 'm',

      ...filterProperties({
        type: text.type,
        properties: {
          ...getGroupStyles(text.memo.key),
          ...getMemoizedStyles(text.styles),
        },
      }),

      text: `<p>${setLiterals(value).split('\n').filter((line) => !!line.trim()).join('</p><p>')}</p>`,

      id: 'new',
      type: text.type,
      position,
      parent: '',
    };

    if (target.size === 's') {
      position[0] -= 4;
      position[1] -= 10;
    } else if (target.size === 'l') {
      position[0] -= 7;
      position[1] -= 15;
    } else {
      position[0] -= 6;
      position[1] -= 12;
    }

    target.position = position;

    blocks[text.type].element.onCopy({
      file: doc,
      transition: { new: getNextId(doc) },
      target,
      position,
      parent: '',
      auto: true,
      native: true,
      diagram: id,
      ticket: ticket,
    });
  };

  // Initialize Drag & Drop
  useEffect(() => {
    if (!canvas.element || block) {
      return () => {};
    }

    const onPrevent = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const onEnter = (e: any) => {
      if (e.relatedTarget) {
        return;
      }

      document.body.classList.add('element-dropping');
    };

    const onExit = (e: any) => {
      if (e.relatedTarget) {
        return;
      }

      document.body.classList.remove('element-dropping');
    };

    const onDrop = async (e: DragEvent) => {
      const blob = await getBlobFromHTML(e.dataTransfer?.getData('text/html'));
      const text = e.dataTransfer?.getData('text')?.trim();

      if (!e.dataTransfer || (e.dataTransfer.files.length < 1 && !blob && !text)) {
        return;
      }

      const diagram = getState((state) => state.diagram.diagram);
      const file = getState((state) => state.diagram.file);

      const cursor = [e.clientX, e.clientY];
      const location = canvas.position;
      const screen = canvas.screen;
      const zoom = canvas.zoom;
      const top = canvas.top;

      const position = [
        Math.round(-location[0] + (cursor[0] - screen[0] / 2) / zoom),
        Math.round(-location[1] + (cursor[1] - screen[1] / 2 - top) / zoom),
      ];

      const doc = { ...file };
      const offset = [0, 0];

      if (e.dataTransfer.files.length > 0 || blob) {
        (blob ? [blob] : Array.from(e.dataTransfer.files)).forEach((file, index) => {
          uploadPicture({
            file: file as Blob,
            getElement: (width, height) => {
              offset[0] += width + 10;

              if (index === 0) {
                offset[0] -= width / 2 + 10;
                offset[1] = height / 2;
              }

              return blocks[image.type].element.onCreate({
                canvas,
                file: doc,
                position: [
                  position[0] + (index === 0 ? (-width / 2) : (offset[0] - width)),
                  position[1] + (index === 0 ? (-height / 2) : -offset[1]),
                ],
                parent: '',
                auto: false,
                properties: {
                  width,
                  height,
                  realWidth: width,
                  realHeight: height,
                },
              });
            },
          });
        });

        return;
      }

      if (text) {
        createTextBlock(text, position, doc, diagram.id, diagram.ticket);
      }
    };

    window.addEventListener('drag', onPrevent);
    window.addEventListener('dragstart', onPrevent);
    window.addEventListener('dragend', onPrevent);
    window.addEventListener('dragover', onPrevent);
    window.addEventListener('dragenter', onPrevent);
    window.addEventListener('dragleave', onPrevent);
    window.addEventListener('drop', onPrevent);
    window.addEventListener('dragenter', onEnter);
    window.addEventListener('dragend', onExit);
    window.addEventListener('dragleave', onExit);
    window.addEventListener('drop', onExit);
    window.addEventListener('drop', onDrop);

    return () => {
      window.removeEventListener('drag', onPrevent);
      window.removeEventListener('dragstart', onPrevent);
      window.removeEventListener('dragend', onPrevent);
      window.removeEventListener('dragover', onPrevent);
      window.removeEventListener('dragenter', onPrevent);
      window.removeEventListener('dragleave', onPrevent);
      window.removeEventListener('drop', onPrevent);
      window.removeEventListener('dragenter', onEnter);
      window.removeEventListener('dragend', onExit);
      window.removeEventListener('dragleave', onExit);
      window.removeEventListener('drop', onExit);
      window.removeEventListener('drop', onDrop);
    };
  }, [!canvas.element || block]);

  const onCopy = () => {
    if (block || selection.length < 1) {
      return [];
    }

    const diagram = getState((state) => state.diagram.diagram);
    const file = getState((state) => state.diagram.file);
    const childs = getState((state) => state.diagram.links);
    const elements = points.elements;
    const links = points.links;

    const context = getBlockContextContainers(selection);
    const selected = new Set(context.filter((id) => !file[id]?.loading));

    if (context.find((id) => file[id]?.loading) && selected.size === 0) {
      dispatch(serviceAlert.showAlert({
        message: `${context.length < 2 ? 'This element' : 'These elements'} are loading`,
        type: 'default',
      }));
    }

    const parents = Array.from(selected).filter((id) => {
      let parent = file[id]?.parent;

      while (parent) {
        if (selected.has(parent)) {
          return false;
        }

        parent = file[parent]?.parent;
      }

      return true;
    });

    const doc = parents.filter((id) => {
      return id in elements;
    }).map((id) => {
      return {
        id,
        size: elements[id].size,
        position: elements[id].position,
      };
    });

    const lines = parents.every((id) => blocks[file[id]?.type || '']?.element.options.includes('element-connector'));

    const bounds = doc.filter((block) => {
      return lines || !blocks[file[block.id]?.type || '']?.element.options.includes('element-connector');
    }).reduce((prev, curr) => [
      Math.min(prev[0], curr.position[0]),
      Math.min(prev[1], curr.position[1]),
      Math.max(prev[2], curr.position[0] + curr.size[0]),
      Math.max(prev[3], curr.position[1] + curr.size[1]),
    ], [doc[0].position[0], doc[0].position[1], doc[0].position[0] + doc[0].size[0], doc[0].position[1] + doc[0].size[1]]);

    const center = [
      (bounds[0] + bounds[2]) / 2,
      (bounds[1] + bounds[3]) / 2,
    ];

    const payload = Object.fromEntries(doc.map((block) => [
      block.id,
      {
        ...file[block.id],

        _center: center,
        _position: [...block.position],
        _prime: true,
        _diagram: diagram.id,
      },
    ]));

    Object.keys(payload).forEach((id) => {
      childs[id].forEach((child) => {
        if (child in payload) {
          return;
        }

        payload[child] = {
          ...file[child],
        };
      });
    });

    Object.keys(payload).forEach((element) => {
      links[element]?.forEach((element) => {
        if (!blocks[file[element]?.type]?.element.options.includes('element-container') && !(element in payload)) {
          if (blocks[file[element]?.type]?.element.options.includes('element-connector')) {
            const inner = file[element]?.points?.every((point: IPoint) => {
              if (!point.parent) {
                return point.role === 'minor';
              }

              return point.parent in payload;
            });

            if (!inner) {
              return;
            }
          }

          payload[element] = {
            ...file[element],
          };
        }
      });
    });

    const transition = Object.fromEntries(Object.keys(payload).map((id) => [id, id]));

    Object.values(payload).forEach((element) => {
      if (blocks[element.type]?.element.options.includes('element-connector')) {
        element.points = extractPoints(element.points, element.startFree, element.endFree, transition);
      }
    });

    const clipboard = JSON.parse(localStorage.getItem('exemplar-clipboard')?.trim() as string || '{}') as IClipboard;

    localStorage.setItem('exemplar-clipboard', JSON.stringify({
      styles: clipboard.styles,
      elements: payload,
    }));

    navigator.clipboard.writeText('');

    dispatch(serviceAlert.showAlert({
      message: `Element${Object.keys(payload).length > 1 ? 's' : ''} copied to clipboard`,
      type: 'default',
    }));

    return Object.keys(payload);
  };

  const onPaste = async () => {
    if (block) {
      return;
    }

    const diagram = getState((state) => state.diagram.diagram);
    const file = getState((state) => state.diagram.file);

    const doc = { ...file };

    const cursor = canvas.cursor;
    const location = canvas.position;
    const screen = canvas.screen;
    const zoom = canvas.zoom;
    const top = canvas.top;

    const position = [
      Math.round(-location[0] + (cursor[0] - screen[0] / 2) / zoom),
      Math.round(-location[1] + (cursor[1] - screen[1] / 2 - top) / zoom),
    ];

    const picture = await (async () => {
      try {
        const payload = await navigator.clipboard.read();

        if (!payload || payload.length < 1) {
          return undefined;
        }

        const item = payload[0];
        const type = item.types.find((type) => type.startsWith('image/'));

        if (!type) {
          return undefined;
        }

        return item.getType(type);
      } catch (e) {
        return undefined;
      }
    })();

    if (picture) {
      uploadPicture({
        file: picture,
        getElement: (width, height) => {
          return blocks[image.type].element.onCreate({
            canvas,
            file: doc,
            position: [
              position[0] - (width / 2),
              position[1] - (height / 2),
            ],
            parent: '',
            auto: false,
            properties: {
              width,
              height,
              realWidth: width,
              realHeight: height,
            },
          });
        },
      });

      return;
    }

    const payload = await navigator.clipboard.readText();

    if (payload.trim()) {
      createTextBlock(payload.trim(), position, doc, diagram.id, diagram.ticket);

      return;
    }

    const clipboard = JSON.parse(localStorage.getItem('exemplar-clipboard')?.trim() as string || '{}') as IClipboard;
    const elements = clipboard.elements;

    if (elements) {
      let id = +getNextId(doc);

      const transition: {[key: string]: string} = {};

      Object.keys(elements).forEach((key) => {
        transition[key] = (id++).toString();
      });

      Object.keys(elements).forEach((id) => {
        const element = elements[id];
        const derivated = !element._prime || (element.sticky && element.sticky in transition);
        const container = blocks[element.type]?.element.options.includes('element-container') && (element.elements ?? []).length > 0;

        elements[id]._native = element._diagram === diagram.id;
        elements[id]._origin = [...(elements[id]._position ?? [0, 0])];

        if (container) {
          const offset = [
            position[0] - element._center[0],
            position[1] - element._center[1],
          ];

          elements[id]._parent = '';
          elements[id]._position = [0, 0];

          elements[id].elements = (elements[id].elements ?? []).filter((id: string) => {
            return id in transition;
          }).map((id: string) => {
            return transition[id];
          });

          elements[id].border = [
            Number.isFinite(elements[id].border?.[0]) ? elements[id].border[0] + offset[0] : undefined,
            Number.isFinite(elements[id].border?.[1]) ? elements[id].border[1] + offset[1] : undefined,
            Number.isFinite(elements[id].border?.[2]) ? elements[id].border[2] + offset[0] : undefined,
            Number.isFinite(elements[id].border?.[3]) ? elements[id].border[3] + offset[1] : undefined,
          ];
        } else if (derivated) {
          elements[id]._parent = element.parent in transition ? transition[element.parent] : element.parent;
          elements[id]._position = element.position;
        } else {
          const point = [
            position[0] + (element._position[0] - element._center[0]),
            position[1] + (element._position[1] - element._center[1]),
          ];

          elements[id].position = point;

          elements[id]._parent = '';
          elements[id]._position = point;
        }

        delete elements[id]._prime;
        delete elements[id]._diagram;
        delete elements[id]._center;
      });

      Object.keys(transition).forEach((id) => {
        const copy = { ...elements[id] };

        const native = copy._native;
        const parent = copy._parent;
        const position = copy._position;
        const origin = copy._origin;

        delete copy._native;
        delete copy._parent;
        delete copy._position;
        delete copy._origin;

        blocks[copy.type].element.onCopy({
          file: doc,
          transition,
          target: { id, ...copy },
          position: position,
          parent: parent,
          native: native,
          diagram: diagram.id,
          ticket: diagram.ticket,
          auto: true,
          origin,
        });
      });

      dispatch(storeDiagram.setComputedProperties(Object.keys(elements).map((id) => transition[id] || id)));
      dispatch(storeGraphic.setSelectionElements(Object.keys(elements).map((id) => transition[id] || id)));
    }
  };

  const onCut = () => {
    if (block || selection.length < 1) {
      return;
    }

    const elements = onCopy();

    deleteElements(getBlockContextElements(elements));
    dispatch(storeGraphic.setSelectionElements([]));
  };

  useHotkey({ code: 67, ctrl: true, alt: false, prevent: true, isActive: () => !block, onPress: onCopy, deps: [block, selection] });
  useHotkey({ code: 67, meta: true, alt: false, prevent: true, isActive: () => !block, onPress: onCopy, deps: [block, selection] });
  useHotkey({ code: 88, ctrl: true, alt: false, prevent: true, isActive: () => !block, onPress: onCut, deps: [block, selection] });
  useHotkey({ code: 88, meta: true, alt: false, prevent: true, isActive: () => !block, onPress: onCut, deps: [block, selection] });
  useHotkey({ code: 86, ctrl: true, alt: false, prevent: true, isActive: () => !block, onPress: onPaste, deps: [block] });
  useHotkey({ code: 86, meta: true, alt: false, prevent: true, isActive: () => !block, onPress: onPaste, deps: [block] });

  const getStyles = (elements: string[]) => {
    const file = getState((state) => state.diagram.file);

    const payload: {[key: string]: any} = {};

    elements.forEach((id) => {
      Object.keys(file[id] || {}).forEach((key) => {
        if (config.styles.has(key)) {
          if (!(key in payload && !file[id][key])) {
            payload[key] = file[id][key];
          }
        }
      });
    });

    return payload;
  };

  const onCopyStyle = () => {
    if (block || selection.length < 1) {
      return;
    }

    const payload = getStyles(selection);
    const clipboard = JSON.parse(localStorage.getItem('exemplar-clipboard')?.trim() as string || '{}') as IClipboard;

    localStorage.setItem('exemplar-clipboard', JSON.stringify({
      styles: payload,
      elements: clipboard.elements,
    }));

    navigator.clipboard.writeText('');

    dispatch(serviceAlert.showAlert({
      message: 'Style copied to clipboard',
      type: 'default',
    }));
  };

  const onPasteStyle = () => {
    if (block || selection.length === 0) {
      return;
    }

    const clipboard = JSON.parse(localStorage.getItem('exemplar-clipboard')?.trim() as string || '{}') as IClipboard;
    const file = getState((state) => state.diagram.file);
    const captured = getState((state) => state.diagram.captured);

    selection.forEach((id) => {
      const type = file[id]?.type;
      const element = blocks[type].element;
      const properties: Record<string, any> = {};

      if (!element || captured[id] || file[id]?.lock) {
        return;
      }

      (element.styles || []).forEach((style) => {
        if (style in (clipboard.styles ?? {})) {
          properties[style] = clipboard.styles[style];
        }
      });

      dispatch(storeDiagram.setElement({
        id,
        properties: filterProperties({ type, properties }),
      }));
    });
  };

  useHotkey({ code: 67, meta: true, alt: true, prevent: true, isActive: () => !block, onPress: onCopyStyle, deps: [block, selection] });
  useHotkey({ code: 86, meta: true, alt: true, prevent: true, isActive: () => !block, onPress: onPasteStyle, deps: [block, selection] });

  const alt = useHotkey({ alt: true, prevent: true, isActive: () => !creating, deps: [creating] });
  const selected = useProperty<string[]>([]);
  const last = useProperty('');

  // Memorize Last Selection
  useEffect(() => {
    if (selection.length === 1) {
      last.set(selection[0]);
    } else {
      last.set('');
    }
  }, [selection.length === 1, selection[0]]);

  // Save Selection
  useEffect(() => {
    if (selection.length !== 0) {
      selected.set([...selection]);
    }
  }, [selection]);

  // Process Styles of Selection
  useEffect(() => {
    if (selection.length !== 0) {
      return;
    }

    if (!alt) {
      storage.styles = {};

      return;
    }

    if (block) {
      return;
    }

    const file = getState((state) => state.diagram.file);

    storage.styles = getStyles(selected.value);

    if (last.value) {
      const type = file[last.value]?.type;

      if (blocks[type]?.element.options.includes('element-connector') || blocks[type]?.element.options.includes('element-marker')) {
        dispatch(storeGraphic.setComponentTab(blocks[type].button.type));
        dispatch(storeGraphic.setComponentPreventTabStatus());
      } else {
        dispatch(serviceAlert.showAlert({
          message: 'Style saved for next block',
          type: 'default',
        }));
      }
    }
  }, [selection.length === 0]);
};
