import React, { memo, useLayoutEffect } from 'react';
import { IBlock, IConfig } from 'blocks';

import { storeGraphic } from 'store/graphic';

import { useOption } from 'hooks/useOption';
import { useResizer } from 'hooks/useResizer';
import { IElement, useElement } from 'hooks/useElement';
import { useCacheProperty } from 'hooks/useCacheProperty';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';
import { useArrangementElement } from 'hooks/useArrangementElement';

import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';
import { createRender } from 'blocks/factories/complex/Render';
import design from 'blocks/factories/assets/Simple.module.css';

import { Text } from './components/Text';
import { Stereotype } from './components/Stereotype';
import { Attributes } from './components/Attributes';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Component',
  type: 'uml-component-component-box',
  search: 'component box uml unified modeling language',
  group: ['UML'],
  memo: {
    key: 'uml-component-shape',
    styles: [
      'color',
      'render',
    ],
  },
  styles: [
    'color',
    'render',
  ],
};

interface IModel {
  stereotype: string;
  text: string;
  attributes: string;
  color: string;
  render: number;
  width?: number;
  shortened: boolean;
  lockKey: string;
  lock: boolean;
}

interface IElements {
  attributes: IElement<HTMLDivElement>;
  shortened: boolean;
}

const Render = createRender<IModel, IElements>({
  render: ({ width, height, elements }) => {
    const border = 2;

    const x = border / 2;
    const y = border / 2;
    const w = Math.max(width - border, 0);
    const h = Math.max(height - border, 0);

    const a = elements.attributes.ref?.offsetTop ?? 0;

    return (
      <svg className="block-render" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect className="render-fill render-stroke" x={x} y={y} width={w} height={h} strokeLinecap="round" rx="6" strokeWidth={border} />
        {elements.attributes.ref && <path className="render-fill render-stroke" d={`M${border} ${a}L${w} ${a}`} strokeLinejoin="round" strokeLinecap="round" strokeWidth={border} />}
        {elements.shortened && <path transform={`translate(${width - 13}, ${height - 13})`} className="render-stroke-fill" d="M12 0V7C12 9.76142 9.76142 12 7 12H0L12 0Z" />}

        <svg x={w - 18} y={5}>
          <path className="render-stroke" d="M3.47059 12.5294H2C1.44772 12.5294 1 12.0817 1 11.5294V10.2353C1 9.68301 1.44772 9.23529 2 9.23529H3.47059M3.47059 12.5294H4.94118C5.49346 12.5294 5.94118 12.0817 5.94118 11.5294V10.2353C5.94118 9.68301 5.49346 9.23529 4.94118 9.23529H3.47059M3.47059 12.5294V13C3.47059 14.1046 4.36602 15 5.47059 15H12C13.1046 15 14 14.1046 14 13V3C14 1.89543 13.1046 1 12 1H5.47059C4.36602 1 3.47059 1.89543 3.47059 3V3.47059M3.47059 3.47059H4.94118C5.49346 3.47059 5.94118 3.9183 5.94118 4.47059V5.76471C5.94118 6.31699 5.49346 6.76471 4.94118 6.76471H3.47059M3.47059 3.47059H2C1.44772 3.47059 1 3.9183 1 4.47059V5.76471C1 6.31699 1.44772 6.76471 2 6.76471H3.47059M3.47059 6.76471V9.23529" strokeWidth="2" style={{ strokeDasharray: '100, 0' }} />
        </svg>
      </svg>
    );
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const attributes = useElement<HTMLDivElement>();

    const shortened = useCacheProperty('shortened') ?? false;

    const shortable = !!`${properties.attributes || ''}`;

    const arrangement = useArrangementElement();
    const resier = useResizer({
      fixed: true,
      maxWidth: 340,
    });

    useEnterOnSelection({
      onPress: (dispatch) => dispatch(storeGraphic.setComponentKey('text')),
      mode: 'edit',
    });

    useOption({
      id: element.id,
      key: 'shortened',
      value: !shortable ? 'disable' : '',
      deps: [!shortable],
    });

    // Update Points on Property Change
    useLayoutEffect(() => {
      element.updatePoints();
    }, [
      shortened,
      properties.stereotype,
      properties.text,
      properties.attributes,
    ]);

    return (
      <Shape
        classElement={style.element}
        classProperties={
          `${design[`color-${properties.color}`]} ${design[`render-${properties.render}`]} ${design.text}`
        }
      >
        <Arrangement {...arrangement} />
        <Render
          properties={properties}
          elements={{
            shortened: shortable && shortened,
            attributes,
          }}
        />

        <Selection status={!resier.status} />

        <div
          className="block-content"
          {...element.trigger}
          {...resier.wrap}
        >

          <Stereotype.Component value={properties.stereotype} property="stereotype" />
          <Text.Component value={properties.text} property="text" />
          <Attributes.Component value={properties.attributes} property="attributes" element={attributes} />

        </div>

        <Resizer.Width {...resier.attributes} minWidth={48} status={resier.visibility} />
      </Shape>
    );
  },
});

export const UMLComponentComponentBox : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['container-title-none'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      component: 'text',
      properties: {
        stereotype: '',
        text: '',
        attributes: '',
        color: 'dark',
        render: 0,
        shortened: false,
        lockKey: '',
        lock: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [148, 40],
    icon,
    class: {
      title: 'Component',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [
    'link-text',
    'link-attributes',
    'link-stereotype',
    'render',
    'color',
    'shortened',
    'lock',
    'delete',
  ],
  toolbars: [
    Stereotype.toolbar,
    Text.toolbar,
    Attributes.toolbar,
  ],
};
