import React, { memo, useLayoutEffect } from 'react';
import { IBlock, IConfig, blocks } from 'blocks';
import { getState } from 'utils/store';

import { storeGraphic } from 'store/graphic';

import { useResizer } from 'hooks/useResizer';
import { useStoreSelector } from 'hooks/useStore';
import { useArrangementText } from 'hooks/useArrangementText';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';

import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';
import design from 'blocks/factories/assets/Simple.module.css';

import { Text as Value } from './components/Text';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Text',
  type: 'general-text',
  search: 'general heading paragraph text information',
  group: ['General', 'Information'],
  memo: {
    key: 'general-text',
    styles: [
      'color',
      'size',
    ],
  },
  styles: [
    'color',
    'size',
  ],
};

interface IModel {
  text: string;
  color: string;
  size: 's' | 'm' | 'l';
  width?: number;
  vertical: boolean;
  lockKey: string;
  lock: boolean;
  placeholder?: string;
}

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const component = useStoreSelector((state) => !!state.graphic.component.key);
    const limiter = useStoreSelector((state) => (
      blocks[state.diagram.file[properties.parent]?.type]?.element.options.includes('text-limiter')
      || blocks[state.diagram.file[state.diagram.file[properties.parent]?.parent]?.type]?.element.options.includes('text-limiter')
    ));

    const arrangement = useArrangementText();
    const resier = useResizer({
      fixed: true,
      maxWidth: limiter ? 120 : 640,
    });

    useEnterOnSelection({
      onPress: (dispatch) => dispatch(storeGraphic.setComponentKey('text')),
      mode: 'edit',
    });

    // Update Points on Property Change
    useLayoutEffect(() => {
      element.updatePoints();
    }, [properties.text, properties.size]);

    return (
      <Shape
        classElement={style.element}
        classProperties={`${design[`color-${properties.color}`]} ${design[`size-${properties.size}`]} ${design.text}`}
      >
        <Arrangement {...arrangement} />
        {(!limiter || !component) && <Selection status={!resier.status} /> }

        <div
          className={'block-content'.appendWhen(properties.vertical, 'text-vertical')}
          {...element.trigger}
          {...resier.wrap}
        >

          <Value.Component value={properties.text} property="text" placeholder="Add text" />

        </div>

        <Resizer.Width {...resier.attributes} minWidth={42} status={resier.visibility} />
      </Shape>
    );
  },
});

const Help = () => (
  <>
    <div className="text-10">Text</div>
    <div className="text-9">The Text block in Exemplar is a versatile element that can be nested within other blocks, allowing you to add textual content wherever you need it.</div>
    <ul>
      <li className="text-9"><b>Edit Mode</b>: To edit the content of a Text block, simply select it and press Enter. This makes it easy to update text whenever you need.</li>
      <li className="text-9"><b>Navigation</b>: When Text blocks are part of a layout, you can use the Tab key to navigate between them. Pressing Shift + Tab allows you to move in the reverse direction.</li>
      <li className="text-9"><b>Duplicate Blocks</b>: If a Text block is within a layout, you can quickly create a copy of it and its parent blocks by pressing Shift + Enter. This is a handy way to replicate content efficiently.</li>
      <li className="text-9"><b>Auto Deletion</b>: If you choose this option in Exemplar settings, leaving a Text block empty will result in its automatic deletion along with its parent blocks. This helps keep your canvas clean and clutter-free.</li>
      <li className="text-9"><b>Automatic Layout</b>: While dragging text blocks, hold the <b>SPACE</b> key to control their placement within a layout. This feature ensures your content is neatly arranged.</li>
      <li className="text-9"><b>Nested Blocks</b>: If you want to nest a text block within another block, hold <b>CTRL</b> or <b>CMD</b> while dragging. This action allows you to maintain a structured hierarchy.</li>
      <li className="text-9"><b>Layout Preview</b>: Layouts are not visible by default. To make them visible, hold the <b>CTRL</b> or <b>CMD</b> key and hover over the target layout structure. This feature prevents overfilling with secondary elements and helps you ensure that your content is well-organized.</li>
      <li className="text-9"><b>Style Memorization</b>: To memorize the style of a block, hold <b>ALT</b> while unselecting the block. The memorized styles will be applied automatically to the next block you create. This feature is useful for maintaining consistent formatting across your content.</li>
    </ul>
    <div className="text-9">The Text block is a valuable tool for adding and managing text-based information within your Exemplar projects.</div>
  </>
);

export const GeneralText : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    Help,
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-text'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      component: 'text',
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        text: '',
        size: 'm',
        color: 'dark',
        width: undefined,
        vertical: false,
        lockKey: '',
        lock: false,
      },
      condition: (payload) => {
        const type = getState((state) => state.diagram.file[payload.parent]?.type);

        if (payload.canvas?.space && !payload.parent) {
          payload.properties = {
            ...(payload.properties ?? {}),

            deletable: false,
          };
        }

        if (!blocks[type]?.element.options.includes('element-wrap') || blocks[type]?.element.options.includes('element-wrap-inline')) {
          return payload;
        }

        if (!payload.properties?.text?.trim()) {
          payload.properties = {
            ...(payload.properties ?? {}),

            text: '<p class="ql-align-center"><br></p>',
          };
        }

        return payload;
      },
    }),
    onCopy: createCopier({
      type: config.type,
      component: 'text',
    }),
    title: config.title,
    size: [66, 25],
    icon,
    class: {
      title: 'Text',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [
    'link-text',
    'size',
    'vertical',
    'color',
    'lock',
    'delete',
  ],
  toolbars: [
    Value.toolbar,
  ],
};
