import React from 'react';
import { ITool } from 'tools';
import { blocks } from 'blocks';
import { getState } from 'utils/store';
import { createTool } from 'tools/ToolBasic';

import icon from './assets/icon.svg';

const Help = () => (
  <>
    <div className="text-10">Auto Centering</div>
    <div className="text-9">The Auto Centering property is a feature in Exemplar that influences the positioning of control points when connecting blocks.</div>
    <ul>
      <li className="text-9"><b>Active Mode</b>: When the Auto Centering property is active, the first control point of a connector will be automatically centered on the side of the joined block. This creates a symmetrical and centered connection.</li>
      <li className="text-9"><b>Inactive Mode</b>: When the Auto Centering property is not active, the first control point will be connected to the closest point on the target side of the joined block. In this mode, the connection is based on proximity, and the control point attaches to the nearest available point on the block&apos;s side. Importantly, when this mode is inactive, the first control point will not be shifted, and it will not affect the offset of other centered connector&apos;s control points on the side of the block.</li>
    </ul>
    <div className="text-9">The Auto Centering property provides users with the flexibility to choose between centered or proximity-based connections, allowing for precise control over the positioning of connectors in their diagrams, without impacting other centered connector points.</div>
  </>
);

const getStatus = (selection: string[]) => {
  const file = getState((state) => state.diagram.file);

  return selection.every((id) => {
    return !file[id]?.endFree || file[id]?.points?.length > 2;
  });
};

export const Tool = createTool({
  key: 'startFree',
  def: false,
  title: 'Start centring',
  alt: 'Start centring',
  icon,
  Help,
  allowDisabledHint: true,
  getActive: (value) => !value,
  onMemo: (memo, value) => {
    const drop = getState((state) => state.graphic.drop);

    memo(blocks[drop?.block ?? '_']?.element.memo ?? '', 'startFree', !value);

    if (!value) {
      memo(blocks[drop?.block ?? '_']?.element.memo ?? '', 'endFree', false);
    }
  },
  getDisabled: (selection, value) => {
    if (value) {
      return false;
    }

    const status = getStatus(selection);

    return !status;
  },
  getTitle: (value, selection) => {
    if (value) {
      return undefined;
    }

    const status = getStatus(selection);

    if (status) {
      return undefined;
    }

    return 'Third point is required';
  },
});

export const ConnectorStartFree: ITool = {
  key: 'connector-start-free',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
