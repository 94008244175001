import React, { FC, ReactNode } from 'react';

import './assets/Wrap.css';

interface WrapProps {
  children: ReactNode;
  variant: 'great' | 'big' | 'normal' | 'small' | 'short';
  type: 'block' | 'text';
  cut?: 'none' | 'vertical' | 'horizontal';
  className?: string;
}

export const Wrap: FC<WrapProps> = ({ children, variant, type, cut, className }) => {
  return (
    <div className={`wrap-${type}-${variant} wrap-cut-${cut}${className ? ` ${className}` : ''}`}>
      {children}
    </div>
  );
};

Wrap.defaultProps = {
  cut: 'none',
  className: '',
};
