import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import iconCap0 from './assets/cap-0.svg';
import iconCap1 from './assets/cap-1.svg';

export const Tool = createTool('connector-uml-class-start', 'start', 0, 'Start', 'Start', {
  0: iconCap0,
  1: iconCap1,
});

export const ConnectorUMLClassStart: ITool = {
  key: 'connector-uml-class-start',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
