import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { storage as localStorage } from 'utils/storage';

import { IUser } from './account';
import { IRole } from './diagram';

interface State {
  users: {[key: string]: IUser};
  shares: {[key: string]: IRole};
  diagrams: {[key: string]: any};
  elements: {[key: string]: any};
  groups: {[key: string]: any};
  tabs: {[key: string]: any};
}

export const defaultState: State = {
  users: {},
  shares: JSON.parse(localStorage.getItem('share') || '{}'),
  diagrams: JSON.parse(localStorage.getItem('diagram') || '{}'),
  elements: JSON.parse(localStorage.getItem('elements') || '{}'),
  groups: {},
  tabs: {},
};

const slice = createSlice({
  name: 'cache',
  initialState: defaultState,
  reducers: {
    reset: (state) => {
      state.tabs = defaultState.tabs;
    },
    addUser: (state, action: PayloadAction<IUser>) => {
      state.users[action.payload.id] = {
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        color: action.payload.color,
      };
    },
    setElement: (state, action: PayloadAction<{ diagram: string, id: string, properties: {} }>) => {
      if (!(action.payload.diagram in state.elements)) {
        state.elements[action.payload.diagram] = {};
      }

      state.elements[action.payload.diagram][action.payload.id] = {
        ...(state.elements[action.payload.diagram][action.payload.id] || {}),
        ...action.payload.properties,
      };

      localStorage.setItem('elements', JSON.stringify(state.elements));
    },
    removeElements: (state, action: PayloadAction<{ diagram: string, ids: string[] }>) => {
      if (!state.elements[action.payload.diagram]) {
        return;
      }

      action.payload.ids.forEach((id) => {
        delete state.elements[action.payload.diagram][id];
      });

      localStorage.setItem('elements', JSON.stringify(state.elements));
    },
    resetElements: (state, action: PayloadAction<{ diagram: string, elements: {} }>) => {
      state.elements[action.payload.diagram] = action.payload.elements;

      localStorage.setItem('elements', JSON.stringify(state.elements));
    },
    removeUser: (state, action: PayloadAction<{ id: string }>) => {
      delete state.users[action.payload.id];
    },
    addShare: (state, action: PayloadAction<{ id: string, role: IRole}>) => {
      state.shares[action.payload.id] = action.payload.role;

      localStorage.setItem('share', JSON.stringify(state.shares));
    },
    removeShare: (state, action: PayloadAction<{ id: string }>) => {
      delete state.shares[action.payload.id];

      localStorage.setItem('share', JSON.stringify(state.shares));
    },
    initDiagram: (state, action: PayloadAction<{ id: string }>) => {
      state.diagrams[action.payload.id] = {
        ...(state.diagrams.new || {}),
      };

      localStorage.setItem('diagram', JSON.stringify(state.diagrams));
    },
    setDiagram: (state, action: PayloadAction<{ id: string, data: {}}>) => {
      if (!(action.payload.id in state.diagrams)) {
        state.diagrams[action.payload.id] = {};
      }

      state.diagrams[action.payload.id] = {
        ...state.diagrams[action.payload.id],
        ...action.payload.data,
      };

      localStorage.setItem('diagram', JSON.stringify(state.diagrams));
    },
    removeDiagram: (state, action: PayloadAction<string>) => {
      delete state.elements[action.payload];

      localStorage.setItem('elements', JSON.stringify(state.elements));
    },
    setGroup: (state, action: PayloadAction<{ id: string, data: {}}>) => {
      if (!(action.payload.id in state.diagrams)) {
        state.groups[action.payload.id] = {};
      }

      state.groups[action.payload.id] = {
        ...state.groups[action.payload.id],
        ...action.payload.data,
      };
    },
    setTab: (state, action: PayloadAction<{ key: string, data: {} }>) => {
      if (!(action.payload.key in state.tabs)) {
        state.tabs[action.payload.key] = {};
      }

      state.tabs[action.payload.key] = {
        ...state.tabs[action.payload.key],
        ...action.payload.data,
      };
    },
  },
});

export const storeCache = {
  addUser: slice.actions.addUser,
  removeUser: slice.actions.removeUser,
  addShare: slice.actions.addShare,
  removeShare: slice.actions.removeShare,
  setDiagram: slice.actions.setDiagram,
  initDiagram: slice.actions.initDiagram,
  setElement: slice.actions.setElement,
  removeElements: slice.actions.removeElements,
  resetElements: slice.actions.resetElements,
  setGroup: slice.actions.setGroup,
  setTab: slice.actions.setTab,
  reset: slice.actions.reset,
};

export default slice.reducer;
