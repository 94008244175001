import React, { useContext, useEffect } from 'react';

import { useProperty } from 'hooks/useProperty';
import { useStoreSelector } from 'hooks/useStore';

import { ElementContext } from './Element';

export const Captured = () => {
  const element = useContext(ElementContext);

  const captured = useStoreSelector((state) => state.diagram.mode === 'edit' && state.diagram.captured[element.id]);
  const user = useStoreSelector((state) => state.cache.users[captured || '']);

  const hidden = useProperty(!captured);
  const color = useProperty(user?.color);

  useEffect(() => {
    if (user?.color) {
      color.set(user.color);
    }
  }, [user?.color]);

  useEffect(() => {
    if (!captured) {
      const timer = setTimeout(() => {
        hidden.set(true);
      }, 700);

      return () => {
        clearTimeout(timer);
      };
    }

    hidden.set(false);

    return () => {};
  }, [captured]);

  if (hidden.value) {
    return null;
  }

  return (
    <div style={{ ['--color' as string]: color.value }} className={'block-captured'.appendWhen(!captured, 'block-hiding')} />
  );
};
