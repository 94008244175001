import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import iconCenter from './assets/center.svg';
import iconRight from './assets/right.svg';
import iconLeft from './assets/left.svg';

const Tool = createTool('align-horizontal', 'alignHorizontal', 'center', 'Horizontal alignment', 'Alignment', {
  center: iconCenter,
  right: iconRight,
  left: iconLeft,
});

export const AlignHorizontal: ITool = {
  key: 'align-horizontal',
  tool: Tool,
  options: ['only-edit'],
};
