import React, { memo, useContext, useEffect } from 'react';
import { IBlock, IConfig } from 'blocks';
import { getState } from 'utils/store';
import { Emitter } from 'utils/events';
import { url } from 'services';
import { store } from 'store';

import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

import { serviceDiagram } from 'services/diagram';

import { useResizer } from 'hooks/useResizer';
import { GridContext } from 'hooks/useGrid';
import { useProperty } from 'hooks/useProperty';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';
import { useClickOnSelection } from 'hooks/useClickOnSelection';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';
import { useArrangementWrapper } from 'hooks/useArrangementWrapper';
import { useTextCreatorWithWrapper } from 'hooks/useTextCreatorWithWrapper';

import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCreator } from 'blocks/factories/simple/Creator';
import design from 'blocks/factories/assets/Simple.module.css';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Image',
  search: 'general image picture photo',
  type: 'general-image',
  group: ['General'],
  memo: {
    key: 'general-image',
    styles: [
      'color',
    ],
  },
  styles: [
    'color',
  ],
};

interface IModel {
  url: string;
  diagram: string;
  ticket: string;
  color: string;
  loading: boolean;
  width: number;
  height: number;
  realWidth: number;
  realHeight: number;
  lockKey: string;
  lock: boolean;
}

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const dispatch = useStoreDispatch();

    const diagram = useStoreSelector((state) => state.diagram.diagram);

    const grid = useContext(GridContext);

    const create = useTextCreatorWithWrapper();
    const arrangement = useArrangementWrapper();
    const resier = useResizer<HTMLImageElement>({
      strict: true,
      realWidth: properties.realWidth,
      realHeight: properties.realHeight,
      minWidth: 24,
      minHeight: 24,
    });

    const loading = useProperty(true);
    const failed = useProperty(false);

    useEnterOnSelection({
      onPress: create('enter'),
      mode: 'edit',
    });

    useClickOnSelection({
      onClick: create('click'),
      mode: 'edit',
    });

    // Wait for image to be loaded
    useEffect(() => {
      if (!loading.value || properties.loading || !properties.url) {
        return;
      }

      const img = new Image();

      img.onload = () => {
        loading.set(false);
        failed.set(false);
      };

      img.onerror = (e) => {
        loading.set(false);
        failed.set(true);
      };

      img.src = `${url}/diagrams/asset/${properties.diagram}/${properties.ticket}/${properties.url}`;
    }, [properties.url, properties.loading, loading.value]);

    // Refresh Grid on Loading is Finished
    useUpdateEffect(() => {
      if (!loading.value && properties.url) {
        grid.refresh();
      }
    }, [!loading.value && properties.url]);

    // Update Diagram Properties
    useEffect(() => {
      if (diagram.ticket !== properties.ticket || diagram.id !== properties.diagram) {
        dispatch(storeDiagram.setElement({
          id: element.id,
          properties: {
            diagram: diagram.id,
            ticket: diagram.ticket,
          },
        }));

        loading.set(true);
      }
    }, [diagram.ticket !== properties.ticket || diagram.id !== properties.diagram]);

    return (
      <Shape
        classElement={style.element}
        classProperties={`${design[`color-${properties.color}`]}`.appendWhen(properties.color !== 'none', style.outline)}
      >
        <Arrangement {...arrangement} />
        <Selection status={!resier.status} />

        <div
          className="block-content"
          {...element.trigger}
          {...resier.wrap}
        >
          <div
            className={
              `${style.image}`
                .appendWhen(properties.loading || loading.value, 'unit-loading')
                .appendWhen(!properties.loading && failed.value, 'unit-failed')
            }
          >
            <img src={(properties.diagram && properties.ticket && properties.url) ? `${url}/diagrams/asset/${properties.diagram}/${properties.ticket}/${properties.url}` : ''} alt="" />
          </div>
        </div>

        <Resizer.Size {...resier.attributes} status={resier.visibility} symmetrically />
      </Shape>
    );
  },
});

export const GeneralImage : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-wrap', 'element-wrapable'],
    constraints: {
      prevent: {
        color: [
          'white',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        loading: true,
        color: 'none',
        url: '',
        diagram: '',
        ticket: '',
        height: 150,
        width: 150,
        realHeight: 150,
        realWidth: 150,
        lockKey: '',
        lock: false,
      },
      condition: (payload) => {
        const diagram = getState((state) => state.diagram.diagram);

        payload.properties = {
          ...(payload.properties ?? {}),

          diagram: diagram.id,
          ticket: diagram.ticket,
        };

        return payload;
      },
    }),
    onCopy: ({ file, transition, target, position, parent, auto, native, diagram, ticket }) => {
      const id = transition[target.id];

      file[id] = {
        ...target,

        loading: !native && true,
        url: native ? target.url : undefined,

        id,
        type: config.type,
        position: auto ? target.position : position,
        parent,
        diagram,
        ticket,
      };

      store.dispatch(storeDiagram.addElement({
        element: file[id],
      }));

      if (!auto) {
        store.dispatch(storeGraphic.setSelectionElements([id]));
      }

      const duplicate = (diagram: { id: string }) => {
        Emitter.off('diagram-creation', duplicate);

        store.dispatch(serviceDiagram.duplicate({
          id: diagram.id,
          url: `${url}/diagrams/asset/${target.diagram}/${target.ticket}/${target.url}`,
          callback: (payload) => {
            if (!payload || !payload.url) {
              store.dispatch(storeDiagram.setElement({
                id,
                properties: {
                  url: '',
                  loading: false,
                },
              }));

              return;
            }

            store.dispatch(storeDiagram.setElement({
              id,
              properties: {
                url: payload.url,
                loading: false,
              },
            }));
          },
        }));
      };

      if (!native && target.url && diagram) {
        duplicate({
          id: diagram,
        });
      } else {
        Emitter.on('diagram-creation', duplicate);
      }

      return id;
    },
    title: config.title,
    size: [150, 150],
    icon,
    class: {
      title: 'Image',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit', 'load-file'],
    type: 'drag',
  },
  tools: [
    'color',
    'lock',
    'delete',
  ],
  toolbars: [

  ],
};
