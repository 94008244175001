import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { Cap0 } from './Cap0';
import { Cap1 } from './Cap1';
import { Cap2 } from './Cap2';
import { Cap3 } from './Cap3';
import { Cap4 } from './Cap4';
import { Cap5 } from './Cap5';

export interface IConnector {
  Start: React.ComponentType<ICap>,
  End: React.ComponentType<ICap>,
}

export const Start: {[key: number]: React.ComponentType<ICap>} = {
  0: Cap0.Start,
  1: Cap1.Start,
  2: Cap2.Start,
  3: Cap3.Start,
  4: Cap4.Start,
  5: Cap5.Start,
};

export const End: {[key: number]: React.ComponentType<ICap>} = {
  0: Cap0.End,
  1: Cap1.End,
  2: Cap2.End,
  3: Cap3.End,
  4: Cap4.End,
  5: Cap5.End,
};
