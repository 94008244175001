import React, { memo, useCallback } from 'react';
import { IBlock } from 'blocks';

import { useResizer } from 'hooks/useResizer';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';
import design from 'blocks/factories/assets/Simple.module.css';

import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';

import style from './assets/style.module.css';
import icon from './assets/icon.svg';
import iconWhite from './assets/icon-white.svg';

export const config = {
  title: 'Marker',
  search: 'general arrow line marker draw paint highlighter',
  type: 'general-marker',
  group: ['General'],
  memo: {
    key: 'marker-general',
    styles: [
      'size',
      'color',
    ],
  },
  styles: [
    'size',
    'color',
  ],
};

interface IModel {
  color: string;
  size: string;
  style: string;
  points: number[][];
  path: string;
  width: number;
  height: number;
  realWidth: number;
  realHeight: number;
  lockKey: string;
  lock: boolean;
}

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const resier = useResizer<HTMLImageElement>({
      strict: true,
      realWidth: properties.realWidth,
      realHeight: properties.realHeight,
      minWidth: 22,
      minHeight: 22,
    });

    const shape = useCallback((position: [number, number], size: [number, number]) : [number, number][] => {
      return properties.points.map(([x, y]) => [
        (x * (size[0] / properties.realWidth)) + position[0],
        (y * (size[1] / properties.realHeight)) + position[1],
      ]);
    }, []);

    return (
      <Shape
        drawn
        classElement={style.element}
        classProperties={''.append(`${design[`color-${properties.color}`]}`)}
        shape={shape}
      >

        <svg className="block-render" style={{ ...resier.wrap.style }} viewBox={`0 0 ${properties.realWidth} ${properties.realHeight}`}>
          <path d={properties.path} fillOpacity={properties.style === 'highlighter' ? 0.25 : 1} {...element.trigger} className="render-hit" />
        </svg>

        <Selection status={!resier.status} />

        <div
          className="block-content"
          {...resier.wrap}
        />

        <Resizer.Size {...resier.attributes} status={resier.visibility} symmetrically />
      </Shape>
    );
  },
});

export const GeneralMarker : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    memo: config.memo.key,
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-marker', 'parent-none', 'child-none', 'container-none', 'container-title-none', 'no-selection-view-mode'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        color: 'dark',
        style: 'pen',
        size: 'm',
        points: [],
        path: '',
        height: 64,
        width: 64,
        realHeight: 64,
        realWidth: 64,
        lockKey: '',
        lock: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [64, 64],
    icon,
    class: {
      title: 'Marker',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: [
      'only-edit',
    ],
    type: 'marker-general',
  },
  tools: [
    'color',
    'lock',
    'delete',
  ],
  toolbars: [

  ],
};
