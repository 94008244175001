import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { Model } from 'blocks/factories/shape/Model';
import { createRender } from 'blocks/factories/simple/Render';
import { createPadding } from 'blocks/factories/simple/Padding';
import { createBlock, block } from 'blocks/factories/shape/Block';

import icon from './assets/icon.svg';

type IModel = Model<{}>;

export const config: IConfig = {
  title: 'Rectangle',
  search: 'basic shape rectangle rounded',
  type: 'basic-shape-rectangle-rounded',
  group: ['Basic', 'Basic Shape'],
  memo: {
    key: 'basic-shape',
    styles: block.element.memo,
  },
  styles: block.element.styles,
};

const Render = createRender<IModel>({
  render: ({ width, height, element }) => {
    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - border, 0);
    const y = Math.max(height - border, 0);

    const points = [
      [offset, offset],
      [x, y],
    ];

    return (
      <svg className="block-render" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect {...element.trigger} className="render-fill render-stroke render-hit" x={points[0][0]} y={points[0][1]} width={points[1][0]} height={points[1][1]} strokeLinecap="round" rx="14" strokeWidth={border} />
      </svg>
    );
  },
});

const Padding = createPadding<IModel>({
  size: [0, 0],
  padding: [1, 1, 1, 1],
  creator: () => {
    return [0, 0, 0, 0];
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  Render,
  Padding,
});

export const BasicShapeRectangleRounded : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: block.element.options,
    constraints: block.element.constraints,
    onCreate: block.creator(config, {
      width: 64,
      height: 64,
    }),
    onCopy: block.copier(config),
    title: config.title,
    size: [64, 64],
    icon,
    class: block.element.class,
  },
  button: block.button(config),
  tools: ['shape-basic', ...block.tools],
  toolbars: block.toolbars,
};
