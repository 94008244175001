import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import icon from './assets/icon.svg';

const Tool = createTool('aws', '_', 'icon', 'Icon', 'Icon', {
  icon,
});

export const AWS: ITool = {
  key: 'aws',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
