import React, { memo } from 'react';
import { IBlock } from 'blocks';

import { Model } from 'blocks/factories/connector/Model';
import { createBlock } from 'blocks/factories/connector/Block';
import { createCopier } from 'blocks/factories/connector/Copier';
import { createCreator } from 'blocks/factories/connector/Creator';

import { Button } from 'elements/Block/Button';

import { End, Start } from './components/Cap';

import icon from './assets/icon.svg';
import iconWhite from './assets/icon-white.svg';

export const config = {
  title: 'Connector',
  search: 'general arrow line connector',
  type: 'general-connector',
  group: ['General'],
  memo: {
    key: 'connector-general',
    styles: [
      'size',
      'color',
      'style',
      'stroke',
      'start',
      'end',
      'startFree',
      'endFree',
    ],
  },
  styles: [
    'size',
    'color',
    'style',
    'stroke',
    'start',
    'end',
    'startFree',
    'endFree',
  ],
};

type IModel = Model<{}>;

const Block = createBlock<IModel>({
  config,
  caps: {
    Start,
    End,
  },
});

const Help = () => (
  <>
    <div className="text-10">Connector</div>
    <div className="text-9">The Connector Block is a vital tool for linking and organizing elements within your canvas.</div>
    <div className="text-8">Key Features:</div>
    <ul>
      <li className="text-9"><b>Joining Elements</b>: The Connector Block&apos;s primary function is to join other blocks together, facilitating seamless connections.</li>
      <li className="text-9"><b>Control Points</b>: Connectors have a minimum of two control points, but users can add extra control points for added flexibility.</li>
      <li className="text-9"><b>Control Point Flexibility</b>: Control points can be joined to other blocks, allowing for precise control over connections.</li>
      <li className="text-9"><b>Limitations</b>: Control points cannot be joined to Stickers, Connectors, or Drawings. They are exclusively for connecting to other standard blocks.</li>
    </ul>
    <div className="text-8">Joining Control Points:</div>
    <ul>
      <li className="text-9"><b>Centered Point Join</b>: To join a control point to a block, drag it onto the target block and release the control point when a special black line appears. This black line highlights the side where the control point will be attached.</li>
      <li className="text-9"><b>Auto-Side Selection</b>: When connecting to the center of a block, the control point will automatically select the best side based on the connector&apos;s position and direction.</li>
      <li className="text-9"><b>Relative Point Join</b>: Users can join a control point to a related point on the block by holding <b>CTRL</b> (or <b>CMD</b>) while dragging the control point onto the block and releasing it. A special black line won&apos;t appear in this case, but the target block will become transparent.</li>
      <li className="text-9"><b>Space Key Functionality</b>: Users can hold down the <b>SPACE</b> key if they wish to prevent the connector&apos;s control points from joining to any blocks. This feature provides users with greater control over the connector&apos;s behavior and placement.</li>
    </ul>
    <div className="text-9"><b>Multiple Joins</b>: When a block has multiple connections from one side, control points are automatically adjusted to prevent overlaps, ensuring a clean and organized diagram.</div>
    <div className="text-9">The Connector Block empowers you to create well-structured and interconnected diagrams with precision and ease. It&apos;s a valuable tool for enhancing the clarity and visual flow of your work.</div>
  </>
);

export const GeneralConnector : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    Help,
    memo: config.memo.key,
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-connector', 'parent-none', 'child-none', 'container-none', 'container-title-none', 'no-selection-view-mode', 'lock-delete'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      config,
    }),
    onCopy: createCopier({
      config,
    }),
    title: config.title,
    size: [64, 64],
    icon,
    class: {
      title: 'Connector',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: [
      'only-edit',
    ],
    type: 'connector-general',
  },
  tools: [
    'connector-general-start',
    'connector-general-end',
    'size',
    'line-style',
    'line-stroke',
    'color',
    'connector-start-free',
    'connector-end-free',
    'connector-reverse',
    'lock',
    'delete',
  ],
  toolbars: [

  ],
};
