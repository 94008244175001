import { ITool } from 'tools';
import { createTool } from 'tools/ToolLink';

import icon from './assets/icon.svg';

const Tool = createTool('fields', 'Fields', 'Fields', icon);

export const LinkFields: ITool = {
  key: 'link-fields',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
