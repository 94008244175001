import { ITool } from 'tools';
import { blocks } from 'blocks';
import { createTool } from 'tools/ToolTab';

import iconTopLeft from './assets/top-left.svg';
import iconTopCenter from './assets/top-center.svg';
import iconTopRight from './assets/top-right.svg';
import iconRightTop from './assets/right-top.svg';
import iconRightCenter from './assets/right-center.svg';
import iconRightBottom from './assets/right-bottom.svg';
import iconBottomLeft from './assets/bottom-left.svg';
import iconBottomCenter from './assets/bottom-center.svg';
import iconBottomRight from './assets/bottom-right.svg';
import iconLeftTop from './assets/left-top.svg';
import iconLeftCenter from './assets/left-center.svg';
import iconLeftBottom from './assets/left-bottom.svg';

const Tool = createTool('placing', 'placing', 'bottom', 'Placing', 'Placing', {
  top: iconTopCenter,
  right: iconRightCenter,
  bottom: iconBottomCenter,
  left: iconLeftCenter,
  'top-left': iconTopLeft,
  'top-right': iconTopRight,
  'right-top': iconRightTop,
  'right-bottom': iconRightBottom,
  'bottom-left': iconBottomLeft,
  'bottom-right': iconBottomRight,
  'left-top': iconLeftTop,
  'left-bottom': iconLeftBottom,
}, (state) => {
  const selection = state.graphic.selection.elements;

  return !selection.every((id) => {
    if (blocks[state.diagram.file[id]?.type]?.element.options.includes('element-container')) {
      return !(state.diagram.childs[id]?.length > 0);
    }

    return !blocks[state.diagram.file[id]?.type]?.element.options.includes('element-wrap-inline');
  });
});

export const Placing: ITool = {
  key: 'placing',
  tool: Tool,
  options: ['only-edit'],
};
