import React, { memo } from 'react';
import { IBlock } from 'blocks';

import { useConnector } from 'hooks/useConnector';
import { useConnectorElbow } from 'hooks/useConnectorElbow';
import { useConnectorStyle } from 'hooks/useConnectorStyle';
import { useConnectorMasks } from 'hooks/useConnectorMasks';
import { useConnectorSmooth } from 'hooks/useConnectorSmooth';
import { useConnectorStroke } from 'hooks/useConnectorStroke';
import { useConnectorDefault } from 'hooks/useConnectorDefault';
import { useConnectorPicture } from 'hooks/useConnectorPicture';
import { useResizerConnectorCreator } from 'hooks/useResizerConnectorCreator';
import { useConnectorPostprocessing } from 'hooks/useConnectorPostprocessing';

import { Model } from 'blocks/factories/connector/Model';
import { createBlock } from 'blocks/factories/connector/Block';
import { createCopier } from 'blocks/factories/connector/Copier';
import { createCreator } from 'blocks/factories/connector/Creator';

import { Button } from 'elements/Block/Button';

import { End, Start } from './components/Cap';

import icon from './assets/icon.svg';
import iconWhite from './assets/icon-white.svg';

export const config = {
  title: 'Connector',
  search: 'arrow line connector information',
  type: 'information-connector',
  group: ['Information'],
  memo: {
    key: 'connector-information',
    styles: [
      'color',
      'style',
      'stroke',
      'start',
      'end',
      'startFree',
    ],
  },
  styles: [
    'color',
    'style',
    'stroke',
    'start',
    'end',
    'startFree',
  ],
};

type IModel = Model<{}>;

const Block = createBlock<IModel>({
  config,
  caps: {
    Start,
    End,
  },
  flow: ({ resizer }) => {
    const connector = useConnector(resizer.offset);
    const points = useConnectorPostprocessing(connector);

    const dots = useConnectorDefault(points);
    const elbows = useConnectorElbow(points, connector);
    const levers = useConnectorSmooth(points, connector);

    const picture = useConnectorPicture(points, levers.handles);
    const path = useConnectorStyle(elbows.points ?? dots, levers.handles, picture.position, picture.padding);

    const stroke = useConnectorStroke();
    const creator = useResizerConnectorCreator(elbows.axis);
    const masks = useConnectorMasks(connector, picture.padding);

    return {
      points,
      dots,
      elbows,
      levers,
      picture,
      path,
      stroke,
      creator,
      masks,
      vertices: [],
    };
  },
});

const Help = () => (
  <>
    <div className="text-10">Connector (Information)</div>
    <div className="text-9">The Connector (Information) is a specialized type of connector block designed for specific purposes.</div>
    <div className="text-8">Key Features:</div>
    <ul>
      <li className="text-9"><b>Control Points</b>: Unlike standard connectors, the Connector (Information) has only two control points. Csers haven&apos;t got any options to add extra control points.</li>
      <li className="text-9"><b>Control Point Behavior</b>: The first control point follows the default behavior of a connector, allowing users to connect it to various elements as needed.</li>
      <li className="text-9"><b>Last Control Point</b>: The last control point of the Connector (Information) serves a unique purpose. It can only be joined to related position of element.</li>
    </ul>
    <div className="text-9">This specialized connector is ideal for situations where you need to create connections with specific constraints, ensuring that the last control point is reserved for related position. It provides a structured approach to your diagramming needs.</div>
  </>
);

export const InformationConnector : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    Help,
    memo: config.memo.key,
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: [
      'element-connector',
      'parent-none',
      'child-none',
      'container-none',
      'container-title-none',
      'no-selection-view-mode',
      'lock-delete',
      'no-rebinding',
    ],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
      free: {
        handle: '1',
      },
    },
    onCreate: createCreator<IModel>({
      config,
      properties: {
        end: 9,
      },
    }),
    onCopy: createCopier({
      config,
    }),
    title: config.title,
    size: [64, 64],
    icon,
    class: {
      title: 'Information Connector',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: [
      'only-edit',
    ],
    type: 'connector-information',
  },
  tools: [
    'connector-information-start',
    'connector-information-end',
    'line-style',
    'line-stroke',
    'color',
    'connector-start-free',
    'lock',
    'delete',
  ],
  toolbars: [

  ],
};
