import React, { memo, useEffect, useRef } from 'react';
import { IBlock, IConfig } from 'blocks';
import { getState } from 'utils/store';
import 'external-svg-loader';

import { OptionAnimation } from 'store/settings';

import { useOption } from 'hooks/useOption';
import { useResizer } from 'hooks/useResizer';
import { useProperty } from 'hooks/useProperty';
import { useStoreSelector } from 'hooks/useStore';

import { Sticky } from 'elements/Block/Sticky';
import { Button } from 'elements/Block/Button';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';
import design from 'blocks/factories/assets/Simple.module.css';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Sticker',
  search: 'general icon logo emoji sticker gif animation',
  type: 'general-sticker',
  group: ['General'],
  memo: {
    key: 'general-sticker',
    styles: [
      'color',
    ],
  },
  styles: [
    'color',
    'width',
    'height',
  ],
};

interface IModel {
  url: string;
  color: string;
  width: number;
  height: number;
  realWidth: number;
  realHeight: number;
  sticky: string;
  offset: [number, number];
  lockKey: string;
  lock: boolean;
}

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const mode = useStoreSelector((state) => state.diagram.mode);
    const permission = useStoreSelector((state) => state.settings.app.animation.includes(OptionAnimation.Sticker));
    const exists = useStoreSelector((state) => state.graphic.elements[element.id] && (!properties.sticky || properties.sticky in state.graphic.elements));

    const loading = useProperty(exists);
    const failed = useProperty(false);
    const animating = useRef(false);

    const isIcon = properties.url.endsWith('.svg');

    const resier = useResizer<HTMLImageElement>({
      strict: true,
      realWidth: properties.realWidth,
      realHeight: properties.realHeight,
      minWidth: 22,
      minHeight: 22,
    });

    const animate = () => {
      if (animating.current) {
        return;
      }

      animating.current = true;
      element.wrap.ref?.classList.add(style.animating);

      setTimeout(() => {
        element.wrap.ref?.classList.remove(style.animating);
        animating.current = false;
      }, 200);
    };

    if (mode === 'view') {
      element.trigger.onClick = (e) => {
        e.preventDefault();

        animate();
      };
    }

    useEffect(() => {
      if (mode === 'view' && element.highlighted) {
        animate();
      }
    }, [mode === 'view' && element.highlighted]);

    useOption({
      id: element.id,
      key: 'color',
      value: !isIcon ? 'disable' : '',
      deps: [!isIcon],
    });

    // Wait for image to be loaded
    useEffect(() => {
      loading.set(true);

      const img = new Image();

      img.onload = () => {
        loading.set(false);
        failed.set(false);
      };

      img.onerror = (e) => {
        loading.set(false);
        failed.set(true);
      };

      img.src = properties.url;
    }, [properties.url]);

    return (
      <Sticky
        classElement={style.element}
        classProperties={''.append(`${design[`color-${properties.color}`]}`)}
      >
        <Selection status={!resier.status} />

        <div
          className={
            `block-content ${style.image}`
              .appendWhen(!permission, style.static)
              .appendWhen(loading.value, 'unit-loading')
              .appendWhen(!properties.url && failed.value, 'unit-failed')
          }
          {...element.trigger}
          {...resier.wrap}
        >
          {isIcon ? (
            <svg data-src={properties.url} />
          ) : (
            <img src={properties.url} alt="" />
          )}
        </div>

        <Resizer.Size {...resier.attributes} status={resier.visibility} symmetrically />
      </Sticky>
    );
  },
});

const Help = () => (
  <>
    <div className="text-10">Sticker</div>
    <div className="text-9">The Sticker block in Exemplar adds a touch of creativity and customization to your canvas.</div>
    <div className="text-8">Key Features:</div>
    <ul>
      <li className="text-9"><b>Non-Nestable</b>: Stickers are designed to be standalone elements and cannot be nested within other blocks.</li>
      <li className="text-9"><b>View Mode Exclusivity</b>: During view mode, Stickers are intentionally excluded from selection, ensuring that they don&apos;t interfere with your presentation.</li>
      <li className="text-9"><b>Versatile Attachment</b>: Stickers can be attached to other blocks, allowing you to add context, labels, or visual cues to your diagrams. However, they cannot be attached to Connectors, other Stickers, or Drawings.</li>
      <li className="text-9"><b>Seamless Attachment</b>: To attach a Sticker to another block, simply hold down the <b>SPACE</b>, <b>CTRL</b>, or <b>CMD</b> key while dragging the Sticker onto the target block. When the target block becomes transparent, release the Sticker to have it seamlessly adhere to the desired block.</li>
      <li className="text-9"><b>Visual Highlight</b>: In view mode, a Sticker will be automatically highlighted when the block it&apos;s connected to is selected. This ensures that your audience can easily identify and understand the relationship between the Sticker and the associated block.</li>
      <li className="text-9"><b>Layout Mode Consideration</b>: If you have set the layout mode to automatic in Exemplar settings, keep in mind that when a Sticker is already attached to a block, you&apos;ll need to hold <b>SPACE</b>, <b>CTRL</b>, or <b>CMD</b> during dragging to temporarily disable the attaching state. This allows for greater control over the placement of your Stickers.</li>
    </ul>
    <div className="text-9">The Sticker block empowers you to add informative labels and visual elements to your canvas, enhancing the clarity and visual appeal of your work. Whether you&apos;re creating diagrams, presentations, or illustrations, Stickers provide an efficient way to convey information and enhance your designs.</div>
  </>
);

export const GeneralSticker : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    Help,
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-sticker', 'no-selection-view-mode', 'parent-free', 'container-title-none', 'child-none', 'container-child-none'],
    constraints: {},
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        url: '',
        color: 'dark',
        height: 64,
        width: 64,
        realHeight: 64,
        realWidth: 64,
        sticky: '',
        offset: [0, 0],
        lockKey: '',
        lock: false,
      },
      condition: (payload) => {
        const drag = getState((state) => state.graphic.drag);

        if (!drag || !payload.parent) {
          return payload;
        }

        payload.properties = {
          ...(payload.properties ?? {}),

          sticky: payload.parent,
          offset: [
            -(drag.frame.size[0] / 2),
            -(drag.frame.size[1] / 2),
          ],
        };

        payload.parent = '';

        return payload;
      },
    }),
    onCopy: createCopier({
      type: config.type,
      condition: (payload) => {
        const drag = getState((state) => state.graphic.drag);

        if (payload.native && drag && payload.parent) {
          payload.target = {
            ...(payload.target ?? {}),

            sticky: payload.parent,
            offset: [
              -(drag.frame.size[0] / 2 + drag.frame.offset[0]),
              -(drag.frame.size[1] / 2 + drag.frame.offset[1]),
            ],
          };
        } else if (payload.target.sticky && payload.target.sticky in payload.transition) {
          payload.target = {
            ...(payload.target ?? {}),

            sticky: payload.transition[payload.target.sticky],
          };
        } else {
          payload.target = {
            ...(payload.target ?? {}),

            sticky: '',
            offset: [0, 0],
          };
        }

        payload.parent = '';

        return payload;
      },
    }),
    title: config.title,
    size: [64, 64],
    icon,
    class: {
      title: 'Sticker',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'sticker',
  },
  tools: [
    'sticker',
    'color',
    'lock',
    'delete',
  ],
  toolbars: [

  ],
};
