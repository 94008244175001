import React, { useEffect } from 'react';
import { Emitter } from 'utils/events';
import { colors } from 'tabs/FormatHighlight';
import { ITool } from 'tools';

import { storeGraphic } from 'store/graphic';

import { useProperty } from 'hooks/useProperty';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import style from './assets/style.module.css';

const Tool = () => {
  const dispatch = useStoreDispatch();

  const selection = useStoreSelector((state) => state.graphic.selection.elements.length === 1);
  const status = useProperty((() => {
    try {
      return ((window as any).editor?.getFormat() || {}).background || '';
    } catch (e) {
      return '';
    }
  })());
  const visible = useProperty(!!(window as any).editor || selection);

  const onClick = () => {
    dispatch(storeGraphic.setComponentTab('format-highlight'));
  };

  // Listen to Editor Change
  useEffect(() => {
    const onChange = () => {
      visible.set(!!(window as any).editor || selection);
    };

    Emitter.on('editor-change', onChange);

    return () => {
      Emitter.off('editor-change', onChange);
    };
  }, []);

  // Listen to Selection Change
  useEffect(() => {
    const onSelect = (payload: {[key: string]: string}) => {
      status.set(payload.background || '');
    };

    Emitter.on('selection-change', onSelect);

    return () => {
      Emitter.off('selection-change', onSelect);
    };
  }, []);

  return (
    <Hint message={`Highlight (${status.value})`} disabled={!visible.value} side="bottom" variant="default">
      <button type="button" className={`button-icon v-1${!visible.value ? ' button-disabled' : ''}`} onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <div className={`${style.icon}${status.value ? '' : ` ${style.none}`}${(!status.value || status.value in colors) ? '' : ` ${style.failed}`}`} style={{ background: status.value in colors ? colors[status.value] : 'rgba(183, 183, 223, 0.5)' }} />
      </button>
    </Hint>
  );
};

export const FormatHighlight: ITool = {
  key: 'format-highlight',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
