import { useState } from 'react';

export const useProperty = <T> (value: T) => {
  const [property, setProperty] = useState(value);

  return {
    value: property,
    set: setProperty,
  };
};
