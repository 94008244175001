import { useContext } from 'react';
import { getState } from 'utils/store';

import { ElementContext } from 'elements/Block/Element';
import { useStoreSelector } from './useStore';

export const useCacheProperty = (property: string, id?: string) => {
  const element = useContext(ElementContext);

  const mode = getState((state) => state.diagram.mode);
  const file = useStoreSelector((state) => state.diagram.file[id ?? element.id]?.[property]);
  const cache = useStoreSelector((state) => state.cache.elements[state.diagram.diagram.id]?.[id ?? element.id]?.[property]);

  if (mode === 'view') {
    return cache ?? file;
  }

  return file;
};
