import { useContext } from 'react';
import { blocks } from 'blocks';

import { IDrag } from 'store/graphic';

import { ElementContext } from 'elements/Block/Element';
import { IArrangement } from 'elements/Block/Arrangement';

import { useStoreSelector } from './useStore';
import { useArrangementElement } from './useArrangementElement';

export const setArrangementText = (arrangement: IArrangement, parent: string, drag: IDrag | undefined) => {
  if (drag) {
    arrangement.prevent?.push('cl');
    arrangement.prevent?.push('cr');
  }

  arrangement.prevent?.push('tl');
  arrangement.prevent?.push('tr');
  arrangement.prevent?.push('bl');
  arrangement.prevent?.push('br');

  if (drag && (parent || blocks[drag?.element.type || '']?.element.options.includes('element-layout'))) {
    arrangement.prevent?.push('ct');
    arrangement.prevent?.push('cb');
  }
};

export const useArrangementText = () => {
  const element = useContext(ElementContext);

  const drag = useStoreSelector((state) => state.graphic.drag);

  const arrangement = useArrangementElement();

  setArrangementText(arrangement, element.parent, drag);

  return arrangement;
};
