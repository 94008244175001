import React, { memo, useLayoutEffect, useMemo } from 'react';
import { IBlock, IConfig, blocks } from 'blocks';
import { getState } from 'utils/store';

import { useEmpty } from 'hooks/useEmpty';
import { GridContext, useGrid } from 'hooks/useGrid';

import { Cell } from 'elements/Layout/Cell';
import { Frame } from 'elements/Layout/Frame';
import { Layout } from 'elements/Block/Layout';
import { Button } from 'elements/Block/Button';
import { Element } from 'elements/Block/Element';
import { Selection } from 'elements/Block/Selection';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCreator } from 'blocks/factories/simple/Creator';
import { createCopier } from 'blocks/factories/simple/Copier';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Grid',
  search: 'core grid',
  type: 'core-grid',
  group: ['Core'],
  styles: [],
  memo: {
    key: 'core-grid',
    styles: [],
  },
};

export interface IModel {
  alignHorizontal: 'left' | 'center' | 'right';
  alignVertical: 'top' | 'center' | 'bottom';
  padding: 's' | 'm' | 'l';
  lockKey: string;
  lock: boolean;
}

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const file = getState((state) => state.diagram.file);

    const grid = useGrid();

    const empty = useEmpty(grid.elements);

    // Update Points on Property Change
    useLayoutEffect(() => {
      element.updatePoints();
    }, [properties.alignHorizontal, properties.alignVertical, properties.padding]);

    return (
      <GridContext.Provider value={grid}>
        <Layout classElement={style.element.appendWhen(empty, 'layout-empty')}>
          <Selection />
          <Frame />

          <div
            className={
              'block-content'
                .append(style[`size-${properties.padding}`])
                .append(style[`vertical-${properties.alignVertical}`])
                .append(style[`horizontal-${properties.alignHorizontal}`])
            }
            style={{
              gridTemplateColumns: Array(grid.size[0]).fill('auto').join(' '),
            }}
          >

            {Array.from(Array(grid.size[0] * grid.size[1]).keys()).map((i) => {
              const y = Math.floor(i / grid.size[0]);
              const x = i - y * grid.size[0];

              const child = grid.layout[y] ? (grid.layout[y][x] ?? '') : '';
              const Block = blocks[file[child]?.type]?.block;

              if (!Block) {
                return (
                  <Cell key={`${x}-${y}`} point={[x, y]} />
                );
              }

              return (
                <Cell key={child} point={[x, y]}>
                  <Element id={child}>
                    <Block id={child} />
                  </Element>
                </Cell>
              );
            })}

          </div>
        </Layout>
      </GridContext.Provider>
    );
  },
});

export const CoreGrid : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['no-selection', 'element-layout', 'container-title-none', 'lock-delete'],
    constraints: {},
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        alignHorizontal: 'center',
        alignVertical: 'center',
        padding: 'm',
        lockKey: '',
        lock: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [64, 64],
    icon,
    class: {
      title: 'Grid',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [
    'padding',
    'align-horizontal',
    'align-vertical',
    'lock',
    'delete-wrap',
    'delete',
  ],
  toolbars: [

  ],
};
