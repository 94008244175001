import React, { FC, useContext, useEffect, useRef } from 'react';
import { getState } from 'utils/store';
import { points } from 'utils/points';

import { withCondition } from 'hocs/core/withCondition';

import { usePoints } from 'hooks/usePoints';
import { useProperty } from 'hooks/useProperty';
import { useStoreSelector } from 'hooks/useStore';
import { usePointPosition } from 'hooks/usePointPosition';

import { IPoint } from './Connector';
import { CanvasDynamicContext } from '../Canvas';

export namespace Resizer {
  const config = {
    margin: 3,
  };

  interface IHandle {
    position: [number, number];
    offset: [number, number];
    pivot?: [number, number];
    virtual?: boolean;
    hidden?: boolean;
    marked?: boolean;
    strict?: boolean;
    id?: string;

    onUpdate?: (offset: [number, number]) => void;
    onDrop?: (offset: [number, number]) => void;

    onDelete?: () => void;
    onStart?: () => void;
    onEnd?: () => void;
  }

  interface IResizerWidth {
    id: string;
    entry?: boolean;
    minWidth?: number;

    onUpdate: (offset: [number, number, number, number]) => void;
    onDrop: (offset: [number, number, number, number]) => void;

    onDelete?: (index?: number) => void;
    onStart?: () => void;
    onEnd?: () => void;
  }

  interface IResizerSize extends IResizerWidth {
    symmetrically?: boolean;
    minHeight?: number;
  }

  interface IResizerController {
    points: IPoint[];
    position: number[];

    onUpdate: (id: string, offset: number[]) => void;
    onDrop: (id: string, offset: number[]) => void;

    onDelete?: (id: string) => void;
    onStart?: (id: string) => void;
    onEnd?: (id: string) => void;
  }

  interface IResizerCreator {
    points: {
      id: string;
      index: number;
      hidden: boolean;
      position: number[];
    }[];
    position: number[];

    onStart?: (id: string, index: number, position: number[]) => void;
  }

  export const Handle: FC<IHandle> = ({ id, position, strict, offset, pivot = [0, 0], hidden, virtual, marked, onUpdate, onDrop, onDelete, onStart, onEnd }) => {
    const canvas = useContext(CanvasDynamicContext);

    const origin = useRef<[number, number]>([0, 0]);
    const status = useProperty(false);
    const active = useRef(false);
    const check = useRef(false);

    const usePosition = usePointPosition();

    const getOrigin = (cursor: [number, number]): [number, number] => {
      if (!strict) {
        return cursor;
      }

      return [
        (position[0] + canvas.position[0]) * canvas.zoom + canvas.screen[0] / 2,
        (position[1] + canvas.position[1]) * canvas.zoom + canvas.top + canvas.screen[1] / 2,
      ];
    };

    const getOffset = (cursor: [number, number]) : [number, number] => {
      const point = usePosition(cursor, [0, 0], [0, 0], offset);

      point[0] = (point[0] + canvas.position[0]) * canvas.zoom + canvas.screen[0] / 2;
      point[1] = (point[1] + canvas.position[1]) * canvas.zoom + canvas.top + canvas.screen[1] / 2;

      return [
        (point[0] - origin.current[0]) / canvas.zoom,
        (point[1] - origin.current[1]) / canvas.zoom,
      ];
    };

    const onMouseDown = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.button !== 0) {
        return;
      }

      origin.current = getOrigin([
        e.clientX,
        e.clientY,
      ]);

      status.set(true);
    };

    const onMouseMove = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.button !== 0) {
        return;
      }

      if (!active.current) {
        active.current = true;

        if (onStart) {
          onStart();
        }
      }

      const offset = getOffset([
        e.clientX,
        e.clientY,
      ]);

      if (check.current && (Math.abs(offset[0]) > 5 || Math.abs(offset[1]) > 5)) {
        check.current = false;
      }

      onUpdate?.(offset);
    };

    const onMouseUp = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.button !== 0) {
        return;
      }

      const offset = getOffset([
        e.clientX,
        e.clientY,
      ]);

      if (check.current) {
        check.current = false;

        if (Math.abs(offset[0]) <= 5 && Math.abs(offset[1]) <= 5) {
          return;
        }
      }

      onDrop?.(offset);

      if (active.current) {
        active.current = false;

        if (onEnd) {
          onEnd();
        }
      }

      status.set(false);
    };

    const onDoubleClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (onDelete) {
        onDelete();
      }
    };

    // Listen to Mouse Events
    useEffect(() => {
      if (!status.value) {
        return () => {};
      }

      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);

      return () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
      };
    }, [status.value]);

    useEffect(() => {
      const drop = getState((state) => state.graphic.drop && state.graphic.drop?.handle === id);

      if (drop) {
        check.current = true;
        active.current = true;
        origin.current = getOrigin(position);

        status.set(true);

        const offset = getOffset(canvas.cursor);

        onStart?.();
        onUpdate?.(offset);
      }

      return () => {
        const drop = getState((state) => state.graphic.drop && state.graphic.drop?.handle === id);

        if (drop) {
          const offset = getOffset(canvas.cursor);

          onDrop?.(offset);
          onEnd?.();
        }
      };
    }, []);

    if (hidden) {
      return null;
    }

    return (
      <div
        className={'element-grabber'.appendWhen(marked, 'marked').appendWhen(virtual, 'virtual')}
        onMouseDown={onMouseDown}
        onDoubleClick={onDoubleClick}
        style={{
          left: position[0] - pivot[0] - offset[0],
          top: position[1] - pivot[1] - offset[1],
        }}
      />
    );
  };

  export const Width = withCondition(({ id, entry = false, minWidth = 24, onUpdate, onDrop, onDelete, onStart, onEnd }: IResizerWidth) => {
    const marked = useStoreSelector((state) => !state.diagram.file[id].width);
    const vertical = useStoreSelector((state) => !!state.diagram.file[id].vertical);

    const active = useProperty(false);

    const { render, unrender } = usePoints();

    const size = points.elements[id]?.size;

    const getScale = (offset: number, side: number) => {
      return Math.max(size[vertical ? 1 : 0] + (entry ? 1 : 0) + side * offset, minWidth);
    };

    const getFrame = (offset: number, side: number): [number, number, number, number] => {
      const scale = getScale(offset, side);

      return [
        scale,
        0,
        side < 0 ? -scale + size[vertical ? 1 : 0] : 0,
        0,
      ];
    };

    const start = () => {
      active.set(true);

      if (onStart) {
        onStart();
      }
    };

    const end = () => {
      active.set(false);

      if (onEnd) {
        onEnd();
      }
    };

    const remove = () => {
      if (onDelete) {
        onDelete();
      }
    };

    useEffect(() => {
      if (active.value) {
        return () => {};
      }

      render(id);

      return () => {
        unrender(id);
      };
    }, [active.value]);

    if (!size) {
      return null;
    }

    return (
      <>
        <Handle
          position={[vertical ? size[0] / 2 : 0, vertical ? 0 : size[1] / 2]}
          offset={[vertical ? 0 : config.margin, vertical ? config.margin : 0]}
          hidden={active.value}
          onUpdate={(offset: number[]) => onUpdate(getFrame(offset[vertical ? 1 : 0], -1))}
          onDrop={(offset: number[]) => onDrop(getFrame(offset[vertical ? 1 : 0], -1))}
          onDelete={remove}
          onStart={start}
          onEnd={end}
          marked={marked}
        />
        <Handle
          position={[vertical ? size[0] / 2 : size[0], vertical ? size[1] : size[1] / 2]}
          offset={[vertical ? 0 : -config.margin, vertical ? -config.margin : 0]}
          hidden={active.value}
          onUpdate={(offset: number[]) => onUpdate(getFrame(offset[vertical ? 1 : 0], 1))}
          onDrop={(offset: number[]) => onDrop(getFrame(offset[vertical ? 1 : 0], 1))}
          onDelete={remove}
          onStart={start}
          onEnd={end}
          marked={marked}
        />
      </>
    );
  }, () => {
    return useStoreSelector((state) => state.diagram.mode === 'edit');
  });

  export const Size = withCondition(({ id, minWidth = 22, minHeight = 22, symmetrically, onUpdate, onDrop, onDelete, onStart, onEnd }: IResizerSize) => {
    const marked = useStoreSelector((state) => !state.diagram.file[id].width && !state.diagram.file[id].height);

    const active = useProperty(false);

    const { render, unrender } = usePoints();

    const size = points.elements[id]?.size;

    const getScale = (offset: number[], side: number[]) => {
      const scale = [
        Math.max(size[0] + side[0] * offset[0], minWidth),
        Math.max(size[1] + side[1] * offset[1], minHeight),
      ];

      if (symmetrically) {
        const ratio = size[1] / size[0];
        const active = scale[1] / scale[0];

        if (active < ratio) {
          scale[1] = scale[0] * ratio;
        } else {
          scale[0] = scale[1] / ratio;
        }
      }

      return scale;
    };

    const getFrame = (offset: number[], side: number[]): [number, number, number, number] => {
      const scale = getScale([
        offset[0],
        offset[1],
      ], side);

      return [
        scale[0],
        scale[1],
        side[0] < 0 ? -scale[0] + size[0] : 0,
        side[1] < 0 ? -scale[1] + size[1] : 0,
      ];
    };

    const start = () => {
      active.set(true);

      if (onStart) {
        onStart();
      }
    };

    const end = () => {
      active.set(false);

      if (onEnd) {
        onEnd();
      }
    };

    const remove = () => {
      if (onDelete) {
        onDelete();
      }
    };

    useEffect(() => {
      if (active.value) {
        return () => {};
      }

      render(id);

      return () => {
        unrender(id);
      };
    }, [active.value]);

    if (!size) {
      return null;
    }

    return (
      <>
        <Handle
          position={[0, 0]}
          offset={[config.margin, config.margin]}
          hidden={active.value}
          onUpdate={(offset: number[]) => onUpdate(getFrame(offset, [-1, -1]))}
          onDrop={(offset: number[]) => onDrop(getFrame(offset, [-1, -1]))}
          onDelete={remove}
          onStart={start}
          onEnd={end}
          marked={marked}
        />
        <Handle
          position={[size[0], 0]}
          offset={[-config.margin, config.margin]}
          hidden={active.value}
          onUpdate={(offset: number[]) => onUpdate(getFrame(offset, [1, -1]))}
          onDrop={(offset: number[]) => onDrop(getFrame(offset, [1, -1]))}
          onDelete={remove}
          onStart={start}
          onEnd={end}
          marked={marked}
        />
        <Handle
          position={[size[0], size[1]]}
          offset={[-config.margin, -config.margin]}
          hidden={active.value}
          onUpdate={(offset: number[]) => onUpdate(getFrame(offset, [1, 1]))}
          onDrop={(offset: number[]) => onDrop(getFrame(offset, [1, 1]))}
          onDelete={remove}
          onStart={start}
          onEnd={end}
          marked={marked}
        />
        <Handle
          position={[0, size[1]]}
          offset={[config.margin, -config.margin]}
          hidden={active.value}
          onUpdate={(offset: number[]) => onUpdate(getFrame(offset, [-1, 1]))}
          onDrop={(offset: number[]) => onDrop(getFrame(offset, [-1, 1]))}
          onDelete={remove}
          onStart={start}
          onEnd={end}
          marked={marked}
        />
      </>
    );
  }, () => {
    return useStoreSelector((state) => state.diagram.mode === 'edit');
  });

  export const Border = withCondition(({ id, onUpdate, onDrop, onDelete, onStart, onEnd }: IResizerSize) => {
    const marked = useStoreSelector((state) => {
      const L = Number.isFinite(state.diagram.file[id].border?.[0]) ? 0 : 1;
      const T = Number.isFinite(state.diagram.file[id].border?.[1]) ? 0 : 1;
      const R = Number.isFinite(state.diagram.file[id].border?.[2]) ? 0 : 1;
      const B = Number.isFinite(state.diagram.file[id].border?.[3]) ? 0 : 1;

      return `${L}${T}${R}${B}`;
    });

    const active = useProperty(false);

    const { render, unrender } = usePoints();

    const size = points.elements[id]?.size;

    const getFrame = (offset: number[], side: number[]): [number, number, number, number] => {
      return [
        side[0] === -1 ? offset[0] : NaN,
        side[1] === -1 ? offset[1] : NaN,
        side[0] === 1 ? offset[0] : NaN,
        side[1] === 1 ? offset[1] : NaN,
      ];
    };

    const start = () => {
      active.set(true);

      if (onStart) {
        onStart();
      }
    };

    const end = () => {
      active.set(false);

      if (onEnd) {
        onEnd();
      }
    };

    const remove = (side: number) => {
      if (onDelete) {
        onDelete(side);
      }
    };

    useEffect(() => {
      if (active.value) {
        return () => {};
      }

      render(id);

      return () => {
        unrender(id);
      };
    }, [active.value]);

    if (!size) {
      return null;
    }

    return (
      <>
        <Handle
          position={[0, size[1] / 2]}
          offset={[config.margin, 0]}
          hidden={active.value}
          onUpdate={(offset: number[]) => onUpdate(getFrame(offset, [-1, 0]))}
          onDrop={(offset: number[]) => onDrop(getFrame(offset, [-1, 0]))}
          onDelete={() => remove(0)}
          onStart={start}
          onEnd={end}
          marked={!!+marked[0]}
        />
        <Handle
          position={[size[0] / 2, 0]}
          offset={[0, config.margin]}
          hidden={active.value}
          onUpdate={(offset: number[]) => onUpdate(getFrame(offset, [0, -1]))}
          onDrop={(offset: number[]) => onDrop(getFrame(offset, [0, -1]))}
          onDelete={() => remove(1)}
          onStart={start}
          onEnd={end}
          marked={!!+marked[1]}
        />
        <Handle
          position={[size[0], size[1] / 2]}
          offset={[-config.margin, 0]}
          hidden={active.value}
          onUpdate={(offset: number[]) => onUpdate(getFrame(offset, [1, 0]))}
          onDrop={(offset: number[]) => onDrop(getFrame(offset, [1, 0]))}
          onDelete={() => remove(2)}
          onStart={start}
          onEnd={end}
          marked={!!+marked[2]}
        />
        <Handle
          position={[size[0] / 2, size[1]]}
          offset={[0, -config.margin]}
          hidden={active.value}
          onUpdate={(offset: number[]) => onUpdate(getFrame(offset, [0, 1]))}
          onDrop={(offset: number[]) => onDrop(getFrame(offset, [0, 1]))}
          onDelete={() => remove(3)}
          onStart={start}
          onEnd={end}
          marked={!!+marked[3]}
        />
      </>
    );
  }, () => {
    return useStoreSelector((state) => state.diagram.mode === 'edit');
  });

  export const Controller = withCondition(({ points, position, onUpdate, onDrop, onDelete, onStart, onEnd }: IResizerController) => {
    const active = useProperty(false);

    const start = (id: string) => {
      active.set(true);

      if (onStart) {
        onStart(id);
      }
    };

    const end = (id: string) => {
      active.set(false);

      if (onEnd) {
        onEnd(id);
      }
    };

    const remove = (id: string) => {
      if (onDelete) {
        onDelete(id);
      }
    };

    return (
      <>
        {points.map((point) => (
          <Handle
            id={point.id}
            key={point.id}
            pivot={position as [number, number]}
            position={point.position as [number, number]}
            offset={[0, 0]}
            hidden={active.value}
            onUpdate={(offset: number[]) => onUpdate(point.id, offset)}
            onDrop={(offset: number[]) => onDrop(point.id, offset)}
            onDelete={() => remove(point.id)}
            onStart={() => start(point.id)}
            onEnd={() => end(point.id)}
            marked={!!point.parent}
            strict
          />
        ))}
      </>
    );
  }, () => {
    return useStoreSelector((state) => state.diagram.mode === 'edit');
  });

  export const Creator = withCondition(({ points, position, onStart }: IResizerCreator) => {
    const start = (id: string, index: number, position: number[]) => {
      if (onStart) {
        onStart(id, index, position);
      }
    };

    return (
      <>
        {points.map((point) => (
          <Handle
            key={point.id}
            pivot={position as [number, number]}
            position={point.position as [number, number]}
            offset={[0, 0]}
            hidden={point.hidden}
            onStart={() => start(point.id, point.index, point.position)}
            virtual
            strict
          />
        ))}
      </>
    );
  }, () => {
    return useStoreSelector((state) => state.diagram.mode === 'edit');
  });
}
