import { getState } from 'utils/store';

export const config = {
  margin: {
    s: 4,
    m: 12,
    l: 24,
  },
};

export type IPaddingCreator<T> = (payload: {
  properties: Extract<T, Record<string, any>>;
  width: number;
  height: number;
}) => [number, number, number, number];

interface IPaddingConfig<T> {
  size: [0 | 1, 0 | 1];
  padding: [0 | 1, 0 | 1, 0 | 1, 0 | 1];
  creator: IPaddingCreator<T>;
}

export const createPadding = <T, > (payload: IPaddingConfig<T>) => {
  return (id: string, frame: HTMLDivElement, wrap: HTMLDivElement) => {
    const properties = getState((state) => state.diagram.file[id]);
    const margin = config.margin[(properties.padding as 's' | 'm' | 'l') || 'm'];
    const size = [0, 0];

    const padding = payload.creator({
      properties,
      width: size[0],
      height: size[1],
    });

    if (!payload.padding[0]) wrap.style.paddingLeft = '';
    else if (parseFloat(wrap.style.paddingTop) !== padding[0] + margin) wrap.style.paddingLeft = `${padding[0] + margin}px`;

    if (!payload.padding[1]) wrap.style.paddingTop = '';
    else if (parseFloat(wrap.style.paddingTop) !== padding[1] + margin) wrap.style.paddingTop = `${padding[1] + margin}px`;

    if (!payload.padding[2]) wrap.style.paddingRight = '';
    else if (parseFloat(wrap.style.paddingRight) !== padding[2] + margin) wrap.style.paddingRight = `${padding[2] + margin}px`;

    if (!payload.padding[3]) wrap.style.paddingBottom = '';
    else if (parseFloat(wrap.style.paddingBottom) !== padding[3] + margin) wrap.style.paddingBottom = `${padding[3] + margin}px`;

    if (payload.size[0]) size[0] = frame.offsetWidth;
    if (payload.size[1]) size[1] = frame.offsetHeight;

    return payload.creator({
      properties,
      width: size[0],
      height: size[1],
    }).map((value) => {
      return value + margin;
    }) as [number, number, number, number];
  };
};
