import React from 'react';

import { withCondition } from 'hocs/core/withCondition';

import { useStoreSelector } from 'hooks/useStore';

export const Selection = withCondition(({ active = false }: { active?: boolean }) => (
  <div className={'block-selection'.appendWhen(active, 'active')} />
), () => {
  return useStoreSelector((state) => state.diagram.mode === 'edit');
});
