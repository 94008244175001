import React from 'react';
import { blocks, IBlock, IConfigBase } from 'blocks';

import { config as note } from 'blocks/Information/ShapeNote';
import { config as connector } from 'blocks/Information/Connector';
import { config as text } from 'blocks/General/Text';

import { Button } from 'elements/Block/Button';

import icon from './assets/icon.svg';

export const config: IConfigBase = {
  title: 'Note',
  search: 'information note text connector arrow pointer',
  type: 'information-note-connector',
  group: ['Information', 'UML', 'Amazon Web Services', 'Azure', 'Google Cloud', 'Kubernetes', 'Cisco', 'VMware'],
};

export const InformationNoteWithConnector : IBlock = {
  type: config.type,
  block: () => null,
  element: {
    group: config.group,
    search: config.search,
    options: [],
    styles: [],
    constraints: {},
    onCreate: ({ file, position, parent, canvas }) => {
      const wrap = blocks[note.type].element.onCreate({
        file,
        position,
        parent,
        auto: true,
        properties: {
          width: undefined,
          height: undefined,
          transparent: false,
          deletable: true,
        },
      });

      blocks[text.type].element.onCreate({
        file,
        position: [0, 0],
        parent: wrap,
        auto: canvas?.space ? false : undefined,
        preventComponentStatus: true,
      });

      const cursor = canvas?.cursor || [0, 0];
      const location = canvas?.position || [0, 0];
      const screen = canvas?.screen || [0, 0];
      const zoom = canvas?.zoom || 1;
      const top = canvas?.top || 0;

      const mouse = [
        Math.round(-location[0] + (cursor[0] - screen[0] / 2) / zoom),
        Math.round(-location[1] + (cursor[1] - screen[1] / 2 - top) / zoom),
      ];

      blocks[connector.type].element.onCreate({
        file,
        position: [0, 0],
        parent: '',
        auto: true,
        preventComponentStatus: true,
        properties: {
          stroke: 'dashed',
          style: 'smooth',
          startFree: true,
          points: [
            {
              id: '0',
              role: 'mayor',
              type: 'parent',
              parent: wrap,
              position: [0, 0],
            },
            {
              id: '1',
              role: 'mayor',
              type: 'default',
              parent: '',
              position: [
                mouse[0] + 150,
                mouse[1] - 1,
              ],
            },
          ],
        },
      });

      return wrap;
    },
    onCopy: () => '',
    title: config.title,
    size: [98, 50],
    icon,
    class: {
      title: '',
      icon: '',
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [],
  toolbars: [],
};
