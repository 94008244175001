import React, { FC, useContext } from 'react';
import optimizers from 'utils/optimizers';

import { IElement } from 'hooks/useElement';
import { useStoreSelector } from 'hooks/useStore';
import { useCacheProperty } from 'hooks/useCacheProperty';

import { ElementContext } from 'elements/Block/Element';

import { View } from './View';
import { Edit } from './Edit';
import { Context } from './Context';

import style from '../../assets/style.module.css';

export const config = {
  key: 'attributes',
};

export interface IComponent {
  value: string;
  property: string;
  element?: IElement<HTMLDivElement>;
  context?: string;
}

const Component: FC<IComponent> = ({ value, property, element: wrap, context = '' }) => {
  const element = useContext(ElementContext);

  const editing = useStoreSelector((state) => !(state.diagram.file[element.id]?.lock || state.diagram.captured[element.id] || state.graphic.options[element.id]?.invisible) && state.diagram.mode === 'edit' && state.graphic.component.key === config.key);

  const shortened = useCacheProperty('shortened') ?? false;

  if (((!editing || !element.selected) && (!optimizers.noHtml(`${optimizers.withSpaces(context)}${value || ''}`).trim() || shortened))) {
    return null;
  }

  return (
    <div ref={wrap?.link} className={style.attributes}>
      <Context value={context} property={property} />

      {element.selected && editing ? (
        <Edit value={value} property={property} />
      ) : (
        <View value={value} property={property} />
      )}
    </div>
  );
};

export const Attributes = {
  Component,
  toolbar: {
    key: config.key,
    tools: [
      'link-back',
      'format-alignment',
      'format-link',
      'format-highlight-common',
      'format-bold',
      'format-italic',
      'format-underline',
      'format-strike',
    ],
  },
};
