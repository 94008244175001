import React, { ComponentType, useLayoutEffect } from 'react';
import { IConfig } from 'blocks';

import { useWrap } from 'hooks/useWrap';
import { IElement } from 'hooks/useElement';
import { useTextCreator } from 'hooks/useTextCreator';
import { useCacheProperty } from 'hooks/useCacheProperty';
import { useResizerBorder } from 'hooks/useResizerBorder';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';
import { useClickOnSelection } from 'hooks/useClickOnSelection';
import { useArrangementContainer } from 'hooks/useArrangementContainer';

import design from 'blocks/factories/assets/Simple.module.css';
import { createBlock as block } from 'blocks/factories/simple/Block';

import { Wrap } from 'elements/Block/Wrap';
import { Title } from 'elements/Block/Title';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Container } from 'elements/Block/Container';
import { Arrangement } from 'elements/Block/Arrangement';

import { Model } from './Model';
import { IRender } from '../complex/Render';

import style from '../assets/Container.module.css';

export interface IElements {
  heading: IElement<HTMLDivElement>;
}

interface IBlock <T> {
  config: IConfig;
  Render: ComponentType<IRender<Model<T>, IElements>>;
  classElement?: string;
  classFrame?: (properties: Model<T>) => string | undefined;
  heading?: number[];
  spacing?: number[];
}

export const createBlock = <T, > ({
  config,
  Render,
  classElement,
  classFrame,
  heading,
  spacing,
}: IBlock<T>) => block<Model<T>>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const hidden = useCacheProperty('hidden') ?? false;

    const wrap = useWrap();
    const create = useTextCreator();
    const arrangement = useArrangementContainer();
    const block = useResizerBorder({
      cap: arrangement.cap,
      minHeight: 64,
      minWidth: 64,
      heading: heading ?? [0, 0],
      spacing,
    });

    useEnterOnSelection({
      onPress: create('enter'),
      mode: 'edit',
    });

    useClickOnSelection({
      onClick: create('click'),
      mode: 'edit',
    });

    useLayoutEffect(() => {
      element.updatePoints();
    }, [properties.elements, properties.space]);

    return (
      <Container
        position={block.position}
        classElement={style.element.append(classElement)}
        classProperties={
          `${design[`color-${properties.color}`]} ${design[`render-${properties.render}`]}`
            .appendWhen(arrangement.disabled, style.disabled)
            .appendWhen(properties.space, style.space)
            .appendWhen(hidden, style.hidden)
        }
      >
        <Arrangement {...arrangement} status={arrangement.status && !arrangement.disabled} />
        <Title />

        <Render
          properties={properties}
          elements={{
            heading: wrap.attributes.element,
          }}
        />

        <Selection status={!block.status} active={arrangement.nesting} />

        <div
          className={`block-content ${style[`placing-${properties.placing}`]}`}
          {...(element.focused ? (!element.selected ? {} : { onMouseDown: element.trigger.onMouseDown }) : element.trigger)}
        >
          {wrap.status && <Wrap {...wrap.attributes} />}
          {!element.focused && (
            <>
              {(!wrap.status && heading) && <div className="header-placeholder" />}
              <div className={style.FL} />
              <div className={style.FT} />
              <div className={style.FR} />
              <div className={style.FB} />
            </>
          )}
        </div>

        <div
          className={style.frame.append(classFrame?.(properties))}
          {...block.wrap}
          {...element.trigger}
        />

        <Resizer.Border {...block.attributes} status={block.visibility} minWidth={48} minHeight={48} />
      </Container>
    );
  },
});
