import { useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import { getState } from 'utils/store';
import { Emitter } from 'utils/events';
import { points } from 'utils/points';
import { blocks } from 'blocks';

import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';
import { useProperty } from './useProperty';
import { usePoints } from './usePoints';
import { useOption } from './useOption';

interface IBlock {
  size: number[];
  padding?: number;
  heading?: number[];
  space: number[];
  cap: number[];
}

export const useBorderBlock = ({ size, padding = 0, cap, space, heading = [0, 0] }: IBlock) => {
  const element = useContext(ElementContext);

  const border = useStoreSelector((state) => state.diagram.file[element.id].border);
  const position = useStoreSelector((state) => state.diagram.file[element.id].position as number[]);
  const width = useStoreSelector((state) => state.diagram.file[element.id].width ?? 0);
  const height = useStoreSelector((state) => state.diagram.file[element.id].height ?? 0);
  const elements = useStoreSelector((state) => state.diagram.file[element.id].elements as string[]) ?? [];
  const moving = useStoreSelector((state) => state.graphic.drag?.element.id === element.id || (state.graphic.drag && state.graphic.selection.elements.includes(element.id)));
  const header = useStoreSelector((state) => state.diagram.childs[element.id]?.[0]);
  const placing = useStoreSelector((state) => state.diagram.file[element.id].placing);
  const invisible = useStoreSelector((state) => elements.filter((id) => !state.graphic.elements[id] || (state.graphic.options[id]?.invisible && state.graphic.options[id]?.closed)).length);
  const drag = useStoreSelector((state) => !!state.graphic.drag);
  const dynamic = useStoreSelector((state) => (state.diagram.file[element.id]?.elements ?? []).every((id: string) => (
    !state.diagram.file[id]?.parent
    && !blocks[state.diagram.file[id]?.type]?.element.options.includes('element-container')
  )));

  const blocking = useStoreSelector((state) => elements.filter((id: string) => {
    return id in state.diagram.file;
  }).every((id) => {
    return (
      state.diagram.file[id].parent
      || blocks[state.diagram.file[id]?.type]?.element.options.includes('element-container')
    );
  }));

  const bar = [...(points.elements[header]?.size || heading)];

  if (header) {
    bar[0] += space[0] * (['top', 'top-left', 'top-right', 'bottom', 'bottom-left', 'bottom-right'].includes(placing) ? 2 : 1);
    bar[1] += space[1] * (['left', 'left-top', 'left-bottom', 'right', 'right-top', 'right-bottom'].includes(placing) ? 2 : 1);
  }

  const render = useProperty({});

  const parent = usePoints();

  useOption({
    id: element.id,
    key: 'closed',
    value: invisible === elements.length && elements.length > 0,
    deps: [invisible === elements.length && elements.length > 0],
  });

  // Update Points on Properties Change
  useLayoutEffect(() => {
    element.updatePoints();
  }, [placing]);

  // Subscribe for parent
  useEffect(() => {
    if (elements.length === 0) {
      return () => {};
    }

    elements.forEach((id) => {
      parent.subscribe(id);
    });

    return () => {
      elements.forEach((id) => {
        parent.unsubscribe(id);
      });
    };
  }, [elements]);

  // Subscribe for header
  useEffect(() => {
    if (!header || (!blocking && moving) || (!dynamic && drag)) {
      return () => {};
    }

    parent.subscribe(header);

    return () => {
      parent.unsubscribe(header);
    };
  }, [header, !header || (!blocking && moving) || (!dynamic && drag)]);

  // Render on Element Move
  useEffect(() => {
    if (!blocking) {
      return () => {};
    }

    const onMove = ({ element: id }: { element: string }) => {
      if (id === element.id || element.selected) {
        render.set({});
      }
    };

    Emitter.on('element-move', onMove);

    return () => {
      Emitter.off('element-move', onMove);
    };
  }, [blocking, element.selected]);

  const container = useMemo(() => {
    const visibility = getState((state) => state.graphic.elements);
    const options = getState((state) => state.graphic.options);
    const file = getState((state) => state.diagram.file);

    return elements.filter((id) => {
      return visibility[id] && !(options[id]?.invisible && options[id]?.closed);
    }).reduce((frame, id) => {
      const element = points.elements[id];

      if (!element) {
        return frame;
      }

      const border = (file[id]?.elements ?? []).length > 0 && file[id]?.border;

      const offset = [
        element.offset[0],
        element.offset[1],
      ];

      const position = [
        element.position[0],
        element.position[1],
      ];

      const size = [
        element.size[0],
        element.size[1],
      ];

      const box = [
        Math.min(position[0], (border?.[0] ?? Infinity) + offset[0]),
        Math.min(position[1], (border?.[1] ?? Infinity) + offset[1]),
        Math.max(position[0] + size[0], (border?.[2] ?? -Infinity) + offset[0]),
        Math.max(position[1] + size[1], (border?.[3] ?? -Infinity) + offset[1]),
      ];

      if (!border) {
        box[0] += offset[0];
        box[1] += offset[1];
        box[2] += offset[0];
        box[3] += offset[1];
      }

      if (frame.length === 0) {
        return box;
      }

      return [
        Math.min(frame[0], box[0]),
        Math.min(frame[1], box[1]),
        Math.max(frame[2], box[2]),
        Math.max(frame[3], box[3]),
      ];
    }, cap.length === 4 ? cap : []);
  }, [parent.value, render.value, cap, invisible, elements]);

  if (container.length === 4 && points.elements[element.id]) {
    const offset = [
      points.elements[element.id].offset[0],
      points.elements[element.id].offset[1],
    ];

    const base = [
      container[0] - padding - (['left', 'left-top', 'left-bottom'].includes(placing) ? bar[0] : 0),
      container[1] - padding - (['top', 'top-left', 'top-right'].includes(placing) ? bar[1] : 0),
      container[2] + padding + (['right', 'right-top', 'right-bottom'].includes(placing) ? bar[0] : 0),
      container[3] + padding + (['bottom', 'bottom-left', 'bottom-right'].includes(placing) ? bar[1] : 0),
    ];

    const limit = [
      Math.min(base[0], (border?.[0] ?? Infinity) + offset[0]),
      Math.min(base[1], (border?.[1] ?? Infinity) + offset[1]),
      Math.max(base[2], (border?.[2] ?? -Infinity) + offset[0]),
      Math.max(base[3], (border?.[3] ?? -Infinity) + offset[1]),
    ];

    limit[2] = Math.max(limit[2], limit[0] + bar[0]);
    limit[3] = Math.max(limit[3], limit[1] + bar[1]);

    return {
      base,
      limit: [
        limit[2] - bar[0],
        limit[3] - bar[1],
        limit[0] + bar[0],
        limit[1] + bar[1],
      ],
      position: [
        limit[0],
        limit[1],
      ],
      size: [
        limit[2] - limit[0],
        limit[3] - limit[1],
      ],
    };
  }

  const base = [
    position[0] + width - size[0],
    position[1] + height - size[1],
    position[0] + size[0],
    position[1] + size[1],
  ];

  const frame = [
    Math.max(width, size[0], bar[0]),
    Math.max(height, size[1], bar[1]),
  ];

  const limit = [
    (position[0] + frame[0]) - bar[0],
    (position[1] + frame[1]) - bar[1],
    position[0] + bar[0],
    position[1] + bar[1],
  ];

  return {
    base: base,
    limit: limit,
    position,
    size: frame,
  };
};
