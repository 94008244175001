import React from 'react';
import { blocks, IBlock, IConfigBase } from 'blocks';

import { config as shape } from 'blocks/UMLActivity/ShapeStart';

import { Button } from 'elements/Block/Button';

import icon from './assets/icon.svg';

export const config: IConfigBase = {
  title: 'Start',
  search: 'start uml unified modeling language',
  type: 'uml-activity-start',
  group: ['UML'],
};

export const UMLActivityStart : IBlock = {
  type: config.type,
  block: () => null,
  element: {
    group: config.group,
    search: config.search,
    options: [],
    styles: [],
    constraints: {},
    onCreate: ({ file, position, parent }) => {
      return blocks[shape.type].element.onCreate({
        file,
        position,
        parent,
        auto: false,
        properties: {
          width: 36,
          height: 36,
          realWidth: 36,
          realHeight: 36,
          transparent: false,
          deletable: true,
          title: '<p class="ql-align-center">Start</p>',
        },
      });
    },
    onCopy: () => '',
    title: config.title,
    size: [36, 36],
    icon,
    class: {
      title: '',
      icon: '',
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [],
  toolbars: [],
};
