import React from 'react';
import { blocks, IBlock, IConfigBase } from 'blocks';

import { config as shape } from 'blocks/Flowchart/ShapeDatabase';
import { config as text } from 'blocks/General/Text';

import { Button } from 'elements/Block/Button';

import icon from './assets/icon.svg';

export const config: IConfigBase = {
  title: 'Database',
  search: 'flowchart database',
  type: 'flowchart-database',
  group: ['Flowchart'],
};

export const FlowchartDatabase : IBlock = {
  type: config.type,
  block: () => null,
  element: {
    group: config.group,
    search: config.search,
    options: [],
    styles: [],
    constraints: {},
    onCreate: ({ file, position, parent, canvas }) => {
      const wrap = blocks[shape.type].element.onCreate({
        file,
        position,
        parent,
        auto: true,
        properties: {
          width: undefined,
          height: undefined,
          transparent: false,
          deletable: true,
        },
      });

      blocks[text.type].element.onCreate({
        file,
        position: [0, 0],
        parent: wrap,
        auto: canvas?.space ? false : undefined,
        preventComponentStatus: true,
        properties: {
          text: '<p class="ql-align-center">Database</p>',
        },
      });

      return wrap;
    },
    onCopy: () => '',
    title: config.title,
    size: [96, 78],
    icon,
    class: {
      title: '',
      icon: '',
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [],
  toolbars: [],
};
