import { useContext } from 'react';
import { blocks } from 'blocks';

import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';
import { setArrangementText } from './useArrangementText';
import { useArrangementElement } from './useArrangementElement';

export const useArrangementWrap = () => {
  const element = useContext(ElementContext);

  const drag = useStoreSelector((state) => state.graphic.drag);
  const text = useStoreSelector((state) => !!blocks[state.diagram.file[state.diagram.childs[element.id]?.[0]]?.type]?.element.options.includes('element-text'));

  const arrangement = useArrangementElement();

  if (text) {
    setArrangementText(arrangement, element.parent, drag);
  }

  return arrangement;
};
