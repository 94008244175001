import React, { memo, useLayoutEffect } from 'react';
import { IBlock, IConfig } from 'blocks';

import { storeGraphic } from 'store/graphic';

import { useResizer } from 'hooks/useResizer';
import { useArrangementElement } from 'hooks/useArrangementElement';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';

import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';
import design from 'blocks/factories/assets/Simple.module.css';

import { Text as Value } from './components/Text';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Code',
  type: 'general-code',
  search: 'code block information',
  group: ['Information'],
  memo: {
    key: 'general-code',
    styles: [
      'color',
      'size',
    ],
  },
  styles: [
    'color',
    'size',
  ],
};

interface IModel {
  text: string;
  color: string;
  size: 's' | 'm' | 'l';
  width?: number;
  lockKey: string;
  lock: boolean;
  placeholder?: string;
}

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const arrangement = useArrangementElement();
    const resier = useResizer({
      fixed: true,
      maxWidth: 640,
    });

    useEnterOnSelection({
      onPress: (dispatch) => dispatch(storeGraphic.setComponentKey('text')),
      mode: 'edit',
    });

    // Update Points on Property Change
    useLayoutEffect(() => {
      element.updatePoints();
    }, [properties.text, properties.size]);

    return (
      <Shape
        classElement={style.element}
        classProperties={`${style[`color-${properties.color}`]} ${style[`size-${properties.size}`]} ${design.text}`}
      >
        <Arrangement {...arrangement} />

        <div className={'block-render'.append(style.render)} />
        <Selection status={!resier.status} />

        <div
          className="block-content"
          {...element.trigger}
          {...resier.wrap}
        >

          <Value.Component value={properties.text} property="text" placeholder="1 Add code" />

        </div>

        <Resizer.Width {...resier.attributes} minWidth={42} status={resier.visibility} />
      </Shape>
    );
  },
});

export const InformationCode : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['container-title-none'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      component: 'text',
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        text: '',
        size: 'm',
        color: 'dark',
        width: undefined,
        lockKey: '',
        lock: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
      component: 'text',
    }),
    title: config.title,
    size: [126, 48],
    icon,
    class: {
      title: 'Code',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [
    'link-text',
    'size',
    'color',
    'lock',
    'delete',
  ],
  toolbars: [
    Value.toolbar,
  ],
};
