const digits = [
  [10, 1],
  [100, 2],
  [1000, 3],
  [10000, 4],
  [100000, 5],
  [1000000, 6],
  [10000000, 7],
  [100000000, 8],
  [1000000000, 9],
];

export const useLineCounting = <T extends { html: string, attributes: any}> (input: T) => {
  const count = input.html.split('</p>').length - 1;
  const length = digits.find((digit) => count < digit[0])?.[1] ?? 9;

  input.attributes.style = {
    '--line-counting': length,
  };

  return input;
};
