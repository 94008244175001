import { ITool } from 'tools';
import { getState } from 'utils/store';
import { createTool } from 'tools/ToolBasic';

import { storeDiagram } from 'store/diagram';

import icon from './assets/icon.svg';

const Tool = createTool({
  key: 'lock',
  def: false,
  title: 'Lock',
  alt: 'Lock',
  icon,
  getActive: () => false,
  onClick: (selection, dispatch) => {
    const links = getState((state) => state.diagram.links);
    const file = getState((state) => state.diagram.file);
    const user = getState((state) => state.account.user.id);

    selection.forEach((id) => {
      [...(links[id] ?? [id])].forEach((id) => {
        dispatch(storeDiagram.setElement({
          id,
          properties: {
            lock: true,
            lockKey: (!file[id]?.lock && file[id]?.lockKey && file[id]?.lockKey !== user) ? '' : file[id]?.lockKey,
          },
        }));
      });
    });
  },
});

export const Lock: ITool = {
  key: 'lock',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
