import React, { memo, useLayoutEffect } from 'react';
import optimizers from 'utils/optimizers';
import { IBlock, IConfig } from 'blocks';
import { getState } from 'utils/store';

import { storeGraphic } from 'store/graphic';

import { useJoins } from 'hooks/useJoins';
import { useClosed } from 'hooks/useClosed';
import { useOption } from 'hooks/useOption';
import { useResizer } from 'hooks/useResizer';
import { useJoinsPath } from 'hooks/useJoinsPath';
import { useJoinsSide } from 'hooks/useJoinsSide';
import { useStoreSelector } from 'hooks/useStore';
import { usePathFilter } from 'hooks/usePathFilter';
import { IElement, useElement } from 'hooks/useElement';
import { useCacheProperty } from 'hooks/useCacheProperty';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';
import { useArrangementElement } from 'hooks/useArrangementElement';

import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';
import { createRender } from 'blocks/factories/complex/Render';
import design from 'blocks/factories/assets/Simple.module.css';

import { Text } from './components/Text';
import { Operations } from './components/Operations';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Interface',
  search: 'interface uml unified modeling language',
  type: 'uml-class-interface',
  group: ['UML', 'UML Class Shape'],
  memo: {
    key: 'uml-class-shape',
    styles: [
      'color',
      'render',
    ],
  },
  styles: [
    'color',
    'render',
  ],
};

interface IModel {
  text: string;
  operations: string;
  color: string;
  render: number;
  safe: boolean;
  width?: number;
  detailed: boolean;
  shortened: boolean;
  lockKey: string;
  lock: boolean;
}

interface IElements {
  operations: IElement<HTMLDivElement>;
  collapsed: boolean;
  shortened: boolean;
  side: 'L' | 'T' | 'R' | 'B';
}

const Render = createRender<IModel, IElements>({
  render: ({ width, height, elements }) => {
    const border = 2;

    const x = border / 2;
    const y = border / 2;
    const w = Math.max(width - border, 0);
    const h = Math.max(height - border, 0);

    const a = elements.operations.ref?.offsetTop ?? 0;

    return (
      <>
        <svg className="block-render" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect className="render-fill render-stroke" x={x} y={y} width={w} height={h} strokeLinecap="round" rx="6" strokeWidth={border} />
          {elements.operations.ref && <path className="render-fill render-stroke" d={`M${border} ${a}L${w} ${a}`} strokeLinejoin="round" strokeLinecap="round" strokeWidth={border} />}
          {elements.shortened && <path transform={`translate(${width - 13}, ${height - 13})`} className="render-stroke-fill" d="M12 0V7C12 9.76142 9.76142 12 7 12H0L12 0Z" />}
        </svg>

        {elements.collapsed && <div className={`block-render-item ${style.collapsed} ${style[elements.side.toLowerCase()]}`} />}
      </>
    );
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const operations = useElement<HTMLDivElement>();

    const detailed = useCacheProperty('detailed') ?? true;
    const shortened = useCacheProperty('shortened') ?? false;
    const collapsed = useCacheProperty('collapsed') ?? false;

    const { joins } = useJoins('from', [
      'uml-class-interface-multiplicity',
      'uml-class-class-multiplicity',
      'uml-class-interface',
      'uml-class-class',
    ], ([_, connector]) => {
      return getState((state) => state.diagram.file[connector]?.type === 'uml-class-connector' && state.diagram.file[connector]?.end === 2);
    });

    const { connectors } = useJoins('to', [
      'uml-class-interface-multiplicity',
      'uml-class-class-multiplicity',
      'uml-class-interface',
      'uml-class-class',
    ], ([_, connector]) => {
      return getState((state) => state.diagram.file[connector]?.type === 'uml-class-connector' && state.diagram.file[connector]?.end === 2);
    });

    const path = useJoinsPath(joins, [
      'uml-class-interface-multiplicity',
      'uml-class-class-multiplicity',
      'uml-class-interface',
      'uml-class-class',
    ]);

    const extending = usePathFilter(path, [
      'uml-class-interface-multiplicity',
      'uml-class-interface',
    ]);

    const context = {
      operations: optimizers.withParagraphStart(['+', '#'], useStoreSelector((state) => {
        return extending.map((id) => {
          return state.diagram.file[id]?.operations;
        }).filter((value) => {
          return value;
        }).reverse().join('');
      })),
    };

    const validator = {
      operations: optimizers.noHtml(optimizers.withSpaces(context.operations)).trim(),
    };

    const detailable = validator.operations || `${properties.operations || ''}`.includes(':');
    const shortable = !!`${validator.operations}${properties.operations || ''}`;
    const collapsable = connectors.length > 0;

    const closed = useClosed(path);
    const side = useJoinsSide(connectors);
    const arrangement = useArrangementElement();
    const resier = useResizer({
      fixed: true,
      maxWidth: 340,
    });

    useEnterOnSelection({
      onPress: (dispatch) => dispatch(storeGraphic.setComponentKey('text')),
      mode: 'edit',
    });

    useOption({
      id: element.id,
      key: 'detailed',
      value: !detailable ? 'disable' : '',
      deps: [!detailable],
    });

    useOption({
      id: element.id,
      key: 'shortened',
      value: !shortable ? 'disable' : '',
      deps: [!shortable],
    });

    useOption({
      id: element.id,
      key: 'collapsed',
      value: !collapsable ? 'disable' : '',
      deps: [!collapsable],
    });

    // Update Points on Property Change
    useLayoutEffect(() => {
      element.updatePoints();
    }, [
      closed,
      detailed,
      shortened,
      properties.text,
      properties.operations,
      context.operations,
    ]);

    if (closed) {
      return null;
    }

    return (
      <Shape
        classElement={style.element}
        classProperties={
          `${design[`color-${properties.color}`]} ${design[`render-${properties.render}`]} ${design.text}`
            .appendWhen(detailed, style.detailed)
        }
      >
        <Arrangement {...arrangement} />
        <Render
          properties={properties}
          elements={{
            collapsed: collapsable && collapsed,
            shortened: shortable && shortened,
            operations,
            side,
          }}
        />

        <Selection status={!resier.status} />

        <div
          className="block-content"
          {...element.trigger}
          {...resier.wrap}
        >

          <div className={style.stereotype}>«interface»</div>

          <Text.Component value={properties.text} property="text" />
          <Operations.Component value={properties.operations} property="operations" element={operations} context={detailed ? context.operations : ''} />

        </div>

        <Resizer.Width {...resier.attributes} minWidth={48} status={resier.visibility} />
      </Shape>
    );
  },
});

export const UMLClassInterface : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['container-title-none', 'connector-double-render'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      component: 'text',
      properties: {
        text: '',
        operations: '',
        color: 'dark',
        render: 0,
        safe: false,
        detailed: true,
        shortened: false,
        lockKey: '',
        lock: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [144, 40],
    icon,
    class: {
      title: 'Interface',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [
    'link-text',
    'link-operations',
    'shape-uml-class',
    'render',
    'color',
    'detailed',
    'shortened',
    'collapsed',
    'lock',
    'delete',
  ],
  toolbars: [
    Text.toolbar,
    Operations.toolbar,
  ],
};
