import { useContext } from 'react';
import { getLoadingStatus, getPath, getState, getTree } from 'utils/store';
import { getNextId } from 'utils';
import { blocks } from 'blocks';
import optimizers from 'utils/optimizers';

import { storeGraphic } from 'store/graphic';
import { storeDiagram } from 'store/diagram';

import { serviceAlert } from 'services/alert';

import { config } from 'blocks/Core/Grid';

import { ElementContext } from 'elements/Block/Element';

import { GridContext } from './useGrid';
import { useDragCell } from './useDragCell';
import { useStoreDispatch } from './useStore';

interface ICopy {
  head: string;
  tree: string[];
  position: [number, number];
  parent: string;
  file: Record<string, any>;
}

export const useTabGridText = () => {
  const dispatch = useStoreDispatch();

  const file = getState((state) => state.diagram.file);
  const captured = getState((state) => state.diagram.captured);
  const options = getState((state) => state.graphic.options);
  const childs = getState((state) => state.diagram.childs);
  const roots = getState((state) => state.diagram.roots);
  const diagram = getState((state) => state.diagram.diagram);

  const grid = useContext(GridContext);
  const element = useContext(ElementContext);

  const cell = useDragCell();

  const copy = ({ head, tree, position, parent, file }: ICopy) => {
    const id = +getNextId(file);

    const root = element.id;

    const transition = Object.fromEntries(tree.map((node, index) => {
      return [node, `${id + index}`];
    }));

    Object.keys(transition).forEach((child) => {
      const payload = {
        file,
        transition,
        target: { id: child, ...file[child], deletable: true },
        preventComponentStatus: true,
        auto: true as boolean | undefined,
      };

      if (child === root) {
        if (tree.length > 1 && !blocks[file[file[root]?.parent]?.type]?.element.options.includes('element-wrap-inline')) {
          payload.target.text = '<p class="ql-align-center"><br></p>';
        } else {
          payload.target.text = '';
        }

        payload.auto = true;
      }

      if (child === head) {
        payload.auto = false;

        blocks[file[child].type].element.onCopy({
          ...payload,

          position,
          parent,
          diagram: diagram.id,
          ticket: diagram.ticket,
          native: true,
        });

        return;
      }

      blocks[file[child].type].element.onCopy({
        ...payload,

        position: [0, 0],
        parent: transition[file[child].parent] ?? file[child].parent,
        diagram: diagram.id,
        ticket: diagram.ticket,
        native: true,
      });
    });

    dispatch(storeDiagram.setComputedProperties(Object.values(transition)));
    dispatch(storeGraphic.setSelectionElements([transition[root]]));
    dispatch(storeGraphic.setComponentKey('text'));
    dispatch(storeGraphic.setComponentPreventStatus());
  };

  return (html: string, direction: 'T' | 'B', forced = false) => {
    const root = element.id;

    const path = getPath(root, { file });

    if (path.length === 0 || path.find((id) => blocks[file[id]?.type]?.element.options.includes('element-container'))) {
      return;
    }

    const index = path.findIndex((_, index) => {
      if (!blocks[file[path[index + 1]]?.type]?.element.options.includes('element-wrap')) {
        return true;
      }

      return false;
    });

    path.splice(index + 1, path.length - (index + 1));

    const head = path[path.length - 1];

    if (Object.keys(grid.layout).length > 0) {
      const queue: string[] = [];

      Object.keys(grid.layout).forEach((row) => {
        Object.keys(grid.layout[+row]).forEach((col) => {
          const cell = grid.layout[+row][+col];

          if (blocks[file[cell]?.type]?.element.options.includes('element-text') && !(captured[cell] || file[cell]?.lock || options[cell]?.invisible)) {
            queue.push(cell);
          } else if (blocks[file[cell]?.type]?.element.options.includes('element-wrap') && blocks[file[roots[cell]]?.type]?.element.options.includes('element-text') && !(captured[roots[cell]] || file[roots[cell]]?.lock || options[roots[cell]]?.invisible)) {
            queue.push(roots[cell]);
          }
        });
      });

      let index = queue.indexOf(root) + (direction === 'B' ? 1 : -1);

      if ((direction === 'B' && index > queue.length - 1) || (direction === 'T' && index < 0) || forced) {
        if (optimizers.noHtml(html).trim()) {
          const doc = { ...file };
          const position = [
            grid.arrangement[head][0],
            grid.arrangement[head][1] + (direction === 'B' ? 1 : -1),
          ] as [number, number];

          const tree = getTree(head, { childs });
          const loading = getLoadingStatus(tree);

          if (loading) {
            dispatch(serviceAlert.showAlert({
              message: 'This element is loading',
              type: 'default',
            }));

            return;
          }

          if (position[0] >= 0 && position[1] >= 0 && position[0] < grid.size[0] && position[1] < grid.size[1] && !grid.layout[position[1]]?.[position[0]]) {
            copy({
              head,
              tree,
              position,
              parent: grid.id,
              file: doc,
            });

            return;
          }

          const arrangement = cell.getPosition({
            direction: [0, (direction === 'B' ? 1 : -1)],
            position: grid.arrangement[head],
            element: 'new',
          });

          copy({
            head,
            tree,
            position: arrangement.new,
            parent: grid.id,
            file: doc,
          });

          cell.setPosition({
            arrangement,
            element: 'new',
          });

          return;
        }

        if ((direction === 'B' && index > queue.length - 1) || (direction === 'T' && index < 0)) {
          index = (direction === 'B' ? 0 : queue.length - 1);
        }
      }

      const next = queue[index];

      if (next) {
        dispatch(storeGraphic.setSelectionElements([next]));
        dispatch(storeGraphic.setComponentKey('text'));
        dispatch(storeGraphic.setComponentPreventStatus());
      }

      return;
    }

    if (!optimizers.noHtml(html).trim()) {
      return;
    }

    const from = {
      parent: file[head]?.parent || '',
      position: [...file[head]?.position || [0, 0]],
    };

    const properties = {
      padding: 's',
      alignHorizontal: 'left',
    };

    const a = path.length === 1;
    const b = !['top', 'bottom'].includes(file[head]?.placing) && path.length === 2 && blocks[file[head]?.type]?.element.options.includes('element-wrap-text');
    const c = !['top', 'bottom'].includes(file[head]?.placing) && path.length === 2 && blocks[file[head]?.type]?.element.options.includes('element-wrap-inline');

    if (!a && !b && !c) {
      properties.padding = 'm';
      properties.alignHorizontal = 'center';
    }

    if (!from.parent) {
      if (b) {
        from.position[0] -= 1;
        from.position[1] -= 1;
      } else if (a || c) {
        from.position[0] -= 1;
        from.position[1] -= 4;
      } else {
        from.position[0] -= 24;
        from.position[1] -= 24;
      }
    }

    const doc = { ...file };
    const tree = getTree(head, { childs });
    const loading = getLoadingStatus(tree);

    if (loading) {
      dispatch(serviceAlert.showAlert({
        message: 'This element is loading',
        type: 'default',
      }));

      return;
    }

    const container = blocks[config.type].element.onCreate({
      file: doc,
      position: from.position,
      parent: from.parent,
      auto: true,
      properties,
    });

    dispatch(storeDiagram.setElement({
      id: head,
      properties: {
        parent: container,
        position: [0, (direction === 'B' ? 0 : 1)],
      },
    }));

    copy({
      head,
      tree,
      position: [0, (direction === 'B' ? 1 : 0)],
      parent: container,
      file: doc,
    });
  };
};
