import React from 'react';
import { ITool } from 'tools';

import { storeDiagram } from 'store/diagram';

import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import icon from './assets/icon.svg';

const Tool = () => {
  const dispatch = useStoreDispatch();

  const selection = useStoreSelector((state) => state.graphic.selection.elements);
  const file = useStoreSelector((state) => state.diagram.file);

  const onClick = () => {
    selection.forEach((id) => {
      const current = [...file[id].points].map((point) => ({ ...point }));
      const rotated = file[id].points.reduce((status: boolean, point: any) => (point.inverted ? !status : status), false);
      const rotate = file[id].points.length > 2 && ((file[id].points.length % 2 === 0 && !rotated) || (file[id].points.length % 2 !== 0 && rotated));

      const points = current.map((point, index) => ({
        ...point,

        inverted: (rotate && index === 1),
        parent: current[current.length - 1 - index].parent,
        position: current[current.length - 1 - index].position,
        direction: current[current.length - 1 - index].direction,
      }));

      dispatch(storeDiagram.setElement({
        id,
        properties: {
          points,
        },
      }));
    });
  };

  return (
    <Hint message="Reverse" side="bottom" variant="default">
      <button type="button" className="button-icon v-1" onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt="Reverse" />
      </button>
    </Hint>
  );
};

export const LineReverse: ITool = {
  key: 'line-reverse',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
