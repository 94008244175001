import React, { FC, useContext } from 'react';
import { deleteElements, getBlockContextElements, getState } from 'utils/store';
import optimizers from 'utils/optimizers';
import ReactQuill from 'react-quill';

import { storeGraphic } from 'store/graphic';
import { OptionEmptyTextBlur } from 'store/settings';

import { useEditable } from 'hooks/useEditable';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { useEditableBlur } from 'hooks/useEditableBlur';
import { useEditableBindings } from 'hooks/useEditableBindings';
import { useEditableComponent } from 'hooks/useEditableComponent';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { ElementContext } from 'elements/Block/Element';

import { Hint } from 'components/Hint';
import { Pointer } from 'components/Pointer';

import { IComponent, config } from '.';

import style from '../../assets/style.module.css';

export const Edit: FC<IComponent> = ({ value, property }) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  const safe = useStoreSelector((state) => !!state.diagram.file[element.id]?.safe);

  const bindings = useEditableBindings({
    key: config.key,
    shift: false,
  });

  const onBlur = useEditableBlur({
    property,
    key: config.key,
    onBefore: (value, input) => {
      const blur = getState((state) => state.settings.app.emptyTextBlur);

      if (optimizers.noHtml(value).trim() === '') {
        if (optimizers.noHtml(input.default).trim() === '' && !blur.includes(OptionEmptyTextBlur.Disable)) {
          deleteElements(getBlockContextElements([element.id]));
          dispatch(storeGraphic.setSelectionElements([]));

          return false;
        }

        input.reset();

        return false;
      }

      return true;
    },
  });

  const input = useEditable({
    value,
    bindings,
    formats: [
      'paragraph',
      'bold',
      'link',
      'italic',
      'underline',
      'strike',
      'background',
    ],
    validator: (value: string) => {
      if (!safe) {
        return '';
      }

      const file = getState((state) => state.diagram.file);
      const duplication = !Object.entries(file).every(([key, block]) => {
        if (config.effect.includes(block.type)) {
          if (key === element.id) {
            return true;
          }

          if (optimizers.noHtml(value).trim() === optimizers.noHtml(block.text).trim()) {
            return false;
          }
        }

        return true;
      });

      if (duplication) {
        return 'This object already exists';
      }

      return '';
    },
    onBlur: (value) => onBlur(value, input),
  });

  useEditableComponent(input);

  // Validate to Safe Mode Status Change
  useUpdateEffect(() => {
    if (!input.hasFocus()) {
      input.focus();
    }

    input.validate();
  }, [safe]);

  return (
    <div className={'input-hint-wrap'.appendWhen(input.error, style.error)}>
      <ReactQuill
        {...input.attributes}
        className={style.input}
        placeholder="Type something"
      />
      {input.error && (
        <Hint side="top" variant="warning" message={input.error}>
          <Pointer variant="warning" />
        </Hint>
      )}
    </div>
  );
};
