import { useContext, useMemo } from 'react';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';
import { IPointControl } from './useConnector';

export interface IElbows {
  axis: boolean;
  points: number[][] | undefined;
}

export const useConnectorElbow = (points: IPoint[], control: IPointControl[]): IElbows => {
  const element = useContext(ElementContext);

  const style = useStoreSelector((state) => state.diagram.file[element.id].style);
  const horizontal = useStoreSelector((state) => state.diagram.file[element.id].horizontal);

  const isHorizontal = () => {
    if (points.length > 2) {
      return horizontal;
    }

    if (control[0].parent && control[0].type !== 'join' && !control[0]._moving) {
      if (control[0]._side[0] !== 0) {
        return false;
      }

      return true;
    }

    if (control[1].parent && control[1].type !== 'join' && !control[1]._moving) {
      if (control[1]._side[0] !== 0) {
        return true;
      }

      return false;
    }

    const direction = [
      points[1].position[0] - points[0].position[0],
      points[1].position[1] - points[0].position[1],
    ];

    if (Math.abs(direction[0]) > Math.abs(direction[1])) {
      return false;
    }

    return true;
  };

  return useMemo(() => {
    if (style !== 'elbow') {
      return {
        axis: false,
        points: undefined,
      };
    }

    const vertices = [[
      points[0].position[0],
      points[0].position[1],
    ]];

    const path = [...points] as { position: number[] }[];
    const horizontal = isHorizontal();

    if (
      points.length === 2
      && (
        (control[0].type === 'parent' && control[1].type === 'parent')
        || (control[0].type === 'default' && control[1].type === 'default')
      )
      && (!points[0].parent || control[0]._moving) === (!points[1].parent || control[1]._moving)
      && (control[0]._side[0] === control[1]._side[0] || control[0]._side[1] === control[1]._side[1])
    ) {
      const direction = [
        points[1].position[0] - points[0].position[0],
        points[1].position[1] - points[0].position[1],
      ];

      if (Math.abs(direction[0]) > Math.abs(direction[1])) {
        const width = direction[0] / 2;

        direction[1] = (width * direction[1]) / direction[0];
        direction[0] = width;
      } else {
        const height = direction[1] / 2;

        direction[0] = (height * direction[0]) / direction[1];
        direction[1] = height;
      }

      path.splice(1, 0, {
        position: [
          points[0].position[0] + direction[0],
          points[0].position[1] + direction[1],
        ],
      });
    }

    path.slice(1).reduce((horizontal, point, index) => {
      const prev = path[index];

      if (horizontal) {
        vertices.push([
          prev.position[0],
          point.position[1],
        ]);
      } else {
        vertices.push([
          point.position[0],
          prev.position[1],
        ]);
      }

      return !horizontal;
    }, horizontal);

    vertices.push([
      points[points.length - 1].position[0],
      points[points.length - 1].position[1],
    ]);

    return {
      axis: (points.length === 2 && vertices.length === 4) ? !horizontal : horizontal,
      points: vertices,
    };
  }, [points, style, horizontal]);
};
