import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import iconTop from './assets/top.svg';
import iconCenter from './assets/center.svg';
import iconBottom from './assets/bottom.svg';

const Tool = createTool('align-vertical', 'alignVertical', 'center', 'Vertical alignment', 'Alignment', {
  top: iconTop,
  center: iconCenter,
  bottom: iconBottom,
});

export const AlignVertical: ITool = {
  key: 'align-vertical',
  tool: Tool,
  options: ['only-edit'],
};
