import React from 'react';
import { ITool } from 'tools';

import { createFormatTool } from 'tools/ToolFormat';

import icon from './assets/icon.svg';

const Tool = createFormatTool({
  icon,
  title: 'Heading #1',
  format: 'header',
  condition: (value) => value === 1,
  filter: (value) => value === 1,
  set: (status) => (status ? 0 : 1),
});

export const FormatH1: ITool = {
  key: 'format-h1',
  tool: Tool,
  options: ['only-edit'],
};
