import Queue from 'singly-linked-list';

import { getState } from './store';
import { getPathSideCode, getVectorNormalized } from './graphic';

interface IPoint {
  element: string;
  id: string;
  parent: string;
  side: number[];
}

interface IPointControl extends IPoint {
  pivot: number[];
  free: boolean;
}

interface IStore {
  queues: {
    [key: string]: Queue;
  }
  elements: {
    [key: string]: {
      side: 'L' | 'T' | 'R' | 'B';
      parent: string;
      pivot: number;
      offset: number;
      free: boolean;
    };
  };
  renderers: {
    [key: string]: () => void;
  }
  directions: {
    [key: string]: number[];
  }
}

const store: IStore = {
  queues: {},
  elements: {},
  renderers: {},
  directions: {},
};

export const lines = {
  get elements() {
    return store.elements;
  },
  get directions() {
    return store.directions;
  },
  queue: (element: string, side: 'L' | 'T' | 'R' | 'B') => {
    return store.queues[`${element}.${side}`];
  },
  insert: (parent: string, id: string) => {
    if (!store.queues[parent]) {
      store.queues[parent] = new Queue();
    }

    const queue = store.queues[parent].toArray() as unknown as string[];

    const index = queue.findIndex((node) => {
      return store.elements[node].pivot > store.elements[id].pivot;
    });

    if (index < 0) {
      store.queues[parent].insert(id);

      return;
    }

    store.queues[parent].insertAt(index, id);
  },
  remove: (parent: string, id: string) => {
    store.queues[parent]?.removeNode(id);

    if (store.queues[parent]?.getSize() === 0) {
      delete store.queues[parent];
    }
  },
  update: (parent: string) => {
    const elements = getState((state) => state.graphic.elements);
    const options = getState((state) => state.graphic.options);

    const queue = (store.queues[parent]?.toArray() as unknown as string[])?.filter((id) => {
      const [element] = id.split('.');

      return (
        elements[element]
        && !options[element]?.closed
        && !store.elements[id].free
      );
    });

    if (!queue) {
      return;
    }

    queue.reduce((offset, id) => {
      if (store.elements[id].offset !== offset) {
        store.elements[id].offset = offset;
        store.renderers[id]?.();
      }

      return offset + 2;
    }, 1 - queue.length);
  },
  add: (point: IPointControl) => {
    const side = getPathSideCode(point.side);

    const key = `${point.parent}.${side}`;
    const id = `${point.element}.${point.id}`;

    if (store.elements[id]?.parent && store.elements[id].parent !== key) {
      lines.remove(store.elements[id].parent, id);
      lines.update(store.elements[id].parent);
    }

    store.elements[id] = {
      side,
      free: point.free,
      parent: key,
      pivot: getVectorNormalized(point.pivot)[['L', 'R'].includes(side) ? 1 : 0],
      offset: 0,
    };

    lines.remove(key, id);
    lines.insert(key, id);
    lines.update(key);
  },
  delete: (point: IPoint) => {
    const side = getPathSideCode(point.side);

    const key = `${point.parent}.${side}`;
    const id = `${point.element}.${point.id}`;

    lines.remove(store.elements[id]?.parent, id);
    lines.update(store.elements[id]?.parent);

    delete store.elements[id];

    lines.remove(key, id);
    lines.update(key);

    if (store.queues[key]?.getSize() < 1) {
      delete store.queues[key];
    }
  },
  render: ({ element, id, callback }: { element: string, id: string, callback: () => void }) => {
    store.renderers[`${element}.${id}`] = callback;
  },
  unrender: ({ element, id }: { element: string, id: string }) => {
    delete store.renderers[`${element}.${id}`];
  },
  get: ({ element, id, side }: { element: string, id: string, side: number[] }) => {
    const node = store.elements[`${element}.${id}`];

    if (!node || getPathSideCode(side) !== node.side) {
      return [0, 0];
    }

    return [
      ['T', 'B'].includes(node.side) ? node.offset ?? 0 : 0,
      !['T', 'B'].includes(node.side) ? node.offset ?? 0 : 0,
    ];
  },
  direction: ({ element, id, direction }: { element: string, id: string, direction: number[] }) => {
    store.directions[`${element}.${id}`] = direction;
  },
  undirection: ({ element, id }: { element: string, id: string }) => {
    delete store.directions[`${element}.${id}`];
  },
};
