import React, { memo } from 'react';
import { IBlock } from 'blocks';

import { useCacheProperty } from 'hooks/useCacheProperty';

import { Model } from 'blocks/factories/container/Model';
import { IElements, createBlock } from 'blocks/factories/container/Block';
import { createRender } from 'blocks/factories/complex/Render';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';

import { Button } from 'elements/Block/Button';

import style from './assets/style.module.css';
import icon from './assets/icon.svg';
import iconWhite from './assets/icon-white.svg';

type IModel = Model<{}>;

export const config = {
  title: 'Component',
  search: 'container component uml unified modeling language',
  type: 'uml-component-container-component',
  group: ['UML'],
  memo: {
    key: 'uml-component-shape',
    styles: [
      'color',
      'render',
      'padding',
    ],
  },
  styles: [
    'color',
    'render',
    'padding',
  ],
};

const Render = createRender<IModel, IElements>({
  render: ({ width, height, properties, element, elements }) => {
    const hidden = useCacheProperty('hidden') ?? false;

    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - border, 0);
    const y = Math.max(height - border, 0);

    const heading = elements.heading.ref ? elements.heading.ref.offsetHeight - offset : 0;

    return (
      <div className="block-render">
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
          {hidden && (
            <defs>
              <pattern id={`${element.id}-pattern`} patternUnits="userSpaceOnUse" width="24" height="24" viewBox="0 0 8 8">
                <path className="render-stroke-color render-opacity" d="M-1,1 l4,-4 M0,8 l8,-8 M6,10 l4,-4" strokeOpacity={0.075} strokeWidth={4} />
              </pattern>
            </defs>
          )}

          <rect className="render-fill-stroke render-stroke" fillOpacity={0.1} strokeOpacity={properties.render === 7 ? 0.1 : 1} strokeWidth={border} strokeLinecap="round" x={offset} y={offset} width={x} height={y} rx="6" />

          {elements.heading.ref && <path className="render-fill render-stroke" strokeOpacity={properties.render === 7 ? 0.1 : 1} d={`M${border} ${heading}L${x} ${heading}`} strokeLinecap="round" strokeWidth={border} />}

          {hidden && (
            <>
              <rect x={offset + 4} y={offset + heading + 4} width={Math.max(x - 8, 0)} height={Math.max(y - heading - 8, 0)} strokeLinecap="round" rx="6" fill={`url(#${element.id}-pattern)`} />
              <svg x={(width - 62) / 2} y={(height + heading - 30) / 2 + 4} width="62" height="30" viewBox="0 0 62 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="render-stroke-color" d="M2.5 2C4.43193 4.41491 6.62093 6.51562 9 8.29424M59.5 2C57.5127 4.37196 55.3281 6.42336 53 8.16053M9 8.29424L2 20.5M9 8.29424C13.337 11.5366 18.3057 13.7087 23.5 14.7625M23.5 14.7625L21.5 27.5M23.5 14.7625C28.4126 15.7593 33.5271 15.7558 38.5 14.7119M38.5 14.7119L41 28M38.5 14.7119C43.6065 13.6399 48.5638 11.4707 53 8.16053M53 8.16053L60 20" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </>
          )}

          <svg x={x - 19} y={6}>
            <path className="render-stroke" d="M3.47059 12.5294H2C1.44772 12.5294 1 12.0817 1 11.5294V10.2353C1 9.68301 1.44772 9.23529 2 9.23529H3.47059M3.47059 12.5294H4.94118C5.49346 12.5294 5.94118 12.0817 5.94118 11.5294V10.2353C5.94118 9.68301 5.49346 9.23529 4.94118 9.23529H3.47059M3.47059 12.5294V13C3.47059 14.1046 4.36602 15 5.47059 15H12C13.1046 15 14 14.1046 14 13V3C14 1.89543 13.1046 1 12 1H5.47059C4.36602 1 3.47059 1.89543 3.47059 3V3.47059M3.47059 3.47059H4.94118C5.49346 3.47059 5.94118 3.9183 5.94118 4.47059V5.76471C5.94118 6.31699 5.49346 6.76471 4.94118 6.76471H3.47059M3.47059 3.47059H2C1.44772 3.47059 1 3.9183 1 4.47059V5.76471C1 6.31699 1.44772 6.76471 2 6.76471H3.47059M3.47059 6.76471V9.23529" strokeWidth="2" style={{ strokeDasharray: '100, 0' }} />
          </svg>
        </svg>
      </div>
    );
  },
});

const Block = createBlock({
  config,
  Render,
  classElement: style.element,
  spacing: [15, 10],
  classFrame: (properties) => {
    if ((properties.elements ?? []).length === 0) {
      return style.frame;
    }

    return undefined;
  },
});

export const UMLComponentContainerComponent : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-container', 'element-wrap', 'child-none', 'container-title-none'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
        render: [
          6,
          8,
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        color: 'dark',
        render: 0,
        padding: 'm',
        elements: [],
        placing: 'top',
        width: 120,
        height: 120,
        space: true,
        lockKey: '',
        lock: false,
        hidden: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [120, 120],
    icon,
    class: {
      title: 'Component',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: [
      'only-edit',
    ],
    type: 'drag',
  },
  tools: [
    'padding',
    'render',
    'color',
    'hidden',
    'lock',
    'delete-wrap',
    'delete',
  ],
  toolbars: [

  ],
};
