import React, { memo } from 'react';
import { IBlock } from 'blocks';

import { Model } from 'blocks/factories/connector/Model';
import { createBlock } from 'blocks/factories/connector/Block';
import { createCopier } from 'blocks/factories/connector/Copier';
import { createCreator } from 'blocks/factories/connector/Creator';

import { Button } from 'elements/Block/Button';

import { End, Start } from './components/Cap';

import icon from './assets/icon.svg';
import iconWhite from './assets/icon-white.svg';

export const config = {
  title: 'Connector',
  search: 'arrow line connector uml unified modeling language',
  type: 'uml-class-connector',
  group: ['UML'],
  memo: {
    key: 'connector-uml-class',
    styles: [
      'size',
      'color',
      'style',
      'stroke',
      'start',
      'end',
      'startFree',
      'endFree',
    ],
  },
  styles: [
    'size',
    'color',
    'style',
    'stroke',
    'start',
    'end',
    'startFree',
    'endFree',
  ],
};

type IModel = Model<{}>;

const Block = createBlock<IModel>({
  config,
  caps: {
    Start,
    End,
  },
});

const Help = () => (
  <>
    <div className="text-10">Connector (UML Class Diagram)</div>
    <div className="text-9">The Connector (UML Class Diagram) is a specialized type of connector block tailored for UML class diagrams within Exemplar.</div>
    <div className="text-8">Key Features:</div>
    <ul>
      <li className="text-9"><b>Specialized for UML Class Diagrams</b>: This connector is specifically designed to streamline the creation and representation of UML class diagrams within Exemplar.</li>
      <li className="text-9"><b>Information Transmission</b>: When users employ this block to connect components of a UML class diagram and select a special type of cap, the arrow becomes a powerful tool for transmitting information. This capability is especially valuable for implementing classes or interfaces and managing the relationships between them.</li>
      <li className="text-9"><b>Automatic Synchronization</b>: By using this connector with the designated cap type, all necessary information is transmitted and synchronized automatically between connected blocks. This includes details relevant to class implementations, extensions, and other essential aspects of your UML class diagram.</li>
    </ul>
    <div className="text-9">The Connector (UML Class Diagram) simplifies the process of creating and maintaining UML class diagrams in Exemplar. It ensures that your diagram accurately reflects the relationships and information flow within your class structures, saving you time and effort while maintaining precision and clarity.</div>
  </>
);

export const UMLClassConnector : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    Help,
    memo: config.memo.key,
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-connector', 'parent-none', 'child-none', 'container-none', 'container-title-none', 'no-selection-view-mode', 'lock-delete'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      config,
    }),
    onCopy: createCopier({
      config,
    }),
    title: config.title,
    size: [64, 64],
    icon,
    class: {
      title: 'UML Class Connector',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: [
      'only-edit',
    ],
    type: 'connector-uml-class',
  },
  tools: [
    'connector-uml-class-start',
    'connector-uml-class-end',
    'size',
    'line-style',
    'line-stroke',
    'color',
    'connector-start-free',
    'connector-end-free',
    'connector-reverse',
    'lock',
    'delete',
  ],
  toolbars: [

  ],
};
