import React, { useContext, MouseEvent } from 'react';
import { Emitter } from 'utils/events';
import { blocks } from 'blocks';

import { getState } from 'utils/store';
import { storeDiagram } from 'store/diagram';

import { config } from 'blocks/Core/Wrapper';

import { CanvasDynamicContext } from 'elements/Canvas';
import { ElementContext } from 'elements/Block/Element';
import { IArrangement } from 'elements/Block/Arrangement';

import { useStoreDispatch, useStoreSelector } from './useStore';
import { useArrangementElement } from './useArrangementElement';

export const useArrangementWrapper = (): IArrangement => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);
  const canvas = useContext(CanvasDynamicContext);

  const drag = useStoreSelector((state) => state.graphic.drag);
  const layout = useStoreSelector((state) => state.graphic.layout.status);

  const arrangement = useArrangementElement();

  if (drag && layout) {
    arrangement.onCenter = (e: MouseEvent) => {
      const file = { ...getState((state) => state.diagram.file) };
      const parent = getState((state) => state.diagram.file[element.id].parent);
      const position = getState((state) => state.diagram.file[element.id].position);

      const wrapper = blocks[config.type].element.onCreate({
        file,
        parent,
        position,
        auto: true,
      });

      Emitter.emit('drag-up', {
        id: wrapper,
        file,
        event: e,
        position: [0, 1],
        auto: canvas.space ? false : undefined,
      });

      dispatch(storeDiagram.setElement({
        id: element.id,
        properties: {
          parent: wrapper,
          position: [0, 0],
        },
      }));
    };
  }

  return arrangement;
};
