import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { serviceCollection } from 'services/collection';

import { ITemplate } from 'tabs/Template';

import { IDiagram } from './diagram';

interface State {
  loading: boolean;
  search: string;
  collection: { [key: string]: IDiagram };
  templates: { [key: string]: ITemplate };
}

const defaultState: State = {
  loading: true,
  search: '',
  collection: {},
  templates: {},
};

const slice = createSlice({
  name: 'collection',
  initialState: defaultState,
  reducers: {
    reset: () => defaultState,
    removeDiagram: (state, action: PayloadAction<string>) => {
      delete state.collection[action.payload];
    },
    updateDiagram: (state, action: PayloadAction<{ id: string } & any>) => {
      if (!(action.payload.id in state.collection)) {
        return;
      }

      if ('name' in action.payload) state.collection[action.payload.id].name = action.payload.name;
      if ('ticket' in action.payload) state.collection[action.payload.id].ticket = action.payload.ticket;
      if ('members' in action.payload) state.collection[action.payload.id].members = action.payload.members;
      if ('favorite' in action.payload) state.collection[action.payload.id].favorite = action.payload.favorite;
      if ('template' in action.payload) state.collection[action.payload.id].template = action.payload.template;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
  extraReducers: {
    [serviceCollection.getCollection.fulfilled.type]: (state, action: PayloadAction<{ boards: IDiagram[], templates: ITemplate[] }>) => {
      state.loading = false;
      state.collection = {};
      state.templates = {};

      action.payload.boards.forEach((board) => {
        state.collection[board.id] = board;
      });

      action.payload.templates.forEach((template) => {
        state.templates[template.id] = template;
      });
    },
    [serviceCollection.getCollection.rejected.type]: (state) => {
      state.loading = false;
    },
  },
});

export const storeCollection = {
  reset: slice.actions.reset,
  removeDiagram: slice.actions.removeDiagram,
  updateDiagram: slice.actions.updateDiagram,
  setSearch: slice.actions.setSearch,
};

export default slice.reducer;
