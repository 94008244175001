import { useContext, useEffect, useLayoutEffect } from 'react';
import { lines } from 'utils/lines';
import { points } from 'utils/points';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';

export const useConnectorDirections = (vertices: IPoint[]) => {
  const element = useContext(ElementContext);

  useEffect(() => {
    lines.undirection({
      element: element.id,
      id: '0',
    });

    lines.undirection({
      element: element.id,
      id: '1',
    });
  }, []);

  useLayoutEffect(() => {
    const start = vertices[0]?.parent && vertices[0]?.parent in points.elements && vertices[0]?.type === 'parent' && vertices[0];
    const end = vertices[vertices.length - 1]?.parent && vertices[vertices.length - 1]?.parent in points.elements && vertices[vertices.length - 1]?.type === 'parent' && vertices[vertices.length - 1];

    if (start) {
      const parent = [
        points.elements[start.parent].position[0] + points.elements[start.parent].offset[0] + points.elements[start.parent].size[0] / 2,
        points.elements[start.parent].position[1] + points.elements[start.parent].offset[1] + points.elements[start.parent].size[1] / 2,
      ];

      lines.direction({
        element: element.id,
        id: '0',
        direction: [
          start.position[0] - parent[0],
          start.position[1] - parent[1],
        ],
      });
    }

    if (end) {
      const parent = [
        points.elements[end.parent].position[0] + points.elements[end.parent].offset[0] + points.elements[end.parent].size[0] / 2,
        points.elements[end.parent].position[1] + points.elements[end.parent].offset[1] + points.elements[end.parent].size[1] / 2,
      ];

      lines.direction({
        element: element.id,
        id: '1',
        direction: [
          end.position[0] - parent[0],
          end.position[1] - parent[1],
        ],
      });
    }
  }, [vertices]);
};
