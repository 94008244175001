import React, { FC } from 'react';
import { IType, blocks } from 'blocks';

import { useBlockButton } from 'hooks/useBlockButton';

import { Hint } from 'components/Hint';

interface IButton {
  element: string;
  type?: IType;
}

export const Button: FC<IButton> = ({ element, type }) => {
  const block = useBlockButton('button-icon v-1')(element, type);

  return (
    <Hint Help={block.Help} message={blocks[element].element.title} side="bottom" variant="default">
      <button type="button" {...block} onKeyDown={(e) => e.preventDefault()}>
        <img src={blocks[element].element.icon} width={28} height={28} alt={blocks[element].element.title} />
      </button>
    </Hint>
  );
};
