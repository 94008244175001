import { useContext, useMemo } from 'react';
import { renderPathDefault } from 'utils/graphic';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';

export const config = {
  size: {
    s: 1.4,
    m: 2.2,
    l: 4,
  },
  gap: {
    s: 5,
    m: 4,
    l: 3,
  },
};

export const useConnectorStroke = () => {
  const element = useContext(ElementContext);

  const style = useStoreSelector((state) => state.diagram.file[element.id].stroke as 'solid' | 'dashed' | 'dotted');
  const size = useStoreSelector((state) => state.diagram.file[element.id].size as 's' | 'm' | 'l');

  const width = config.size[size];
  const attributes = {
    strokeWidth: width,
    strokeDasharray: '',
  };

  if (style === 'dashed') {
    attributes.strokeDasharray = `${width * 2} ${width * config.gap[size]}`;
  }

  if (style === 'dotted') {
    attributes.strokeDasharray = `0 ${width * config.gap[size]}`;
  }

  return attributes;
};
