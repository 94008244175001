import { blocks } from 'blocks';
import { toolbars } from 'tools';

import { IRole } from 'store/diagram';

import security from './security';

interface IMember {
  _id: string;
  user?: string;
  email: string;
  role: IRole | 'owner';
  favorite: boolean;
}

export const isBlockExist = (type: string) => type in blocks;
export const isToolbarExist = (type: string) => type in toolbars;

export const getFile = (file: { [key: string]: { type: string, parent: string } & any }, exceptions: string[] = []) => {
  const payload = { ...file };

  Object.keys(payload).forEach((key) => {
    if (!key.replace(/\D/g, '') || !isBlockExist(payload[key].type)) {
      delete payload[key];
      return;
    }

    if (!('parent' in payload[key])) {
      payload[key].parent = '';
    }

    payload[key] = { ...payload[key] };

    exceptions.forEach((exception) => {
      delete payload[key][exception];
    });
  });

  return payload as { [key: string]: any };
};

export const getDiagramMember = (member: IMember) => ({
  id: member._id,
  user: member.user,
  email: member.email,
  role: member.role,
});

export const getDiagram = (diagram: any) => {
  const user = security.getUser();

  const client = diagram.members.find((member: IMember) => member.user === user.id);
  const owner = diagram.members.find((member: IMember) => member.role === 'owner')?.user;
  const favorite = client ? client.favorite : false;
  const role = client ? client.role : 'viewer';

  return {
    role: role,
    owner: owner,
    favorite: favorite,
    id: diagram._id,
    name: diagram.name,
    ticket: diagram.ticket,
    lastModified: diagram.lastModified,
    members: diagram.members.map((member: IMember) => getDiagramMember(member)),
    template: diagram.template,
  };
};

export const getTemplate = (diagram: any) => {
  return {
    id: diagram._id,
    file: diagram.file,
    name: diagram.name,
    owner: diagram.owner,
    ticket: diagram.ticket,
  };
};
