import { IConfig } from 'blocks';
import { storeGraphic } from 'store/graphic';

import { createCreator as creator } from 'blocks/factories/simple/Creator';

import { IModel } from './Model';

interface ICreator<T extends IModel> {
  config: IConfig;
  properties?: Partial<Extract<T, Record<string, any>>>;
}

export const createCreator = <T extends IModel, > ({ config, properties = {} }: ICreator<T>) => creator<IModel>({
  type: config.type,
  memo: config.memo.key,
  styles: config.styles,
  history: false,
  properties: {
    color: 'dark',
    size: 'm',
    start: 0,
    end: 1,
    startFree: false,
    endFree: false,
    stroke: 'solid',
    style: 'default',
    lockKey: '',
    lock: false,
    points: [],
    horizontal: false,

    ...properties,
  },
  condition: (payload) => {
    if (payload.auto) {
      payload.parent = '';
      payload.position[0] = 0;
      payload.position[1] = 0;

      return payload;
    }

    const start = {
      id: '0',
      role: 'mayor',
      type: 'default',
      parent: payload.parent,
      position: [
        payload.cursor?.[0] ?? 0,
        payload.cursor?.[1] ?? 0,
      ],
    };

    const end = {
      id: '1',
      role: 'mayor',
      type: 'default',
      parent: '',
      position: [
        payload.cursor?.[0] ?? 0,
        payload.cursor?.[1] ?? 0,
      ],
    };

    if (start.parent && payload.direction) {
      start.type = 'parent';
      start.position = payload.direction;
    }

    if (start.parent && !payload.direction) {
      start.type = 'join';
      start.position = [
        payload.position[0],
        payload.position[1],
      ];
    }

    payload.parent = '';
    payload.position[0] = 0;
    payload.position[1] = 0;

    payload.properties = {
      ...(payload.properties ?? {}),

      points: [
        start,
        end,
      ],
    };

    return payload;
  },
  dispatch: (dispatch, id) => {
    dispatch(storeGraphic.setDrop({
      id,
      handle: '1',
      block: '',
      type: 'move',
    }));
  },
});
