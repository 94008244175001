import React from 'react';
import { ITool } from 'tools';

import { storeDiagram } from 'store/diagram';

import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import icon from './assets/icon.svg';

const Tool = () => {
  const dispatch = useStoreDispatch();

  const selection = useStoreSelector((state) => state.graphic.selection.elements);
  const element = useStoreSelector((state) => state.diagram.file[selection[0]]);
  const shown = useStoreSelector((state) => {
    return selection.every((id) => {
      return state.diagram.file[id].points[state.diagram.file[id].points.length - 1]?.parent;
    });
  });
  const active = useStoreSelector((state) => {
    return selection.every((id) => {
      return (state.diagram.file[id].points || []).length > 2 || !state.diagram.file[id].points[0]?.parent || !state.diagram.file[id]?.freeFinish;
    });
  });

  const value = element.freeStart ?? false;

  const onClick = () => {
    if (active) {
      selection.forEach((id) => {
        dispatch(storeDiagram.setElement({
          id,
          properties: { freeStart: !value },
        }));
      });
    }
  };

  if (!shown) {
    return null;
  }

  return (
    <Hint message={`Center start${!active ? ' (add extra point)' : ''}`} side="bottom" variant="default">
      <button type="button" className={`button-icon v-1${!value ? ' active' : ''}${!active ? ' disabled' : ''}`} onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt="Center start" />
      </button>
    </Hint>
  );
};

export const LinePointCenteredStart: ITool = {
  key: 'line-point-center-start',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
