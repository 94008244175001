import React, { FC, ReactNode } from 'react';

import './assets/Info.css';

interface InfoProps {
  children: ReactNode;
  variant: 'default' | 'warning' | 'information';
  side: 'top' | 'bottom';
}

export const Info: FC<InfoProps> = ({ children, variant, side }) => {
  return (
    <div className={`info info-${variant} ${side}`}>
      {children}
    </div>
  );
};
