import React, { memo, useEffect } from 'react';
import { IBlock, IConfig } from 'blocks';
import 'external-svg-loader';

import { useOption } from 'hooks/useOption';
import { useResizer } from 'hooks/useResizer';
import { useProperty } from 'hooks/useProperty';
import { useStoreSelector } from 'hooks/useStore';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';
import { useClickOnSelection } from 'hooks/useClickOnSelection';
import { useArrangementWrapper } from 'hooks/useArrangementWrapper';
import { useTextCreatorWithWrapper } from 'hooks/useTextCreatorWithWrapper';

import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';
import design from 'blocks/factories/assets/Simple.module.css';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Graphics',
  search: 'general icon logo emoji sticker gif animation',
  type: 'general-icon',
  group: ['General'],
  memo: {
    key: 'general-icon',
    styles: [
      'color',
    ],
  },
  styles: [
    'color',
    'width',
    'height',
  ],
};

interface IModel {
  url: string;
  color: string;
  width: number;
  height: number;
  realWidth: number;
  realHeight: number;
  lockKey: string;
  lock: boolean;
}

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const exists = useStoreSelector((state) => !state.graphic.elements[element.id] && (!element.parent || !!state.graphic.elements[element.parent]));

    const loading = useProperty(exists);
    const failed = useProperty(false);

    const isIcon = properties.url.endsWith('.svg');

    const create = useTextCreatorWithWrapper();
    const arrangement = useArrangementWrapper();
    const resier = useResizer<HTMLImageElement>({
      strict: true,
      realWidth: properties.realWidth,
      realHeight: properties.realHeight,
      minWidth: 22,
      minHeight: 22,
    });

    useEnterOnSelection({
      onPress: create('enter'),
      mode: 'edit',
    });

    useClickOnSelection({
      onClick: create('click'),
      mode: 'edit',
    });

    useOption({
      id: element.id,
      key: 'color',
      value: !isIcon ? 'disable' : '',
      deps: [!isIcon],
    });

    // Wait for image to be loaded
    useEffect(() => {
      loading.set(true);

      const img = new Image();

      img.onload = () => {
        loading.set(false);
        failed.set(false);
      };

      img.onerror = (e) => {
        loading.set(false);
        failed.set(true);
      };

      img.src = properties.url;
    }, [properties.url]);

    return (
      <Shape
        classElement={style.element}
        classProperties={''.append(`${design[`color-${properties.color}`]}`)}
      >
        <Arrangement {...arrangement} />
        <Selection status={!resier.status} />

        <div
          className={
            `block-content ${style.image}`
              .appendWhen(loading.value, 'unit-loading')
              .appendWhen(!properties.url && failed.value, 'unit-failed')
          }
          {...element.trigger}
          {...resier.wrap}
        >
          {isIcon ? (
            <svg data-src={properties.url} />
          ) : (
            <img src={properties.url} alt="" />
          )}
        </div>

        <Resizer.Size {...resier.attributes} status={resier.visibility} symmetrically />
      </Shape>
    );
  },
});

export const GeneralIcon : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-wrap', 'element-wrapable'],
    constraints: {},
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        url: '',
        color: 'dark',
        height: 48,
        width: 48,
        realHeight: 48,
        realWidth: 48,
        lockKey: '',
        lock: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [48, 48],
    icon,
    class: {
      title: 'Icon',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'icon',
  },
  tools: [
    'icon',
    'color',
    'lock',
    'delete',
  ],
  toolbars: [

  ],
};
