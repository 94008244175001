import { ITool } from 'tools';
import { createTool } from 'tools/ToolLink';

import icon from './assets/icon.svg';

const Tool = createTool('text', 'Text', 'Text', icon);

export const LinkText: ITool = {
  key: 'link-text',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
