import { ITool } from 'tools';
import { createTool } from 'tools/ToolBasic';

import icon from './assets/icon.svg';

const Tool = createTool({
  key: 'fill',
  def: false,
  title: 'Fill',
  alt: 'Fill',
  icon,
});

export const Fill: ITool = {
  key: 'fill',
  tool: Tool,
  options: ['only-edit'],
};
