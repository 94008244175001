import { useContext, useMemo } from 'react';
import { renderPathDefault, renderPathSmooth } from 'utils/graphic';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';

import { ILever } from './useConnectorSmooth';
import { useStoreSelector } from './useStore';

export const useConnectorStyle = (points: number[][], levers: ILever[], position: number[], padding: number[]) => {
  const element = useContext(ElementContext);

  const smooth = useStoreSelector((state) => state.diagram.file[element.id].style === 'smooth');

  const verticies = useMemo(() => {
    return points.map((point) => ([
      point[0] - position[0] + padding[0],
      point[1] - position[1] + padding[1],
    ]));
  }, [points, position[0], position[1]]);

  const handles = useMemo(() => {
    return levers.map((lever) => ({
      left: [
        lever.left[0] - position[0] + padding[0],
        lever.left[1] - position[1] + padding[1],
      ],
      right: [
        lever.right[0] - position[0] + padding[0],
        lever.right[1] - position[1] + padding[1],
      ],
    }));
  }, [levers, position[0], position[1]]);

  return useMemo(() => {
    if (smooth) {
      return renderPathSmooth(verticies, handles);
    }

    return renderPathDefault(verticies);
  }, [verticies, handles, smooth]);
};
