import { useContext, useMemo } from 'react';
import { getCacheProperty, getState } from 'utils/store';

import { ElementContext } from 'elements/Block/Element';

import { useOption } from './useOption';
import { useStoreSelector } from './useStore';

export const useEmpty = (elements: string[]) => {
  const element = useContext(ElementContext);

  const empty = useStoreSelector((state) => {
    return elements.every((id) => {
      return state.graphic.options[id]?.closed;
    });
  });

  useOption({
    id: element.id,
    key: 'closed',
    value: empty,
    deps: [empty],
  });

  return empty;
};
