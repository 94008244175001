export const storage = {
  getItem: (key: string) => {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return undefined;
    }
  },
  setItem: (key: string, value: string) => {
    try {
      return localStorage.setItem(key, value);
    } catch (e) {
      return undefined;
    }
  },
  removeItem: (key: string) => {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      return undefined;
    }
  },
};
