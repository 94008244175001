import React, { useEffect, useRef, useState } from 'react';
import { Emitter } from 'utils/events';

import { useProperty } from 'hooks/useProperty';

import { Hint } from 'components/Hint';
import { useStoreSelector } from 'hooks/useStore';

interface IFormatTool {
  format: string;
  title: string;
  icon: string;
  condition?: (value: any) => boolean;
  filter?: (value: any) => boolean;
  set?: (status: boolean) => any;
}

export const createFormatTool = ({
  format,
  title,
  icon,
  condition = (value) => !!value,
  filter = () => true,
  set = (status) => !status,
}: IFormatTool) => () => {
  const selection = useStoreSelector((state) => state.graphic.selection.elements.length === 1);
  const [status, setStatus] = useState(() => {
    try {
      return condition(((window as any).editor?.getFormat() || {})[format]);
    } catch (e) {
      return condition(undefined);
    }
  });
  const visible = useProperty(!!(window as any).editor || selection);
  const empty = useRef(true);

  const onClick = () => {
    if (empty) {
      setStatus((status) => !!set(status));
    }

    Emitter.emit('selection-format', { [format]: set(status) });
  };

  // Listen to Editor Change
  useEffect(() => {
    const onChange = () => {
      visible.set(!!(window as any).editor || selection);
    };

    Emitter.on('editor-change', onChange);

    return () => {
      Emitter.off('editor-change', onChange);
    };
  }, []);

  // Listen to Selection Change
  useEffect(() => {
    const onSelect = (payload: {[key: string]: boolean}) => {
      setStatus(condition(payload[format]));
      empty.current = !(payload.length || 0);
    };

    Emitter.on('selection-change', onSelect);

    return () => {
      Emitter.off('selection-change', onSelect);
    };
  }, []);

  // Listen to Format Emit
  useEffect(() => {
    const onEmit = (payload: any) => {
      if (payload.format === format && filter(payload.value)) {
        onClick();
      }
    };

    Emitter.on('format-emit', onEmit);

    return () => {
      Emitter.off('format-emit', onEmit);
    };
  }, [status]);

  return (
    <Hint message={title} disabled={!visible.value} side="bottom" variant="default">
      <button type="button" className={`button-icon v-1${!visible.value ? ' button-disabled' : ''}${status ? ' active' : ''}`} onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt={title} />
      </button>
    </Hint>
  );
};
