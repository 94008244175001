import React, { useContext, useMemo } from 'react';
import { getCacheProperty, getState } from 'utils/store';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';

export const useConnectorInvisible = () => {
  const element = useContext(ElementContext);

  const points = useStoreSelector((state) => state.diagram.file[element.id].points as IPoint[]);
  const options = useStoreSelector((state) => state.graphic.options);

  return useMemo(() => {
    return !points.every((point) => !point.parent) && points.every((point) => {
      if (!point.parent) {
        return true;
      }

      return options[point.parent]?.invisible;
    });
  }, [points, options]);
};
