import React, { FC, useContext } from 'react';

import { useStoreSelector } from 'hooks/useStore';

import { ElementContext } from 'elements/Block/Element';

import { View } from './View';
import { Edit } from './Edit';

import style from '../../assets/style.module.css';

export const config = {
  key: 'text',
  effect: [
    'uml-class-interface-multiplicity',
    'uml-class-class-multiplicity',
    'uml-class-interface',
    'uml-class-class',
  ],
};

export interface IComponent {
  value: string;
  property: string;
}

const Component: FC<IComponent> = ({ value, property }) => {
  const element = useContext(ElementContext);

  const editing = useStoreSelector((state) => !(state.diagram.file[element.id]?.lock || state.graphic.options[element.id]?.invisible) && state.diagram.mode === 'edit' && state.graphic.component.key === config.key);

  return (
    <div className={style.name}>
      {element.selected && editing ? (
        <Edit value={value} property={property} />
      ) : (
        <View value={value} property={property} />
      )}
    </div>
  );
};

export const Text = {
  Component,
  toolbar: {
    key: config.key,
    tools: [
      'link-back',
      'safe',
      'format-link',
      'format-highlight-common',
      'format-bold',
      'format-italic',
      'format-underline',
      'format-strike',
    ],
  },
};
