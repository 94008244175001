import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import iconSolid from './assets/solid.svg';
import iconDashed from './assets/dashed.svg';
import iconDotted from './assets/dotted.svg';

export const Tool = createTool('line-stroke', 'stroke', 'solid', 'Style', 'Style', {
  solid: iconSolid,
  dashed: iconDashed,
  dotted: iconDotted,
});

export const LineStroke: ITool = {
  key: 'line-stroke',
  tool: Tool,
  options: ['only-edit'],
};
