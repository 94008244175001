import React from 'react';

import { useAuth } from 'hooks/useAuth';

import { Auth } from 'elements/Auth';

export default () => {
  const auth = useAuth();

  return <Auth status={auth} />;
};
