import React, { FC, ReactNode, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';

import { usePopup } from 'hooks/usePopup';
import { useStoreSelector } from 'hooks/useStore';

import icon from 'assets/icons/question.svg';

import { Info } from './Info';
import { Wrap } from './Wrap';
import { Modal } from './Modal';

import './assets/Hint.css';

interface HintProps {
  children: ReactNode;
  variant: 'default' | 'warning' | 'information';
  message: string;
  side: 'top' | 'bottom';
  className?: string;
  disabled?: boolean;
  Help?: React.ComponentType;
}

const root = document.getElementById('tooltip-root') as HTMLElement;

export const Hint: FC<HintProps> = ({ children, variant, className, message, side, disabled, Help }) => {
  const embed = useStoreSelector((state) => !!state.diagram.embed);

  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const popper = useRef(false);

  const popup = usePopup();

  const { styles, attributes } = usePopper(tooltipElement, popperElement, {
    placement: side,
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: [],
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, Help ? 0 : 8],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 10,
        },
      },
    ],
  });

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    if (!Help || !popper.current) {
      setIsVisible(false);
    }
  };

  const handlePopperEnter = () => {
    popper.current = true;
  };

  const handlePopperExit = () => {
    popper.current = false;
  };

  const onHelp = () => {
    if (Help) {
      popup.open();
    }
  };

  if (disabled || embed) {
    return (
      <div>
        {children}
      </div>
    );
  }

  return (
    <>
      <div ref={setTooltipElement} className={'hint'.append(className)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
        {isVisible && ReactDOM.createPortal(
          (
            <div ref={setPopperElement} onMouseEnter={Help ? handlePopperEnter : undefined} onMouseLeave={Help ? handlePopperExit : undefined} style={{ ...styles.popper, paddingTop: (Help && side === 'bottom') ? '8px' : '', paddingBottom: (Help && side === 'top') ? '8px' : '', pointerEvents: Help ? 'all' : 'none', ['--left' as string]: styles.arrow.transform?.split('(')[1].split(',')[0] }} {...attributes.popper}>
              <div ref={setArrowElement} style={styles.arrow} />
              <Info side={side} variant={variant}>
                <Wrap type="text" variant="big" className={Help ? 'hint-flex' : ''}>
                  <div className="text-16">{message}</div>
                  {Help && <button type="button" onMouseDown={(e) => e.stopPropagation()} onClick={onHelp} className="button-help"><img src={icon} width={14} height={14} alt="Help" /></button>}
                </Wrap>
              </Info>
            </div>
          ),
          root
        )}
      </div>
      {Help && popup.status && (
        <Modal variant="v-3" onBackdropClick={popup.close}>
          <Help />
        </Modal>
      )}
    </>
  );
};
