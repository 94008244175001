import React from 'react';

import { storeGraphic } from 'store/graphic';

import { useStoreDispatch } from 'hooks/useStore';

import { Hint } from 'components/Hint';

export const createTool = (key: string, title: string, alt: string, icon: string) => () => {
  const dispatch = useStoreDispatch();

  const onClick = () => {
    dispatch(storeGraphic.setComponentKey(key));
  };

  return (
    <Hint message={title} side="bottom" variant="default">
      <button type="button" className="button-icon v-1" onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt={alt} />
      </button>
    </Hint>
  );
};
