import axios from 'axios';
import security from 'utils/security';

import { getLink } from 'hooks/useAuth';

export const host = 'https://exemplar.me';
export const domain = 'https://app.exemplar.me';
export const url = `${domain}/api`;

export const api = axios.create({
  baseURL: url,
});

export const session = {
  refresh: async () => {
    const session = security.getSession();

    if (!session.refresh) {
      throw new Error('Token is not exist');
    }

    const response = await api.post('/users/refresh', {
      token: session.refresh,
    });

    session.access = response.data.access;
    session.refresh = response.data.refresh;

    security.setSession({
      access: response.data.access,
      refresh: response.data.refresh,
    });
  },
  remove: () => {
    security.removeSession();

    window.location.href = getLink('unauthorized', true);
  },
};

api.interceptors.request.use((config) => {
  const session = security.getSession();

  if (config.headers && session) {
    config.headers.authorization = `Bearer ${session.access}`;
  }

  return config;
});

api.interceptors.response.use((config) => {
  return config;
}, async (e) => {
  if (e.response?.status === 401) {
    const origin = e.config;

    try {
      await session.refresh();

      return api.request(origin);
    } catch (e: any) {
      if (e?.response?.status !== 0) {
        session.remove();

        throw new Error('');
      }
    }
  }

  throw e;
});
