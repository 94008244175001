import React, { useEffect, useState } from 'react';
import { useStoreSelector } from './useStore';

interface IHotkey {
  key?: string,
  code?: number,
  ctrl?: boolean,
  shift?: boolean,
  meta?: boolean,
  alt?: boolean,
  prevent?: boolean,
  isActive?: () => boolean,
  deps?: React.DependencyList,
  onPress?: () => void,
}

export const useHotkey = ({
  key,
  code,
  alt,
  ctrl,
  shift,
  meta,
  prevent,
  onPress,
  isActive = () => true,
  deps = [],
} : IHotkey) => {
  const pause = useStoreSelector((state) => state.settings.pause);

  const [pressed, setPressed] = useState(false);

  const onKeyDown = (e: any) => {
    if (pause) return;

    if (e.target.tagName.toLowerCase() === 'input' || e.target.tagName.toLowerCase() === 'textarea') return;

    if (ctrl !== undefined && ctrl !== e.ctrlKey) return;
    if (shift !== undefined && shift !== e.shiftKey) return;
    if (meta !== undefined && meta !== e.metaKey) return;
    if (alt !== undefined && alt !== e.altKey) return;

    if (((key || code) && e.code !== key && e.key !== key && e.keyCode !== code) || !isActive()) {
      return;
    }

    if (prevent) {
      e.preventDefault();
    }

    if (onPress) {
      onPress();
    } else {
      setPressed(true);
    }
  };

  const onKeyUp = (e: any) => {
    if (ctrl && e.ctrlKey) return;
    if (shift && e.shiftKey) return;
    if (meta && e.metaKey) return;
    if (alt && e.altKey) return;

    if ((key || code) && e.code !== key && e.key !== key && e.keyCode !== code && (shift !== e.shiftKey || e.key !== 'Meta')) {
      return;
    }

    if (prevent) {
      e.preventDefault();
    }

    setPressed(false);
  };

  const onBlur = () => {
    setPressed(false);
  };

  useEffect(() => {
    if (pressed) {
      window.addEventListener('keyup', onKeyUp);
      window.addEventListener('blur', onBlur);

      return () => {
        window.removeEventListener('keyup', onKeyUp);
        window.removeEventListener('blur', onBlur);
      };
    }

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [pressed, pause, ...deps]);

  return pressed;
};
