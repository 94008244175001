import React from 'react';
import { ITool } from 'tools';

import { createFormatTool } from 'tools/ToolFormat';

import icon from './assets/icon.svg';

const Tool = createFormatTool({
  icon,
  title: 'Code-block',
  format: 'code-block',
});

export const FormatCodeBlock: ITool = {
  key: 'format-code-block',
  tool: Tool,
  options: ['only-edit'],
};
