import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { IConnector } from './Cap';

export const Cap0: IConnector = {
  Start: ({ color }: ICap) => null,
  End: ({ color }: ICap) => null,
};
