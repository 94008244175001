import { IType, blocks } from 'blocks';

import { storeGraphic } from 'store/graphic';

import { useStoreDispatch, useStoreSelector } from './useStore';

export const useBlockButton = <T extends {} = {}>(className: string, onAction: (payload?: T) => void = () => {}) => {
  const mobile = useStoreSelector((state) => state.settings.mobile);

  const dispatch = useStoreDispatch();

  return (element: string, button?: IType, payload?: T) => {
    const type = button ?? blocks[element]?.button.type;

    if (type === 'drag') {
      if (mobile) {
        return { className };
      }

      const size = [...blocks[element].element.size] as [number, number];

      if (payload && 'width' in payload) size[0] = +(payload as any).width;
      if (payload && 'height' in payload) size[1] = +(payload as any).height;

      return {
        className,
        Help: blocks[element]?.element?.Help,
        onMouseDown: (e: any) => {
          if (e.button !== 0) {
            return;
          }

          onAction(payload);

          dispatch(storeGraphic.setDrag({
            type: 'create',
            path: [],
            childs: [],
            element: {
              id: '',
              type: element,
            },
            frame: {
              size,
              offset: [0, 0],
            },
          }));
        },
      };
    }

    return {
      className,
      Help: blocks[element]?.element?.Help,
      onClick: (e: any) => {
        if (e.button !== 0) {
          return;
        }

        onAction(payload);

        dispatch(storeGraphic.setComponentTab(type));
      },
    };
  };
};
