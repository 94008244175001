import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import icon0 from './assets/0.svg';
import icon1 from './assets/1.svg';
import icon2 from './assets/2.svg';
import icon3 from './assets/3.svg';
import icon4 from './assets/4.svg';
import icon5 from './assets/5.svg';
import icon6 from './assets/6.svg';
import icon7 from './assets/7.svg';
import icon8 from './assets/8.svg';
import icon9 from './assets/9.svg';

const Tool = createTool('render', 'render', 0, 'Style', 'Style', {
  0: icon0,
  1: icon1,
  2: icon2,
  3: icon3,
  4: icon4,
  5: icon5,
  9: icon9,
  6: icon6,
  7: icon7,
  8: icon8,
});

export const Render: ITool = {
  key: 'render',
  tool: Tool,
  options: ['only-edit'],
};
