import { useContext, useMemo } from 'react';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';
import { useStoreSelector } from './useStore';

export const useConnectorDefault = (points: IPoint[]) => {
  const element = useContext(ElementContext);

  const style = useStoreSelector((state) => state.diagram.file[element.id].style);

  return useMemo(() => {
    return points.map((point) => {
      return point.position;
    });
  }, [points, style]);
};
