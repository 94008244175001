import React from 'react';
import { ITool } from 'tools';

import { createFormatTool } from 'tools/ToolFormat';

import icon from './assets/icon.svg';

const Tool = createFormatTool({
  icon,
  title: 'Numbered list',
  format: 'list',
  condition: (value) => value === 'ordered',
  filter: (value) => value === 'ordered',
  set: (status) => (status ? '' : 'ordered'),
});

export const FormatListNumber: ITool = {
  key: 'format-list-number',
  tool: Tool,
  options: ['only-edit'],
};
