import React, { createContext, FC, useEffect, useMemo, useRef } from 'react';
import { useGesture } from '@use-gesture/react';
import { Emitter } from 'utils/events';
import { points } from 'utils/points';
import { blocks } from 'blocks';

import { storeGraphic } from 'store/graphic';

import { usePhase } from 'hooks/usePhase';
import { useHotkey } from 'hooks/useHotkey';
import { useElement } from 'hooks/useElement';
import { useProperty } from 'hooks/useProperty';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Pointer } from 'elements/Pointer';

interface ICanvas {
  children: React.ReactNode;
}

export interface IStaticContext {
  loading: boolean;
}

export interface IDynamicContext {
  position: [number, number];
  screen: [number, number];
  zoom: number;
  top: number;
  cursor: [number, number];
  space: boolean;
  element: HTMLDivElement | null;
}

export const CanvasStaticContext = createContext<IStaticContext>({
  loading: true,
});

export const CanvasDynamicContext = createContext<IDynamicContext>({
  position: [0, 0],
  screen: [0, 0],
  zoom: 1,
  top: 1,
  cursor: [0, 0],
  space: false,
  element: null,
});

export const Canvas: FC<ICanvas> = ({ children }) => {
  const dispatch = useStoreDispatch();

  const mobile = useStoreSelector((state) => state.settings.mobile);

  const file = useStoreSelector((state) => state.diagram.file);
  const mode = useStoreSelector((state) => state.diagram.mode);
  const elements = useStoreSelector((state) => state.graphic.elements);

  const component = useStoreSelector((state) => !!state.graphic.component.key);
  const stretch = useStoreSelector((state) => state.graphic.stretch);
  const selection = useStoreSelector((state) => state.graphic.selection.elements);
  const drag = useStoreSelector((state) => !!state.graphic.drag);
  const drop = useStoreSelector((state) => !!state.graphic.drop);
  const embed = useStoreSelector((state) => !!state.diagram.embed);
  const droping = useStoreSelector((state) => state.graphic.drop?.type === 'move');

  const element = useElement<HTMLDivElement>();

  const grabbing = useHotkey({ code: 32, isActive: () => !component && !drag && !drop, deps: [component, !drag, !drop] });
  const space = useHotkey({ code: 32, isActive: () => !component, deps: [component] });

  const loading = useProperty(true);
  const ready = useProperty(false);
  const dragging = useProperty(false);

  const position = useProperty([0, 0]);
  const cursor = useRef([0, 0]);
  const bounds = useProperty<number[]>([]);
  const screen = useProperty([0, 0]);
  const zoom = useProperty(1);
  const scale = useProperty(1);
  const focus = useProperty<string[] | null>(null);

  const shapes = points.shapes;

  const contextStatic = useMemo(() => {
    return {
      loading: !ready.value,
    };
  }, [ready.value]);

  const contextDynamic = useRef<IDynamicContext>({
    position: [0, 0],
    screen: [0, 0],
    cursor: [0, 0],
    zoom: 1,
    top: 1,
    space: false,
    element: null,
  });

  contextDynamic.current.position = position.value as [number, number];
  contextDynamic.current.screen = screen.value as [number, number];
  contextDynamic.current.cursor = cursor.current as [number, number];
  contextDynamic.current.zoom = zoom.value;
  contextDynamic.current.top = (embed ? 0 : 55);
  contextDynamic.current.space = space;
  contextDynamic.current.element = element.ref;

  const setScreen = () => {
    screen.set([window.innerWidth, window.innerHeight - 55]);
  };

  const setBounds = () => {
    const frame = [0, 0, 0, 0];

    Object.keys(shapes).forEach((id, i) => shapes[id].points.forEach((point, j) => {
      if (i === 0 && j === 0) {
        frame[0] = point[0];
        frame[1] = point[1];
        frame[2] = point[0];
        frame[3] = point[1];

        return;
      }

      frame[0] = Math.min(frame[0], point[0]);
      frame[1] = Math.min(frame[1], point[1]);
      frame[2] = Math.max(frame[2], point[0]);
      frame[3] = Math.max(frame[3], point[1]);
    }));

    bounds.set(frame);
  };

  const onDrag = (delta: number[]) => {
    if (drag || droping || stretch) {
      return;
    }

    position.set((position) => {
      const value = [
        position[0] + delta[0],
        position[1] + delta[1],
      ];

      const frame = [
        bounds.value[0] - ((screen.value[0] / (mode === 'view' ? 4 : 1)) / zoom.value / 2 + 2),
        bounds.value[1] - ((screen.value[1] / (mode === 'view' ? 4 : 1)) / zoom.value / 2 + 2),
        bounds.value[2] + ((screen.value[0] / (mode === 'view' ? 4 : 1)) / zoom.value / 2 + 2),
        bounds.value[3] + ((screen.value[1] / (mode === 'view' ? 4 : 1)) / zoom.value / 2 + 2),
      ];

      if (delta[0] < 0 && value[0] < -frame[2]) value[0] = position[0] > -frame[2] ? -frame[2] : position[0];
      if (delta[0] > 0 && value[0] > -frame[0]) value[0] = position[0] < -frame[0] ? -frame[0] : position[0];
      if (delta[1] < 0 && value[1] < -frame[3]) value[1] = position[1] > -frame[3] ? -frame[3] : position[1];
      if (delta[1] > 0 && value[1] > -frame[1]) value[1] = position[1] < -frame[1] ? -frame[1] : position[1];

      return value;
    });
  };

  const onZoom = (delta: number, cursor?: number[]) => {
    if (drag || droping || stretch) {
      return;
    }

    zoom.set((zoom) => {
      let value = zoom + delta;

      if (delta > 0) {
        if (zoom > 2) {
          value = zoom;
        } else if (value > 2) {
          value = 2;
        }
      }

      if (delta < 0) {
        if (zoom < scale.value) {
          value = zoom;
        } else if (value < scale.value) {
          value = scale.value;
        }
      }

      if (cursor) {
        const shift = [cursor[0] - screen.value[0] / 2, (cursor[1] - 55) - screen.value[1] / 2];
        const ratio = delta / (value * zoom);

        onDrag([-shift[0] * ratio, -shift[1] * ratio]);
      }

      return value;
    });
  };

  const gesture = useGesture({
    onDragStart: () => {
      dragging.set(true);
    },
    onDrag: (state) => {
      onDrag([state.delta[0] / zoom.value, state.delta[1] / zoom.value]);
    },
    onDragEnd: () => {
      dragging.set(false);
    },
    onWheel: (state) => {
      if (state.ctrlKey || embed) {
        return;
      }

      onDrag([-state.delta[0] / zoom.value, -state.delta[1] / zoom.value]);
    },
    onPinch: (state) => {
      onZoom(state.delta[0], [(state.event as any).clientX, (state.event as any).clientY]);
    },
  }, {
    drag: {
      preventDefault: !mobile,
      pointer: {
        mouse: true,
        touch: true,
        buttons: (grabbing && !component) ? [1, 4] : 4,
        keys: false,
      },
      eventOptions: {
        passive: false,
      },
    },
    pinch: {
      scaleBounds: { min: Math.min(scale.value, zoom.value), max: 2 },
      from: [zoom.value, 0],
      pinchOnWheel: true,
      eventOptions: {
        passive: false,
      },
    },
  });

  const opening = usePhase({
    producers: [focus.value],
    handler: () => {
      loading.set(false);
    },
  });

  const selecting = usePhase({
    producers: [scale.value],
    handler: () => {
      const selected = selection.filter((id) => (id in file && (mode !== 'view' || !blocks[file[id]?.type]?.element.options.includes('no-selection-view-mode'))));

      if (selection.length !== selected.length) {
        dispatch(storeGraphic.setSelectionElements(selected));
      }

      focus.set(selected);

      opening.run();
    },
  });

  const linking = usePhase({
    producers: [],
    handler: () => {
      setBounds();

      selecting.run();
    },
  });

  const appearence = usePhase({
    producers: [elements],
    handler: () => {
      ready.set(true);

      linking.run();
    },
  });

  const initialization = usePhase({
    handler: () => {
      setScreen();

      appearence.run();
    },
  });

  useHotkey({ code: 187, ctrl: true, prevent: true, onPress: () => onZoom(0.1), deps: [drag || droping || stretch, scale.value] });
  useHotkey({ code: 187, meta: true, prevent: true, onPress: () => onZoom(0.1), deps: [drag || droping || stretch, scale.value] });
  useHotkey({ code: 189, ctrl: true, prevent: true, onPress: () => onZoom(-0.1), deps: [drag || droping || stretch, scale.value] });
  useHotkey({ code: 189, meta: true, prevent: true, onPress: () => onZoom(-0.1), deps: [drag || droping || stretch, scale.value] });
  useHotkey({ code: 48, ctrl: true, prevent: true, onPress: () => onZoom(1 - zoom.value), deps: [drag || droping || stretch, scale.value, zoom.value] });
  useHotkey({ code: 48, meta: true, prevent: true, onPress: () => onZoom(1 - zoom.value), deps: [drag || droping || stretch, scale.value, zoom.value] });

  // Set Zoom Limit
  useEffect(() => {
    if (bounds.value.length > 0) {
      const width = bounds.value[2] - bounds.value[0] + 80;
      const height = bounds.value[3] - bounds.value[1] + 80;

      scale.set(Math.min(1, Math.max(Math.min(screen.value[0] / width, screen.value[1] / height), 0.3)));
    }
  }, [bounds.value[0], bounds.value[1], bounds.value[2], bounds.value[3], bounds.value[4], screen.value[0], screen.value[1]]);

  // Set Screen Size on Resize
  useEffect(() => {
    const onResize = () => {
      setScreen();
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.addEventListener('resize', onResize);
    };
  }, []);

  // Track Mouse Position
  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      cursor.current[0] = e.clientX;
      cursor.current[1] = e.clientY;

      if (!mobile) {
        Emitter.emit('cursor', [
          Math.round(-contextDynamic.current.position[0] + (contextDynamic.current.cursor[0] - contextDynamic.current.screen[0] / 2) / contextDynamic.current.zoom),
          Math.round(-contextDynamic.current.position[1] + (contextDynamic.current.cursor[1] - contextDynamic.current.screen[1] / 2 - contextDynamic.current.top) / contextDynamic.current.zoom),
        ]);
      }
    };

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [mobile]);

  // Track Screen Position
  useEffect(() => {
    if (mobile) {
      Emitter.emit('cursor', [
        -position.value[0],
        -position.value[1],
      ]);
    }
  }, [mobile && position.value[0], mobile && position.value[1]]);

  // Prevent from Scrolling
  useEffect(() => {
    if (embed) {
      return () => {};
    }

    const wrap = document.querySelector('main');

    const onScroll = () => {
      wrap?.scroll(0, 0);
    };

    wrap?.addEventListener('scroll', onScroll);

    return () => {
      wrap?.removeEventListener('scroll', onScroll);
    };
  }, [embed]);

  // Prevent Default Browser Behaviour
  useEffect(() => {
    const onPrevent = (e: any) => {
      if (embed && !e.ctrlKey) {
        return;
      }

      e.preventDefault();
    };

    const main = document.getElementsByTagName('main')[0];

    main.addEventListener('wheel', onPrevent, { passive: false });
    main.addEventListener('mousewheel', onPrevent, { passive: false });

    return () => {
      main.removeEventListener('wheel', onPrevent);
      main.removeEventListener('mousewheel', onPrevent);
    };
  }, [embed]);

  // Set Global Zoom
  useEffect(() => {
    dispatch(storeGraphic.setZoomValue(zoom.value));
  }, [zoom.value]);

  // Set Global Zoom Limit
  useEffect(() => {
    dispatch(storeGraphic.setZoomLimit(scale.value));
  }, [scale.value]);

  // Listen to Zoom-To
  useEffect(() => {
    const setZoom = (payload: any) => {
      if (payload.value === 'selection') {
        focus.set([...selection]);
        return;
      }

      if (payload.value === 'content') {
        focus.set([]);
        return;
      }

      if (typeof payload.value === 'number') {
        onZoom(payload.value - zoom.value);
      }
    };

    Emitter.on('zoom-to', setZoom);

    return () => {
      Emitter.off('zoom-to', setZoom);
    };
  }, [zoom.value, selection]);

  // Listen to Go-To
  useEffect(() => {
    const setPosition = (center: number[]) => {
      onDrag([
        -(center[0] + contextDynamic.current.position[0]),
        -(center[1] + contextDynamic.current.position[1]),
      ]);
    };

    Emitter.on('go-to', setPosition);

    return () => {
      Emitter.off('go-to', setPosition);
    };
  }, []);

  // Set Position to Focus
  useEffect(() => {
    if (!focus.value || bounds.value.length !== 4) {
      return;
    }

    const frame = [0, 0, 0, 0];

    (focus.value.length === 0 ? Object.keys(shapes) : focus.value).forEach((id, i) => shapes[id]?.points?.forEach((point, j) => {
      if (i === 0 && j === 0) {
        frame[0] = point[0];
        frame[1] = point[1];
        frame[2] = point[0];
        frame[3] = point[1];

        return;
      }

      frame[0] = Math.min(frame[0], point[0]);
      frame[1] = Math.min(frame[1], point[1]);
      frame[2] = Math.max(frame[2], point[0]);
      frame[3] = Math.max(frame[3], point[1]);
    }));

    const size = [
      frame[2] - frame[0],
      frame[3] - frame[1],
    ];

    const center = [
      frame[0] + size[0] / 2,
      frame[1] + size[1] / 2,
    ];

    onDrag([
      -(center[0] + position.value[0]),
      -(center[1] + position.value[1]),
    ]);

    if (focus.value.length > 0) {
      onZoom(Math.min(1, Math.max(Math.min(screen.value[0] / (size[0] + 80), screen.value[1] / (size[1] + 80)), 0.3)) - zoom.value);
    }

    focus.set(null);
  }, [focus.value, bounds.value.length !== 4]);

  // Wait for Canvas to be Loaded
  useEffect(() => {
    if (element.ref) {
      initialization.run();
    }
  }, [element.ref]);

  useEffect(() => {
    if (!loading.value) {
      setBounds();
    }
  }, [elements, file]);

  useEffect(() => {
    dispatch(storeGraphic.setGrabbingStatus(grabbing || dragging.value));
  }, [grabbing || dragging.value]);

  useEffect(() => {
    if (loading.value) {
      return () => {};
    }

    const onSetBounds = () => {
      setBounds();
    };

    Emitter.on('set-bounds', onSetBounds);

    return () => {
      Emitter.off('set-bounds', onSetBounds);
    };
  }, [loading.value]);

  const canvas = [
    bounds.value[0] - ((screen.value[0] / (mode === 'view' ? 4 : 1)) / zoom.value) - 4,
    bounds.value[1] - ((screen.value[1] / (mode === 'view' ? 4 : 1)) / zoom.value) - 4,
    -(bounds.value[2] + ((screen.value[0] / (mode === 'view' ? 4 : 1)) / zoom.value)) - 4,
    -(bounds.value[3] + ((screen.value[1] / (mode === 'view' ? 4 : 1)) / zoom.value)) - 4,
  ];

  const frame = [
    -position.value[0] - ((screen.value[0] / (mode === 'view' ? 1 : 1)) / zoom.value / 2),
    -position.value[1] - ((screen.value[1] / (mode === 'view' ? 1 : 1)) / zoom.value / 2),
    -(-position.value[0] + ((screen.value[0] / (mode === 'view' ? 1 : 1)) / zoom.value / 2)),
    -(-position.value[1] + ((screen.value[1] / (mode === 'view' ? 1 : 1)) / zoom.value / 2)),
  ];

  return (
    <div
      {...gesture()}
      ref={element.link}
      className={
        'diagram-window-wrap'
          .appendWhen(loading.value, 'diagram-loading')
          .appendWhen(grabbing, 'diagram-grabbing')
          .appendWhen(dragging.value || stretch, 'diagram-dragging')
      }
    >
      <CanvasDynamicContext.Provider value={contextDynamic.current}>
        <CanvasStaticContext.Provider value={contextStatic}>
          <div
            className="diagram-window"
            style={{
              ['--grid' as any]: zoom.value <= 0.5 ? 2 : 1,
              ['--zoom' as any]: zoom.value >= 1 ? zoom.value : 0.6 * (zoom.value - 1) + 1,
              ['--zoom-real' as any]: zoom.value,
              ['--canvas-left' as any]: `${Math.min(canvas[0], frame[0])}px`,
              ['--canvas-top' as any]: `${Math.min(canvas[1], frame[1])}px`,
              ['--canvas-right' as any]: `${Math.min(canvas[2], frame[2])}px`,
              ['--canvas-bottom' as any]: `${Math.min(canvas[3], frame[3])}px`,
              transform: `scale(${zoom.value}) translate3d(${position.value[0]}px, ${position.value[1]}px, 0)`,
            }}
          >
            {children}
          </div>
          <Pointer />
        </CanvasStaticContext.Provider>
      </CanvasDynamicContext.Provider>
    </div>
  );
};
