import React, { useContext } from 'react';
import { getMemoStyle } from 'utils/store';
import { blocks } from 'blocks';

import { ElementContext } from 'elements/Block/Element';
import { IArrangement, IPoint } from 'elements/Block/Arrangement';

import { useStoreSelector } from './useStore';
import { useDropFreely } from './useDropFreely';
import { useDropSnaply } from './useDropSnaply';
import { useDragBlock } from './useDragBlock';

export const useArrangementBase = (): IArrangement => {
  const element = useContext(ElementContext);

  const drop = useStoreSelector((state) => !!state.graphic.drop);
  const drag = useStoreSelector((state) => !!state.graphic.drag);
  const copy = useStoreSelector((state) => state.graphic.copy);
  const layout = useStoreSelector((state) => state.graphic.layout.status);
  const lock = useStoreSelector((state) => state.diagram.file[element.id]?.lock || !!state.diagram.captured[element.id]);
  const free = useStoreSelector((state) => (state.graphic.drop && state.graphic.layout.status) || (blocks[state.graphic.drag?.element.type ?? '_']?.element.options.includes('parent-free')) || (!!state.graphic.drop?.handle && blocks[state.diagram.file[state.graphic.drop.id]?.type]?.element.constraints.free?.handle === state.graphic.drop.handle));
  const create = useStoreSelector((state) => state.graphic.drag?.type === 'create' || state.graphic.copy);
  const container = useStoreSelector((state) => blocks[state.graphic.drag?.element.type ?? '']?.element.options.includes('element-container'));
  const replacing = useStoreSelector((state) => (
    !blocks[state.diagram.file[element.id]?.type]?.element.options.includes('container-title-none')
    || !blocks[state.diagram.file[state.diagram.file[state.graphic.drag?.element.id ?? '']?.parent]?.type]?.element.options.includes('element-container')
  ));
  const centered = useStoreSelector((state) => (
    blocks[state.graphic.drop?.block ?? '']?.element.options.includes('connector-centered')
    && (
      (state.graphic.drop?.id && state.diagram.file[state.graphic.drop.id]?.style !== 'elbow')
      || (getMemoStyle(blocks[state.graphic.drop?.block ?? '_']?.element.memo ?? '', 'style') !== 'elbow')
    )
  ));

  const freely = useDropFreely();
  const snaply = useDropSnaply();
  const block = useDragBlock();

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>, direction: number[]) => {
    if (e.button !== 0) {
      return;
    }

    if (free) {
      freely.onDropDown(e);
    } else if (drop) {
      snaply.onDropDown(e, direction);
    }
  };

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement>, direction: number[]) => {
    if (free) {
      if (drop) {
        freely.onDropUp(e);
      } else if (drag) {
        freely.onDragUp(e);
      }
    } else if (drop) {
      snaply.onDropUp(e, direction);
    } else if (drag) {
      block.onDragUp(e, direction);
    }
  };

  const preventing: IPoint[] = [];

  if (drop) {
    preventing.push('tl');
    preventing.push('tr');
    preventing.push('bl');
    preventing.push('br');
  } else if (layout) {
    preventing.push('tl');
    preventing.push('tr');
    preventing.push('bl');
    preventing.push('br');
    preventing.push('ct');
    preventing.push('cb');
    preventing.push('cl');
    preventing.push('cr');
  } else if (create) {
    preventing.push('cc');
  }

  if (lock && drag && !free) {
    preventing.push('tl');
    preventing.push('tr');
    preventing.push('bl');
    preventing.push('br');
    preventing.push('ct');
    preventing.push('cb');
    preventing.push('cl');
    preventing.push('cr');
  }

  if (centered) {
    preventing.push('ct');
    preventing.push('cb');
    preventing.push('cl');
    preventing.push('cr');
  }

  if (!replacing) {
    preventing.push('cc');
  }

  return {
    layout: 'new',
    prevent: preventing,
    disabled: (
      container
      || (element.wrapping && drop && copy)
    ),
    free,

    onMouseUp,
    onMouseDown,
  };
};
