import React, { FC, useContext } from 'react';
import { deleteElements, getBlockContextElements, getState } from 'utils/store';
import optimizers from 'utils/optimizers';
import ReactQuill from 'react-quill';

import { storeGraphic } from 'store/graphic';
import { OptionEmptyTextBlur } from 'store/settings';

import { useEditable } from 'hooks/useEditable';
import { useStoreDispatch } from 'hooks/useStore';
import { useEditableBlur } from 'hooks/useEditableBlur';
import { useEditableBindings } from 'hooks/useEditableBindings';
import { useEditableComponent } from 'hooks/useEditableComponent';

import { ElementContext } from 'elements/Block/Element';

import { IComponent, config } from '.';

import style from '../../assets/style.module.css';

export const Edit: FC<IComponent> = ({ value, property }) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  const bindings = useEditableBindings({
    key: config.key,
    shift: false,
  });

  const onBlur = useEditableBlur({
    property,
    key: config.key,
    onBefore: (value, input) => {
      const blur = getState((state) => state.settings.app.emptyTextBlur);

      if (optimizers.noHtml(value).trim() === '') {
        if (optimizers.noHtml(input.default).trim() === '' && !blur.includes(OptionEmptyTextBlur.Disable)) {
          deleteElements(getBlockContextElements([element.id]));
          dispatch(storeGraphic.setSelectionElements([]));

          return false;
        }

        input.reset();

        return false;
      }

      return true;
    },
  });

  const input = useEditable({
    value,
    bindings,
    formats: [
      'paragraph',
      'bold',
      'link',
      'italic',
      'underline',
      'strike',
      'background',
    ],
    onBlur: (value) => onBlur(value, input),
  });

  useEditableComponent(input);

  return (
    <ReactQuill
      {...input.attributes}
      className={style.input}
      placeholder="Type something"
    />
  );
};
