import React, { FC } from 'react';
import optimizers from 'utils/optimizers';

import { useEditLink } from 'hooks/useEditLink';

import { IComponent, config } from '.';

import style from '../../assets/style.module.css';

export const View: FC<IComponent> = ({ value }) => {
  const onClick = useEditLink({
    key: config.key,
  });

  if (optimizers.noHtml(value) === '') {
    return null;
  }

  const content = {
    __html: value,
  };

  return (
    <div
      className={style.text}
      dangerouslySetInnerHTML={content}
      onClick={onClick}
      onKeyDown={() => {}}
      aria-label="Text"
      tabIndex={-1}
      role="button"
    />
  );
};
