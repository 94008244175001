import { useEffect } from 'react';
import { ITool } from 'tools';
import { getLockKey, getState } from 'utils/store';
import { createTool } from 'tools/ToolBasic';

import { storeDiagram } from 'store/diagram';

import { serviceAccount } from 'services/account';

import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import icon from './assets/icon.svg';

const Tool = createTool({
  key: 'lockKey',
  def: '',
  title: 'Only I can unlock',
  alt: 'Smart lock',
  icon,
  getActive: () => {
    const selection = getState((state) => state.graphic.selection.elements);

    const key = getLockKey(selection);

    return key !== 'none';
  },
  getTitle: () => {
    const dispatch = useStoreDispatch();

    const selection = getState((state) => state.graphic.selection.elements);

    const key = getLockKey(selection);

    const owner = useStoreSelector((state) => (!['none', 'mine', 'partial', 'multiple'].includes(key) ? state.cache.users[key] : undefined));

    useStoreSelector((state) => state.diagram.file);

    useEffect(() => {
      if (!['none', 'mine', 'partial', 'multiple'].includes(key) && !owner) {
        dispatch(serviceAccount.getUser({
          id: key,
        }));
      }
    }, [!['none', 'mine', 'partial', 'multiple'].includes(key) && !owner]);

    if (key === 'partial') {
      return 'I can unlock some blocks';
    }

    if (key === 'multiple') {
      return 'I can\'t unlock';
    }

    if (key === 'none' || key === 'mine') {
      return 'Only I can unlock';
    }

    if (owner?.name) {
      return `Only ${owner.name} can unlock`;
    }

    return 'Loading...';
  },
  onClick: (selection, dispatch, value) => {
    const user = getState((state) => state.account.user.id);
    const links = getState((state) => state.diagram.links);
    const file = getState((state) => state.diagram.file);

    const key = getLockKey(selection);

    if (!['none', 'mine'].includes(key)) {
      return;
    }

    selection.forEach((id) => {
      [...(links[id] ?? [id])].forEach((id) => {
        if (!file[id]?.lockKey || file[id]?.lockKey === user) {
          dispatch(storeDiagram.setElement({
            id,
            properties: {
              lockKey: !value ? user : '',
            },
          }));
        }
      });
    });
  },
});

export const LockKey: ITool = {
  key: 'lock-key',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
