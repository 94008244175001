import { createAsyncThunk } from '@reduxjs/toolkit';

import errors from 'utils/errors';
import security from 'utils/security';

import { storeCache } from 'store/cache';
import { storeAccount } from 'store/account';
import { storeSettings } from 'store/settings';

import { ISession } from 'windows/Settings/Session';

import { api } from '.';
import { serviceAlert } from './alert';

interface ICallback<T = boolean> {
  callback?: (payload?: T) => void;
}

interface ISignIn {
  email: string;
  password: string;
}

interface ISignUp {
  name: string;
  color: string;
  email: string;
  password: string;
}

interface ISignUpWithGoogle {
  token: string;
  color: string;
}

interface IReSignIn {
  onAuthorized?: () => void;
  onUnauthorized?: () => void;
  onUnverified?: () => void;
}

export const serviceAccount = {
  signIn: createAsyncThunk('account/signIn', async (payload: ISignIn & ICallback<{ active: boolean }>, thunk) => {
    try {
      const response = await api.post('/users/login', {
        email: payload.email,
        password: payload.password,
      });

      const id = response.data._id;

      security.setSession(response.data);

      thunk.dispatch(storeCache.addUser({ ...response.data, id }));
      thunk.dispatch(storeAccount.setUser({ ...response.data, id }));
      thunk.dispatch(storeAccount.setSettings(response.data.settings));

      if (payload.callback) {
        payload.callback({ active: response.data.active });
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        403: 'Password is wrong',
        404: 'This email is not registered yet',
      });
    }
  }),

  signInWithGoogle: createAsyncThunk('account/signInWithGoogle', async (payload: { token: string } & ICallback<{ active: boolean }>, thunk) => {
    try {
      const response = await api.post('/users/login/google', {
        token: payload.token,
        origin: window.location.origin,
      });

      const id = response.data._id;

      security.setSession(response.data);

      thunk.dispatch(storeCache.addUser({ ...response.data, id }));
      thunk.dispatch(storeAccount.setUser({ ...response.data, id }));
      thunk.dispatch(storeAccount.setSettings(response.data.settings));

      if (payload.callback) {
        payload.callback({ active: response.data.active });
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        404: 'This account is not registered yet',
      });
    }
  }),

  signInWithDevice: createAsyncThunk('account/signInWithDevice', async (payload: { id: string } & ICallback, thunk) => {
    try {
      await api.post('/users/device', {
        id: payload.id,
      });

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback(false);
      }

      errors(e, thunk, {
        404: 'This device is not registered',
      });
    }
  }),

  signUp: createAsyncThunk('account/signUp', async (payload: ISignUp & ICallback, thunk) => {
    try {
      const response = await api.post('/users/create', {
        name: payload.name,
        color: payload.color,
        email: payload.email,
        password: payload.password,
        location: `${window.location.origin}/verify`,
      });

      const id = response.data._id;

      security.setSession(response.data);

      thunk.dispatch(storeCache.addUser({ ...response.data, id }));
      thunk.dispatch(storeAccount.setUser({ ...response.data, id }));
      thunk.dispatch(storeAccount.setSettings(response.data.settings));

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        409: 'A user with this email address already exists',
      });
    }
  }),

  signUpWithGoogle: createAsyncThunk('account/signUpWithGoogle', async (payload: ISignUpWithGoogle & ICallback, thunk) => {
    try {
      const response = await api.post('/users/create/google', {
        token: payload.token,
        color: payload.color,
        origin: window.location.origin,
      });

      const id = response.data._id;

      security.setSession(response.data);

      thunk.dispatch(storeCache.addUser({ ...response.data, id }));
      thunk.dispatch(storeAccount.setUser({ ...response.data, id }));
      thunk.dispatch(storeAccount.setSettings(response.data.settings));

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        403: 'This Google account is not verified',
        409: 'A user with data of this Google account already exists',
      });
    }
  }),

  signOut: createAsyncThunk('account/signOut', async (payload: ICallback, thunk) => {
    try {
      await api.post('/users/logout');

      security.removeSession();

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk);
    }
  }),

  setUser: createAsyncThunk('account/setUser', async (payload: IReSignIn, thunk) => {
    try {
      const response = await api.get('/users/user');

      const id = response.data._id;

      thunk.dispatch(storeCache.addUser({ ...response.data, id }));
      thunk.dispatch(storeAccount.setUser({ ...response.data, id }));
      thunk.dispatch(storeAccount.setSettings(response.data.settings));

      if (response.data.active) {
        if (payload.onAuthorized) {
          payload.onAuthorized();
        }

        return;
      }

      if (payload.onUnverified) {
        payload.onUnverified();
      }
    } catch (e) {
      if (((e as any)?.response?.status === 401 || (e as any)?.response?.status === 403) && payload.onUnauthorized) {
        payload.onUnauthorized();
      }
    }
  }),

  verify: createAsyncThunk('account/verify', async (payload: { token: string } & ICallback, thunk) => {
    try {
      await api.post('/users/confirm', {
        token: payload.token,
      });

      thunk.dispatch(storeAccount.verifyUser());
      thunk.dispatch(serviceAlert.showAlert({
        message: 'Account has been verified',
        type: 'default',
      }));

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        403: 'This link is not active',
        404: 'This link is not active',
      });
    }
  }),

  sendVerification: createAsyncThunk('account/resend', async (payload: ICallback, thunk) => {
    try {
      await api.post('/users/resend', {
        location: `${window.location.origin}/verify`,
      });

      thunk.dispatch(serviceAlert.showAlert({
        message: 'The verification letter has been resent',
        type: 'default',
      }));

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        403: 'This link is not active',
      });
    }
  }),

  sendRestoration: createAsyncThunk('account/restore', async (payload: { email: string } & ICallback, thunk) => {
    try {
      await api.post('/users/restore', {
        email: payload.email,
        location: `${window.location.origin}/reset`,
      });

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        404: 'There is no user with this email',
      });
    }
  }),

  reset: createAsyncThunk('account/reset', async (payload: { password: string, token: string } & ICallback, thunk) => {
    try {
      await api.post('/users/reset', {
        token: payload.token,
        password: payload.password,
      });

      thunk.dispatch(serviceAlert.showAlert({
        message: 'Password has been reseted',
        type: 'default',
      }));

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        403: 'This link is not active',
        404: 'This link is not active',
      });
    }
  }),

  getUser: createAsyncThunk('account/getUser', async (payload: { id: string } & ICallback, thunk) => {
    try {
      const response = await api.get(`/users/user/${payload.id}`);

      thunk.dispatch(storeCache.addUser({ ...response.data, id: response.data._id }));

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk);
    }
  }),

  setSettings: createAsyncThunk('account/setSettings', async (payload: { settings: { [key: string]: string } } & ICallback, thunk) => {
    try {
      const response = await api.patch('/users/user/settings', payload.settings);

      thunk.dispatch(storeCache.addUser({ ...response.data, id: response.data._id }));
      thunk.dispatch(storeAccount.setUser({ ...response.data, id: response.data._id }));
      thunk.dispatch(storeAccount.setSettings(response.data.settings));
      thunk.dispatch(storeSettings.reset());

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        403: 'Password is wrong',
        404: 'User is not found',
      });
    }
  }),

  getSessions: createAsyncThunk('account/setSettings', async (payload: ICallback<ISession[]>, thunk) => {
    try {
      const response = await api.get('/users/sessions');

      if (payload.callback) {
        payload.callback(response.data);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk);
    }
  }),

  revokeSession: createAsyncThunk('account/revokeSession', async (payload: { password: string, sid: string } & ICallback, thunk) => {
    try {
      await api.post('/users/revoke', {
        sid: payload.sid,
        auth: payload.password,
      });

      if (payload.callback) {
        payload.callback(true);
      }
    } catch (e) {
      if (payload.callback) {
        payload.callback();
      }

      errors(e, thunk, {
        403: 'Password is wrong',
      });
    }
  }),
};
