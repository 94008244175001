import React, { FC } from 'react';

import { useElement } from 'hooks/useElement';

import { Wrap } from './Wrap';

interface IWrapper {
  id: string;
  point: number[];
}

export const Wrapper: FC<IWrapper> = ({ id, point }) => {
  const wrap = useElement<HTMLDivElement>();

  return (
    <Wrap element={wrap} id={id} point={point} />
  );
};
