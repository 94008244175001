import React, { FC, useMemo } from 'react';
import optimizers from 'utils/optimizers';
import { getDetailedLine } from 'utils';

import { useEditLink } from 'hooks/useEditLink';

import { IComponent, config } from '.';

import style from '../../assets/style.module.css';

export const Context: FC<IComponent> = ({ value }) => {
  const onClick = useEditLink({
    key: config.key,
  });

  const context = useMemo(() => {
    return optimizers.noParagraphDuplication(value);
  }, [value]);

  if (optimizers.noHtml(optimizers.withSpaces(value)).trim() === '') {
    return null;
  }

  const content = {
    __html: context,
  };

  return (
    <div
      className={`${style.text} ${style.detail}`}
      dangerouslySetInnerHTML={content}
      onClick={onClick}
      onKeyDown={() => {}}
      aria-label="Text"
      tabIndex={-1}
      role="button"
    />
  );
};
