import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import icon from './assets/icon.svg';

const Tool = createTool('sticker', '_', 'icon', 'Sticker', 'Sticker', {
  icon,
});

export const Sticker: ITool = {
  key: 'sticker',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
