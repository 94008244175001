import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { IConnector } from './Cap';

export const Cap4: IConnector = {
  Start: () => null,
  End: ({ color, mask }: ICap) => (
    <path d="M18 13L23 8L18 3L13 8L18 13Z" strokeWidth="2" fill={mask ? '#fff' : color} stroke={mask ? '#fff' : color} strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
};
