import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { Model } from 'blocks/factories/shapeIcon/Model';
import { createRender } from 'blocks/factories/simple/Render';
import { createBlock, block } from 'blocks/factories/shapeIcon/Block';

import icon from './assets/icon.svg';

const { serialize } = require('svg-path-round-corners/dist/es6/serialize');

type IModel = Model<{}>;

export const config: IConfig = {
  title: 'Actor',
  search: 'shape actor uml unified modeling language',
  type: 'uml-use-case-shape-actor',
  group: ['UML Use Case Shape'],
  memo: {
    key: 'uml-use-case-shape',
    styles: block.element.memo,
  },
  styles: block.element.styles,
};

const Render = createRender<IModel>({
  render: ({ width, height, element }) => {
    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - border, 0);
    const y = Math.max(height - border, 0);

    const points = [
      { c: 'M', x: (7 / 26) * x + offset, y: (26 / 26) * y + offset },
      { c: 'L', x: (13 / 26) * x + offset, y: (19 / 26) * y + offset },
      { c: 'M', x: (13 / 26) * x + offset, y: (19 / 26) * y + offset },
      { c: 'V', y: (11 / 26) * y + offset },
      { c: 'M', x: (13 / 26) * x + offset, y: (19 / 26) * y + offset },
      { c: 'L', x: (19 / 26) * x + offset, y: (26 / 26) * y + offset },
      { c: 'M', x: (13 / 26) * x + offset, y: (11 / 26) * y + offset },
      { c: 'H', x: (5 / 26) * x + offset },
      { c: 'M', x: (13 / 26) * x + offset, y: (11 / 26) * y + offset },
      { c: 'H', x: (21 / 26) * x + offset },
      { c: 'M', x: (13 / 26) * x + offset, y: (11 / 26) * y + offset },
      { c: 'C', x1: (13 / 26) * x + offset, y1: (11 / 26) * y + offset, x2: (13 / 26) * x + offset, y2: (9.1716 / 26) * y + offset, x: (13 / 26) * x + offset, y: (8 / 26) * y + offset },
      { c: 'M', x: (13 / 26) * x + offset, y: (8 / 26) * y + offset },
      { c: 'C', x1: (10.7909 / 26) * x + offset, y1: (8 / 26) * y + offset, x2: (9 / 26) * x + offset, y2: (6.20914 / 26) * y + offset, x: (9 / 26) * x + offset, y: (4 / 26) * y + offset },
      { c: 'C', x1: (9 / 26) * x + offset, y1: (1.79086 / 26) * y + offset, x2: (10.7909 / 26) * x + offset, y2: (0 / 26) * y + offset, x: (13 / 26) * x + offset, y: (0 / 26) * y + offset },
      { c: 'C', x1: (15.2091 / 26) * x + offset, y1: (0 / 26) * y + offset, x2: (17 / 26) * x + offset, y2: (1.79086 / 26) * y + offset, x: (17 / 26) * x + offset, y: (4 / 26) * y + offset },
      { c: 'C', x1: (17 / 26) * x + offset, y1: (6.20914 / 26) * y + offset, x2: (15.2091 / 26) * x + offset, y2: (8 / 26) * y + offset, x: (13 / 26) * x + offset, y: (8 / 26) * y + offset },
      { c: 'Z' },
    ];

    return (
      <svg className="block-render" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path {...element.trigger} className="render-fill render-stroke render-hit-box" d={serialize(points)} strokeLinecap="round" strokeWidth={border} />
      </svg>
    );
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  Render,
});

export const UMLUseCaseShapeActor : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: block.element.options,
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
        render: [
          9,
          6,
        ],
      },
    },
    onCreate: block.creator(config, {
      width: 64,
      height: 64,
      realWidth: 64,
      realHeight: 64,
    }),
    onCopy: block.copier(config),
    title: config.title,
    size: [64, 64],
    icon,
    class: block.element.class,
  },
  button: block.button(config),
  tools: block.tools,
  toolbars: block.toolbars,
};
