import React from 'react';
import { blocks, IBlock, IConfigBase } from 'blocks';

import { config as wrapper } from 'blocks/Core/Wrapper';
import { config as container } from 'blocks/General/Container';
import { config as text } from 'blocks/General/Text';
import { config as figure } from 'blocks/Google/Icon';

import { Button } from 'elements/Block/Button';

import icon from './assets/icon.svg';

export const config: IConfigBase = {
  title: 'Google Cloud Platform',
  search: 'group google platform cloud architecture',
  type: 'google-group-cloud',
  group: ['Google Cloud'],
};

export const GoogleGroupCloud : IBlock = {
  type: config.type,
  block: () => null,
  element: {
    group: config.group,
    search: config.search,
    options: [],
    styles: [],
    constraints: {},
    onCreate: ({ file, position, parent, canvas }) => {
      const group = blocks[container.type].element.onCreate({
        file,
        position,
        parent,
        auto: true,
        properties: {
          color: 'grey',
          render: 7,
          padding: 'm',
          placing: 'top-left',
          width: 240,
          height: 150,
          space: true,
          elements: [],
          lockKey: '',
          lock: false,
          hidden: false,
        },
      });

      const wrap = blocks[wrapper.type].element.onCreate({
        file,
        position: [0, 0],
        parent: group,
        auto: true,
        properties: {
          placing: 'right',
          alignVertical: 'center',
          alignHorizontal: 'center',
          lockKey: '',
          lock: false,
        },
      });

      blocks[figure.type].element.onCreate({
        file,
        position: [0, 0],
        parent: wrap,
        auto: true,
        properties: {
          file: '34.svg',
          title: '<strong>Google</strong> Cloud Platform',
          height: 32,
          width: 32,
          realHeight: 32,
          realWidth: 32,
          lockKey: '',
          lock: false,
        },
      });

      blocks[text.type].element.onCreate({
        file,
        position: [0, 1],
        parent: wrap,
        auto: canvas?.space ? false : undefined,
        preventComponentStatus: true,
        properties: {
          text: '<strong>Google</strong> Cloud Platform',
          size: 'm',
          color: 'dark',
          width: undefined,
          vertical: false,
          lockKey: '',
          lock: false,
        },
      });

      return group;
    },
    onCopy: () => '',
    title: config.title,
    size: [240, 150],
    icon,
    class: {
      title: '',
      icon: '',
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [],
  toolbars: [],
};
