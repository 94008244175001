import React, { memo } from 'react';
import { IBlock } from 'blocks';

import { useCacheProperty } from 'hooks/useCacheProperty';

import { Model } from 'blocks/factories/container/Model';
import { createBlock } from 'blocks/factories/container/Block';
import { createRender } from 'blocks/factories/simple/Render';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';

import { Button } from 'elements/Block/Button';

import icon from './assets/icon.svg';
import iconWhite from './assets/icon-white.svg';

type IModel = Model<{}>;

export const config = {
  title: 'Container',
  search: 'container',
  type: 'container',
  group: ['General'],
  memo: {
    key: 'container',
    styles: [
      'color',
      'placing',
      'space',
      'render',
      'padding',
    ],
  },
  styles: [
    'color',
    'placing',
    'space',
    'render',
    'padding',
  ],
};

const Render = createRender<IModel>({
  render: ({ width, height, properties, element }) => {
    const hidden = useCacheProperty('hidden') ?? false;

    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - border, 0);
    const y = Math.max(height - border, 0);

    const points = [
      [offset, offset],
      [x, y],
    ];

    return (
      <svg className="block-render" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        {hidden && (
          <defs>
            <pattern id={`${element.id}-pattern`} patternUnits="userSpaceOnUse" width="24" height="24" viewBox="0 0 8 8">
              <path className="render-stroke-color render-opacity" d="M-1,1 l4,-4 M0,8 l8,-8 M6,10 l4,-4" strokeOpacity={0.075} strokeWidth={4} />
            </pattern>
          </defs>
        )}

        <rect className="render-fill-stroke render-stroke" x={points[0][0]} y={points[0][1]} width={points[1][0]} height={points[1][1]} strokeLinecap="round" rx="6" fillOpacity={0.1} strokeOpacity={properties.render === 7 ? 0.1 : 1} strokeWidth={border} />

        {hidden && (
          <>
            <rect x={points[0][0] + 4} y={points[0][1] + 4} width={Math.max(points[1][0] - 8, 0)} height={Math.max(points[1][1] - 8, 0)} strokeLinecap="round" rx="6" fill={`url(#${element.id}-pattern)`} />
            <svg x={(width - 62) / 2} y={(height - 30) / 2 + 4} width="62" height="30" viewBox="0 0 62 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="render-stroke-color" d="M2.5 2C4.43193 4.41491 6.62093 6.51562 9 8.29424M59.5 2C57.5127 4.37196 55.3281 6.42336 53 8.16053M9 8.29424L2 20.5M9 8.29424C13.337 11.5366 18.3057 13.7087 23.5 14.7625M23.5 14.7625L21.5 27.5M23.5 14.7625C28.4126 15.7593 33.5271 15.7558 38.5 14.7119M38.5 14.7119L41 28M38.5 14.7119C43.6065 13.6399 48.5638 11.4707 53 8.16053M53 8.16053L60 20" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </>
        )}
      </svg>
    );
  },
});

const Block = createBlock({
  config,
  Render,
});

const Help = () => (
  <>
    <div className="text-10">Container</div>
    <div className="text-9">The Container Block is a fundamental element in Exemplar, designed to group and organize other elements within your canvas.</div>
    <div className="text-8">Key Features:</div>
    <ul>
      <li className="text-9"><b>Grouping Elements</b>: The Container Block serves as a container for grouping other elements together.</li>
      <li className="text-9"><b>Exclusive Nesting</b>: Containers can only be nested within other Containers, ensuring a structured hierarchy.</li>
      <li className="text-9"><b>Control Points</b>: There are four control points on each side of the Container, allowing precise control over its minimum size.</li>
      <li className="text-9"><b>Automatic Resizing</b>: The position and size of the Container are automatically adjusted based on the inner blocks it contains. No manual resizing required.</li>
    </ul>
    <div className="text-8">Adding and Removing Blocks:</div>
    <ul>
      <li className="text-9"><b>Adding Blocks</b>: To add blocks inside a Container, simply hold the <b>CTRL</b> (or <b>CMD</b>) key while dragging the blocks onto the target Container.</li>
      <li className="text-9"><b>Removing Blocks</b>: To unjoin blocks from a Container, hold the <b>CTRL</b> (or <b>CMD</b>) key while dragging the blocks out of the Container area.</li>
      <li className="text-9"><b>Visual Guidance</b>: You don&apos;t need to hold <b>CTRL</b> (or <b>CMD</b>) keys constantly. An outline appears around the Container when dragging blocks are joined. This outline helps you control the process.</li>
    </ul>
    <div className="text-8">Header Functionality:</div>
    <ul>
      <li className="text-9"><b>Header Blocks</b>: Containers feature a header where you can add regular blocks for additional information or organization.</li>
      <li className="text-9"><b>Adding Blocks to the Header</b>: To add a block to the header, hold the <b>CTRL</b> (or <b>CMD</b>) key while dragging the block and drop it onto the special dot in the top-left corner of the Container. Release the block while still holding <b>CTRL</b> (or <b>CMD</b>) keys.</li>
    </ul>
    <div className="text-9">The Container Block is a powerful tool for structuring your canvas and creating organized visual representations.</div>
  </>
);

export const GeneralContainer : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    Help,
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-container', 'element-wrap', 'child-none', 'container-title-none'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
        render: [
          6,
          8,
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        color: 'dark',
        render: 0,
        padding: 'm',
        elements: [],
        placing: 'top-left',
        width: 150,
        height: 150,
        space: true,
        lockKey: '',
        lock: false,
        hidden: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [150, 150],
    icon,
    class: {
      title: 'Container',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: [
      'only-edit',
    ],
    type: 'drag',
  },
  tools: [
    'padding',
    'space',
    'placing',
    'render',
    'color',
    'hidden',
    'lock',
    'delete-wrap',
    'delete',
  ],
  toolbars: [

  ],
};
