import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { IConnector } from './Cap';

export const Cap6: IConnector = {
  Start: ({ color, mask }: ICap) => (
    <path d="M9 13L1 8L9 3L9 13Z" strokeWidth="2" fill={mask ? '#000' : 'transparent'} stroke={mask ? '#fff' : color} strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
  End: ({ color, mask }: ICap) => (
    <path d="M15 13L23 8L15 3L15 13Z" strokeWidth="2" fill={mask ? '#000' : 'transparent'} stroke={mask ? '#fff' : color} strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
};
