import { useContext, useLayoutEffect } from 'react';
import { points } from 'utils/points';

import { ElementContext } from 'elements/Block/Element';

export type IPadding = (id: string, frame: HTMLDivElement, wrap: HTMLDivElement) => [number, number, number, number];

export const usePadding = (padding: IPadding, wrap: HTMLDivElement | null) => {
  const element = useContext(ElementContext);

  useLayoutEffect(() => {
    if (!wrap) {
      return () => {};
    }

    const preprocess = (frame: HTMLDivElement) => {
      const margin = padding(element.id, frame, wrap);

      wrap.style.paddingLeft = `${margin[0]}px`;
      wrap.style.paddingTop = `${margin[1]}px`;
      wrap.style.paddingRight = `${margin[2]}px`;
      wrap.style.paddingBottom = `${margin[3]}px`;
    };

    points.preprocess({
      id: element.id,
      callback: preprocess,
    });

    element.updatePoints();

    return () => {
      points.unpreprocess({
        id: element.id,
        callback: preprocess,
      });
    };
  }, [wrap]);
};
