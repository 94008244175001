import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { IConnector } from './Cap';

export const Cap5: IConnector = {
  Start: ({ color }: ICap) => null,
  End: ({ color, mask }: ICap) => (
    <>
      <path d="M18 2L22 8L18 14H13L17 8L13 2H18Z" fill={mask ? '#000' : 'transparent'} strokeDasharray="100,0" />
      <path d="M18 13L23 8L18 3" stroke={mask ? '#fff' : color} fill="transparent" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
      <path d="M13 13L18 8L13 3" stroke={mask ? '#fff' : color} fill="transparent" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
    </>
  ),
};
