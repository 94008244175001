import React, { FC } from 'react';

import './assets/Pointer.css';

interface PointerProps {
  variant: 'light' | 'dark' | 'warning' | 'information' | 'hidden' | 'loading';
  size?: 'normal' | 'bigger';
}

export const Pointer: FC<PointerProps> = ({ variant, size }) => {
  return (
    <div className={`pointer pointer-${variant} ${size}`} />
  );
};

Pointer.defaultProps = {
  size: 'normal',
};
