import React from 'react';
import { ITool } from 'tools';

import { createFormatTool } from 'tools/ToolFormat';

import icon from './assets/icon.svg';

const Tool = createFormatTool({
  icon,
  title: 'Code',
  format: 'code',
});

export const FormatCode: ITool = {
  key: 'format-code',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
