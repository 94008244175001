import { AxiosError } from 'axios';

import { serviceAlert } from '../services/alert';

export default (error: unknown, thunk: any, options: { [key: number]: string } = {}, skip: number[] = []) => {
  if (error instanceof AxiosError && error.response) {
    if (error.code === 'ERR_NETWORK') {
      thunk.dispatch(serviceAlert.showAlert({
        message: 'You are offline',
        type: 'error',
      }));

      return;
    }

    if (error.response.status in options) {
      thunk.dispatch(serviceAlert.showAlert({
        message: options[error.response.status],
        type: 'error',
      }));

      return;
    }

    if (skip.includes(error.response.status)) {
      return;
    }

    if (error.response.status === 400) {
      thunk.dispatch(serviceAlert.showAlert({
        message: 'Invalid data has been provided',
        type: 'error',
      }));

      return;
    }

    if (error.response.status === 429) {
      thunk.dispatch(serviceAlert.showAlert({
        message: 'Too many requests, wait a little bit',
        type: 'error',
      }));

      return;
    }

    if (error.response.status === 500) {
      thunk.dispatch(serviceAlert.showAlert({
        message: 'Connection is lost, try again later',
        type: 'error',
      }));

      return;
    }
  }

  if (error instanceof Error && error.message === '') {
    return;
  }

  thunk.dispatch(serviceAlert.showAlert({
    message: 'Something went wrong, try again later',
    type: 'error',
  }));
};
