import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ILayout {
  [key: string]: string | ILayout;
}

export type IGrabOption = 'no-center' | 'special' | 'copy';
export type IDragType = 'move' | 'create';
export type IDropType = 'move' | 'create';

export interface IDrag {
  type: IDragType;
  container?: string[];
  element: {
    id: string;
    type: string;
  };
  path: string[],
  childs: string[],
  frame: {
    size: [number, number];
    offset: [number, number];
  };
}

interface IDrop {
  type: IDropType;
  block: string;
  id: string;
  handle: string;
}

export interface State {
  selectedElement: string;
  grabbedElement: string;
  focusedComponent: string;
  adding: boolean;
  editing: boolean;
  arranging: boolean;
  selecting: boolean,
  focusing: boolean;
  minSize: number[];
  options: { [key: string]: any },
  selection: {
    elements: string[],
    group: boolean;
  };
  component: {
    key: string;
    tab: string;
    opened: boolean;
    failed: boolean;
    preventNextUpdate: boolean;
    preventTabUpdate: boolean;
  },
  tabs: string[];
  zone: {
    status: boolean;
    position: string;
  };
  grab: {
    status: boolean;
    element: string;
    options: IGrabOption[];
  };
  elements: Record<string, true>;
  creator: Record<string, any>;
  drag?: IDrag;
  drop?: IDrop;
  draw?: string;
  copy: boolean;
  stretch: boolean;
  cursor: number[];
  handle: string;
  layout: {
    status: boolean;
    key: string;
  };
  snap: boolean;
  zoom: {
    value: number;
    limit: number;
  };
  resizing: string;
  grabbing: boolean;
}

export const defaultState: State = {
  selectedElement: '',
  grabbedElement: '',
  focusedComponent: '',
  adding: false,
  editing: false,
  arranging: false,
  selecting: false,
  focusing: false,
  minSize: [0, 0],
  options: {},
  selection: {
    elements: [],
    group: false,
  },
  component: {
    key: '',
    tab: '',
    opened: false,
    failed: false,
    preventNextUpdate: false,
    preventTabUpdate: false,
  },
  tabs: [],
  zone: {
    status: false,
    position: '[]',
  },
  grab: {
    status: false,
    element: '',
    options: [],
  },
  zoom: {
    value: 1,
    limit: 1,
  },
  copy: false,
  cursor: [0, 0],
  handle: '',
  layout: {
    status: false,
    key: '',
  },
  snap: false,
  stretch: false,
  elements: {},
  creator: {},
  resizing: '',
  grabbing: false,
};

const slice = createSlice({
  name: 'graphic',
  initialState: defaultState,
  reducers: {
    reset: () => defaultState,
    resetMode: (state) => {
      state.grabbedElement = defaultState.grabbedElement;
      state.selectedElement = defaultState.selectedElement;
      state.focusedComponent = defaultState.focusedComponent;
      state.adding = defaultState.adding;
      state.editing = defaultState.editing;
      state.arranging = defaultState.arranging;
      state.focusing = defaultState.focusing;
      state.component.key = defaultState.component.key;
      state.component.failed = defaultState.component.failed;
      state.component.preventNextUpdate = defaultState.component.preventNextUpdate;
      state.component.preventTabUpdate = defaultState.component.preventTabUpdate;
      state.zone = defaultState.zone;
      state.grab = defaultState.grab;
      state.layout = defaultState.layout;
      state.stretch = defaultState.stretch;
      state.creator = defaultState.creator;
      state.resizing = defaultState.resizing;
      state.grabbing = defaultState.grabbing;
    },
    setSelectedElement: (state, action: PayloadAction<string>) => {
      if (action.payload === undefined) {
        return;
      }

      state.selectedElement = action.payload;
    },
    setGrabbedElement: (state, action: PayloadAction<string>) => {
      if (action.payload === undefined) {
        return;
      }

      state.grabbedElement = action.payload;
    },
    setFocusedComponent: (state, action: PayloadAction<string>) => {
      state.focusedComponent = action.payload;
    },
    setEditingStatus: (state, action: PayloadAction<boolean>) => {
      state.editing = action.payload;
    },
    setArrangingStatus: (state, action: PayloadAction<boolean>) => {
      state.arranging = action.payload;
    },
    setSelectingStatus: (state, action: PayloadAction<boolean>) => {
      state.selecting = action.payload;
    },
    setGrabbingStatus: (state, action: PayloadAction<boolean>) => {
      state.grabbing = action.payload;
    },
    setAddingStatus: (state, action: PayloadAction<boolean>) => {
      state.adding = action.payload;
    },
    setFocusingStatus: (state, action: PayloadAction<boolean>) => {
      state.focusing = action.payload;
    },
    setMinSize: (state, action: PayloadAction<number[]>) => {
      state.minSize = action.payload;
    },
    setZonePosition: (state, action: PayloadAction<string>) => {
      state.zone.position = action.payload;
    },
    setZoneStatus: (state, action: PayloadAction<boolean>) => {
      state.zone.status = action.payload;
    },
    setGrabElement: (state, action: PayloadAction<string>) => {
      state.grab.element = action.payload;
    },
    setGrabOptions: (state, action: PayloadAction<IGrabOption[]>) => {
      if (action.payload) {
        state.grab.options = action.payload;
      }
    },
    setGrabStatus: (state, action: PayloadAction<boolean>) => {
      state.grab.status = action.payload;
    },
    setSelectionElements: (state, action: PayloadAction<string[]>) => {
      state.selection.elements = action.payload;
    },
    setOptions: (state, action: PayloadAction<{ id: string, options: {} }>) => {
      if (!(action.payload.id in state.options)) {
        state.options[action.payload.id] = {};
      }

      state.options[action.payload.id] = {
        ...state.options[action.payload.id],
        ...action.payload.options,
      };
    },
    deleteOptions: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((id) => {
        delete state.options[id];
      });
    },
    setSelectionGroup: (state, action: PayloadAction<boolean>) => {
      state.selection.group = action.payload;
    },
    setComponentKey: (state, action: PayloadAction<string>) => {
      if (state.component.preventNextUpdate) {
        state.component.preventNextUpdate = false;
        return;
      }

      state.component.key = action.payload;
    },
    setComponentPreventStatus: (state) => {
      state.component.preventNextUpdate = true;
    },
    setComponentPreventTabStatus: (state) => {
      state.component.preventTabUpdate = true;
    },
    setComponentKeySafely: (state, action: PayloadAction<{next: string, prev: string, element?: string}>) => {
      if (state.component.key === action.payload.prev && state.selection.elements.length === 1 && (!action.payload.element || action.payload.element === state.selection.elements[0])) {
        if (state.component.preventNextUpdate) {
          state.component.preventNextUpdate = false;
          return;
        }

        state.component.key = action.payload.next;
      }
    },
    setComponentTab: (state, action: PayloadAction<string>) => {
      if (state.component.preventTabUpdate) {
        state.component.preventTabUpdate = false;
        return;
      }

      state.component.tab = action.payload;

      if (!action.payload.trim()) {
        state.tabs = [];
      } else if (state.tabs.length > 1 && state.tabs[state.tabs.length - 2] === state.component.tab) {
        state.tabs.pop();
      } else if (state.tabs.length < 1 || state.tabs[state.tabs.length - 1] !== state.component.tab) {
        state.tabs.push(state.component.tab);
      }
    },
    setComponentOpened: (state, action: PayloadAction<boolean>) => {
      state.component.opened = action.payload;
    },
    setComponentFailed: (state, action: PayloadAction<boolean>) => {
      state.component.failed = action.payload;
    },
    setZoomValue: (state, action: PayloadAction<number>) => {
      state.zoom.value = action.payload;
    },
    setZoomLimit: (state, action: PayloadAction<number>) => {
      state.zoom.limit = action.payload;
    },
    setDrop: (state, action: PayloadAction<IDrop | undefined>) => {
      state.drop = action.payload;
    },
    setDrag: (state, action: PayloadAction<IDrag | undefined>) => {
      if (!action.payload || !state.drop) {
        state.drag = action.payload;
      }
    },
    setDragType: (state, action: PayloadAction<IDragType>) => {
      if (state.drag) {
        state.drag.type = action.payload;
      }
    },
    setCopy: (state, action: PayloadAction<boolean>) => {
      state.copy = action.payload;
    },
    setDraw: (state, action: PayloadAction<string | undefined>) => {
      state.draw = action.payload;
    },
    setSnap: (state, action: PayloadAction<boolean>) => {
      state.snap = action.payload;
    },
    setLayout: (state, action: PayloadAction<boolean>) => {
      state.layout.status = action.payload;

      if ((!action.payload && (!state.drag || state.copy))) {
        state.layout.key = '';
      }
    },
    setLayoutKey: (state, action: PayloadAction<string>) => {
      state.layout.key = action.payload;
    },
    setCursor: (state, action: PayloadAction<number[]>) => {
      state.cursor = action.payload;
    },
    setHandle: (state, action: PayloadAction<string>) => {
      state.handle = action.payload;
    },
    setStretch: (state, action: PayloadAction<boolean>) => {
      state.stretch = action.payload;
    },
    setElement: (state, action: PayloadAction<{ id: string, status: boolean }>) => {
      delete state.elements[action.payload.id];

      if (action.payload.status) {
        state.elements[action.payload.id] = true;
      }
    },
    setResizing: (state, action: PayloadAction<string | undefined>) => {
      state.resizing = action.payload ?? '';
    },
    setCreator: (state, action: PayloadAction<Record<string, any>>) => {
      state.creator = action.payload;
    },
  },
});

export const storeGraphic = {
  reset: slice.actions.reset,
  resetMode: slice.actions.resetMode,
  setGrabbedElement: slice.actions.setGrabbedElement,
  setSelectedElement: slice.actions.setSelectedElement,
  setFocusedComponent: slice.actions.setFocusedComponent,
  setArrangingStatus: slice.actions.setArrangingStatus,
  setAddingStatus: slice.actions.setAddingStatus,
  setEditingStatus: slice.actions.setEditingStatus,
  setFocusingStatus: slice.actions.setFocusingStatus,
  setMinSize: slice.actions.setMinSize,
  setZonePosition: slice.actions.setZonePosition,
  setZoneStatus: slice.actions.setZoneStatus,
  setGrabElement: slice.actions.setGrabElement,
  setGrabStatus: slice.actions.setGrabStatus,
  setSelectionElements: slice.actions.setSelectionElements,
  setSelectionGroup: slice.actions.setSelectionGroup,
  setComponentKey: slice.actions.setComponentKey,
  setComponentKeySafely: slice.actions.setComponentKeySafely,
  setComponentTab: slice.actions.setComponentTab,
  setComponentFailed: slice.actions.setComponentFailed,
  setGrabOptions: slice.actions.setGrabOptions,
  setZoomValue: slice.actions.setZoomValue,
  setZoomLimit: slice.actions.setZoomLimit,
  setOptions: slice.actions.setOptions,
  deleteOptions: slice.actions.deleteOptions,
  setDrop: slice.actions.setDrop,
  setCursor: slice.actions.setCursor,
  setDrag: slice.actions.setDrag,
  setDragType: slice.actions.setDragType,
  setCopy: slice.actions.setCopy,
  setDraw: slice.actions.setDraw,
  setSnap: slice.actions.setSnap,
  setLayout: slice.actions.setLayout,
  setLayoutKey: slice.actions.setLayoutKey,
  setHandle: slice.actions.setHandle,
  setComponentPreventStatus: slice.actions.setComponentPreventStatus,
  setComponentPreventTabStatus: slice.actions.setComponentPreventTabStatus,
  setStretch: slice.actions.setStretch,
  setElement: slice.actions.setElement,
  setResizing: slice.actions.setResizing,
  setCreator: slice.actions.setCreator,
  setGrabbingStatus: slice.actions.setGrabbingStatus,
  setSelectingStatus: slice.actions.setSelectingStatus,
  setComponentOpened: slice.actions.setComponentOpened,
};

export default slice.reducer;
