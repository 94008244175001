import { useContext } from 'react';

import { ElementContext } from 'elements/Block/Element';

import { useOption } from './useOption';
import { useStoreSelector } from './useStore';

export const usePlacingAlignment = () => {
  const element = useContext(ElementContext);

  const vertical = useStoreSelector((state) => ['top', 'bottom'].includes(state.diagram.file[element.id].placing));

  useOption({
    id: element.id,
    key: 'alignHorizontal',
    value: !vertical ? 'disable' : undefined,
    deps: [vertical],
  });

  useOption({
    id: element.id,
    key: 'alignVertical',
    value: vertical ? 'disable' : undefined,
    deps: [vertical],
  });

  return vertical;
};
