import { filterProperties, getGroupStyles, getMemoizedStyles } from 'utils/store';
import { getNextId } from 'utils';
import { ICreate } from 'blocks';
import { AppDispatch, store } from 'store';

import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

interface ICreatorConfig<T> {
  memo: string;
  type: string;
  styles: string[];
  properties: Extract<T, Record<string, any>>;
  condition?: (payload: ICreate) => ICreate;
  component?: string;
  history?: boolean;
  dispatch?: (dispatch: AppDispatch, id: string) => void;
}

export const createCreator = <T, > (config: ICreatorConfig<T>) => {
  return (payload: ICreate) => {
    const { file, position, parent, auto, preventComponentStatus, properties = {} } = config.condition?.(payload) ?? payload;

    const id = getNextId(file);

    file[id] = {
      ...config.properties,

      ...filterProperties({
        type: config.type,
        properties: {
          ...getGroupStyles(config.memo),
          ...getMemoizedStyles(config.styles),
        },
      }),

      ...properties,

      id,
      type: config.type,
      position,
      parent,
    };

    store.dispatch(storeDiagram.addElement({
      element: file[id],
      skip: !(config.history ?? true) && !auto,
    }));

    if (!auto) {
      store.dispatch(storeGraphic.setSelectionElements([id]));

      if (config.component && auto === undefined) {
        store.dispatch(storeGraphic.setComponentKey(config.component));

        if (preventComponentStatus) {
          store.dispatch(storeGraphic.setComponentPreventStatus());
        }
      }

      config.dispatch?.(store.dispatch, id);
    }

    return id;
  };
};
