import { configureStore } from '@reduxjs/toolkit';

import account from './account';
import alert from './alert';
import collection from './collection';
import cache from './cache';
import settings from './settings';
import diagram from './diagram';
import graphic from './graphic';

export const store = configureStore({
  reducer: {
    account,
    alert,
    collection,
    cache,
    settings,
    graphic,
    diagram,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
