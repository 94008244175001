import { useContext, useEffect } from 'react';

import { CanvasStaticContext } from 'elements/Canvas';
import { ElementContext } from 'elements/Block/Element';

import { useOption } from './useOption';
import { useStoreSelector } from './useStore';

export const useJoinsPath = (joins: string[], types: string[]) => {
  const element = useContext(ElementContext);
  const canvas = useContext(CanvasStaticContext);

  const path = useStoreSelector((state) => {
    const pathes = joins.map((join) => {
      return state.graphic.options[join]?.path;
    }).filter((path) => {
      return path;
    }).map((path) => {
      return path.split(',');
    }).flat(1);

    const path = new Set([...joins, ...pathes]);

    path.delete(element.id);

    return Array.from(path).filter((id) => {
      return types.includes(state.diagram.file[id]?.type);
    }).join(',');
  });

  useOption({
    id: element.id,
    key: 'path',
    value: path,
    deps: [path],
  });

  useEffect(() => {
    if (!canvas.loading) {
      element.updatePoints();
    }
  }, [canvas.loading]);

  return joins;
};
