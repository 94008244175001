import React, { FC, ReactNode, useContext, useEffect, useLayoutEffect } from 'react';
import { Emitter } from 'utils/events';
import { points } from 'utils/points';

import { storeDiagram } from 'store/diagram';

import { useOption } from 'hooks/useOption';
import { useProperty } from 'hooks/useProperty';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { CanvasStaticContext } from 'elements/Canvas';

import { ElementContext } from './Element';
import { Captured } from './Captured';

export interface IContainer {
  children: ReactNode;

  classElement: string;
  classProperties?: string;
  position: number[];
}

export const Container: FC<IContainer> = ({ children, classElement, classProperties, position }) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);
  const canvas = useContext(CanvasStaticContext);

  const copy = useStoreSelector((state) => state.graphic.copy);
  const elements = useStoreSelector((state) => state.diagram.file[element.id].elements as string[]) ?? [];
  const loading = useStoreSelector((state) => !state.graphic.elements[element.id] || (elements.length > 0 && elements.filter((id: string) => id in state.diagram.file).every((id) => !state.graphic.elements[id])));

  const hidden = useProperty(loading);
  const hiding = useProperty(loading);

  useOption({
    id: element.id,
    key: 'closed',
    value: loading,
    deps: [loading],
  });

  // Set Hidden
  useEffect(() => {
    hidden.set(loading);
  }, [loading]);

  // Initialize points
  useLayoutEffect(() => {
    if (elements.length > 0 && !canvas.loading) {
      element.updatePoints();
    }
  }, [canvas.loading]);

  // Set Hidden Transition
  useLayoutEffect(() => {
    if (!hidden.value) {
      points.update({
        id: element.id,
      });

      setTimeout(() => {
        hiding.set(false);
      }, 300);
    }
  }, [hidden.value]);

  // Set Shape Points
  useLayoutEffect(() => {
    points.shape({
      id: element.id,
    });

    return () => {
      points.unshape({
        id: element.id,
      });
    };
  }, [element.id]);

  // Listen to Move Event
  useEffect(() => {
    if (elements.length > 0) {
      return () => {};
    }

    const onMove = (payload: { id: string, position: [number, number], parent: string }) => {
      if (payload.id !== element.id) {
        return;
      }

      dispatch(storeDiagram.setElement({
        id: payload.id,
        properties: {
          position: payload.position,
          parent: '',
        },
      }));
    };

    Emitter.on('move-element', onMove);

    return () => {
      Emitter.off('move-element', onMove);
    };
  }, [element.id, elements.length > 0]);

  return (
    <div
      className={
        `diagram-element element-container ${classElement}`
          .append(classProperties)
          .appendWhen(element.grabbed && !copy, 'grabbed')
          .appendWhen(element.selected, 'selected')
          .appendWhen(hidden.value, 'hidden')
          .appendWhen(hiding.value, 'hiding')
          .appendWhen(element.locked, 'locked')
          .appendWhen(element.captured, 'captured')
          .appendWhen(element.wrapper, 'wrapper')
          .appendWhen(element.wrapped, 'wrapped')
          .appendWhen(element.wrapping, 'wrapping')
          .appendWhen(element.invisible, 'invisible')
          .appendWhen(element.highlighted, 'highlighted')
          .appendWhen(element.focused, 'focused')
      }
      ref={element.wrap.link}
      style={{
        position: 'absolute',
        left: position[0],
        top: position[1],
      }}
    >
      {!loading && (
        <>
          {children}
          <Captured />
        </>
      )}
    </div>
  );
};
