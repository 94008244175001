import React, { useEffect } from 'react';
import { IConfig } from 'blocks';
import { url } from 'services';

import { useResizer } from 'hooks/useResizer';
import { useProperty } from 'hooks/useProperty';
import { useStoreSelector } from 'hooks/useStore';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';
import { useClickOnSelection } from 'hooks/useClickOnSelection';
import { useArrangementWrapper } from 'hooks/useArrangementWrapper';
import { useTextCreatorWithWrapper } from 'hooks/useTextCreatorWithWrapper';

import { Shape } from 'elements/Block/Shape';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import { createBlock as block } from 'blocks/factories/simple/Block';

import { IModel } from './Creator';

import style from '../assets/Service.module.css';

interface IBlock {
  config: IConfig;
  dir: string;
}

export const createBlock = ({ config, dir }: IBlock) => block<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const exists = useStoreSelector((state) => !state.graphic.elements[element.id] && (!element.parent || !!state.graphic.elements[element.parent]));

    const loading = useProperty(exists);
    const failed = useProperty(false);

    const create = useTextCreatorWithWrapper({ properties: { text: properties.title } });
    const arrangement = useArrangementWrapper();
    const resier = useResizer<HTMLImageElement>({
      strict: true,
      realWidth: properties.realWidth,
      realHeight: properties.realHeight,
      minWidth: 16,
      minHeight: 16,
    });

    useEnterOnSelection({
      onPress: create('enter'),
      mode: 'edit',
    });

    useClickOnSelection({
      onClick: create('click'),
      mode: 'edit',
    });

    // Wait for image to be loaded
    useEffect(() => {
      loading.set(true);

      const img = new Image();

      img.onload = () => {
        loading.set(false);
        failed.set(false);
      };

      img.onerror = (e) => {
        loading.set(false);
        failed.set(true);
      };

      img.src = `${url}/diagrams/assets/${dir}/${properties.file}`;
    }, [properties.file]);

    return (
      <Shape classElement={style.element}>
        <Arrangement {...arrangement} />
        <Selection status={!resier.status} />

        <div
          className={
            `block-content ${style.image}`
              .appendWhen(loading.value, 'unit-loading')
              .appendWhen(!properties.file && failed.value, 'unit-failed')
          }
          {...element.trigger}
          {...resier.wrap}
        >
          <img src={`${url}/diagrams/assets/${dir}/${properties.file}`} alt="" />
        </div>

        <Resizer.Size {...resier.attributes} status={resier.visibility} symmetrically />
      </Shape>
    );
  },
});
