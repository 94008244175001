import { useContext, MouseEvent } from 'react';
import { IArrangement, getArrangement, optimizeLayout } from 'utils/graphic';
import { Emitter } from 'utils/events';
import { getState } from 'utils/store';

import { storeDiagram } from 'store/diagram';

import { CanvasDynamicContext } from 'elements/Canvas';

import { GridContext } from './useGrid';
import { useStoreDispatch } from './useStore';
import { useNeighborGrid } from './useNeighborGrid';
import { useConnectorsRebind } from './useConnectorsRebind';
import { useNeighborConnectors } from './useNeighborConnectors';

interface IPositionGetter {
  direction: number[];
  position: number[];
  element: string | 'new';
}

interface IPositionSetter {
  arrangement: IArrangement;
  element: string | 'new';
}

export const useDragCell = () => {
  const dispatch = useStoreDispatch();

  const canvas = useContext(CanvasDynamicContext);
  const grid = useContext(GridContext);

  const getNeighbor = useNeighborGrid();
  const getConnectors = useNeighborConnectors();
  const rebindConnectors = useConnectorsRebind();

  const getPosition = ({ direction, position, element }: IPositionGetter) => {
    const copy = getState((state) => state.graphic.copy);

    const arrangement = getArrangement(grid.elements);

    if (copy) {
      arrangement.new = [
        position[0] + Math.max(0, direction[0]),
        position[1] + Math.max(0, direction[1]),
      ];
    } else {
      if (!(element in arrangement)) {
        arrangement[element] = [0, 0];
      }

      arrangement[element][0] = position[0] + Math.max(0, direction[0]);
      arrangement[element][1] = position[1] + Math.max(0, direction[1]);
    }

    Object.keys(arrangement).forEach((id) => {
      if (copy ? id === 'new' : id === element) {
        return;
      }

      if (direction[0] !== 0 && arrangement[id][0] > position[0] + Math.min(0, direction[0])) {
        arrangement[id][0] += 1;
      }

      if (direction[1] !== 0 && arrangement[id][1] > position[1] + Math.min(0, direction[1])) {
        arrangement[id][1] += 1;
      }
    });

    if (!copy) {
      optimizeLayout(arrangement);

      grid.loading = true;
    }

    return arrangement;
  };

  const setPosition = ({ arrangement, element }: IPositionSetter) => {
    const copy = getState((state) => state.graphic.copy);
    const file = getState((state) => state.diagram.file);

    Object.keys(arrangement).forEach((id) => {
      if (id !== 'new' && (copy || id !== element) && (arrangement[id][0] !== file[id].position[0] || arrangement[id][1] !== file[id].position[1])) {
        dispatch(storeDiagram.setElement({
          id,
          properties: {
            position: arrangement[id],
          },
        }));
      }
    });
  };

  return {
    getPosition,
    setPosition,
    onDragUp: ({ e, direction, position }: { e: MouseEvent, direction: number[], position: number[] }) => {
      const drag = getState((state) => state.graphic.drag);
      const copy = getState((state) => state.graphic.copy);

      if (!drag) {
        return;
      }

      const neighbor = getNeighbor(position, direction);
      const connectors = getConnectors(neighbor);

      rebindConnectors((!copy && drag.element.id) || '', connectors);

      const element = (!copy && drag.element.id) || 'new';

      const arrangement = getPosition({
        direction,
        position,
        element: drag.element.id || 'new',
      });

      Emitter.emit('drag-up', {
        id: grid.id,
        event: e,
        position: arrangement[element],
        auto: canvas.space ? false : undefined,
      });

      setPosition({
        arrangement,
        element,
      });
    },
  };
};
