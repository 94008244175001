import { useContext, useEffect } from 'react';
import { getState } from 'utils/store';
import { Emitter } from 'utils/events';

import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';

import { useProperty } from './useProperty';
import { rebuildPoints } from './useConnector';
import { usePointPosition } from './usePointPosition';
import { useStoreDispatch, useStoreSelector } from './useStore';

export interface IResizer {
  offset: {
      id: string;
      value: number[];
  };
  visibility: boolean;
  status: boolean;
  attributes: {
      onUpdate: (id: string, offset: number[]) => void;
      onDrop: () => void;
      onDelete: (id: string) => void;
      onStart: (id: string) => void;
      onEnd: (id: string) => void;
  };
}

export const useResizerConnector = (): IResizer => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  const drop = useStoreSelector((state) => state.graphic.drop?.id === element.id);
  const lock = useStoreSelector((state) => !!state.diagram.captured[element.id] || state.diagram.file[element.id].lock);
  const component = useStoreSelector((state) => !!state.graphic.component.key);

  const node = useProperty('');
  const shift = useProperty([0, 0]);
  const active = useProperty(false);
  const moved = useProperty(false);

  const getPosition = usePointPosition();

  const onStart = (id: string) => {
    active.set(true);
    moved.set(false);

    dispatch(storeGraphic.setDrop({
      id: element.id,
      handle: id,
      block: '',
      type: 'move',
    }));
  };

  const onEnd = (id: string) => {
    active.set(false);
    moved.set(false);

    dispatch(storeGraphic.setDrop());
  };

  const onUpdate = (id: string, offset: number[]) => {
    node.set(id);
    shift.set(offset);
  };

  const onDrop = () => {
    node.set('');
    shift.set([0, 0]);
  };

  const onDelete = (id: string) => {
    const points = getState((state) => state.diagram.file[element.id].points as IPoint[]);
    const elbow = getState((state) => state.diagram.file[element.id].style === 'elbow');
    const startFree = getState((state) => state.diagram.file[element.id].startFree);
    const endFree = getState((state) => state.diagram.file[element.id].endFree);

    const point = points.findIndex((point) => {
      return point.id === id && point.role !== 'mayor';
    });

    if (point < 0) {
      return;
    }

    const edit = [{
      id,
      free: false,
      parent: '',
      position: [0, 0],
      point: [0, 0],
    }];

    const balance = {
      point: points[point + 1],
    };

    if (elbow) {
      if (balance.point.role === 'mayor') {
        balance.point = points[point - 1];
      }

      if (balance.point.role !== 'mayor') {
        edit.push({
          id: balance.point.id,
          free: false,
          parent: '',
          position: [0, 0],
          point: [0, 0],
        });
      }
    }

    const vertices = rebuildPoints(points, startFree, endFree, edit);

    dispatch(storeDiagram.setElement({
      id: element.id,
      properties: {
        points: vertices.filter((point) => {
          return point.id !== id && (edit.length === 1 || point.id !== balance.point.id);
        }),
      },
    }));
  };

  useEffect(() => {
    if (!drop) {
      return () => {};
    }

    const onDrop = (payload: any) => {
      const points = getState((state) => state.diagram.file[element.id].points as IPoint[]);
      const startFree = getState((state) => state.diagram.file[element.id].startFree);
      const endFree = getState((state) => state.diagram.file[element.id].endFree);

      const vertices = rebuildPoints(points, startFree, endFree, [{
        id: node.value,
        free: !!payload.id && !payload.direction,
        parent: payload.id,
        position: payload.direction ?? payload.position,
        point: payload.direction ? payload.position : getPosition([payload.event?.clientX || 0, payload.event?.clientY || 0], [0, 0], [0, 0]),
      }]);

      if (vertices === points) {
        return;
      }

      dispatch(storeDiagram.setElement({
        id: element.id,
        properties: {
          points: vertices,
        },
      }));
    };

    Emitter.on('drop-up', onDrop);

    return () => {
      Emitter.off('drop-up', onDrop);
    };
  }, [drop, node.value]);

  return {
    offset: {
      id: node.value,
      value: shift.value,
    },

    visibility: element.selected && !lock && !component,
    status: active.value && !lock && !component,
    attributes: {
      onUpdate,
      onDrop,
      onDelete,

      onStart,
      onEnd,
    },
  };
};
