interface IStore {
  elements: {
    [key: string]: {
      ref: HTMLDivElement;
      position: [number, number];
      offset: [number, number];
      size: [number, number];
    };
  };
  deleted: {
    [key: string]: number;
  };
  parents: {
    [key: string]: string;
  };
  childs: {
    [key: string]: Set<string>;
  };
  shapes: {
    [key: string]: {
      forced: boolean;
      getter: (position: [number, number], size: [number, number]) => [number, number][];
      points: [number, number][];
    };
  };
  renders: {
    [key: string]: Set<() => void>;
  };
  subscribers: {
    [key: string]: Set<() => void>;
  };
  preprocess: {
    [key: string]: Set<(element: HTMLDivElement) => void>;
  };
  links: {
    [key: string]: Set<string>;
  };
  connectors: {
    start: {
      [key: string]: Set<string>;
    };
    middle: {
      [key: string]: Set<string>;
    };
    end: {
      [key: string]: Set<string>;
    };
    childs: {
      [key: string]: Set<string>;
    };
  };
}

const store: IStore = {
  elements: {},
  deleted: {},
  parents: {},
  childs: {},
  shapes: {},
  renders: {},
  subscribers: {},
  preprocess: {},
  links: {},
  connectors: {
    start: {},
    middle: {},
    end: {},
    childs: {},
  },
};

const notify = (id: string) => {
  store.subscribers[id]?.forEach((callback) => {
    callback();
  });

  // console.log('N()', id);
};

const render = (id: string) => {
  store.renders[id]?.forEach((callback) => {
    callback();
  });

  // console.log('R()', id);
};

const preprocess = (id: string) => {
  store.preprocess[id]?.forEach((callback) => {
    if (store.elements[id].ref) {
      callback(store.elements[id].ref);
    }
  });

  // console.log('P()', id);
};

const preprocessParent = (id: string) => {
  const element = store.elements[id];

  if (!element) {
    return;
  }

  preprocess(id);
  preprocessParent(store.parents[id]);
};

const setElement = (id: string, origin: [number, number], reset = true) => {
  const element = store.elements[id];

  if (!element) {
    return {
      changed: false,
    };
  }

  const position = element.position;
  const size = element.size;

  element.size = [
    element.ref.offsetWidth,
    element.ref.offsetHeight,
  ];

  if (element.size[0] === 0 && element.size[1] === 0) {
    element.size = size;
  }

  if (reset) {
    element.offset = [0, 0];
  }

  element.position = [
    origin[0] + element.ref.offsetLeft,
    origin[1] + element.ref.offsetTop,
  ];

  if (position[0] === element.position[0] && position[1] === element.position[1]) {
    element.position = position;
  }

  if (size[0] === element.size[0] && size[1] === element.size[1]) {
    element.size = size;
  }

  const changed = element.position !== position || element.size !== size;

  if (store.shapes[id] && (changed || store.shapes[id].forced)) {
    store.shapes[id].points = store.shapes[id].getter(element.position, element.size);
  }

  if (changed) {
    notify(id);
  }

  if (element.size !== size) {
    render(id);
  }

  return {
    changed,
  };
};

const setChild = (id: string, forced = new Set()) => {
  if (!store.elements[id]) {
    return;
  }

  const origin = store.elements[id].position;

  store.childs[id]?.forEach((child) => {
    const frame = setElement(child, origin);

    if (frame.changed || forced.has(id)) {
      setChild(child, forced);
    }
  });
};

const setParent = (id: string, forced = new Set(), reset = true) => {
  if (!store.elements[id]) {
    return;
  }

  const frame = setElement(id, [0, 0], reset);

  if (frame.changed || forced.has(id)) {
    setChild(id, forced);
  }
};

const getPath = (id: string, includeOnlyShapes = false): string[] => {
  if (!store.elements[id]) {
    return [];
  }

  if (includeOnlyShapes && !store.shapes[id]) {
    return getPath(store.parents[id], includeOnlyShapes);
  }

  return [id, ...getPath(store.parents[id], includeOnlyShapes)];
};

const getChildren = (id: string): string[] => {
  const children: string[] = [];

  const setChildren = (id: string) => {
    if (id in store.shapes) {
      children.push(id);
    }

    store.childs[id]?.forEach((id) => {
      setChildren(id);
    });
  };

  setChildren(id);

  return children;
};

export const points = {
  get store() {
    return store;
  },
  get elements() {
    return store.elements;
  },
  get shapes() {
    return store.shapes;
  },
  get childs() {
    return store.childs;
  },
  get links() {
    return store.links;
  },
  get connectors() {
    return store.connectors;
  },
  path: (id: string) => {
    return getPath(id, true);
  },
  clear: () => {
    store.elements = {};
    store.childs = {};
    store.parents = {};

    // console.log('C');
  },
  init: () => {
    // console.log('--- init ---');

    Object.keys(store.elements)
      .filter((id) => !(id in store.parents))
      .forEach((id) => {
        setParent(id);
      });

    // console.log('---');
  },
  add: ({ id, ref } : { id: string, ref: HTMLDivElement }) => {
    if (id in store.deleted) {
      window.clearTimeout(store.deleted[id]);
    }

    store.elements[id] = {
      ref,
      position: store.elements[id]?.position ?? [0, 0],
      offset: [0, 0],
      size: store.elements[id]?.size ?? [0, 0],
    };

    preprocessParent(id);

    // console.log('A:', id);
  },
  parent: ({ id, parent }: { id: string, parent: string }) => {
    if (!parent) {
      return;
    }

    store.parents[id] = parent;

    if (!(parent in store.childs)) {
      store.childs[parent] = new Set();
    }

    store.childs[parent].add(id);

    // console.log('P+:', id, parent, store.childs[parent]);
  },
  unparent: ({ id, parent }: { id: string, parent: string }) => {
    delete store.parents[id];

    if (!(parent in store.childs)) {
      // console.log('P-{}:', id, parent);

      return;
    }

    store.childs[parent]?.delete(id);

    if (store.childs[parent] && store.childs[parent].size === 0) {
      delete store.childs[parent];
    }

    // console.log('P-:', id, parent, store.childs[parent]);
  },
  remove: ({ id } : { id: string }) => {
    if (id in store.deleted) {
      window.clearTimeout(store.deleted[id]);
    }

    store.deleted[id] = window.setTimeout(() => {
      delete store.elements[id];
    }, 0);

    // console.log('R:', id);
  },
  shape: ({ id, getter, forced = false } : { id: string; forced?: boolean; getter?: (position: [number, number], size: [number, number]) => [number, number][]; }) => {
    store.shapes[id] = {
      forced,
      points: [],
      getter: getter || ((position: [number, number], size: [number, number]) => ([
        [position[0], position[1]],
        [position[0] + size[0], position[1]],
        [position[0] + size[0], position[1] + size[1]],
        [position[0], position[1] + size[1]],
      ])),
    };

    // console.log('S+:', id);
  },
  unshape: ({ id } : { id: string }) => {
    delete store.shapes[id];

    // console.log('S-:', id);
  },
  update: ({ id, reset = true } : { id: string, reset?: boolean }) => {
    const path = getPath(id);

    if (path.length === 0) {
      // console.log('U-:', id, path);

      return;
    }

    preprocessParent(id);
    setParent(path[path.length - 1], new Set(path), reset);

    // console.log('U:', id, path);
  },
  move: ({ id, offset, silent = false } : { id: string; offset: [number, number], silent?: boolean }) => {
    const path = getChildren(id);

    path.forEach((id) => {
      if (!store.shapes[id] || !store.elements[id]) {
        return;
      }

      store.elements[id].offset = offset;

      store.shapes[id].points = store.shapes[id].getter(store.elements[id].position, store.elements[id].size).map((point) => [
        point[0] + offset[0],
        point[1] + offset[1],
      ]);

      if (!silent) {
        notify(id);
      }
    });

    // console.log('M:', id, path);
  },
  preprocess: ({ id, callback } : { id: string; callback: (element: HTMLDivElement) => void }) => {
    if (!store.preprocess[id]) {
      store.preprocess[id] = new Set();
    }

    store.preprocess[id].add(callback);
  },
  unpreprocess: ({ id, callback } : { id: string; callback: (element: HTMLDivElement) => void }) => {
    if (!store.preprocess[id]) {
      store.preprocess[id] = new Set();
    }

    store.preprocess[id].add(callback);
  },
  subscribe: ({ provider, subscriber, callback, type } : { provider: string; type?: 'start' | 'middle' | 'end'; subscriber: string; callback: () => void }) => {
    if (!store.subscribers[provider]) {
      store.subscribers[provider] = new Set();
    }

    store.subscribers[provider].add(callback);

    if (!(provider in store.links)) {
      store.links[provider] = new Set();
    }

    store.links[provider].add(subscriber);

    if (type === 'start') {
      if (!(provider in store.connectors.start)) {
        store.connectors.start[provider] = new Set();
      }

      store.connectors.start[provider].add(subscriber);
    }

    if (type === 'middle') {
      if (!(provider in store.connectors.middle)) {
        store.connectors.middle[provider] = new Set();
      }

      store.connectors.middle[provider].add(subscriber);

      if (!(subscriber in store.connectors.childs)) {
        store.connectors.childs[subscriber] = new Set();
      }

      store.connectors.childs[subscriber].add(provider);
    }

    if (type === 'end') {
      if (!(provider in store.connectors.end)) {
        store.connectors.end[provider] = new Set();
      }

      store.connectors.end[provider].add(subscriber);
    }
  },
  unsubscribe: ({ provider, subscriber, callback } : { provider: string; subscriber: string; callback: () => void }) => {
    store.subscribers[provider]?.delete(callback);

    if (store.subscribers[provider]?.size === 0) {
      delete store.subscribers[provider];
    }

    store.links[provider]?.delete(subscriber);
    store.connectors.start[provider]?.delete(subscriber);
    store.connectors.middle[provider]?.delete(subscriber);
    store.connectors.end[provider]?.delete(subscriber);
    store.connectors.childs[subscriber]?.delete(provider);

    if (store.links[provider] && store.links[provider].size === 0) {
      delete store.links[provider];
    }

    if (store.connectors.start[provider] && store.connectors.start[provider].size === 0) {
      delete store.connectors.start[provider];
    }

    if (store.connectors.middle[provider] && store.connectors.middle[provider].size === 0) {
      delete store.connectors.middle[provider];
    }

    if (store.connectors.end[provider] && store.connectors.end[provider].size === 0) {
      delete store.connectors.end[provider];
    }

    if (store.connectors.childs[subscriber] && store.connectors.childs[subscriber].size === 0) {
      delete store.connectors.childs[subscriber];
    }
  },
  render: ({ id, callback } : { id: string; callback: () => void }) => {
    if (!store.renders[id]) {
      store.renders[id] = new Set();
    }

    store.renders[id].add(callback);

    // console.log('SR', id);
  },
  unrender: ({ id, callback } : { id: string; callback: () => void }) => {
    store.renders[id]?.delete(callback);

    if (store.renders[id]?.size === 0) {
      delete store.renders[id];
    }

    // console.log('SR-', id);
  },
  draw: ({ id } : { id: string; }) => {
    render(id);
  },
  notify: ({ id } : { id: string; }) => {
    notify(id);
  },
};
