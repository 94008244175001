import React, { ComponentType, useContext } from 'react';

import { useMemoProperties } from 'hooks/useMemoProperties';
import { useStoreSelector } from 'hooks/useStore';

import { ElementContext, IElementContext } from 'elements/Block/Element';

export interface IElement<T> {
  properties: Extract<T & { parent: string; position: [number, number] }, Record<string, any>>;
  element: IElementContext;
}

interface IBlock {
  id: string;
}

interface IBlockConfig<T> {
  memo: {
    key: string;
    properties: string[];
  };
  block: ComponentType<IElement<T>>;
}

export const createBlock = <T, > ({ memo, block: Block } : IBlockConfig<T>) => {
  return ({ id }: IBlock) => {
    const properties = useStoreSelector((state) => state.diagram.file[id]);
    const element = useContext(ElementContext);

    useMemoProperties(properties, memo.key, memo.properties);

    return (
      <Block
        properties={properties}
        element={element}
      />
    );
  };
};
