import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { Button } from 'elements/Block/Button';

import { createBlock } from 'blocks/factories/service/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/service/Creator';

import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Cisco',
  search: 'icon cisco cloud architecture',
  type: 'cisco',
  group: ['Cisco'],
  memo: {
    key: 'cisco',
    styles: [],
  },
  styles: [
    'width',
    'height',
  ],
};

const Block = createBlock({
  dir: 'cisco',
  config,
});

export const ServiceCisco : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-wrap', 'element-wrapable', 'menu-button-hidden'],
    constraints: {},
    onCreate: createCreator({
      tab: 'cisco',
      config,
      size: [82, 82],
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [82, 82],
    icon,
    class: {
      title: 'Cisco',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'cisco',
  },
  tools: [
    'cisco',
    'lock',
    'delete',
  ],
  toolbars: [

  ],
};
