import React, { FC, ReactNode, useContext, useEffect, useLayoutEffect } from 'react';
import { Emitter } from 'utils/events';
import { points } from 'utils/points';

import { storeDiagram } from 'store/diagram';

import { useProperty } from 'hooks/useProperty';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { useClosed } from 'blocks/Information/Connector/hooks/useClosed';

import { ElementContext } from './Element';
import { Captured } from './Captured';

export interface IShape {
  children: ReactNode;

  classElement: string;
  classProperties?: string;

  shape?: (position: [number, number], size: [number, number]) => [number, number][];
  drawn?: boolean;
}

export const Shape: FC<IShape> = ({ children, classElement, classProperties, shape, drawn }) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  const drop = useStoreSelector((state) => !!state.graphic.drop);
  const copy = useStoreSelector((state) => state.graphic.copy);
  const parent = useStoreSelector((state) => state.diagram.file[element.id].parent);
  const position = useStoreSelector((state) => state.diagram.file[element.id].position);
  const frame = useStoreSelector((state) => !!(state.graphic.drag?.element.id === element.id && state.graphic.layout.key && !state.graphic.copy));
  const loading = useStoreSelector((state) => (!state.graphic.draw || !drawn || (element.id in state.diagram.cloud)) && !state.graphic.elements[element.id] && (!parent || !!state.graphic.elements[parent]));

  const hidden = useProperty(loading && !element.loading);
  const hiding = useProperty(loading && !element.loading);

  const closed = useClosed();

  // Set Hidden
  useEffect(() => {
    hidden.set(loading);
  }, [loading]);

  // Set Hidden Transition
  useEffect(() => {
    if (!hidden.value) {
      setTimeout(() => {
        hiding.set(false);
      }, 300);
    }
  }, [hidden.value]);

  // Set Shape Points
  useLayoutEffect(() => {
    points.shape({
      id: element.id,
      getter: shape,
    });

    return () => {
      points.unshape({
        id: element.id,
      });
    };
  }, [element.id, shape]);

  // Update Frame Points on Position Change
  useLayoutEffect(() => {
    if (!parent) {
      element.updatePoints();
    }
  }, [position[0], position[1]]);

  // Listen to Move Event
  useEffect(() => {
    const onMove = (payload: { id: string, position: [number, number], parent: string }) => {
      if (payload.id !== element.id) {
        return;
      }

      dispatch(storeDiagram.setElement({
        id: payload.id,
        properties: {
          position: payload.position,
          parent: payload.parent,
        },
      }));
    };

    Emitter.on('move-element', onMove);

    return () => {
      Emitter.off('move-element', onMove);
    };
  }, [element.id]);

  return (
    <div
      className={
        `diagram-element element-shape ${classElement}`
          .append(classProperties)
          .appendWhen(element.grabbed && !copy, 'grabbed')
          .appendWhen(element.selected, 'selected')
          .appendWhen(hidden.value, 'hidden')
          .appendWhen(hiding.value, 'hiding')
          .appendWhen(frame && !copy, 'frame')
          .appendWhen(element.locked, 'locked')
          .appendWhen(element.nested, 'nested')
          .appendWhen(element.wrapper, 'wrapper')
          .appendWhen(element.wrapped, 'wrapped')
          .appendWhen(element.focused, 'focused')
          .appendWhen(element.wrapping, 'wrapping')
          .appendWhen(element.captured, 'captured')
          .appendWhen(element.containered, 'containered')
          .appendWhen(element.highlighted, 'highlighted')
          .appendWhen(element.invisible, 'invisible')
          .appendWhen(drop && copy && element.wrapped && !element.wrapping, 'pointerable')
      }
      ref={element.wrap.link}
      style={{
        position: parent ? undefined : 'absolute',
        left: parent ? undefined : position[0],
        top: parent ? undefined : position[1],
      }}
    >
      {!closed && (
        <>
          {children}
          <Captured />
        </>
      )}
    </div>
  );
};
