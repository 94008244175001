import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { Cap1 } from './Cap1';
import { Cap0 } from './Cap0';
import { Cap2 } from './Cap2';
import { Cap3 } from './Cap3';
import { Cap4 } from './Cap4';
import { Cap5 } from './Cap5';
import { Cap6 } from './Cap6';
import { Cap7 } from './Cap7';
import { Cap8 } from './Cap8';
import { Cap9 } from './Cap9';
import { Cap10 } from './Cap10';

export interface IConnector {
  Start: React.ComponentType<ICap>,
  End: React.ComponentType<ICap>,
}

export const Start: Record<number, React.ComponentType<ICap>> = {
  0: Cap0.Start,
  1: Cap1.Start,
  2: Cap2.Start,
  3: Cap3.Start,
  4: Cap4.Start,
  5: Cap5.Start,
  6: Cap6.Start,
  7: Cap7.Start,
  8: Cap8.Start,
  9: Cap9.Start,
  10: Cap10.Start,
};

export const End: Record<number, React.ComponentType<ICap>> = {
  0: Cap0.End,
  1: Cap1.End,
  2: Cap2.End,
  3: Cap3.End,
  4: Cap4.End,
  5: Cap5.End,
  6: Cap6.End,
  7: Cap7.End,
  8: Cap8.End,
  9: Cap9.End,
  10: Cap10.End,
};
