import React, { useContext } from 'react';
import { getState } from 'utils/store';
import { Emitter } from 'utils/events';

import { ElementContext } from 'elements/Block/Element';

import { usePointPosition } from './usePointPosition';

export const useDropSnaply = () => {
  const element = useContext(ElementContext);

  const getPosition = usePointPosition();

  return {
    onDropDown: (e: React.MouseEvent, direction: number[]) => {
      const drop = getState((state) => state.graphic.drop);

      if (drop?.type !== 'create') {
        return;
      }

      const position = getPosition([e?.clientX || 0, e?.clientY || 0], [0, 0], [0, 0]);

      Emitter.emit('drop-down', {
        id: element.id,
        direction,
        position,
        event: e,
      });
    },
    onDropUp: (e: React.MouseEvent, direction: number[]) => {
      const drop = getState((state) => state.graphic.drop);

      if (drop?.type !== 'move') {
        return;
      }

      const position = getPosition([e?.clientX || 0, e?.clientY || 0], [0, 0], [0, 0]);

      Emitter.emit('drop-up', {
        id: element.id,
        direction,
        position,
        event: e,
      });
    },
  };
};
