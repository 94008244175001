import { useContext, useLayoutEffect } from 'react';
import { points } from 'utils/points';

import { CanvasStaticContext } from 'elements/Canvas';

import { GridContext } from './useGrid';

interface IPointsElement {
  id: string;
  parent: string;
  ref: HTMLDivElement | null;
}

export const usePointsElement = ({ id, parent, ref }: IPointsElement) => {
  const canvas = useContext(CanvasStaticContext);
  const grid = useContext(GridContext);

  // Parent
  useLayoutEffect(() => {
    if (!parent) {
      return () => {};
    }

    points.parent({ id, parent });

    return () => {
      points.unparent({ id, parent });
    };
  }, [id, parent]);

  // Create
  useLayoutEffect(() => {
    if (!ref) {
      return () => {};
    }

    points.add({ id, ref: ref });

    return () => {
      points.remove({ id });
    };
  }, [id, ref]);

  // Update
  useLayoutEffect(() => {
    if (ref && !canvas.loading && !grid.loading) {
      points.update({ id });
    }
  }, [ref, parent]);
};
