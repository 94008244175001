import React, { FC, useContext } from 'react';
import optimizers from 'utils/optimizers';

import { useStoreSelector } from 'hooks/useStore';

import { ElementContext } from 'elements/Block/Element';

import { View } from './View';
import { Edit } from './Edit';

import style from '../../assets/style.module.css';

export const config = {
  key: 'stereotype',
};

export interface IComponent {
  value: string;
  property: string;
}

const Component: FC<IComponent> = ({ value, property }) => {
  const element = useContext(ElementContext);

  const editing = useStoreSelector((state) => !(state.diagram.file[element.id]?.lock || state.diagram.captured[element.id] || state.graphic.options[element.id]?.invisible) && state.diagram.mode === 'edit' && state.graphic.component.key === config.key);

  if ((!editing || !element.selected) && !optimizers.noHtml(value).trim()) {
    return null;
  }

  return (
    <div className={style.stereotype}>
      {element.selected && editing ? (
        <Edit value={value} property={property} />
      ) : (
        <View value={value} property={property} />
      )}
    </div>
  );
};

export const Stereotype = {
  Component,
  toolbar: {
    key: config.key,
    tools: [
      'link-back',
      'format-link',
      'format-highlight-common',
      'format-bold',
      'format-italic',
      'format-underline',
      'format-strike',
    ],
  },
};
