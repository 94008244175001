import { blocks } from 'blocks';
import { lines } from 'utils/lines';
import { points } from 'utils/points';
import { getState } from 'utils/store';

import { IPoint } from 'elements/Block/Connector';

import { INeighbor } from './useNeighborGrid';

export const useNeighborConnectors = () => {
  return (neighbor: INeighbor) => {
    if (!neighbor.id) {
      return [];
    }

    const drag = getState((state) => state.graphic.drag);
    const copy = getState((state) => state.graphic.copy);
    const file = getState((state) => state.diagram.file);

    const connectors = [] as string[];
    const queue = lines.queue(neighbor.id, neighbor.side)?.toArray() as unknown as string[] ?? [];
    const links = points.links[drag?.element.id ?? '_'];

    queue.forEach((line) => {
      const id = line.split('.')[0];

      if (!copy && links?.has(id)) return;
      if (file[id]?.points?.find((point: IPoint) => point.parent && point.role === 'minor')) return;
      if (blocks[file[id]?.type]?.element.options.includes('no-rebinding')) return;

      connectors.push(line);
    });

    return connectors;
  };
};
