import React, { FC, ReactNode } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactDOM from 'react-dom';

import { OptionAnimation } from 'store/settings';

import { useAnimation } from 'hooks/useAnimation';
import { useStoreSelector } from 'hooks/useStore';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/Modal.css';

interface IModal {
  variant: 'v-1' | 'v-2' | 'v-3';
  children: ReactNode;
  onBackdropClick?: () => void;
  maxWidth?: boolean;
}

const root = document.getElementById('modal-root') as HTMLElement;

export const Modal: FC<IModal> = ({ variant, children, onBackdropClick, maxWidth = false }) => {
  const animation = useStoreSelector((state) => state.settings.app.animation);

  const backAnimation = useAnimation(animation.includes(OptionAnimation.Modal) ? 'animation-fade' : '');
  const wrapAnimation = useAnimation(animation.includes(OptionAnimation.Modal) ? 'animation-scale' : '');

  const onClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      if (onBackdropClick) {
        onBackdropClick();
      }
    }
  };

  return ReactDOM.createPortal(
    (
      <div className={'modal'.append(variant).appendWhen(maxWidth, 'max-width')}>
        <PerfectScrollbar>
          <div className="modal-window">
            <div className={`modal-back ${backAnimation.name}`} onClick={onClick} onKeyDown={onKeyDown} role="button" tabIndex={0}> </div>
            <div className={`modal-wrap ${wrapAnimation.name}`}>
              {children}
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    ),
    root
  );
};
