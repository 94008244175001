import React, { memo, useLayoutEffect } from 'react';
import { IBlock, IConfig, blocks } from 'blocks';
import { getState } from 'utils/store';

import { useGrid } from 'hooks/useGrid';
import { useStoreSelector } from 'hooks/useStore';
import { useTextSelector } from 'hooks/useTextSelector';
import { usePlacingAlignment } from 'hooks/usePlacingAlignment';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';
import { useClickOnSelection } from 'hooks/useClickOnSelection';
import { useArrangementElement } from 'hooks/useArrangementElement';

import { Frame } from 'elements/Wrap/Frame';
import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';
import { Wrapper as Wrap } from 'elements/Block/Wrapper';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Wrapper',
  type: 'core-wrapper',
  search: 'core wrapper',
  group: ['Core'],
  memo: {
    key: 'core-wrapper',
    styles: [
      'placing',
      'alignHorizontal',
      'alignVertical',
    ],
  },
  styles: [
    'placing',
    'alignHorizontal',
    'alignVertical',
  ],
};

interface IModel {
  placing: 'top' | 'left' | 'right' | 'bottom';
  alignHorizontal: 'left' | 'center' | 'right';
  alignVertical: 'top' | 'center' | 'bottom';
  lockKey: string;
  lock: boolean;
}

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const sticker = useStoreSelector((state) => blocks[state.graphic.drag?.element.type ?? '_']?.element.options.includes('element-sticker'));
    const file = getState((state) => state.diagram.file);

    const arrangement = useArrangementElement();
    const grid = useGrid();
    const select = useTextSelector();
    const vertical = usePlacingAlignment();

    const inline = blocks[file[grid.layout[1]?.[0]]?.type]?.element.options.includes('element-text');

    useEnterOnSelection({
      onPress: select('enter'),
      mode: 'edit',
    });

    useClickOnSelection({
      onClick: select('click'),
      mode: 'edit',
    });

    // Update Points on Property Change
    useLayoutEffect(() => {
      element.updatePoints();
    }, [properties.placing, properties.alignHorizontal, properties.alignVertical]);

    return (
      <Shape classElement={style.element.appendWhen(inline && !vertical, style.inline).appendWhen(sticker, style.opened)}>
        <Arrangement status={!sticker} {...arrangement} />
        <Selection />
        <Frame />

        <div
          className={
            'block-content'
              .append(style[`placing-${properties.placing}`])
              .append(style[`align-${vertical ? properties.alignHorizontal : properties.alignVertical}`])
          }
          {...(element.focused ? (!element.selected ? {} : { onMouseDown: element.trigger.onMouseDown }) : {})}
        >
          {Array.from(Array(grid.size[0] * grid.size[1]).keys()).map((i) => {
            const y = Math.floor(i / grid.size[0]);
            const x = i - y * grid.size[0];

            const child = grid.layout[y] ? (grid.layout[y][x] ?? '') : '';

            if (!child) {
              return null;
            }

            return (
              <div key={child} className={style.wrap}>
                <Wrap id={child} point={[x, y]} />
              </div>
            );
          })}
        </div>
      </Shape>
    );
  },
});

export const CoreWrapper : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-wrap', 'element-wrap-grid', 'element-wrap-inline', 'lock-delete'],
    constraints: {
      prevent: {
        placing: [
          'top-left',
          'top-right',
          'left-top',
          'left-bottom',
          'bottom-left',
          'bottom-right',
          'right-top',
          'right-bottom',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        placing: 'bottom',
        alignVertical: 'center',
        alignHorizontal: 'center',
        lockKey: '',
        lock: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [64, 64],
    icon,
    class: {
      title: 'Wrapper',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [
    'placing',
    'align-horizontal',
    'align-vertical',
    'lock',
    'delete-wrap',
    'delete',
  ],
  toolbars: [

  ],
};
