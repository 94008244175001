import React, { memo, useEffect, useLayoutEffect } from 'react';
import { IBlock, IConfig } from 'blocks';
import { getState } from 'utils/store';

import { serviceAccount } from 'services/account';

import { useWrap } from 'hooks/useWrap';
import { useResizer } from 'hooks/useResizer';
import { usePadding } from 'hooks/usePadding';
import { useTextCreator } from 'hooks/useTextCreator';
import { useCacheProperty } from 'hooks/useCacheProperty';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';
import { useClickOnSelection } from 'hooks/useClickOnSelection';
import { useArrangementElement } from 'hooks/useArrangementElement';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { createBlock } from 'blocks/factories/simple/Block';
import { createRender } from 'blocks/factories/simple/Render';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';
import { createPadding } from 'blocks/factories/simple/Padding';
import design from 'blocks/factories/assets/Simple.module.css';

import { Wrap } from 'elements/Block/Wrap';
import { Frame } from 'elements/Wrap/Frame';
import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

const { serialize } = require('svg-path-round-corners/dist/es6/serialize');
const { roundCorners } = require('svg-path-round-corners/dist/es6/index');

interface IModel {
  color: string;
  owner: string;
  width?: number;
  height?: number;
  detailed: boolean;
  lockKey: string;
  lock: boolean;
  hidden: boolean;
}

export const config: IConfig = {
  title: 'Sticky Note',
  search: 'information shape sticky note text',
  type: 'information-shape-sticky-note',
  group: ['Information'],
  memo: {
    key: 'information-note',
    styles: [
      'color',
      'detailed',
    ],
  },
  styles: [
    'color',
    'detailed',
  ],
};

const Render = createRender<IModel>({
  render: ({ width, height, element }) => {
    const hidden = useCacheProperty('hidden') ?? false;

    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - offset, 0);
    const y = Math.max(height - offset, 0);

    const a = 16;

    const points = [
      { c: 'M', x: x - a, y: y },
      { c: 'H', x: offset },
      { c: 'V', y: offset },
      { c: 'H', x: x },
      { c: 'V', y: y - a },
      { c: 'Z' },
    ];

    const corner = [
      { c: 'M', x: x - offset, y: y - a },
      { c: 'C', x1: x - offset, y1: y - a, x2: x - a, y2: y - offset, x: x - a, y: y - offset },
      { c: 'V', y: y - a },
      { c: 'Z' },
    ];

    return (
      <svg className="block-render" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path {...element.trigger} className="render-fill render-hit" d={serialize(roundCorners(points, 6))} strokeLinejoin="round" strokeLinecap="round" strokeWidth={border} />
        <path className="render-stroke-fill" opacity="0.75" d={serialize(roundCorners(corner, 6))} strokeLinejoin="round" strokeLinecap="round" strokeWidth={border} />

        {hidden && (
          <svg x={(width - 50) / 2} y={(height - 26) / 2} width="50" height="26" viewBox="0 0 50 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="render-stroke-color" d="M2.39655 2C3.92877 4.04339 5.66488 5.82091 7.55172 7.32589M47.6034 2C46.0273 4.00704 44.2947 5.74284 42.4483 7.21275M7.55172 7.32589L2 17.6538M7.55172 7.32589C10.9914 10.0695 14.9321 11.9073 19.0517 12.7991M19.0517 12.7991L17.4655 23.5769M19.0517 12.7991C22.9479 13.6425 27.0042 13.6396 30.9483 12.7562M30.9483 12.7562L32.931 24M30.9483 12.7562C34.9983 11.8491 38.9299 10.0137 42.4483 7.21275M42.4483 7.21275L48 17.2308" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        )}
      </svg>
    );
  },
});

const Padding = createPadding<IModel>({
  size: [0, 0],
  padding: [1, 1, 1, 1],
  creator: () => {
    return [4, 0, 4, 0];
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const dispatch = useStoreDispatch();

    const owner = useStoreSelector((state) => state.cache.users[properties.owner]);
    const offline = useStoreSelector((state) => state.settings.offline);

    const detailed = useCacheProperty('detailed') ?? true;

    const wrap = useWrap();
    const resier = useResizer();
    const arrangement = useArrangementElement();
    const create = useTextCreator();

    usePadding(Padding, wrap.element);

    useEnterOnSelection({
      onPress: create('enter'),
      mode: 'edit',
    });

    useClickOnSelection({
      onClick: create('click'),
      mode: 'edit',
    });

    // Load user
    useEffect(() => {
      if (owner || !properties.owner || !detailed) {
        return;
      }

      dispatch(serviceAccount.getUser({
        id: properties.owner,
      }));
    }, [offline, detailed]);

    // Update Points on Property Change
    useLayoutEffect(() => {
      element.updatePoints();
    }, [detailed]);

    return (
      <Shape
        classElement={style.element}
        classProperties={`${design[`color-${properties.color}`]} ${style[`color-${properties.color}`]}`}
      >
        <Arrangement {...arrangement} />
        <Render properties={properties} />
        <Selection status={!resier.status} />
        <Frame disabled={!element.selected} />

        {detailed && (
          <div className={`block-render ${style.owner}`}>
            {owner?.name ?? 'Loading...'}
          </div>
        )}

        <div
          className={'block-content'.appendWhen(wrap.status, style.wrap)}
          {...(element.focused ? (!element.selected ? {} : { onMouseDown: element.trigger.onMouseDown }) : {})}
          {...resier.wrap}
        >
          <Wrap {...wrap.attributes} />
        </div>

        <Resizer.Size {...resier.attributes} status={resier.visibility} minWidth={24} minHeight={24} />
      </Shape>
    );
  },
});

export const InformationShapeStickyNote : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-wrap', 'text-limiter', 'container-title-none', 'element-wrap-hidden'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        owner: '',
        color: 'yellow',
        width: 116,
        height: 116,
        detailed: true,
        lockKey: '',
        lock: false,
        hidden: false,
      },
      condition: (payload) => {
        if (payload.canvas?.space && !payload.parent) {
          payload.properties = {
            ...(payload.properties ?? {}),

            deletable: false,
          };
        }

        payload.properties = {
          ...(payload.properties ?? {}),

          owner: getState((state) => {
            return state.account.user.id;
          }),
        };

        return payload;
      },
    }),
    onCopy: createCopier({
      type: config.type,
      condition: (payload) => {
        payload.target = {
          ...(payload.target ?? {}),

          owner: getState((state) => {
            return state.account.user.id;
          }),
        };

        return payload;
      },
    }),
    title: config.title,
    size: [116, 116],
    icon,
    class: {
      title: 'Sticky Note',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit', 'block-core'],
    type: 'drag',
  },
  tools: [
    'color',
    'detailed',
    'hidden',
    'lock',
    'delete-wrap',
    'delete',
  ],
  toolbars: [

  ],
};
