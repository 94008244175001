import { useEffect, useState } from 'react';
import { delay } from 'utils';

interface IUseAnimationResult {
  name: string;
  reset: () => void;
}

export const useAnimation = (className: string): IUseAnimationResult => {
  const [animation, setAnimation] = useState(className);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (className) {
      setTimer(window.setTimeout(() => {
        setAnimation('');
      }, 250));
    }
  }, []);

  return {
    name: animation,
    reset: () => {
      if (className) {
        window.clearTimeout(timer);
        setAnimation(className);
        setTimer(window.setTimeout(() => {
          setAnimation('');
        }, 250));
      }
    },
  };
};
