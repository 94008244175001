import { storeCache } from 'store/cache';

import { useStoreDispatch, useStoreSelector } from './useStore';
import { useUpdateEffect } from './useUpdateEffect';

export const useMemoProperties = <T extends {[key: string]: any}> (element: T, key: string, properties: string[]) => {
  const dispatch = useStoreDispatch();

  const id = useStoreSelector((state) => state.diagram.diagram.id);

  useUpdateEffect(() => {
    dispatch(storeCache.setGroup({
      id,
      data: {
        [key]: Object.fromEntries(properties.map((property) => [property, element[property]])),
      },
    }));
  }, [...properties.map((property) => element[property])]);
};
