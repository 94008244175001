import { useMemo } from 'react';

import { IPoint } from 'elements/Block/Connector';

import { ILever } from './useConnectorSmooth';

const config = {
  padding: 24,
};

export interface IPicture {
  padding: number[];
  margin: {
    marginLeft: number;
    marginTop: number;
    marginRight: number;
    marginBottom: number;
  };
  position: number[];
  width: number;
  height: number;
}

export const useConnectorPicture = (points: IPoint[], levers: ILever[]) => {
  return useMemo(() => {
    const border = points.reduce((frame, { position }) => {
      return [
        Math.min(frame[0], position[0]),
        Math.min(frame[1], position[1]),
        Math.max(frame[2], position[0]),
        Math.max(frame[3], position[1]),
      ];
    }, [
      points[0].position[0],
      points[0].position[1],
      points[0].position[0],
      points[0].position[1],
    ]);

    const outline = levers.reduce((frame, { left, right }) => {
      return [
        Math.min(frame[0], left[0], right[0]),
        Math.min(frame[1], left[1], right[1]),
        Math.max(frame[2], left[0], right[0]),
        Math.max(frame[3], left[1], right[1]),
      ];
    }, [
      levers[0].left[0],
      levers[0].left[1],
      levers[0].left[0],
      levers[0].left[1],
    ]);

    const frame = [
      Math.min(border[0], outline[0]),
      Math.min(border[1], outline[1]),
      Math.max(border[2], outline[2]),
      Math.max(border[3], outline[3]),
    ];

    const padding = [
      Math.max(border[0] - frame[0], 0) + config.padding / 2,
      Math.max(border[1] - frame[1], 0) + config.padding / 2,
      Math.max(frame[2] - border[2], 0) + config.padding / 2,
      Math.max(frame[3] - border[3], 0) + config.padding / 2,
    ];

    frame[0] -= config.padding / 2;
    frame[1] -= config.padding / 2;
    frame[2] += config.padding / 2;
    frame[3] += config.padding / 2;

    const position = [
      frame[0] + padding[0],
      frame[1] + padding[1],
    ];

    return {
      padding,
      margin: {
        marginLeft: -padding[0],
        marginTop: -padding[1],
        marginRight: -padding[2],
        marginBottom: -padding[3],
      },
      position,
      width: frame[2] - position[0] + padding[0],
      height: frame[3] - position[1] + padding[1],
    };
  }, [points, levers]);
};
