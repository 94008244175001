import { ITool } from 'tools';
import { getState } from 'utils/store';
import { createTool } from 'tools/ToolBasic';

import icon from './assets/icon.svg';

const Tool = createTool({
  key: 'transparent',
  def: true,
  title: 'Transparent',
  alt: 'Transparent',
  icon,
  getStatus: () => {
    return getState((state) => {
      const selection = state.graphic.selection.elements;
      const renders = [0, 2, 4];

      return !selection.every((id) => {
        return !(renders.includes(state.diagram.file[id]?.render));
      });
    });
  },
});

export const Transparent: ITool = {
  key: 'transparent',
  tool: Tool,
  options: ['only-edit'],
};
