import React from 'react';
import { ITool } from 'tools';

import { createFormatTool } from 'tools/ToolFormat';

import icon from './assets/icon.svg';

const Tool = createFormatTool({
  icon,
  title: 'Bulleted list',
  format: 'list',
  condition: (value) => value === 'bullet',
  filter: (value) => value === 'bullet',
  set: (status) => (status ? '' : 'bullet'),
});

export const FormatListBullet: ITool = {
  key: 'format-list-bullet',
  tool: Tool,
  options: ['only-edit'],
};
