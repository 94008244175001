import React, { FC, useContext } from 'react';

import { useStoreSelector } from 'hooks/useStore';

import { ElementContext } from 'elements/Block/Element';

import { View } from './View';
import { Edit } from './Edit';

export const config = {
  key: 'text',
};

export interface IComponent {
  value: string;
  property: string;
  placeholder: string;
}

const Component: FC<IComponent> = ({ value, property, placeholder }) => {
  const element = useContext(ElementContext);

  const editing = useStoreSelector((state) => !(state.diagram.file[element.id]?.lock || state.diagram.captured[element.id] || state.graphic.options[element.id]?.invisible) && state.diagram.mode === 'edit' && state.graphic.component.key === config.key);
  const statement = useStoreSelector((state) => state.diagram.file[element.id]?.placeholder ?? placeholder);

  if (element.selected && editing) {
    return <Edit value={value} property={property} placeholder={statement} />;
  }

  return <View value={value} property={property} placeholder={statement} />;
};

export const Text = {
  Component,
  toolbar: {
    key: config.key,
    tools: [
      'link-back',
      'format-alignment',
      'format-h1',
      'format-h2',
      'format-code-block',
      'format-list-bullet',
      'format-list-number',
      'format-link',
      'format-code',
      'format-highlight-common',
      'format-bold',
      'format-italic',
      'format-underline',
      'format-strike',
    ],
  },
};
