import { RootState, store } from 'store';
import { isMatch } from 'utils';
import { blocks } from 'blocks';

import { storeCache } from 'store/cache';
import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';
import { serviceAlert } from 'services/alert';
import { serviceDiagram } from 'services/diagram';

import { storage } from 'hooks/useClipboard';

import { points } from './points';
import { Emitter } from './events';

export const MAX_IMAGE_SIZE = 2048;
export const TOOLS_MAX_COUNT = 13;

export const getState = <T> (selector: (state: RootState) => T) : T => {
  const state = store.getState();
  const result = selector(state);

  return result;
};

export const getContainers = (elements: string[]) => {
  const file = getState((state) => state.diagram.file);

  const getList = (elements: string[]): string[] => {
    const containers = elements.filter((id) => {
      return blocks[file[id]?.type]?.element.options.includes('element-container');
    });

    return [...containers, ...containers.map((id) => getList(file[id]?.elements ?? [])).flat(1)];
  };

  return Array.from(new Set(getList(elements)));
};

export const getPath = (id: string, state: { file: Record<string, { parent: string }> }): string[] => {
  if (!state.file[id]) {
    return [];
  }

  return [id, ...getPath(state.file[id].parent, state)];
};

export const getTree = (id: string, state: { childs: Record<string, string[]> }): string[] => {
  const childs = state.childs[id] || [];

  if (childs.length === 0) {
    return [id];
  }

  return [id, ...childs.flatMap((child) => getTree(child, state))];
};

export const getBlockContextContainers = (elements: string[]) => {
  const file = getState((state) => state.diagram.file);

  const contaners = getContainers(elements).map((id) => {
    return (file[id]?.elements ?? []) as string[];
  }).flat(1);

  return Array.from(new Set([...elements, ...contaners]));
};

export const getBlockContextLinks = (elements: string[]) => {
  const file = getState((state) => state.diagram.file);
  const captured = getState((state) => state.diagram.captured);
  const connections = getState((state) => state.diagram.links);
  const childs = getState((state) => state.diagram.links);
  const links = points.links;

  const list = elements.filter((id) => (
    [...(connections[id] ?? [id])]?.every((id) => !captured[id] && !file[id]?.lock)
  ));

  const setOfElements = new Set(list);

  setOfElements.forEach((id) => {
    if (blocks[file[id]?.type]?.element.options.includes('element-container')) {
      childs[id]?.forEach((id) => {
        setOfElements.add(id);
      });
    }
  });

  setOfElements.forEach((id) => {
    const parent = file[id]?.parent;

    if (blocks[file[parent]?.type]?.element.options.includes('element-wrapper-single')) {
      setOfElements.add(parent);
    }
  });

  setOfElements.forEach((id) => {
    links[id]?.forEach((id) => {
      if (!blocks[file[id]?.type]?.element.options.includes('element-container')) {
        setOfElements.add(id);
      }
    });
  });

  return Array.from(setOfElements);
};

export const getBlockContextPathes = (elements: string[]) => {
  const file = getState((state) => state.diagram.file);

  const setOfElements = new Set(elements);

  setOfElements.forEach((id) => {
    const path = getPath(id, { file });

    if (path.length === 0) {
      return;
    }

    const index = path.findIndex((_, index) => {
      if (!blocks[file[path[index + 1]]?.type]?.element.options.includes('element-wrap')) {
        return true;
      }

      return false;
    });

    path.splice(index + 1, path.length - (index + 1));

    path.forEach((id) => {
      setOfElements.add(id);
    });
  });

  return Array.from(setOfElements);
};

export const getBlockContextElements = (elements: string[]) => {
  const captured = getState((state) => state.diagram.captured);
  const childs = getState((state) => state.diagram.childs);
  const links = getState((state) => state.diagram.links);
  const file = getState((state) => state.diagram.file);

  const list = elements.filter((id) => (
    [...(links[id] ?? [id])]?.every((id) => !captured[id] && !file[id]?.lock)
  ));

  const setOfElements = new Set(getBlockContextContainers(list));

  Array.from(setOfElements).forEach((id) => {
    if (blocks[file[id]?.type]?.element.options.includes('element-container')) {
      (file[id].elements ?? []).forEach((id: string) => {
        setOfElements.add(id);
      });
    }
  });

  const getElements = (parent: string) => {
    setOfElements.add(parent);

    if (!(parent in childs)) {
      return;
    }

    childs[parent].forEach((id) => {
      getElements(id);
    });
  };

  setOfElements.forEach((id) => {
    getElements(id);
  });

  getBlockContextLinks(Array.from(setOfElements)).forEach((id) => {
    setOfElements.add(id);
  });

  return Array.from(setOfElements);
};

export const isMatchLatest = (type: string, search: string) => {
  return !!getState((state) => state.cache.diagrams[state.diagram.diagram.id || 'new']?.[type] ?? []).find((icon: { slug: string }) => {
    return isMatch(icon.slug + type, search.trim());
  });
};

export const setLatest = (type: string) => (payload?: { file: string, slug: string; title: string; }) => {
  const diagram = getState((state) => state.diagram.diagram);
  const collection = getState((state) => state.cache.tabs[type]?.collection);
  const latest = [...(getState((state) => state.cache.diagrams[diagram.id || 'new']?.[type]) ?? [])];

  if (payload && !latest.find((icon) => icon.file === payload.file)) {
    latest.unshift({
      file: payload.file,
      slug: payload.slug,
      title: payload.title,
    });
  }

  store.dispatch(storeCache.setDiagram({
    id: diagram.id || 'new',
    data: {
      [type]: [...latest, ...(latest.length < 20 ? [...collection].sort(() => 0.5 - Math.random()) : [])].slice(0, 20),
    },
  }));
};

export const setToolbar = (type: string) => {
  const state = store.getState();

  const diagrams = state.cache.diagrams;
  const id = state.diagram.diagram.id;

  const toolbar = [...(diagrams[id || 'new']?.toolbar || [])];

  if (!toolbar.includes(type)) {
    toolbar.unshift(type);
  }

  store.dispatch(storeCache.setDiagram({
    id: id || 'new',
    data: {
      toolbar: toolbar.slice(0, TOOLS_MAX_COUNT),
    },
  }));
};

export const deleteElements = (elements: string[]) => {
  const mode = getState((state) => state.diagram.mode);
  const file = getState((state) => state.diagram.file);
  const links = getState((state) => state.diagram.links);
  const id = getState((state) => state.diagram.diagram.id);
  const captured = getState((state) => state.diagram.captured);

  if (mode !== 'edit') return;

  const list = elements.filter((id) => (
    [...(links[id] ?? [id])]?.every((id) => !captured[id] && (!file[id]?.lock || blocks[file[id]?.type]?.element.options.includes('lock-delete')))
  ));

  if (list.length === 0) {
    return;
  }

  store.dispatch(storeDiagram.deleteElements(list));
  store.dispatch(storeGraphic.deleteOptions(list));
  store.dispatch(storeCache.removeElements({
    diagram: id,
    ids: list,
  }));
};

export const reparentWraps = (elements: string[]) => {
  const captured = getState((state) => state.diagram.captured);
  const childs = getState((state) => state.diagram.childs);
  const file = getState((state) => state.diagram.file);

  const status = getState((state) => {
    const selection = state.graphic.selection.elements;
    const links = state.diagram.links;

    const map = Object.fromEntries(selection.map((id) => [id, true]));

    return selection.every((id) => {
      const wrap = blocks[state.diagram.file[id]?.type]?.element.options.includes('element-wrap');
      const container = blocks[state.diagram.file[id]?.type]?.element.options.includes('element-container');
      const layout = blocks[state.diagram.file[id]?.type]?.element.options.includes('element-layout') || blocks[state.diagram.file[id]?.type]?.element.options.includes('element-wrap-grid');

      if (container) {
        return true;
      }

      return (
        links[id]?.every((child) => child === id || !(child in map))
        && (
          (wrap && !layout && state.diagram.childs[id]?.length > 0)
          || (layout && !state.diagram.file[id]?.parent)
        )
      );
    });
  });

  if (!status) {
    store.dispatch(serviceAlert.showAlert({
      message: elements.length > 1 ? 'You can\'t delete (as wrappers) these elements' : 'You can\'t delete (as wrapper) this element',
      type: 'error',
    }));

    return null;
  }

  return elements.filter((id) => {
    return !blocks[file[id]?.type]?.element.options.includes('element-container') && !captured[id] && (!file[id]?.lock || blocks[file[id]?.type]?.element.options.includes('lock-delete'));
  }).reduce((path, id) => {
    childs[id]?.forEach((child) => {
      const parent = file[id].parent;
      const position = file[id].position;
      const point = points.elements[child]?.position ?? position;

      store.dispatch(storeDiagram.setElement({
        id: child,
        properties: {
          parent,
          position: parent ? position : point,
        },
      }));
    });

    return [...path, ...(childs[id] || [])];
  }, [] as string[]);
};

export const uploadPicture = ({ file, getElement } : { file: Blob; getElement: (width: number, height: number) => string; }) => {
  const onDelete = (id: string) => {
    deleteElements(getBlockContextElements([id]));
    store.dispatch(storeGraphic.setSelectionElements([]));
  };

  const img = new Image();
  const diagram = getState((state) => state.diagram.diagram);

  img.onload = async () => {
    const canvas = document.createElement('canvas');

    let width = img.naturalWidth;
    let height = img.naturalHeight;

    if (width > MAX_IMAGE_SIZE || height > MAX_IMAGE_SIZE) {
      if (width > height) {
        height *= MAX_IMAGE_SIZE / width;
        width = MAX_IMAGE_SIZE;
      } else {
        width *= MAX_IMAGE_SIZE / height;
        height = MAX_IMAGE_SIZE;
      }
    }

    canvas.width = width;
    canvas.height = height;

    const element = getElement(width, height);

    const ctx = canvas.getContext('2d');

    if (!ctx) {
      onDelete(element);

      return;
    }

    ctx.drawImage(img, 0, 0, width, height);

    const blob: Blob | null = await new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, file.type);
    });

    if (!blob) {
      onDelete(element);

      return;
    }

    const upload = (diagram: { id: string }) => {
      Emitter.off('diagram-creation', upload);

      store.dispatch(serviceDiagram.upload({
        id: diagram.id,
        blob,
        callback: (payload) => {
          if (!payload || !payload.url) {
            onDelete(element);

            return;
          }

          store.dispatch(storeDiagram.setElement({
            id: element,
            properties: {
              loading: false,
              url: payload.url,
            },
          }));
        },
      }));
    };

    if (diagram.id) {
      upload({
        id: diagram.id,
      });
    } else {
      Emitter.on('diagram-creation', upload);
    }
  };

  img.src = URL.createObjectURL(file);
};

export const filterProperties = (payload : { type: string; properties: Record<string, any>; }) => {
  const element = blocks[payload.type]?.element;
  const properties = { ...payload.properties };

  if (!element) {
    return properties;
  }

  Object.keys(properties).forEach((property) => {
    if (!element.styles.includes(property)) {
      delete properties[property];
    }

    if (element.constraints.prevent?.[property]?.includes(properties[property])) {
      delete properties[property];
    }
  });

  return properties;
};

export const getMemoizedStyles = (styles: string[]) => {
  const properties: Record<string, any> = {};

  Object.entries(storage.styles).forEach(([style, value]) => {
    if (styles.includes(style)) {
      properties[style] = value;
    }
  });

  return properties;
};

export const setMemoizedStyles = <T> (style: string, value: T) => {
  storage.styles[style] = value;
};

export const getGroupStyles = (memo: string) => {
  const state = store.getState();

  const groups = state.cache.groups;
  const diagram = state.diagram.diagram;

  return groups[diagram.id]?.[memo] || {};
};

export const getMemoStyle = (memo: string, style: string) => {
  const group = getGroupStyles(memo);
  const memoized = getMemoizedStyles([style]);

  return memoized?.[style] ?? group?.[style];
};

export const addRoot = (id: string, state: { roots: Record<string, string>, file: Record<string, { parent: string, type: string, position: [number, number] }> }) => {
  if (!state.file[id]) {
    return;
  }

  if (blocks[state.file[state.file[id]?.parent]?.type]?.element.options.includes('element-wrap-grid') && state.file[id]?.position[0] === 0 && state.file[id]?.position[1] === 0) {
    return;
  }

  if (!(id in state.roots)) {
    state.roots[id] = id;
  }

  getPath(id, state).forEach((parent) => {
    state.roots[parent] = state.roots[id];
  });
};

export const removeRoot = (id: string, state: { roots: Record<string, string>, file: Record<string, { type: string, parent: string, position: number[] }> }) => {
  const parent = state.file[id]?.parent;
  const position = state.file[id]?.position;

  if (!parent || (blocks[state.file[parent]?.type]?.element.options.includes('element-wrap-grid') && position[0] === 0 && position[1] === 0)) {
    return;
  }

  state.roots[parent] = parent;

  getPath(parent, state).forEach((node) => {
    state.roots[node] = state.roots[parent];
  });
};

export const addLink = (id: string, state: { links: Record<string, string[]>, file: Record<string, { parent: string }> }) => {
  const links = [...(state.links[id] || []), id];

  getPath(id, state).forEach((parent) => {
    if (!state.links[parent]) {
      state.links[parent] = [];
    }

    links.forEach((id) => {
      if (!state.links[parent].includes(id)) {
        state.links[parent].push(id);
      }
    });
  });
};

export const addJoin = (id: string, state: { joins: { from: Record<string, string[]>, to: Record<string, string[]> }, file: Record<string, { points: { parent: string }[] }> }) => {
  const from = state.file[id]?.points?.[0].parent;
  const to = state.file[id]?.points?.[state.file[id].points.length - 1].parent;

  const start = `${to}.${id}`;
  const end = `${from}.${id}`;

  if (from && !state.joins.from[from]) {
    state.joins.from[from] = [];
  }

  if (to && !state.joins.to[to]) {
    state.joins.to[to] = [];
  }

  if (from && !state.joins.from[from].includes(start)) {
    state.joins.from[from].push(start);
  }

  if (to && !state.joins.to[to].includes(end)) {
    state.joins.to[to].push(end);
  }
};

export const removeJoin = (id: string, state: { joins: { from: Record<string, string[]>, to: Record<string, string[]> }, file: Record<string, { points: { parent: string }[] }> }) => {
  const from = state.file[id]?.points?.[0].parent;
  const to = state.file[id]?.points?.[state.file[id].points.length - 1].parent;

  if (from && state.joins.from[from]) {
    const key = `${to}.${id}`;

    state.joins.from[from] = state.joins.from[from].filter((id) => {
      return id !== key;
    });

    if (state.joins.from[from].length === 0) {
      delete state.joins.from[from];
    }
  }

  if (to && state.joins.to[to]) {
    const key = `${from}.${id}`;

    state.joins.to[to] = state.joins.to[to].filter((id) => {
      return id !== key;
    });

    if (state.joins.to[to].length === 0) {
      delete state.joins.to[to];
    }
  }
};

export const removeLink = (id: string, state: { links: Record<string, string[]>, file: Record<string, { parent: string }> }) => {
  const parent = state.file[id]?.parent;
  const links = [...(state.links[id] || []), id];

  getPath(parent, state).forEach((parent) => {
    if (!state.links[parent]) {
      return;
    }

    state.links[parent] = state.links[parent].filter((child) => {
      return !links.includes(child);
    });

    if (state.links[parent].length === 0) {
      delete state.links[parent];
    }
  });
};

export const addParent = (id: string, state: { parents: Record<string, string[]>, file: Record<string, { elements: string[], type: string }> }) => {
  if (!blocks[state.file[id]?.type]?.element.options.includes('element-container')) {
    return;
  }

  const elements = state.file[id]?.elements ?? [];

  elements.forEach((child) => {
    if (!(child in state.parents)) {
      state.parents[child] = [];
    }

    if (!state.parents[child].includes(id)) {
      state.parents[child].push(id);
    }
  });
};

export const addChild = (id: string, state: { childs: Record<string, string[]>, file: Record<string, { parent: string }> }) => {
  const parent = state.file[id]?.parent;

  if (!parent) {
    return;
  }

  if (!(parent in state.childs)) {
    state.childs[parent] = [];
  }

  if (!state.childs[parent].includes(id)) {
    state.childs[parent].push(id);
  }
};

export const removeChild = (id: string, state: { childs: Record<string, string[]>, file: Record<string, { parent: string }> }) => {
  const parent = state.file[id]?.parent;

  if (!parent || !(parent in state.childs)) {
    return;
  }

  state.childs[parent] = state.childs[parent].filter((child) => {
    return id !== child;
  });

  if (state.childs[parent].length === 0) {
    delete state.childs[parent];
  }
};

export const removeParent = (id: string, state: { parents: Record<string, string[]>, file: Record<string, { elements: string[], type: string }> }) => {
  if (!blocks[state.file[id]?.type]?.element.options.includes('element-container')) {
    return;
  }

  const elements = state.file[id]?.elements ?? [];

  elements.forEach((child) => {
    if (child in state.parents) {
      state.parents[child] = state.parents[child].filter((parent) => {
        return id !== parent;
      });

      if (state.parents[child].length === 0) {
        delete state.parents[child];
      }
    }
  });
};

export const getLoadingStatus = (elements: string[]) => {
  const file = getState((store) => store.diagram.file);

  return !elements.every((id) => {
    return !file[id].loading;
  });
};

export const getLockKey = (elements: string[]) => {
  const user = getState((state) => state.account.user.id);
  const file = getState((state) => state.diagram.file);

  const keys = new Set<string>();

  elements.forEach((id) => {
    if (!file[id]?.lock) {
      return;
    }

    keys.add(file[id]?.lockKey || '_');
  });

  if (keys.size === 0) return 'none';
  if (keys.size === 1 && keys.has('_')) return 'none';
  if (keys.size === 1 && keys.has(user)) return 'mine';
  if (keys.size === 2 && keys.has(user) && keys.has('_')) return 'mine';
  if (keys.size > 1 && keys.has(user)) return 'partial';
  if (keys.size > 1 && keys.has('_')) return 'partial';
  if (keys.size > 1) return 'multiple';

  return keys.values().next().value;
};

export const getCacheProperty = (state: RootState, id: string, key: string) => {
  const mode = state.diagram.mode;
  const file = state.diagram.file[id]?.[key];
  const cache = state.cache.elements[state.diagram.diagram.id]?.[id]?.[key];

  if (mode === 'view') {
    return !!(cache ?? file);
  }

  return !!file;
};

export const getAssetsFromDiff = (diff: Record<string, any>) => {
  const file = getState((state) => state.diagram.file);
  const cloud = getState((state) => state.diagram.cloud);

  const assets = {} as Record<string, boolean>;
  const images = Object.fromEntries(Object.values(file).filter((properties) => {
    return properties.type === 'general-image' && properties.url;
  }).map((properties) => {
    return [properties.url, true];
  }));

  Object.keys(diff).forEach((element) => {
    if ((file[element]?.type ?? cloud[element]?.type) === 'general-image') {
      const url = file[element]?.url ?? cloud[element]?.url;

      if (!url) {
        return;
      }

      if (diff[element] === 'deleted') {
        assets[url] = false;
      } else if (diff[element].url) {
        assets[url] = true;
      }
    }
  });

  Object.keys(assets).forEach((url) => {
    if (!assets[url] && url in images) {
      assets[url] = true;
    }
  });

  return assets;
};
