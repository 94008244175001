import React, { useContext, useEffect } from 'react';
import { Emitter } from 'utils/events';
import { AppDispatch } from 'store';

import { IMode } from 'store/diagram';

import { ElementContext } from 'elements/Block/Element';

import { useStoreDispatch, useStoreSelector } from './useStore';

interface IClickOnSelection {
  onClick: (dispatch: AppDispatch) => void;
  mode?: IMode;
  deps?: React.DependencyList;
}

export const useClickOnSelection = ({ onClick, mode, deps = [] }: IClickOnSelection) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  const active = useStoreSelector((state) => !(state.diagram.captured[element.id] || state.diagram.file[element.id]?.lock || state.graphic.options[element.id]?.invisible) && (!mode || state.diagram.mode === mode) && state.graphic.selection.elements.length === 1 && state.graphic.selection.elements[0] === element.id && !state.graphic.component.key && !state.graphic.drag && !state.graphic.drop && !state.graphic.layout.key && !state.graphic.copy);

  useEffect(() => {
    if (!active) {
      return () => {};
    }

    const click = ({ id }: { id: string }) => {
      if (id === element.id) {
        onClick(dispatch);
      }
    };

    Emitter.on('element-click-selected', click);

    return () => {
      Emitter.off('element-click-selected', click);
    };
  }, [active, ...deps]);
};
