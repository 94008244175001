import { useContext, useLayoutEffect, useRef } from 'react';
import { getState } from 'utils/store';
import { blocks } from 'blocks';

import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

import { ElementContext } from 'elements/Block/Element';

import { useElement } from './useElement';
import { GridContext } from './useGrid';
import { useProperty } from './useProperty';
import { useStoreDispatch, useStoreSelector } from './useStore';

const config = {
  minWidth: 22,
  minHeight: 22,
};

interface IResizer {
  fixed?: boolean;
  strict?: boolean;
  maxWidth?: number;
  realWidth?: number;
  realHeight?: number;
  minWidth?: number;
  minHeight?: number;
}

export const useResizer = <T extends HTMLElement = HTMLDivElement> ({ fixed = false, strict = false, maxWidth, realWidth, realHeight, minWidth, minHeight } : IResizer = {}) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);
  const grid = useContext(GridContext);

  const width = useStoreSelector((state) => state.diagram.file[element.id].width);
  const height = useStoreSelector((state) => state.diagram.file[element.id].height);
  const padding = useStoreSelector((state) => state.diagram.file[element.id].padding);
  const position = useStoreSelector((state) => state.diagram.file[element.id].position);
  const vertical = useStoreSelector((state) => state.diagram.file[element.id].vertical);
  const parent = useStoreSelector((state) => state.diagram.file[element.id].parent);
  const lock = useStoreSelector((state) => !!state.diagram.captured[element.id] || state.diagram.file[element.id].lock);
  const component = useStoreSelector((state) => !!state.graphic.component.key || state.graphic.selection.elements.length > 1);

  const wrap = useElement<T>();

  const offset = useProperty<[number, number]>([0, 0]);
  const size = useProperty<number[]>([]);
  const active = useProperty(false);
  const moved = useProperty(false);
  const resizing = useRef(false);

  const onStart = () => {
    active.set(true);
    moved.set(false);
    resizing.current = true;
    dispatch(storeGraphic.setResizing(element.id));
  };

  const onEnd = () => {
    active.set(false);
    moved.set(false);
    size.set([]);
    resizing.current = false;
    dispatch(storeGraphic.setResizing());
  };

  const onUpdate = (frame: [number, number, number, number]) => {
    if (!wrap.ref) {
      return;
    }

    size.set([frame[0], frame[1]]);
    offset.set([frame[2], frame[3]]);
  };

  const onDrop = (frame: [number, number, number, number]) => {
    const sticker = getState((state) => blocks[state.diagram.file[element.id].type]?.element.options.includes('element-sticker') && state.diagram.file[element.id].sticky);
    const offset = getState((state) => state.diagram.file[element.id].offset) ?? [0, 0];

    if (sticker) {
      dispatch(storeDiagram.setElement({
        id: element.id,
        properties: {
          width: frame[0],
          height: frame[1],
          offset: parent ? offset : [
            offset[0] + frame[2],
            offset[1] + frame[3],
          ],
        },
      }));

      return;
    }

    dispatch(storeDiagram.setElement({
      id: element.id,
      properties: {
        width: frame[0],
        height: frame[1],
        position: parent ? position : [
          position[0] + frame[vertical ? 3 : 2],
          position[1] + frame[vertical ? 2 : 3],
        ],
      },
    }));
  };

  const onDelete = () => {
    dispatch(storeDiagram.setElement({
      id: element.id,
      properties: {
        width: realWidth,
        height: realHeight,
      },
    }));
  };

  useLayoutEffect(() => {
    if (element.wrap.ref) {
      if (!parent) {
        element.wrap.ref.style.marginLeft = '';
        element.wrap.ref.style.marginTop = '';
      }

      element.updatePoints();
    }
  }, [width, height, padding, vertical]);

  useLayoutEffect(() => {
    if (element.wrap.ref && resizing.current) {
      if (!parent) {
        if (vertical) {
          element.wrap.ref.style.marginTop = `${offset.value[0]}px`;
          element.wrap.ref.style.marginLeft = `${offset.value[1]}px`;
        } else {
          element.wrap.ref.style.marginLeft = `${offset.value[0]}px`;
          element.wrap.ref.style.marginTop = `${offset.value[1]}px`;
        }
      }

      element.updatePoints();
    }
  }, [offset.value]);

  const style = size.value.length > 0 ? {
    minWidth: size.value[0],
    minHeight: size.value[1],
    width: (strict || fixed) ? size.value[0] : undefined,
    height: strict ? size.value[1] : undefined,
  } : {
    width: (width && (fixed || strict)) ? `${Math.max(width, element.wrapping ? 0 : (minWidth ?? config.minWidth))}px` : '',
    minWidth: (width && !fixed) ? `${Math.max(width, element.wrapping ? 0 : (minWidth ?? config.minWidth))}px` : '',
    maxWidth: (!width && maxWidth) ? `${Math.max(maxWidth, element.wrapping ? 0 : (minWidth ?? config.minWidth))}px` : '',
    height: (height && strict) ? `${Math.max(height, element.wrapping ? 0 : (minHeight ?? config.minHeight))}px` : '',
    minHeight: height ? `${Math.max(height, element.wrapping ? 0 : (minHeight ?? config.minHeight))}px` : '',
    maxHeight: undefined as (string | undefined),
  };

  if (vertical) {
    const width = style.width;
    const minWidth = style.minWidth;
    const maxWidth = style.maxWidth;
    const height = style.height;
    const minHeight = style.minHeight;
    const maxHeight = undefined;

    style.width = height;
    style.minWidth = minHeight;
    style.maxWidth = maxHeight;
    style.height = width;
    style.minHeight = minWidth;
    style.maxHeight = maxWidth;
  }

  return {
    visibility: element.selected && !lock && !component && !element.containered && !grid.selected,
    status: active.value && !lock && !component && !element.containered && !grid.selected,
    wrap: {
      ref: wrap.link,
      style,
    },
    attributes: {
      id: element.id,
      entry: !width,

      minWidth,
      minHeight,

      onUpdate,
      onDrop,
      onDelete,

      onStart,
      onEnd,
    },
  };
};
