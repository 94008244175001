import React, { FC } from 'react';

import { useAnimation } from 'hooks/useAnimation';

interface ILoader {
  top?: boolean;
  width?: number;
  height?: number;
  fixed?: boolean;
}

export const Loader: FC<ILoader> = ({ top, width, height, fixed }) => {
  const animation = useAnimation('animation-scale');

  return (
    <div className={`layout-alert unit-loading${top ? ' top' : ''}${fixed ? ' fixed' : ''}`}>
      <svg className={animation.name} width={width || 72} height={height || 72} style={{ opacity: 0.5 }} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="app-loader-line" d="M27.4124 3.64674C22.4813 2.78444 13.5189 2.78443 8.58777 3.6467C6.01425 4.09671 4.09671 6.01424 3.6467 8.58777C2.78444 13.5188 2.78443 22.4812 3.6467 27.4122C4.09671 29.9857 6.01424 31.9033 8.58775 32.3533C13.5188 33.2155 22.4812 33.2155 27.4123 32.3533C29.9858 31.9033 31.9033 29.9858 32.3533 27.4123C33.2156 22.4812 33.2156 13.5187 32.3533 8.58766C31.9033 6.01422 29.9858 4.09676 27.4124 3.64674Z" stroke="#b7b7df" strokeWidth="3" strokeLinejoin="round" />
        <path d="M9.14935 14.4H16.6194V16.596H11.6154V19.53H16.1694V21.726H11.6154V24.804H16.7094V27H9.14935V14.4Z" fill="#b7b7df" />
        <path d="M20.5336 22.392L17.8336 18H20.6236L21.7936 19.926L22.2977 21.186L22.8377 19.926L24.0617 18H26.5997L23.8816 22.32L26.7796 27H24.0617L22.6756 24.84L22.1356 23.508L21.5597 24.84L20.1736 27H17.6177L20.5336 22.392Z" fill="#b7b7df" />
      </svg>
    </div>
  );
};
