import React, { useEffect } from 'react';
import { Emitter } from 'utils/events';
import { ITool } from 'tools';

import { storeGraphic } from 'store/graphic';

import { useProperty } from 'hooks/useProperty';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import iconCenter from './assets/center.svg';
import iconRight from './assets/right.svg';
import iconLeft from './assets/left.svg';
import iconJustify from './assets/justify.svg';

const icons: {[key: string]: string} = {
  center: iconCenter,
  right: iconRight,
  left: iconLeft,
  justify: iconJustify,
};

const Tool = () => {
  const dispatch = useStoreDispatch();

  const selection = useStoreSelector((state) => state.graphic.selection.elements.length === 1);
  const status = useProperty((() => {
    try {
      return ((window as any).editor?.getFormat() || {}).align || '';
    } catch (e) {
      return '';
    }
  })());
  const visible = useProperty(!!(window as any).editor || selection);

  const onClick = () => {
    dispatch(storeGraphic.setComponentTab('format-alignment'));
  };

  // Listen to Editor Change
  useEffect(() => {
    const onChange = () => {
      visible.set(!!(window as any).editor || selection);
    };

    Emitter.on('editor-change', onChange);

    return () => {
      Emitter.off('editor-change', onChange);
    };
  }, []);

  // Listen to Selection Change
  useEffect(() => {
    const onSelect = (payload: {[key: string]: string}) => {
      status.set(payload.align || 'left');
    };

    Emitter.on('selection-change', onSelect);

    return () => {
      Emitter.off('selection-change', onSelect);
    };
  }, []);

  return (
    <Hint message="Alignment" disabled={!visible.value} side="bottom" variant="default">
      <button type="button" className={`button-icon v-1${!visible.value ? ' button-disabled' : ''}`} onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icons[status.value] || icons.left} width={28} height={28} alt="Alignment" />
      </button>
    </Hint>
  );
};

export const FormatAlignment: ITool = {
  key: 'format-alignment',
  tool: Tool,
  options: ['only-edit'],
};
