import React, { memo, useLayoutEffect } from 'react';
import { IBlock, IConfig, blocks } from 'blocks';
import { getState } from 'utils/store';

import { storeCache } from 'store/cache';
import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

import { useWrap } from 'hooks/useWrap';
import { useStoreDispatch } from 'hooks/useStore';
import { useCacheProperty } from 'hooks/useCacheProperty';
import { useArrangementWrap } from 'hooks/useArrangementWrap';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';
import { usePlacingAlignment } from 'hooks/usePlacingAlignment';

import { Wrap } from 'elements/Block/Wrap';
import { Frame } from 'elements/Wrap/Frame';
import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import { createBlock } from 'blocks/factories/simple/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/simple/Creator';
import design from 'blocks/factories/assets/Simple.module.css';

import style from './assets/style.module.css';
import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'Checkbox',
  search: 'information shape checkbox selection option',
  type: 'information-shape-checkbox',
  group: ['Information'],
  memo: {
    key: 'information-checkbox',
    styles: [
      'color',
      'size',
      'placing',
      'alignHorizontal',
      'alignVertical',
    ],
  },
  styles: [
    'color',
    'size',
    'placing',
    'alignHorizontal',
    'alignVertical',
  ],
};

interface IModel {
  color: string;
  size: 's' | 'm' | 'l';
  selected: boolean;
  placing: 'top' | 'left' | 'right' | 'bottom';
  alignHorizontal: 'left' | 'center' | 'right';
  alignVertical: 'top' | 'center' | 'bottom';
  lockKey: string;
  lock: boolean;
}

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const dispatch = useStoreDispatch();

    const selected = useCacheProperty('selected') ?? false;

    const wrap = useWrap();
    const vertical = usePlacingAlignment();
    const arrangement = useArrangementWrap();

    const inline = blocks[wrap.type]?.element.options.includes('element-text');

    const checkbox = {
      onClick: (e?: React.MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        const mode = getState((state) => state.diagram.mode);
        const diagram = getState((state) => state.diagram.diagram.id);

        if (mode === 'edit') {
          dispatch(storeDiagram.setElement({
            id: element.id,
            properties: { selected: !selected },
          }));
        }

        dispatch(storeCache.setElement({
          id: element.id,
          diagram,
          properties: { selected: !selected },
        }));
      },
    };

    useEnterOnSelection({
      onPress: () => checkbox.onClick(),
      deps: [selected],
    });

    // Update Points on Property Change
    useLayoutEffect(() => {
      element.updatePoints();
    }, [properties.size, properties.placing]);

    return (
      <Shape
        classElement={style.element}
        classProperties={`${design[`color-${properties.color}`]} ${style[`size-${properties.size}`]} ${style[`placing-${properties.placing}`]} ${style[`align-${vertical ? properties.alignHorizontal : properties.alignVertical}`]}`.appendWhen(inline && !vertical, style.inline)}
      >
        <Arrangement {...arrangement} />
        <Selection />
        <Frame />

        <div
          className={'block-content'.appendWhen(!wrap.status, style.empty)}
          {...(element.focused ? (!element.selected ? {} : { onMouseDown: element.trigger.onMouseDown }) : {})}
        >

          <div
            className={`block-render ${style.checkbox}`.appendWhen(selected, style.selected)}
            {...element.trigger}
            {...checkbox}
          >
            <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect className="render-stroke render-stroke-fill" x="1" y="1" width="14" height="14" rx="3.5" />
              <path d="M4.66669 7.99998L6.66669 9.99998L11.3334 5.33331" stroke="white" strokeWidth="2.2" />
            </svg>
          </div>

          <Wrap {...wrap.attributes} />

        </div>
      </Shape>
    );
  },
});

export const InformationShapeCheckbox : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-wrap', 'element-wrap-inline', 'element-wrap-text'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
        placing: [
          'top-left',
          'top-right',
          'left-top',
          'left-bottom',
          'bottom-left',
          'bottom-right',
          'right-top',
          'right-bottom',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        size: 'm',
        color: 'dark',
        selected: false,
        placing: 'right',
        alignVertical: 'center',
        alignHorizontal: 'center',
        lockKey: '',
        lock: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [32, 32],
    icon,
    class: {
      title: 'Checkbox',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit', 'block-core'],
    type: 'drag',
  },
  tools: [
    'size',
    'placing',
    'align-horizontal',
    'align-vertical',
    'color',
    'lock',
    'delete-wrap',
    'delete',
  ],
  toolbars: [

  ],
};
