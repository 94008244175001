import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { Model } from 'blocks/factories/shapeIcon/Model';
import { createRender } from 'blocks/factories/simple/Render';
import { createBlock, block } from 'blocks/factories/shapeIcon/Block';

import icon from './assets/icon.svg';

type IModel = Model<{}>;

export const config: IConfig = {
  title: 'Start',
  search: 'shape start uml unified modeling language',
  type: 'uml-activity-shape-start',
  group: ['UML Activity Shape Icon'],
  memo: {
    key: 'uml-activity-shape',
    styles: block.element.memo,
  },
  styles: block.element.styles,
};

const Render = createRender<IModel>({
  render: ({ width, height, element }) => {
    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - border, 0);
    const y = Math.max(height - border, 0);

    const points = [
      [x / 2 + offset, y / 2 + offset],
      [x / 2, y / 2],
    ];

    return (
      <svg className="block-render" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse {...element.trigger} className="render-stroke-fill render-hit" cx={points[0][0]} cy={points[0][1]} rx={points[1][0]} ry={points[1][1]} />
      </svg>
    );
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  symmetrically: true,
  Render,
});

export const UMLActivityShapeStart : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: block.element.options,
    constraints: block.element.constraints,
    onCreate: block.creator(config, {
      width: 64,
      height: 64,
      realWidth: 64,
      realHeight: 64,
    }),
    onCopy: block.copier(config),
    title: config.title,
    size: [64, 64],
    icon,
    class: block.element.class,
  },
  button: block.button(config),
  tools: [
    'shape-uml-activity-icon',
    'color',
    'lock',
    'delete',
  ],
  toolbars: block.toolbars,
};
