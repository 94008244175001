import React, { ComponentType } from 'react';
import { IButton, IConfig, IOption } from 'blocks';

import { useResizer } from 'hooks/useResizer';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';
import { useClickOnSelection } from 'hooks/useClickOnSelection';
import { useArrangementWrapper } from 'hooks/useArrangementWrapper';
import { useTextCreatorWithWrapper } from 'hooks/useTextCreatorWithWrapper';

import { Frame } from 'elements/Wrap/Frame';
import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import { IRender } from 'blocks/factories/simple/Render';
import { IModel, Model } from 'blocks/factories/shapeIcon/Model';
import { createBlock as create } from 'blocks/factories/simple/Block';
import design from 'blocks/factories/assets/Simple.module.css';

import { createCreator } from '../simple/Creator';
import { createCopier } from '../simple/Copier';

import style from '../assets/Shape.module.css';
import icon from '../assets/Shape.svg';

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

interface IBlockConfig<T> {
  memo: {
    key: string;
    properties: string[];
  };
  Render: ComponentType<IRender<Model<T>>>;
  symmetrically?: boolean;
}

export const createBlock = <T, > ({ memo, Render, symmetrically = false } : IBlockConfig<T>) => create<Model<T>>({
  memo,
  block: ({ properties }) => {
    const arrangement = useArrangementWrapper();
    const create = useTextCreatorWithWrapper({ properties: { text: properties.title } });
    const resier = useResizer<HTMLImageElement>({
      strict: true,
      realWidth: properties.realWidth,
      realHeight: properties.realHeight,
      minWidth: 16,
      minHeight: 16,
    });

    useEnterOnSelection({
      onPress: create('enter'),
      mode: 'edit',
    });

    useClickOnSelection({
      onClick: create('click'),
      mode: 'edit',
    });

    return (
      <Shape
        classElement={style.element}
        classProperties={`${design[`color-${properties.color}`]} ${design[`render-${properties.render}`]}`.appendWhen(properties.transparent, style.transparent).appendWhen(!properties.transparent, design.opaque)}
      >
        <Arrangement {...arrangement} />
        <Render properties={properties} />
        <Selection status={!resier.status} />
        <Frame />

        <div
          className="block-content"
          {...resier.wrap}
        />

        <Resizer.Size {...resier.attributes} status={resier.visibility} symmetrically={symmetrically} />
      </Shape>
    );
  },
});

export const block = {
  element: {
    styles: [
      'color',
      'render',
      'transparent',
    ],
    memo: [
      'color',
      'render',
      'transparent',
    ],
    options: ['element-wrap', 'element-wrapable'] as IOption[],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
    },
    class: {
      title: 'Shape',
      icon,
    },
  },
  creator: <T extends IModel, > (config: IConfig, properites: Optional<T, 'color' | 'render' | 'transparent' | 'lock' | 'lockKey'>) => createCreator<T>({
    type: config.type,
    memo: config.memo.key,
    styles: config.styles,
    properties: {
      color: 'dark',
      render: 0,
      transparent: true,
      lockKey: '',
      lock: false,

      ...properites,
    } as any,
  }),
  copier: (config: IConfig) => createCopier({
    type: config.type,
  }),
  button: (config: IConfig): IButton => ({
    tool: () => <Button element={config.type} />,
    options: [
      'only-edit',
    ],
    type: 'drag',
  }),
  tools: [
    'transparent',
    'render',
    'color',
    'lock',
    'delete',
  ],
  toolbars: [

  ],
};
