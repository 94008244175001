import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { IConnector } from './Cap';

export const Cap3: IConnector = {
  Start: ({ color }: ICap) => null,
  End: ({ color, mask }: ICap) => (
    <path d="M12 12L23 8L12 4" stroke={mask ? '#fff' : color} fill="transparent" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
};
