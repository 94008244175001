import React from 'react';
import { ITool } from 'tools';

import { createFormatTool } from 'tools/ToolFormat';

import icon from './assets/icon.svg';

const Tool = createFormatTool({
  icon,
  title: 'Heading #2',
  format: 'header',
  condition: (value) => value === 2,
  filter: (value) => value === 2,
  set: (status) => (status ? 0 : 2),
});

export const FormatH2: ITool = {
  key: 'format-h2',
  tool: Tool,
  options: ['only-edit'],
};
