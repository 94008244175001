import { ITool } from 'tools';
import { createTool } from 'tools/ToolTab';

import icon from './assets/icon.svg';

const Tool = createTool('kubernetes', '_', 'icon', 'Icon', 'Icon', {
  icon,
});

export const Kubernetes: ITool = {
  key: 'kubernetes',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
