import React, { FC } from 'react';

import { Delete } from './Delete';
import { LinkBack } from './LinkBack';
import { LinkText } from './LinkText';
import { Color } from './Color';
import { FormatItalic } from './FormatItalic';
import { LinkFields } from './LinkFields';
import { LinkMethods } from './LinkMethods';
import { Detailed } from './Detailed';
import { Fill } from './Fill';
import { Shortened } from './Shortened';
import { Italic } from './Italic';
import { FormatBold } from './FormatBold';
import { FormatUnderline } from './FormatUnderline';
import { FormatStrikethrough } from './FormatStrikethrough';
import { AlignHorizontal } from './AlignHorizontal';
import { AlignVertical } from './AlignVertical';
import { LineStyle } from './LineStyle';
import { Size } from './Size';
import { LineStroke } from './LineStroke';
import { LineReverse } from './LineReverse';
import { LinePointCenteredFinish } from './LinePointCenteredFinish';
import { LinePointCenteredStart } from './LinePointCenteredStart';
import { ConnectorGeneralStart } from './ConnectorGeneralStart';
import { ConnectorGeneralEnd } from './ConnectorGeneralEnd';
import { Safe } from './Safe';
import { FormatH1 } from './FormatH1';
import { FormatH2 } from './FormatH2';
import { FormatAlignment } from './FormatAlignment';
import { FormatLink } from './FormatLink';
import { Collapsed } from './Collapsed';
import { LinkStereotype } from './LinkStereotype';
import { FormatHighlight } from './FormatHighlight';
import { FormatHighlightCommon } from './FormatHighlightCommon';
import { Extend } from './Extend';
import { FormatListBullet } from './FormatListBullet';
import { FormatListNumber } from './FormatListNumber';
import { Padding } from './Padding';
import { Render } from './Render';
import { Random } from './Random';
import { Transparent } from './Transparent';
import { DeleteWrap } from './DeleteWrap';
import { Placing } from './Placing';
import { LinkAttributes } from './LinkAttributes';
import { LinkOperations } from './LinkOperations';
import { ShapeBasic } from './ShapeBasic';
import { ShapeFlowchart } from './ShapeFlowchart';
import { ShapeFlowchartIcon } from './ShapeFlowchartIcon';
import { ShapeUmlClass } from './ShapeUMLClass';
import { Icon } from './Icon';
import { Sticker } from './Sticker';
import { AWS } from './AWS';
import { Azure } from './Azure';
import { Cisco } from './Cisco';
import { Google } from './Google';
import { Kubernetes } from './Kubernetes';
import { VMware } from './VMware';
import { Vertical } from './Vertical';
import { Space } from './Space';
import { Lock } from './Lock';
import { Unlock } from './Unlock';
import { LockKey } from './LockKey';
import { Hidden } from './Hidden';
import { ConnectorReverse } from './ConnectorReverse';
import { ConnectorStartFree } from './ConnectorStartFree';
import { ConnectorEndFree } from './ConnectorEndFree';
import { ConnectorUMLClassEnd } from './ConnectorUMLClassEnd';
import { ConnectorUMLClassStart } from './ConnectorUMLClassStart';
import { ConnectorInformationStart } from './ConnectorInformationStart';
import { ConnectorInformationEnd } from './ConnectorInformationEnd';
import { ShapeUMLActivityIcon } from './ShapeUMLActivityIcon';
import { ConnectorUMLUseCaseEnd } from './ConnectorUMLUseCaseEnd';
import { ConnectorUMLUseCaseStart } from './ConnectorUMLUseCaseStart';
import { FormatCode } from './FormatCode';
import { FormatCodeBlock } from './FormatCodeBlock';
import { Hide } from './Hide';
import { MarkerPen } from './MarkerPen';
import { MarkerHighlighter } from './MarkerHighlighter';

export type IOption = (
  | 'only-draw'
  | 'only-edit'
  | 'only-single'
  | 'side-left'
  | 'side-right'
  | 'app-core'
  | 'load-file'
  | 'block-core'
);

interface IToolbars {
  [key: string]: {
    tool: React.ElementType;
    options: IOption[];
  };
}

export interface IToolData {
  tool: React.ElementType;
  options: IOption[];
}

export interface ITool extends IToolData {
  key: string;
}

const toolbars: IToolbars = {};

export const addToConfig = (tool: ITool) => {
  toolbars[tool.key] = {
    tool: tool.tool,
    options: tool.options,
  };
};

addToConfig(Delete);
addToConfig(LinkText);
addToConfig(LinkBack);
addToConfig(Color);
addToConfig(FormatItalic);
addToConfig(FormatBold);
addToConfig(FormatUnderline);
addToConfig(FormatStrikethrough);
addToConfig(FormatCode);
addToConfig(Italic);
addToConfig(LinkFields);
addToConfig(LinkMethods);
addToConfig(Detailed);
addToConfig(Fill);
addToConfig(Shortened);
addToConfig(AlignHorizontal);
addToConfig(AlignVertical);
addToConfig(LineStyle);
addToConfig(LineStroke);
addToConfig(LineReverse);
addToConfig(Size);
addToConfig(LinePointCenteredFinish);
addToConfig(LinePointCenteredStart);
addToConfig(ConnectorGeneralStart);
addToConfig(ConnectorGeneralEnd);
addToConfig(Safe);
addToConfig(FormatH1);
addToConfig(FormatH2);
addToConfig(FormatAlignment);
addToConfig(FormatLink);
addToConfig(FormatCodeBlock);
addToConfig(Collapsed);
addToConfig(LinkStereotype);
addToConfig(FormatHighlight);
addToConfig(FormatHighlightCommon);
addToConfig(Extend);
addToConfig(FormatListBullet);
addToConfig(FormatListNumber);
addToConfig(Padding);
addToConfig(Render);
addToConfig(Random);
addToConfig(Transparent);
addToConfig(DeleteWrap);
addToConfig(Placing);
addToConfig(LinkOperations);
addToConfig(LinkAttributes);
addToConfig(ShapeBasic);
addToConfig(ShapeFlowchart);
addToConfig(ShapeFlowchartIcon);
addToConfig(ShapeUmlClass);
addToConfig(Icon);
addToConfig(Sticker);
addToConfig(AWS);
addToConfig(Azure);
addToConfig(Cisco);
addToConfig(Google);
addToConfig(Kubernetes);
addToConfig(VMware);
addToConfig(Vertical);
addToConfig(Space);
addToConfig(Lock);
addToConfig(LockKey);
addToConfig(Unlock);
addToConfig(Hidden);
addToConfig(ConnectorReverse);
addToConfig(ConnectorStartFree);
addToConfig(ConnectorEndFree);
addToConfig(ConnectorUMLClassEnd);
addToConfig(ConnectorUMLClassStart);
addToConfig(ConnectorInformationStart);
addToConfig(ConnectorInformationEnd);
addToConfig(ShapeUMLActivityIcon);
addToConfig(ConnectorUMLUseCaseEnd);
addToConfig(ConnectorUMLUseCaseStart);
addToConfig(Hide);
addToConfig(MarkerPen);
addToConfig(MarkerHighlighter);

export { toolbars };
