import React, { FC, ReactNode, useContext } from 'react';
import { blocks } from 'blocks';

import { useElement } from 'hooks/useElement';
import { useDragCell } from 'hooks/useDragCell';
import { useStoreSelector } from 'hooks/useStore';
import { useDropFreely } from 'hooks/useDropFreely';
import { usePointsElement } from 'hooks/usePointsElement';

import { ElementContext } from 'elements/Block/Element';
import { Arrangement } from 'elements/Block/Arrangement';

interface ICell {
  children?: ReactNode;
  point: [number, number];
}

export const Cell: FC<ICell> = ({ point, children }) => {
  const element = useContext(ElementContext);

  const drag = useStoreSelector((state) => !!state.graphic.drag);
  const sticker = useStoreSelector((state) => blocks[state.graphic.drag?.element.type ?? '_']?.element.options.includes('element-sticker'));
  const nest = useStoreSelector((state) => state.graphic.layout.status);

  const wrap = useElement<HTMLDivElement>();

  usePointsElement({
    id: `${element.id}.${point[0]}-${point[1]}`,
    parent: element.id,
    ref: wrap.ref,
  });

  const cell = useDragCell();

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement>, direction: number[]) => {
    if (drag) {
      cell.onDragUp({
        e,
        direction,
        position: point,
      });
    }
  };

  return (
    <div
      ref={wrap.link}
      className="block-cell"
    >
      {!nest && (
        <Arrangement
          status={!sticker && drag}
          layout={element.id}
          prevent={children ? ['cc'] : []}
          onMouseUp={onMouseUp}
        />
      )}

      {children}
    </div>
  );
};
