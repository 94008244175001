import { createContext, useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import { ILayout, getArrangement, getLayout, getLayoutSize, optimizeLayout } from 'utils/graphic';
import { deleteElements, getBlockContextLinks, getState } from 'utils/store';
import { storeGraphic } from 'store/graphic';
import { points } from 'utils/points';
import { blocks } from 'blocks';

import { storeDiagram } from 'store/diagram';

import { ElementContext } from 'elements/Block/Element';

import { useProperty } from './useProperty';
import { useStoreDispatch, useStoreSelector } from './useStore';

export interface IGrid {
  id: string;
  selected: boolean;
  elements: string[];
  layout: ILayout;
  size: [number, number];
  arrangement: Record<string, [number, number]>;
  loading: boolean;
  refresh: () => void;
}

export const GridContext = createContext<IGrid>({
  id: '',
  selected: false,
  elements: [],
  layout: {},
  arrangement: {},
  size: [0, 0],
  loading: false,
  refresh: () => {},
});

export const useGrid = (): IGrid => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);
  const parent = useContext(GridContext);

  const file = getState((state) => state.diagram.file);
  const elements = useStoreSelector((state) => state.diagram.childs[element.id]) || [];

  const loading = useProperty(true);
  const updater = useProperty({});
  const placement = elements.map((id) => `${id}.${file[id].position.join('-')}`).join(',');

  const grid = useMemo(() => {
    const arrangement = getArrangement(elements);

    optimizeLayout(arrangement);

    const layout = getLayout(arrangement);
    const size = getLayoutSize(layout);

    return {
      id: element.id,
      selected: element.selected || parent.selected,
      elements,
      layout,
      size,
      arrangement,

      loading: true,
      refresh: () => {
        updater.set({});
      },
    };
  }, [placement, updater.value]);

  // Update Position on Arrangement Change
  useEffect(() => {
    Object.keys(grid.arrangement).forEach((id) => {
      if (grid.arrangement[id][0] !== file[id].position[0] || grid.arrangement[id][1] !== file[id].position[1]) {
        dispatch(storeDiagram.setElement({
          id,
          properties: {
            position: grid.arrangement[id],
          },
        }));
      }
    });
  }, [placement]);

  // Strip Grid on Children Change
  useEffect(() => {
    if (grid.elements.length > 1) {
      return;
    }

    if (grid.elements.length > 0) {
      const id = grid.elements[0];

      const path = points.path(element.id);

      if (path.length === 0) {
        return;
      }

      const wrap = path.findIndex((id, index) => {
        if (points.childs[id]?.size > 1 || (blocks[file[id]?.type]?.element.options.includes('element-wrap') && index > 0)) {
          return true;
        }

        return false;
      });

      const parent = path[Math.max(wrap - 1, 0)];

      dispatch(storeDiagram.setElement({
        id,
        properties: {
          parent: file[parent].parent,
          position: (!file[parent].parent && points.elements[id]) ? [
            points.elements[id].position[0],
            points.elements[id].position[1],
          ] : [
            file[parent].position[0],
            file[parent].position[1],
          ],
        },
      }));
    }

    deleteElements(getBlockContextLinks([element.id]));

    if (grid.elements.length > 0) {
      dispatch(storeDiagram.setComputedProperties([grid.elements[0]]));
    }
  }, [placement]);

  useEffect(() => {
    loading.set(true);
  }, [placement]);

  useLayoutEffect(() => {
    grid.selected = element.selected || parent.selected;
  }, [element.selected || parent.selected]);

  useLayoutEffect(() => {
    if (loading.value) {
      loading.set(false);

      grid.loading = parent.loading;

      if (!grid.loading) {
        element.updatePoints();

        dispatch(storeGraphic.setElement({
          id: element.id,
          status: true,
        }));
      }

      return;
    }

    grid.loading = false;
  }, [loading.value]);

  return grid;
};
