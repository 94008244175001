import React, { useContext } from 'react';
import { blocks } from 'blocks';

import { ElementContext } from 'elements/Block/Element';
import { IArrangement, IPoint } from 'elements/Block/Arrangement';

import { useStoreSelector } from './useStore';
import { useArrangementBase } from './useArrangementBase';

interface IArrangementElement {
  prevent?: IPoint[];
}

export const useArrangementElement = ({ prevent }: IArrangementElement = {}): IArrangement => {
  const element = useContext(ElementContext);

  const drag = useStoreSelector((state) => state.graphic.drag);
  const layout = useStoreSelector((state) => state.graphic.layout.status);
  const lock = useStoreSelector((state) => ((state.diagram.file[state.diagram.roots[element.id]]?.lock || !!state.diagram.captured[state.diagram.roots[element.id]]) && layout) || ((state.diagram.file[element.id]?.lock || !!state.diagram.captured[element.id]) && !layout));
  const create = useStoreSelector((state) => state.graphic.drag?.type === 'create' || state.graphic.copy);
  const nest = useStoreSelector((state) => (
    blocks[state.diagram.file[state.diagram.roots[element.id]]?.type]?.element.options.includes('element-wrap')
    && (
      !blocks[state.diagram.file[state.diagram.roots[element.id]]?.type]?.element.options.includes('element-wrapable')
      || state.diagram.roots[element.id] === element.id
    )
  ));
  const wrapper = useStoreSelector((state) => (
    blocks[state.diagram.file[state.diagram.file[state.graphic.drag?.element.id || '_']?.parent]?.type]?.element.options.includes('element-wrap-grid')
    && state.diagram.file[state.graphic.drag?.element.id || '_']?.position[0] === 0
    && state.diagram.file[state.graphic.drag?.element.id || '_']?.position[1] === 0
    && !create
    && !layout
  ));

  const wrap = (drag?.path || []).includes(element.id);
  const grid = blocks[drag?.element.type || '']?.element.options.includes('element-layout');

  const arrangement = useArrangementBase();

  if (drag && (lock || (grid || wrapper || (wrap && !create) || (!nest && (layout || create))))) {
    arrangement.prevent?.push('cc');
  }

  if (prevent && !arrangement.prevent) {
    arrangement.prevent = prevent;
  }

  prevent?.forEach((point) => {
    if (!arrangement.prevent?.includes(point)) {
      arrangement.prevent?.push(point);
    }
  });

  return arrangement;
};
