import { useContext } from 'react';

import { storeDiagram } from 'store/diagram';
import { storeGraphic } from 'store/graphic';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';

import { insertPoint } from './useConnector';
import { useStoreDispatch, useStoreSelector } from './useStore';

export const useResizerConnectorCreator = (axis: boolean) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  const points = useStoreSelector((state) => state.diagram.file[element.id].points as IPoint[]);
  const startFree = useStoreSelector((state) => state.diagram.file[element.id].startFree);
  const endFree = useStoreSelector((state) => state.diagram.file[element.id].endFree);

  const onStart = (id: string, index: number, position: number[]) => {
    const vertices = insertPoint(points, index, position, startFree, endFree);

    if (vertices === points) {
      return;
    }

    dispatch(storeDiagram.setElement({
      id: element.id,
      properties: {
        points: vertices,
        horizontal: id === '0' ? !axis : axis,
      },
    }));

    dispatch(storeGraphic.setDrop({
      id: element.id,
      handle: vertices[index].id,
      block: '',
      type: 'move',
    }));
  };

  return {
    attributes: {
      onStart,
    },
  };
};
