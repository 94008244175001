import { ILever } from 'hooks/useConnectorSmooth';

import { toFixed } from './index';
import { getState } from './store';

const { serialize } = require('svg-path-round-corners/dist/es6/serialize');

export const Rad2Deg = 180 / Math.PI;

export interface ILayout {
  [key: number]: {
    [key: number]: string;
  };
}

export interface IArrangement {
  [k: string]: [number, number];
}

export const getArrangement = (elements: string[]): IArrangement => {
  const file = getState((state) => state.diagram.file);

  return Object.fromEntries(elements.map((id) => {
    return [id, [
      file[id].position[0],
      file[id].position[1],
    ] as [number, number]];
  }));
};

export const getLayout = (arrangement: {[key: string]: number[]}) => {
  const layout: ILayout = {};
  const skiped: string[] = [];
  const size = [0, 0];

  Object.keys(arrangement).forEach((id) => {
    if (!(arrangement[id][1] in layout)) {
      layout[arrangement[id][1]] = {};
    }

    if (arrangement[id][0] in layout[arrangement[id][1]]) {
      skiped.push(id);
    } else {
      layout[arrangement[id][1]][arrangement[id][0]] = id;

      size[0] = Math.max(size[0], arrangement[id][0] + 1);
      size[1] = Math.max(size[1], arrangement[id][1] + 1);
    }
  });

  skiped.forEach((id, i) => {
    layout[size[1] - 1][size[0] + i] = id;
  });

  return layout;
};

export const getLayoutSize = (layout: ILayout) => {
  const size = [0, 0];

  size[1] = Math.max(size[1], ...Object.keys(layout).map((y) => +y + 1));

  Object.keys(layout).forEach((y) => {
    size[0] = Math.max(size[0], ...Object.keys(layout[+y]).map((x) => +x + 1));
  });

  size[0] = Math.floor(size[0]);
  size[1] = Math.floor(size[1]);

  return size as [number, number];
};

export const optimizeLayout = (arrangement: {[key: string]: number[]}) => {
  const layout = getLayout(arrangement);
  const size = getLayoutSize(layout);

  const empty = {
    x: [] as number[],
    y: [] as number[],
  };

  Array.from(Array(size[1]).keys()).forEach((y) => {
    if (!(y in layout)) {
      empty.y.push(y);
    }
  });

  Array.from(Array(size[0]).keys()).forEach((x) => {
    if (!Object.values(layout).some((line) => x in line)) {
      empty.x.push(x);
    }
  });

  Object.keys(arrangement).forEach((id) => {
    arrangement[id][0] -= empty.x.filter((x) => x < arrangement[id][0]).length;
    arrangement[id][1] -= empty.y.filter((y) => y < arrangement[id][1]).length;
  });
};

export const renderPathDefault = (points: number[][]) => {
  const path = points.map((_, index) => {
    if (index === 0) {
      return { c: 'M', x: points[index][0], y: points[index][1] };
    }

    return { c: 'L', x: points[index][0], y: points[index][1] };
  });

  return serialize(path);
};

export const renderPathSmooth = (points: number[][], levers: ILever[]): string => {
  const path = [] as any;

  const getOffset = (a: number[], b: number[]) => {
    const direction = [
      b[0] - a[0],
      b[1] - a[1],
    ];

    const length = (direction[0] ** 2 + direction[1] ** 2) ** (1 / 2);

    if (length === 0) {
      return undefined;
    }

    return [
      (direction[0] / (length || 1)) * 5,
      (direction[1] / (length || 1)) * 5,
    ];
  };

  points.forEach((_, index) => {
    const point = [
      points[index][0],
      points[index][1],
    ];

    if (index === 0) {
      const offset = getOffset(point, levers[index].right);

      path.push({ c: 'M', x: point[0], y: point[1] });

      if (offset) {
        path.push({ c: 'L', x: point[0] + offset[0], y: point[1] + offset[1] });
      }

      return;
    }

    const before = levers[index - 1].right;
    const after = levers[index].left;

    if (index !== points.length - 1) {
      path.push({ c: 'C', x1: before[0], y1: before[1], x2: after[0], y2: after[1], x: point[0], y: point[1] });

      return;
    }

    const offset = getOffset(point, levers[index].left);

    path.push({ c: 'C', x1: before[0], y1: before[1], x2: after[0], y2: after[1], x: point[0] + (offset?.[0] ?? 0), y: point[1] + (offset?.[1] ?? 0) });

    if (offset) {
      path.push({ c: 'L', x: point[0], y: point[1] });
    }
  });

  return serialize(path);
};

export const renderPathFromStroke = (stroke: number[][]) => {
  if (!stroke || stroke.length < 1) {
    return '';
  }

  const d = stroke.reduce(
    (acc, [x0, y0], i, arr) => {
      const [x1, y1] = arr[(i + 1) % arr.length];
      acc.push(toFixed(x0, 2), toFixed(y0, 2), toFixed((x0 + x1) / 2, 2), toFixed((y0 + y1) / 2, 2));
      return acc;
    },
    ['M', ...[toFixed(stroke[0][0], 2), toFixed(stroke[0][1], 2)], 'Q']
  );

  d.push('Z');

  return d.join(' ');
};

export const cropStroke = (stroke: [number, number, number][], padding: number) => {
  if (stroke.length === 0) {
    return {
      cropped: [],
      position: [0, 0],
      size: [0, 0],
    };
  }

  const bounds = stroke.reduce((origin, point) => {
    return [
      Math.min(origin[0], point[0]),
      Math.min(origin[1], point[1]),
      Math.max(origin[2], point[0]),
      Math.max(origin[3], point[1]),
    ];
  }, [
    stroke[0][0],
    stroke[0][1],
    stroke[0][0],
    stroke[0][1],
  ]);

  return {
    cropped: stroke.map(([x, y, z]) => [
      toFixed(x - bounds[0] + padding, 2),
      toFixed(y - bounds[1] + padding, 2),
      z,
    ]) as [number, number, number][],
    position: [
      bounds[0] - padding,
      bounds[1] - padding,
    ],
    size: [
      bounds[2] - bounds[0] + padding + padding,
      bounds[3] - bounds[1] + padding + padding,
    ],
  };
};

export const optimizeStroke = (stroke: [number, number, number][], delta = 1) => {
  if (stroke.length === 0) {
    return [];
  }

  const path = [stroke[0]] as [number, number, number][];

  stroke.slice(0, -1).reduce((from, to) => {
    if (Math.abs(to[0] - from[0]) > delta || Math.abs(to[1] - from[1]) > delta) {
      path.push(to);

      return to;
    }

    return from;
  }, stroke[0]);

  path.push(stroke[stroke.length - 1]);

  return path;
};

export const getPathSide = (side: number[], delta?: number[]) => {
  if ((side && !delta) || (side && (side[0] !== 0 || side[1] !== 0))) {
    return [
      side[0] === 0 ? 0 : (side[0] < 0 ? -1 : 1),
      side[1] === 0 ? 0 : (side[1] < 0 ? -1 : 1),
    ];
  }

  if (delta && Math.abs(delta[0]) < Math.abs(delta[1])) {
    if (delta[1] < 0) {
      return [0, -1];
    }

    return [0, 1];
  }

  if (delta && delta[0] < 0) {
    return [-1, 0];
  }

  return [1, 0];
};

export const getPathSideCode = (side: number[]): 'L' | 'T' | 'R' | 'B' => {
  if (side[1] < 0) return 'T';
  if (side[1] > 0) return 'B';
  if (side[0] < 0) return 'L';
  return 'R';
};

export const getVectorNormalized = (vector: number[]) => {
  const distance = (vector[0] ** 2 + vector[1] ** 2) ** (1 / 2) || 1;

  return [
    vector[0] / distance,
    vector[1] / distance,
  ];
};

export const closestAngle = (from: number, to: number) => {
  return from + ((((to - from) % 360) + 540) % 360) - 180;
};
