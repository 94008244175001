import React, { ComponentType, useContext, useEffect } from 'react';
import { points } from 'utils/points';

import { ElementContext, IElementContext } from 'elements/Block/Element';

import { usePoints } from 'hooks/usePoints';

export interface IRender<T, E> {
  properties: Extract<T, Record<string, any>>;
  elements: Extract<E, Record<string, any>>;
}

interface IRenderConfig<T, E> {
  render: ComponentType<{
    width: number;
    height: number;
    element: IElementContext;
    properties: Extract<T, Record<string, any>>;
    elements: Extract<E, Record<string, any>>;
  }>;
}

export const createRender = <T, E = unknown > ({ render: Render }: IRenderConfig<T, E>) => {
  return ({ properties, elements } : IRender<T, E>) => {
    const element = useContext(ElementContext);

    const { render, unrender } = usePoints();

    useEffect(() => {
      render(element.id);

      return () => {
        unrender(element.id);
      };
    }, []);

    const size = points.elements[element.id]?.size;

    if (!size) {
      return null;
    }

    return (
      <Render
        element={element}
        width={size[0]}
        height={size[1]}
        properties={properties}
        elements={elements}
      />
    );
  };
};
