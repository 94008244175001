import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { storage as localStorage } from 'utils/storage';
import { getEnumItems } from 'utils';

export enum OptionAnimation { Popup, Modal, Notification, Sticker }
export enum OptionGrid { Edit, View }
export enum OptionSecondElementClick { TextFocusing, TextCreating }
export enum OptionEmptyTextBlur { TextRemoving, ParentRemoving, Disable }
export enum OptionLayout { Auto, Enable, Disable }

interface IFields {
  animation: OptionAnimation[];
  grid: OptionGrid[];
  layout: OptionLayout[];
  secondElementClick: OptionSecondElementClick[];
  emptyTextBlur: OptionEmptyTextBlur[];
}

interface State {
  offline: boolean;
  mobile: boolean;
  pause: boolean;
  validate: number;
  reset: number;
  search: string;
  app: IFields;
  profile: { [key: string]: string };
}

const storage = JSON.parse(localStorage.getItem('settings') || '{}');

const defaultState: State = {
  offline: false,
  mobile: false,
  pause: false,
  validate: 0,
  reset: 0,
  search: '',
  app: {
    animation: getEnumItems(OptionAnimation, storage.animation, [OptionAnimation.Notification, OptionAnimation.Popup, OptionAnimation.Modal, OptionAnimation.Sticker]),
    grid: getEnumItems(OptionGrid, storage.grid, [OptionGrid.Edit]),
    layout: getEnumItems(OptionLayout, storage.layout, [OptionLayout.Auto]),
    secondElementClick: getEnumItems(OptionSecondElementClick, storage.secondElementClick, [OptionSecondElementClick.TextCreating, OptionSecondElementClick.TextFocusing]),
    emptyTextBlur: getEnumItems(OptionEmptyTextBlur, storage.emptyTextBlur, [OptionEmptyTextBlur.ParentRemoving]),
  },
  profile: {},
};

const slice = createSlice({
  name: 'settings',
  initialState: defaultState,
  reducers: {
    setApp: (state, action: PayloadAction<IFields>) => {
      state.app = action.payload;

      localStorage.setItem('settings', JSON.stringify(state.app));
    },
    setProfile: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.profile = action.payload;
    },
    validate: (state) => {
      state.validate = Date.now();
    },
    reset: (state) => {
      state.reset = Date.now();
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setOffline: (state, action: PayloadAction<boolean>) => {
      state.offline = action.payload;
    },
    setMobile: (state, action: PayloadAction<boolean>) => {
      state.mobile = action.payload;
    },
    setPause: (state, action: PayloadAction<boolean>) => {
      state.pause = action.payload;
    },
  },
});

export const storeSettings = {
  setApp: slice.actions.setApp,
  setProfile: slice.actions.setProfile,
  validate: slice.actions.validate,
  reset: slice.actions.reset,
  setSearch: slice.actions.setSearch,
  setOffline: slice.actions.setOffline,
  setMobile: slice.actions.setMobile,
  setPause: slice.actions.setPause,
};

export default slice.reducer;
