import { ITool } from 'tools';
import { createTool } from 'tools/ToolLink';

import icon from './assets/icon.svg';

const Tool = createTool('operations', 'Operations', 'Operations', icon);

export const LinkOperations: ITool = {
  key: 'link-operations',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
