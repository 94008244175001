import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { getLink, IAuthStatus } from 'hooks/useAuth';

import { Loader } from 'components/Loader';

interface IAuthElement {
  status: IAuthStatus;
}

export const Auth: FC<IAuthElement> = ({ status }) => (
  <>
    <Loader />
    {status !== 'loading' && <Navigate to={getLink(status, true)} replace />}
  </>
);
