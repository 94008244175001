import { ITool } from 'tools';
import { getLockKey, getState } from 'utils/store';
import { createTool } from 'tools/ToolBasic';

import { storeDiagram } from 'store/diagram';

import { useStoreSelector } from 'hooks/useStore';

import icon from './assets/icon.svg';

const Tool = createTool({
  key: 'lock',
  def: false,
  title: 'Unlock',
  alt: 'Unlock',
  icon,
  getActive: () => false,
  getTitle: () => {
    useStoreSelector((state) => state.diagram.file);

    return 'Unlock';
  },
  getDisabled: (selection) => {
    return !['none', 'mine', 'partial'].includes(getLockKey(selection));
  },
  onClick: (selection, dispatch) => {
    const user = getState((state) => state.account.user.id);
    const links = getState((state) => state.diagram.links);
    const file = getState((state) => state.diagram.file);

    selection.forEach((id) => {
      [...(links[id] ?? [id])].forEach((id) => {
        if (!file[id]?.lockKey || file[id]?.lockKey === user) {
          dispatch(storeDiagram.setElement({
            id,
            properties: {
              lock: false,
            },
          }));
        }
      });
    });
  },
});

export const Unlock: ITool = {
  key: 'unlock',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
