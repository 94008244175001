import React from 'react';
import { ITool } from 'tools';

import { storeDiagram } from 'store/diagram';

import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import icon from './assets/icon.svg';

const Tool = () => {
  const dispatch = useStoreDispatch();

  const options = useStoreSelector((state) => state.graphic.options);
  const selection = useStoreSelector((state) => state.graphic.selection.elements);
  const element = useStoreSelector((state) => state.diagram.file[selection[0]]);

  const value = element.extend ?? false;

  const onClick = () => {
    selection.forEach((id) => {
      dispatch(storeDiagram.setElement({
        id,
        properties: { extend: !value, detailed: true, shortened: false },
      }));
    });
  };

  const active = selection.every((id) => {
    return options[id]?.extended;
  });

  if (!active) {
    return null;
  }

  return (
    <Hint message="Extend" side="bottom" variant="default">
      <button type="button" className={`button-icon v-1${value ? ' active' : ''}`} onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt="Extend" />
      </button>
    </Hint>
  );
};

export const Extend: ITool = {
  key: 'extend',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
