import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { Model } from 'blocks/factories/shape/Model';
import { createRender } from 'blocks/factories/simple/Render';
import { createPadding } from 'blocks/factories/simple/Padding';
import { createBlock, block } from 'blocks/factories/shape/Block';

import icon from './assets/icon.svg';

const { serialize } = require('svg-path-round-corners/dist/es6/serialize');
const { roundCorners } = require('svg-path-round-corners/dist/es6/index');

type IModel = Model<{}>;

export const config: IConfig = {
  title: 'Arrow left-right',
  search: 'basic shape arrow left right',
  type: 'basic-shape-arrow-left-right',
  group: ['Shape', 'Basic Shape'],
  memo: {
    key: 'basic-shape',
    styles: block.element.memo,
  },
  styles: block.element.styles,
};

const Render = createRender<IModel>({
  render: ({ width, height, element }) => {
    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - offset, 0);
    const y = Math.max(height - offset, 0);

    let a = (30 / 64) * y;
    let b = 20;

    if ((x / 2 - 8) / y <= 30 / 64) a = x / 2 - 8;
    if (y - 20 * 2 < 34) b = (y - 34) / 2;

    const points = [
      { c: 'M', x: offset, y: y / 2 },
      { c: 'L', x: offset + a, y: y },
      { c: 'C', x1: offset + a, y1: y, x2: offset + a, y2: y - b, x: offset + a, y: y - b },
      { c: 'H', x: x - a },
      { c: 'C', x1: x - a, y1: y - b, x2: x - a, y2: y, x: x - a, y: y },
      { c: 'L', x: x, y: y / 2 },
      { c: 'L', x: x - a, y: offset },
      { c: 'C', x1: x - a, y1: offset, x2: x - a, y2: offset + b, x: x - a, y: offset + b },
      { c: 'H', x: offset + a },
      { c: 'C', x1: offset + a, y1: offset + b, x2: offset + a, y2: offset, x: offset + a, y: offset },
      { c: 'Z' },
    ];

    return (
      <svg className="block-render" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path {...element.trigger} className="render-fill render-stroke render-hit" d={serialize(roundCorners(points, 6))} strokeLinejoin="round" strokeLinecap="round" strokeWidth={border} />
      </svg>
    );
  },
});

const Padding = createPadding<IModel>({
  size: [0, 1],
  padding: [0, 1, 0, 1],
  creator: ({ height }) => {
    const a = 20;
    const b = (30 / 64) * height - a;

    return [b, a, b, a];
  },
});

const Block = createBlock<IModel>({
  size: [42, 36],
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  Render,
  Padding,
});

export const BasicShapeArrowLeftRight : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: block.element.options,
    constraints: block.element.constraints,
    onCreate: block.creator(config, {
      width: 88,
      height: 64,
    }),
    onCopy: block.copier(config),
    title: config.title,
    size: [88, 64],
    icon,
    class: block.element.class,
  },
  button: block.button(config),
  tools: ['shape-basic', ...block.tools],
  toolbars: block.toolbars,
};
