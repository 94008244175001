import React, { memo } from 'react';
import { IBlock, IConfig } from 'blocks';

import { Button } from 'elements/Block/Button';

import { createBlock } from 'blocks/factories/service/Block';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createCreator } from 'blocks/factories/service/Creator';

import iconWhite from './assets/icon-white.svg';
import icon from './assets/icon.svg';

export const config: IConfig = {
  title: 'VMware',
  search: 'icon vmware cloud architecture',
  type: 'vmware',
  group: ['VMware'],
  memo: {
    key: 'vmware',
    styles: [],
  },
  styles: [
    'width',
    'height',
  ],
};

const Block = createBlock({
  dir: 'vmware',
  config,
});

export const ServiceVMware : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-wrap', 'element-wrapable', 'menu-button-hidden'],
    constraints: {},
    onCreate: createCreator({
      tab: 'vmware',
      config,
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [48, 48],
    icon,
    class: {
      title: 'VMware',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'vmware',
  },
  tools: [
    'vmware',
    'lock',
    'delete',
  ],
  toolbars: [

  ],
};
