import React, { FC, ReactNode, useContext, useEffect, useLayoutEffect } from 'react';
import { Emitter } from 'utils/events';
import { getState } from 'utils/store';
import { points } from 'utils/points';
import { blocks } from 'blocks';

import { storeDiagram } from 'store/diagram';

import { useOption } from 'hooks/useOption';
import { usePoints } from 'hooks/usePoints';
import { useProperty } from 'hooks/useProperty';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { CanvasStaticContext } from '../Canvas';
import { ElementContext } from './Element';
import { Captured } from './Captured';

export interface IShape {
  children: ReactNode;

  classElement: string;
  classProperties?: string;
}

export const Sticky: FC<IShape> = ({ children, classElement, classProperties }) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);
  const canvas = useContext(CanvasStaticContext);

  const copy = useStoreSelector((state) => state.graphic.copy);
  const sticky = useStoreSelector((state) => state.diagram.file[element.id].sticky);
  const position = useStoreSelector((state) => state.diagram.file[element.id].position);
  const moving = useStoreSelector((state) => !blocks[state.diagram.file[sticky]?.type]?.element.options.includes('element-container') || state.diagram.file[sticky].elements.length === 0);
  const offset = useStoreSelector((state) => state.diagram.file[element.id].offset) ?? [0, 0];
  const loading = useStoreSelector((state) => !state.graphic.elements[element.id] || (sticky && !(sticky in state.graphic.elements)));

  const hidden = useProperty(loading);
  const hiding = useProperty(loading);

  const point = (!sticky || !points.elements[sticky]) ? position : [
    points.elements[sticky].position[0] + (moving ? points.elements[sticky?.split('.')[0]].offset[0] : 0) + points.elements[sticky].size[0] * position[0] + offset[0],
    points.elements[sticky].position[1] + (moving ? points.elements[sticky?.split('.')[0]].offset[1] : 0) + points.elements[sticky].size[1] * position[1] + offset[1],
  ];

  const parent = usePoints();

  useOption({
    id: element.id,
    key: 'closed',
    value: loading,
    deps: [loading],
  });

  // Set Hidden
  useEffect(() => {
    hidden.set(loading);
  }, [loading]);

  // Subscribe for parent
  useEffect(() => {
    if (!sticky) {
      return () => {};
    }

    const element = sticky.split('.')[0];

    parent.subscribe(element);

    return () => {
      parent.unsubscribe(element);
    };
  }, [sticky]);

  useLayoutEffect(() => {
    if (!canvas.loading) {
      element.updatePoints();
    }
  }, [canvas.loading]);

  useEffect(() => {
    if (!hidden.value) {
      element.updatePoints();
    }
  }, [hidden.value]);

  // Set Hidden Transition
  useEffect(() => {
    if (!hidden.value) {
      setTimeout(() => {
        hiding.set(false);
      }, 300);
    }
  }, [hidden.value]);

  // Set Shape Points
  useLayoutEffect(() => {
    points.shape({
      id: element.id,
    });

    return () => {
      points.unshape({
        id: element.id,
      });
    };
  }, [element.id]);

  // Update Frame Points on Position Change
  useLayoutEffect(() => {
    element.updatePoints();
  }, [point[0], point[1]]);

  // Listen to Move Event
  useEffect(() => {
    const onMove = (payload: { id: string, position: [number, number], parent: string }) => {
      if (payload.id !== element.id) {
        return;
      }

      if (!payload.parent) {
        dispatch(storeDiagram.setElement({
          id: payload.id,
          properties: {
            position: payload.position,
            sticky: '',
            offset: [0, 0],
            parent: '',
          },
        }));

        return;
      }

      const drag = getState((state) => state.graphic.drag);

      if (!drag) {
        return;
      }

      const offset = [
        -(drag.frame.size[0] / 2 + drag.frame.offset[0]),
        -(drag.frame.size[1] / 2 + drag.frame.offset[1]),
      ];

      dispatch(storeDiagram.setElement({
        id: payload.id,
        properties: {
          position: payload.position,
          sticky: payload.parent,
          offset,
        },
      }));
    };

    Emitter.on('move-element', onMove);

    return () => {
      Emitter.off('move-element', onMove);
    };
  }, [element.id]);

  return (
    <div
      className={
        `diagram-element element-sticker ${classElement}`
          .append(classProperties)
          .appendWhen(element.grabbed && !copy, 'grabbed')
          .appendWhen(element.selected, 'selected')
          .appendWhen(hidden.value, 'hidden')
          .appendWhen(hiding.value, 'hiding')
          .appendWhen(element.locked, 'locked')
          .appendWhen(element.captured, 'captured')
          .appendWhen(element.highlighted, 'highlighted')
          .appendWhen(element.containered, 'containered')
          .appendWhen(element.focused || element.highlighted, 'focused')
          .appendWhen(element.invisible, 'invisible')
      }
      ref={element.wrap.link}
      style={{
        position: 'absolute',
        left: point[0],
        top: point[1],
      }}
    >
      {!loading && (
        <>
          {children}
          <Captured />
        </>
      )}
    </div>
  );
};
