import { useContext, useMemo } from 'react';

import { IPoint } from 'elements/Block/Connector';
import { ElementContext } from 'elements/Block/Element';

import { useStoreSelector } from './useStore';

const config = {
  minDistance: 800,
};

export interface IVertex {
  id: string;
  index: number;
  hidden: boolean;
  position: number[];
}

const getPoint = (a: number[], b: number[]) => {
  const direction = [
    b[0] - a[0],
    b[1] - a[1],
  ];

  return {
    length: direction[0] ** 2 + direction[1] ** 2,
    position: [
      (b[0] + a[0]) / 2,
      (b[1] + a[1]) / 2,
    ],
  };
};

export const useConnectorCreator = (points: IPoint[], path: number[][], levers?: number[][][]) => {
  const element = useContext(ElementContext);

  const drop = useStoreSelector((state) => state.graphic.drop?.id === element.id);
  const style = useStoreSelector((state) => state.diagram.file[element.id].style);

  return useMemo(() => {
    if (style === 'elbow') {
      if (points.length === 2 && path.length === 4) {
        const point = getPoint(path[1], path[2]);

        return [
          {
            id: points[0].id,
            index: 1,
            hidden: point.length < config.minDistance || drop,
            position: point.position,
          },
        ];
      }

      const from = getPoint(path[0], path[1]);
      const to = getPoint(path[path.length - 1], path[path.length - 2]);

      return [
        {
          id: points[0].id,
          index: 1,
          hidden: from.length < config.minDistance || drop,
          position: from.position,
        },
        {
          id: points[points.length - 1].id,
          index: points.length - 1,
          hidden: to.length < config.minDistance || drop,
          position: to.position,
        },
      ];
    }

    if (style === 'smooth' && levers) {
      return levers.slice(1).map((_, index) => {
        const to = points[index + 1];
        const from = points[index];

        const point = getPoint(from.position, to.position);

        return {
          id: `${from.id}-${to.id}`,
          index: index + 1,
          hidden: point.length < config.minDistance || drop,
          position: levers[index][2],
        };
      });
    }

    return points.slice(1).map((_, index) => {
      const to = points[index + 1];
      const from = points[index];

      const point = getPoint(from.position, to.position);

      return {
        id: `${from.id}-${to.id}`,
        index: index + 1,
        hidden: point.length < config.minDistance || drop,
        position: point.position,
      };
    });
  }, [points, drop, style, levers]);
};
