import React from 'react';
import { ITool } from 'tools';

import { createFormatTool } from 'tools/ToolFormat';

import icon from './assets/icon.svg';

const Tool = createFormatTool({
  icon,
  title: 'Highlight',
  format: 'background',
  condition: (value) => !!value,
  filter: (value) => !!value,
  set: (status) => (status ? '' : 'rgba(58, 61, 78, 0.2)'),
});

export const FormatHighlightCommon: ITool = {
  key: 'format-highlight-common',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
