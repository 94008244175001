import React, { memo, useEffect, useLayoutEffect } from 'react';
import { IBlock, IConfig } from 'blocks';
import { Rad2Deg } from 'utils/graphic';
import { getState } from 'utils/store';
import { points } from 'utils/points';
import { lines } from 'utils/lines';

import { useJoins } from 'hooks/useJoins';
import { useOption } from 'hooks/useOption';
import { useResizer } from 'hooks/useResizer';
import { useEnterOnSelection } from 'hooks/useEnterOnSelection';
import { useClickOnSelection } from 'hooks/useClickOnSelection';
import { useArrangementWrapper } from 'hooks/useArrangementWrapper';
import { useTextCreatorWithWrapper } from 'hooks/useTextCreatorWithWrapper';

import { Frame } from 'elements/Wrap/Frame';
import { Shape } from 'elements/Block/Shape';
import { Button } from 'elements/Block/Button';
import { Resizer } from 'elements/Block/Resizer';
import { Selection } from 'elements/Block/Selection';
import { Arrangement } from 'elements/Block/Arrangement';

import { createCreator } from 'blocks/factories/simple/Creator';
import { createRender } from 'blocks/factories/complex/Render';
import { createCopier } from 'blocks/factories/simple/Copier';
import { createBlock } from 'blocks/factories/simple/Block';
import design from 'blocks/factories/assets/Simple.module.css';
import shape from 'blocks/factories/assets/Shape.module.css';

import icon from './assets/icon.svg';
import iconWhite from './assets/icon-white.svg';

type IModel = {
  color: string;
  render: number;
  width?: number;
  height?: number;
  realWidth?: number;
  realHeight?: number;
  title?: string;
  lockKey: string;
  lock: boolean;
  hidden: boolean;
};

export const config: IConfig = {
  title: 'Interface',
  search: 'shape interface uml unified modeling language',
  type: 'uml-component-shape-interface',
  group: ['UML Component Shape Icon'],
  memo: {
    key: 'uml-component-shape',
    styles: [
      'color',
      'render',
    ],
  },
  styles: [
    'color',
    'render',
  ],
};

interface IElements {
  connectors: string[];
}

const Render = createRender<IModel, IElements>({
  render: ({ width, height, element, properties, elements }) => {
    const border = 2;
    const offset = border / 2;

    const x = Math.max(width - border, 0);
    const y = Math.max(height - border, 0);

    const points = [
      [x / 2 + offset, y / 2 + offset],
      [x / 2, y / 2],
    ];

    const o = 6;

    const angles = elements.connectors.map((connector) => {
      return [connector, lines.directions[`${connector}.1`]] as [string, number[]];
    }).filter(([_, direction]) => {
      return direction;
    }).map(([id, direction]) => {
      return {
        key: id,
        value: -Math.atan2(direction[0], direction[1]) * Rad2Deg - 90,
      };
    });

    return (
      <div className="block-render" style={{ marginTop: -o, marginLeft: -o }}>
        <svg width={width + o * 2} height={height + o * 2} viewBox={`0 0 ${width + o * 2} ${height + o * 2}`} fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse {...element.trigger} className={'render-fill render-stroke render-hit'.appendWhen(properties.hidden && elements.connectors.length > 0, shape.hidden)} cx={points[0][0] + o} cy={points[0][1] + o} rx={points[1][0]} ry={points[1][1]} strokeLinecap="round" strokeWidth={border} />

          {angles.map(({ key, value }) => (
            <g key={key} style={{ transformOrigin: 'center', transform: `rotate(${value}deg)` }}>
              <svg x={0} y={0} width={(width + o * 2) / 2} height={height + o * 2} viewBox={`0 0 ${(width + o * 2) / 2} ${height + o * 2}`} style={{ transform: 'rotate(45deg)' }} fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse className="render-stroke" cx={points[0][0] + o} cy={points[0][1] + o} rx={points[1][0] + o} ry={points[1][1] + o} style={{ strokeDasharray: '100, 0' }} stroke="round" strokeWidth={border} />
              </svg>
            </g>
          ))}
        </svg>
      </div>
    );
  },
});

const Block = createBlock<IModel>({
  memo: {
    key: config.memo.key,
    properties: config.memo.styles,
  },
  block: ({ properties, element }) => {
    const arrangement = useArrangementWrapper();
    const create = useTextCreatorWithWrapper({ properties: { text: properties.title } });
    const resier = useResizer<HTMLImageElement>({
      strict: true,
      realWidth: properties.realWidth,
      realHeight: properties.realHeight,
      minWidth: 16,
      minHeight: 16,
    });

    const { connectors } = useJoins('to', [], ([_, connector]) => {
      return getState((state) => state.diagram.file[connector]?.type === 'uml-use-case-connector' && state.diagram.file[connector]?.end === 0);
    });

    useEffect(() => {
      const callback = () => {
        points.draw({
          id: element.id,
        });
      };

      connectors.forEach((id) => {
        points.render({
          id,
          callback,
        });
      });

      return () => {
        connectors.forEach((id) => {
          points.unrender({
            id,
            callback,
          });
        });
      };
    }, [connectors.join(',')]);

    useEnterOnSelection({
      onPress: create('enter'),
      mode: 'edit',
    });

    useClickOnSelection({
      onClick: create('click'),
      mode: 'edit',
    });

    useOption({
      id: element.id,
      key: 'hidden',
      value: connectors.length === 0 ? 'disable' : '',
      deps: [connectors.length === 0],
    });

    useOption({
      id: element.id,
      key: 'connector-centered',
      value: (connectors.length > 0 && properties.hidden) ? ['0'] : '',
      deps: [connectors.length > 0 && properties.hidden],
    });

    useLayoutEffect(() => {
      points.notify({
        id: element.id,
      });
    }, [connectors.length > 0 && properties.hidden]);

    return (
      <Shape
        classElement={shape.element}
        classProperties={`${design[`color-${properties.color}`]} ${design[`render-${properties.render}`]}`}
      >
        <Arrangement {...arrangement} />
        <Render properties={properties} elements={{ connectors }} />
        <Selection status={!resier.status} />
        <Frame />

        <div
          className="block-content"
          {...resier.wrap}
        />

        <Resizer.Size {...resier.attributes} status={resier.visibility} symmetrically />
      </Shape>
    );
  },
});

export const UMLComponentShapeInterface : IBlock = {
  type: config.type,
  block: memo(Block),
  element: {
    group: config.group,
    search: config.search,
    styles: config.styles,
    options: ['element-wrap', 'element-wrapable'],
    constraints: {
      prevent: {
        color: [
          'none',
          'white',
        ],
      },
    },
    onCreate: createCreator<IModel>({
      type: config.type,
      memo: config.memo.key,
      styles: config.styles,
      properties: {
        color: 'dark',
        render: 0,
        lockKey: '',
        lock: false,
        width: 28,
        height: 28,
        realWidth: 28,
        realHeight: 28,
        hidden: false,
      },
    }),
    onCopy: createCopier({
      type: config.type,
    }),
    title: config.title,
    size: [28, 28],
    icon,
    class: {
      title: 'Shape',
      icon: iconWhite,
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: [
      'only-edit',
    ],
    type: 'drag',
  },
  tools: [
    'hide',
    'render',
    'color',
    'lock',
    'delete',
  ],
  toolbars: [

  ],
};
