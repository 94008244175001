import React from 'react';
import { ITool } from 'tools';
import { blocks } from 'blocks';
import { getMemoStyle } from 'utils/store';

import { storeGraphic } from 'store/graphic';

import { config } from 'tabs/Color';

import { useConstraintStatus } from 'hooks/useConstraintStatus';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import style from './assets/style.module.css';

export const Tool = () => {
  const dispatch = useStoreDispatch();

  const active = useConstraintStatus('color');

  const element = useStoreSelector((state) => {
    if (state.graphic.selection.elements.length > 0) {
      return state.diagram.file[state.graphic.selection.elements[0]];
    }

    return {
      color: getMemoStyle(blocks[state.graphic.drop?.block || state.graphic.draw || '_']?.element.memo ?? '', 'color'),
    };
  });

  const onClick = () => {
    dispatch(storeGraphic.setComponentTab('color'));
  };

  if (!active) {
    return null;
  }

  return (
    <Hint message="Color" side="bottom" variant="default">
      <button type="button" className="button-icon v-1" onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <div
          className={
            style.icon
              .appendWhen(element.color === 'white', style.border)
              .appendWhen(element.color === 'none', style.none)
          }
          style={{ background: config.colors[element.color || 'dark'] }}
        />
      </button>
    </Hint>
  );
};

export const Color: ITool = {
  key: 'color',
  tool: Tool,
  options: ['only-edit'],
};
