import React, { createContext, FC, useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import { getCacheProperty } from 'utils/store';
import { points } from 'utils/points';
import { blocks } from 'blocks';

import { storeGraphic } from 'store/graphic';

import { useOption } from 'hooks/useOption';
import { GridContext } from 'hooks/useGrid';
import { useProperty } from 'hooks/useProperty';
import { useBlockOffset } from 'hooks/useBlockOffset';
import { usePointsElement } from 'hooks/usePointsElement';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';
import { ITrigger, IWrap, useBlockControl } from 'hooks/useBlockControl';

import { CanvasStaticContext } from '../Canvas';

export interface IElement {
  id: string;
  children: React.ReactNode;
}

export interface IElementContext {
  updatePoints: (reset?: boolean) => void;

  wrap: IWrap;
  trigger: ITrigger;

  id: string;
  parent: string;
  selected: boolean;
  grabbed: boolean;
  wrapper: boolean;
  wrapped: boolean;
  focused: boolean;
  nested: boolean;
  wrapping: boolean;
  highlighted: boolean;
  containered: boolean;
  invisible: boolean;
  locked: boolean;
  captured: boolean;
  loading: boolean;
}

export const ElementContext = createContext<IElementContext>({
  updatePoints: () => {},

  wrap: {
    ref: null,
    link: () => {},
  },
  trigger: {
    onMouseDown: () => {},
    onClick: () => {},
  },

  id: '',
  parent: '',
  selected: false,
  grabbed: false,
  wrapper: false,
  wrapped: false,
  focused: false,
  nested: false,
  wrapping: false,
  highlighted: false,
  containered: false,
  invisible: false,
  locked: false,
  captured: false,
  loading: false,
});

export const Element: FC<IElement> = ({ children, id }) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);
  const canvas = useContext(CanvasStaticContext);
  const grid = useContext(GridContext);

  const parent = useStoreSelector((state) => state.diagram.file[id].parent);
  const position = useStoreSelector((state) => state.diagram.file[id].position);

  const locked = useStoreSelector((state) => state.diagram.file[id].lock || !!state.diagram.captured[id]);
  const captured = useStoreSelector((state) => !!state.diagram.captured[id]);
  const copy = useStoreSelector((state) => state.graphic.copy);
  const mode = useStoreSelector((state) => state.diagram.mode);
  const view = useStoreSelector((state) => state.diagram.mode === 'view' && state.graphic.selection.elements.length === 0);
  const grabbed = useStoreSelector((state) => state.graphic.drag?.element.id === id);
  const selected = useStoreSelector((state) => (state.graphic.selection.elements.includes(id) || !!(state.diagram.mode === 'view' && blocks[state.diagram.file[parent]?.type]?.element.options.includes('element-layout') && blocks[state.diagram.file[id]?.type]?.element.options.includes('element-layout') && element.selected)));
  const wrapped = useStoreSelector((state) => blocks[state.diagram.file[parent]?.type]?.element.options.includes('element-wrap'));
  const wrapper = useStoreSelector((state) => blocks[state.diagram.file[id]?.type]?.element.options.includes('element-wrap'));
  const container = useStoreSelector((state) => blocks[state.diagram.file[id]?.type]?.element.options.includes('element-container'));
  const wrapping = useStoreSelector((state) => wrapper && state.diagram.childs[id]?.length > 0);
  const highlighted = useStoreSelector((state) => state.diagram.highlighted[state.diagram.file[id]?.sticky] ?? state.diagram.highlighted[id]);
  const containered = useStoreSelector((state) => element.containered || (state.graphic.selection.elements.includes(parent) && blocks[state.diagram.file[parent]?.type]?.element.options.includes('element-container')) || !state.graphic.selection.elements.every((parent) => !state.diagram.parents[state.diagram.file[id]?.sticky ?? id]?.includes(parent)));
  const focused = useStoreSelector((state) => element.selected || state.diagram.focused[id] || (mode === 'view' && wrapping && !wrapped && !container));
  const sticky = useStoreSelector((state) => blocks[state.diagram.file[id]?.type]?.element.options.includes('element-sticker') && state.diagram.file[id]?.sticky);
  const invisible = useStoreSelector((state) => (
    (
      blocks[state.diagram.file[parent]?.type]?.element.options.includes('element-wrap-hidden')
      && getCacheProperty(state, parent, 'hidden')
    )
    || (sticky && state.graphic.options[sticky]?.invisible)
    || !(state.diagram.parents[id] ?? []).every((id) => {
      return !getCacheProperty(state, id, 'hidden') && !state.graphic.options[id]?.invisible;
    })
    || (
      state.diagram.file[id].elements
      && state.diagram.file[id].elements.length > 0
      && state.diagram.file[id].elements.every((id: string) => {
        return state.graphic.options[id]?.closed;
      })
    )
  ));

  const nested = !useStoreSelector((state) => {
    if (
      !wrapped
      || element.selected
      || state.graphic.selection.elements.includes(parent)
      || (element.focused && state.diagram.focused[element.id])
      || (element.focused && mode === 'view' && blocks[state.diagram.file[element.id]?.type]?.element.options.includes('element-wrap-inline'))
    ) {
      return true;
    }

    return focused;
  });

  const focusing = useProperty(focused);

  const block = useBlockControl(id, wrapped, selected);

  const updatePoints = (reset = true) => {
    if (!canvas.loading && block.wrap.ref && !grid.loading) {
      points.update({ id, reset });
    }
  };

  useBlockOffset(id, block.wrap.ref);

  usePointsElement({
    id,
    parent: parent && `${parent}.${position[0]}-${position[1]}`,
    ref: block.wrap.ref,
  });

  useOption({
    id,
    key: 'invisible',
    value: invisible || element.invisible,
    deps: [invisible || element.invisible],
  });

  const context = useMemo(() => ({
    updatePoints,

    wrap: block.wrap,
    trigger: block.trigger,

    id,
    parent,
    grabbed,
    selected,
    wrapper,
    wrapped,
    focused,
    locked,
    nested,
    highlighted,
    containered,
    wrapping,
    captured,
    invisible: invisible || element.invisible,
    loading: canvas.loading,
  }), [
    id,
    parent,
    block.wrap,
    block.trigger,
    grabbed,
    selected,
    wrapper,
    wrapped,
    focused,
    nested,
    wrapping,
    highlighted,
    containered,
    locked,
    captured,
    invisible || element.invisible,
    canvas.loading,
  ]);

  // Reset Selection on Grab
  useEffect(() => {
    if (grabbed && !focused && !selected && !copy) {
      dispatch(storeGraphic.setSelectionElements([]));
    }
  }, [grabbed && !focused && !selected && !copy]);

  // Register Element
  useEffect(() => {
    if (!block.wrap.ref) {
      return () => {};
    }

    dispatch(storeGraphic.setElement({ id, status: true }));

    return () => {
      dispatch(storeGraphic.setElement({ id, status: false }));
    };
  }, [block.wrap.ref]);

  // Memo Focused Value
  useEffect(() => {
    if (highlighted || !view) {
      focusing.set(highlighted);

      return () => {};
    }

    const timer = setTimeout(() => {
      focusing.set(false);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [highlighted, view]);

  // Keep Z-Index active
  useLayoutEffect(() => {
    if (!context.wrap.ref) {
      return;
    }

    if (focusing.value && !highlighted && view) {
      context.wrap.ref.classList.add('showing');
    } else {
      context.wrap.ref.classList.remove('showing');
    }
  }, [context.wrap.ref && focusing.value && !highlighted && view]);

  return (
    <ElementContext.Provider value={context}>
      {children}
    </ElementContext.Provider>
  );
};
