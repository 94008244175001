/* eslint-disable no-console */
import React, { ComponentType } from 'react';
import { IToolData } from 'tools';

import { IDynamicContext } from 'elements/Canvas';
import { UMLClassClass } from './UMLClass/Class';
import { UMLClassInterface } from './UMLClass/Interface';
import { GeneralText } from './General/Text';
import { BasicShapeArrowLeft } from './Basic/ShapeArrowLeft';
import { BasicShapeArrowLeftRight } from './Basic/ShapeArrowLeftRight';
import { BasicShapeArrowRight } from './Basic/ShapeArrowRight';
import { BasicShapeBarrel } from './Basic/ShapeBarrel';
import { BasicShapeBlob } from './Basic/ShapeBlob';
import { BasicShapeBubble } from './Basic/ShapeBubble';
import { BasicShapeCloud } from './Basic/ShapeCloud';
import { BasicShapeCross } from './Basic/ShapeCross';
import { BasicShapeEllipse } from './Basic/ShapeEllipse';
import { BasicShapeHexagon } from './Basic/ShapeHexagon';
import { BasicShapeOctagon } from './Basic/ShapeOctagon';
import { BasicShapeParallelogram } from './Basic/ShapeParallelogram';
import { BasicShapePentagon } from './Basic/ShapePentagon';
import { BasicShapeRectangle } from './Basic/ShapeRectangle';
import { BasicShapeRectangleRounded } from './Basic/ShapeRectangleRounded';
import { BasicShapeRhombus } from './Basic/ShapeRhombus';
import { BasicShapeStar } from './Basic/ShapeStar';
import { BasicShapeTrapezoid } from './Basic/ShapeTrapezoid';
import { BasicShapeTriangle } from './Basic/ShapeTriangle';
import { CoreGrid } from './Core/Grid';
import { CoreWrapper } from './Core/Wrapper';
import { FlowchartCard } from './Flowchart/Card';
import { FlowchartConnector } from './Flowchart/Connector';
import { FlowchartData } from './Flowchart/Data';
import { FlowchartDatabase } from './Flowchart/Database';
import { FlowchartDecision } from './Flowchart/Decision';
import { FlowchartDelay } from './Flowchart/Delay';
import { FlowchartDirectAccessStorage } from './Flowchart/DirectAccessStorage';
import { FlowchartDisplay } from './Flowchart/Display';
import { FlowchartDocument } from './Flowchart/Document';
import { FlowchartExtract } from './Flowchart/Extract';
import { FlowchartInternalStorage } from './Flowchart/InternalStorage';
import { FlowchartLoopLimit } from './Flowchart/LoopLimit';
import { FlowchartManualInput } from './Flowchart/ManualInput';
import { FlowchartManualOperation } from './Flowchart/ManualOperation';
import { FlowchartMerge } from './Flowchart/Merge';
import { FlowchartMultipleDocuments } from './Flowchart/MultipleDocuments';
import { FlowchartOffPageConnector } from './Flowchart/OffPageConnector';
import { FlowchartOr } from './Flowchart/Or';
import { FlowchartPredefinedProcess } from './Flowchart/PredefinedProcess';
import { FlowchartPreparation } from './Flowchart/Preparation';
import { FlowchartProcess } from './Flowchart/Process';
import { FlowchartShapeCard } from './Flowchart/ShapeCard';
import { FlowchartShapeConnector } from './Flowchart/ShapeConnector';
import { FlowchartShapeData } from './Flowchart/ShapeData';
import { FlowchartShapeDatabase } from './Flowchart/ShapeDatabase';
import { FlowchartShapeDecision } from './Flowchart/ShapeDecision';
import { FlowchartShapeDelay } from './Flowchart/ShapeDelay';
import { FlowchartShapeDirectAccessStorage } from './Flowchart/ShapeDirectAccessStorage';
import { FlowchartShapeDisplay } from './Flowchart/ShapeDisplay';
import { FlowchartShapeDocument } from './Flowchart/ShapeDocument';
import { FlowchartShapeExtract } from './Flowchart/ShapeExtract';
import { FlowchartShapeInternalStorage } from './Flowchart/ShapeInternalStorage';
import { FlowchartShapeLoopLimit } from './Flowchart/ShapeLoopLimit';
import { FlowchartShapeManualInput } from './Flowchart/ShapeManualInput';
import { FlowchartShapeManualOperation } from './Flowchart/ShapeManualOperation';
import { FlowchartShapeMerge } from './Flowchart/ShapeMerge';
import { FlowchartShapeMultipleDocuments } from './Flowchart/ShapeMultipleDocuments';
import { FlowchartShapeOffPageConnector } from './Flowchart/ShapeOffPageConnector';
import { FlowchartShapeOr } from './Flowchart/ShapeOr';
import { FlowchartShapePredefinedProcess } from './Flowchart/ShapePredefinedProcess';
import { FlowchartShapePreparation } from './Flowchart/ShapePreparation';
import { FlowchartShapeProcess } from './Flowchart/ShapeProcess';
import { FlowchartShapeStoredData } from './Flowchart/ShapeStoredData';
import { FlowchartShapeSummingJunction } from './Flowchart/ShapeSummingJunction';
import { FlowchartShapeTerminator } from './Flowchart/ShapeTerminator';
import { FlowchartStoredData } from './Flowchart/StoredData';
import { FlowchartSummingJunction } from './Flowchart/SummingJunction';
import { FlowchartTerminator } from './Flowchart/Terminator';
import { GeneralIcon } from './General/Icon';
import { GeneralImage } from './General/Image';
import { GeneralSticker } from './General/Sticker';
import { InformationCheckbox } from './Information/Checkbox';
import { InformationNote } from './Information/Note';
import { InformationQuote } from './Information/Quote';
import { InformationShapeCheckbox } from './Information/ShapeCheckbox';
import { InformationShapeNote } from './Information/ShapeNote';
import { InformationShapeQuote } from './Information/ShapeQuote';
import { InformationShapeStickyNote } from './Information/ShapeStickyNote';
import { InformationStickyNote } from './Information/StickyNote';
import { AWSIcon } from './AWS/Icon';
import { ServiceAzure } from './Services/Azure';
import { ServiceCisco } from './Services/Cisco';
import { GoogleIcon } from './Google/Icon';
import { ServiceKubernetes } from './Services/Kubernetes';
import { ServiceVMware } from './Services/VMware';
import { GeneralContainer } from './General/Container';
import { GeneralConnector } from './General/Connector';
import { InformationCode } from './Information/Code';
import { UMLClassConnector } from './UMLClass/Connector';
import { InformationConnector } from './Information/Connector';
import { InformationNoteWithConnector } from './Information/NoteWithConnector';
import { UMLClassClassMultiplicity } from './UMLClass/ClassMultiplicity';
import { UMLClassInterfaceMultiplicity } from './UMLClass/InterfaceMultiplicity';
import { UMLUseCaseUseCase } from './UMLUseCase/UseCase';
import { UMLUseCaseShapeUseCase } from './UMLUseCase/ShapeUseCase';
import { UMLActivityActivity } from './UMLActivity/Activity';
import { UMLActivityShapeActivity } from './UMLActivity/ShapeActivity';
import { UMLActivityShapeFlowFinal } from './UMLActivity/ShapeFlowFinal';
import { UMLActivityFlowFinal } from './UMLActivity/FlowFinal';
import { UMLActivityShapeDecision } from './UMLActivity/ShapeDecision';
import { UMLActivityDecision } from './UMLActivity/Decision';
import { UMLActivityShapeStart } from './UMLActivity/ShapeStart';
import { UMLActivityStart } from './UMLActivity/Start';
import { UMLActivityShapeEnd } from './UMLActivity/ShapeEnd';
import { UMLActivityEnd } from './UMLActivity/End';
import { UMLActivityShapeHistoryPseudostate } from './UMLActivity/ShapeHistoryPseudostate';
import { UMLActivityHistoryPseudostate } from './UMLActivity/HistoryPseudostate';
import { UMLActivityShapeSendSignal } from './UMLActivity/ShapeSendSignal';
import { UMLActivitySendSignal } from './UMLActivity/SendSignal';
import { UMLActivityShapeReceiveSignal } from './UMLActivity/ShapeReceiveSignal';
import { UMLActivityReceiveSignal } from './UMLActivity/ReceiveSignal';
import { UMLPackageShapeCard } from './UMLPackage/ShapeCard';
import { UMLPackageCard } from './UMLPackage/Card';
import { UMLActivityFork } from './UMLActivity/Fork';
import { UMLUseCaseShapeActor } from './UMLUseCase/ShapeActor';
import { UMLUseCaseActor } from './UMLUseCase/Actor';
import { UMLDeploymentContainerNode } from './UMLDeployment/ContainerNode';
import { UMLPackageContainerPackage } from './UMLPackage/ContainerPackage';
import { UMLDeploymentShapeNode } from './UMLDeployment/ShapeNode';
import { UMLDeploymentNode } from './UMLDeployment/Node';
import { UMLPackageShapePackage } from './UMLPackage/ShapePackage';
import { UMLPackagePackage } from './UMLPackage/Package';
import { UMLComponentComponentBox } from './UMLComponent/ComponentBox';
import { UMLComponentShapeComponent } from './UMLComponent/ShapeComponent';
import { UMLComponentComponent } from './UMLComponent/Component';
import { UMLUseCaseConnector } from './UMLUseCase/Connector';
import { UMLComponentShapeInterface } from './UMLComponent/ShapeInterface';
import { UMLComponentInterface } from './UMLComponent/Interface';
import { UMLComponentShapePort } from './UMLComponent/ShapePort';
import { UMLComponentPort } from './UMLComponent/Port';
import { AWSGroupCloud } from './AWS/GroupCloud';
import { AWSGroupZone } from './AWS/GroupZone';
import { AWSGroupGeneric } from './AWS/GroupGeneric';
import { AWSGroupRegion } from './AWS/GroupRegion';
import { GoogleGroupCloud } from './Google/GroupCloud';
import { GoogleGroupUser } from './Google/GroupUser';
import { GoogleGroupZone } from './Google/GroupZone';
import { GoogleGroupOptional } from './Google/GroupOptional';
import { UMLComponentContainerComponent } from './UMLComponent/ContainerComponent';
import { GeneralMarker } from './General/Marker';

export type IType = 'drag' | string;
export type IOption = (
  | 'element-marker'
  | 'element-sticker'
  | 'element-wrapper'
  | 'element-wrapper-single'
  | 'no-selection-view-mode'
  | 'element-line'
  | 'element-text'
  | 'element-container'
  | 'no-color-transparent'
  | 'no-color-white'
  | 'no-selection'
  | 'no-rebinding'
  | 'element-layout'
  | 'element-wrap'
  | 'element-wrapable'
  | 'element-wrap-hidden'
  | 'element-wrap-inline'
  | 'element-wrap-text'
  | 'element-wrap-grid'
  | 'text-limiter'
  | 'parent-free'
  | 'parent-none'
  | 'child-none'
  | 'connector-double-render'
  | 'container-none'
  | 'container-title-none'
  | 'container-child-none'
  | 'menu-button-hidden'
  | 'lock-delete'
  | 'element-connector'
  | 'connector-centered'
);

export interface IElement<T> {
  id: string;
  position: number[];
  parent: string;
  properties: T;
}

export interface IConfigBase {
  title: string;
  search: string;
  type: string;
  group: string[];
}

export interface IConfig extends IConfigBase {
  memo: {
    key: string;
    styles: string[];
  };
  styles: string[];
}

export interface IButton extends IToolData {
  type: IType;
}

export interface ICreate {
  file: {[key: string]: any};
  position: number[];
  parent: string;
  auto?: boolean;
  direction?: number[];
  cell?: number[];
  cursor?: number[];
  preventSkip?: boolean;
  properties?: {[key: string]: any};
  preventComponentStatus?: boolean;
  canvas?: IDynamicContext;
}

export interface ICopy {
  file: {[key: string]: any};
  transition: {[key: string]: string};
  target: {[key: string]: any};
  position: number[];
  parent: string;
  auto?: boolean;
  origin?: number[];
  native?: boolean;
  diagram: string;
  ticket: string;
  preventComponentStatus?: boolean;
}

export interface IBlockElement {
  memo?: string;
  Help?: React.ComponentType,
  group: string[];
  onCreate: (payload: ICreate) => string;
  onCopy: (payload: ICopy) => string;
  title: string;
  search: string;
  options: IOption[];
  styles: string[];
  constraints: Record<string, any>;
  size: [number, number];
  icon: string;
  class: {
    title: string;
    icon: string;
  };
}

export interface IBlock {
  type: string;
  block: ComponentType<any>;
  element: IBlockElement;
  button: IButton;
  tools: string[];
  toolbars: {
    key: string;
    tools: string[];
    skip?: boolean;
  }[];
}

interface IBlocks {
  [key: string]: {
    block: ComponentType<any>;
    element: IBlockElement;
    button: IButton;
    tools: string[];
    components: string[];
    toolbars: {
      [key: string]: string[];
    };
  };
}

const blocks : IBlocks = {};

const addToConfig = (element: IBlock) => {
  blocks[element.type] = {
    block: element.block,
    button: element.button,
    element: element.element,
    tools: element.tools,
    components: [],
    toolbars: {},
  };

  element.toolbars.forEach((toolbar) => {
    if (!toolbar.skip) {
      blocks[element.type].components.push(toolbar.key);
    }

    blocks[element.type].toolbars[toolbar.key] = toolbar.tools;
  });
};

addToConfig(BasicShapeRectangle);
addToConfig(BasicShapeRectangleRounded);
addToConfig(BasicShapeEllipse);
addToConfig(BasicShapeTriangle);
addToConfig(BasicShapeRhombus);
addToConfig(BasicShapeParallelogram);
addToConfig(BasicShapePentagon);
addToConfig(BasicShapeOctagon);
addToConfig(BasicShapeHexagon);
addToConfig(BasicShapeTrapezoid);
addToConfig(BasicShapeCross);
addToConfig(BasicShapeStar);
addToConfig(BasicShapeArrowLeft);
addToConfig(BasicShapeArrowLeftRight);
addToConfig(BasicShapeArrowRight);
addToConfig(BasicShapeBarrel);
addToConfig(BasicShapeBlob);
addToConfig(BasicShapeBubble);
addToConfig(BasicShapeCloud);

addToConfig(CoreGrid);
addToConfig(CoreWrapper);

addToConfig(FlowchartProcess);
addToConfig(FlowchartPredefinedProcess);
addToConfig(FlowchartTerminator);
addToConfig(FlowchartDecision);
addToConfig(FlowchartDocument);
addToConfig(FlowchartMultipleDocuments);
addToConfig(FlowchartManualInput);
addToConfig(FlowchartPreparation);
addToConfig(FlowchartData);
addToConfig(FlowchartDatabase);
addToConfig(FlowchartDirectAccessStorage);
addToConfig(FlowchartInternalStorage);
addToConfig(FlowchartManualOperation);
addToConfig(FlowchartDelay);
addToConfig(FlowchartStoredData);
addToConfig(FlowchartMerge);
addToConfig(FlowchartExtract);
addToConfig(FlowchartConnector);
addToConfig(FlowchartOr);
addToConfig(FlowchartSummingJunction);
addToConfig(FlowchartDisplay);
addToConfig(FlowchartOffPageConnector);
addToConfig(FlowchartCard);
addToConfig(FlowchartLoopLimit);
addToConfig(FlowchartShapeProcess);
addToConfig(FlowchartShapePredefinedProcess);
addToConfig(FlowchartShapeTerminator);
addToConfig(FlowchartShapeDecision);
addToConfig(FlowchartShapeDocument);
addToConfig(FlowchartShapeMultipleDocuments);
addToConfig(FlowchartShapeManualInput);
addToConfig(FlowchartShapePreparation);
addToConfig(FlowchartShapeData);
addToConfig(FlowchartShapeDatabase);
addToConfig(FlowchartShapeDirectAccessStorage);
addToConfig(FlowchartShapeInternalStorage);
addToConfig(FlowchartShapeManualOperation);
addToConfig(FlowchartShapeDelay);
addToConfig(FlowchartShapeStoredData);
addToConfig(FlowchartShapeMerge);
addToConfig(FlowchartShapeExtract);
addToConfig(FlowchartShapeConnector);
addToConfig(FlowchartShapeSummingJunction);
addToConfig(FlowchartShapeOr);
addToConfig(FlowchartShapeDisplay);
addToConfig(FlowchartShapeOffPageConnector);
addToConfig(FlowchartShapeCard);
addToConfig(FlowchartShapeLoopLimit);

addToConfig(GeneralText);
addToConfig(GeneralContainer);
addToConfig(GeneralMarker);
addToConfig(GeneralConnector);
addToConfig(GeneralIcon);
addToConfig(GeneralSticker);
addToConfig(GeneralImage);

addToConfig(InformationConnector);
addToConfig(InformationCode);
addToConfig(InformationShapeCheckbox);
addToConfig(InformationCheckbox);
addToConfig(InformationShapeQuote);
addToConfig(InformationQuote);
addToConfig(InformationShapeNote);
addToConfig(InformationNote);
addToConfig(InformationNoteWithConnector);
addToConfig(InformationShapeStickyNote);
addToConfig(InformationStickyNote);

addToConfig(AWSIcon);
addToConfig(AWSGroupCloud);
addToConfig(AWSGroupZone);
addToConfig(AWSGroupGeneric);
addToConfig(AWSGroupRegion);

addToConfig(GoogleIcon);
addToConfig(GoogleGroupCloud);
addToConfig(GoogleGroupUser);
addToConfig(GoogleGroupZone);
addToConfig(GoogleGroupOptional);

addToConfig(ServiceAzure);
addToConfig(ServiceKubernetes);
addToConfig(ServiceCisco);
addToConfig(ServiceVMware);

addToConfig(UMLClassConnector);
addToConfig(UMLClassClass);
addToConfig(UMLClassClassMultiplicity);
addToConfig(UMLClassInterface);
addToConfig(UMLClassInterfaceMultiplicity);

addToConfig(UMLUseCaseConnector);
addToConfig(UMLUseCaseShapeActor);
addToConfig(UMLUseCaseActor);
addToConfig(UMLUseCaseShapeUseCase);
addToConfig(UMLUseCaseUseCase);

addToConfig(UMLActivityShapeActivity);
addToConfig(UMLActivityActivity);
addToConfig(UMLActivityShapeFlowFinal);
addToConfig(UMLActivityFlowFinal);
addToConfig(UMLActivityShapeDecision);
addToConfig(UMLActivityDecision);
addToConfig(UMLActivityShapeStart);
addToConfig(UMLActivityStart);
addToConfig(UMLActivityShapeEnd);
addToConfig(UMLActivityEnd);
addToConfig(UMLActivityShapeHistoryPseudostate);
addToConfig(UMLActivityHistoryPseudostate);
addToConfig(UMLActivityShapeSendSignal);
addToConfig(UMLActivitySendSignal);
addToConfig(UMLActivityShapeReceiveSignal);
addToConfig(UMLActivityReceiveSignal);
addToConfig(UMLActivityFork);

addToConfig(UMLPackageShapeCard);
addToConfig(UMLPackageCard);
addToConfig(UMLPackageContainerPackage);
addToConfig(UMLPackageShapePackage);
addToConfig(UMLPackagePackage);

addToConfig(UMLDeploymentContainerNode);
addToConfig(UMLDeploymentShapeNode);
addToConfig(UMLDeploymentNode);

addToConfig(UMLComponentContainerComponent);
addToConfig(UMLComponentComponentBox);
addToConfig(UMLComponentShapeComponent);
addToConfig(UMLComponentComponent);
addToConfig(UMLComponentShapeInterface);
addToConfig(UMLComponentInterface);
addToConfig(UMLComponentShapePort);
addToConfig(UMLComponentPort);

export { blocks };
