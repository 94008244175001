import { createNoise2D } from 'simplex-noise';

const { spline } = require('@georgedoescode/spline');

const PI = Math.PI;
const noise = createNoise2D(() => 1);

export function cloud(x: any, y: any, width: number, height: number, scale: number) {
  const n = Math.ceil((((width + height) / 2) * PI) / scale) * 2;

  const points: [number, number][] = new Array(Math.max(n, 0)).fill(0).map((_, i) => {
    const offset = {
      t: (1 / n / 2) * (i % 3 ? 0.6 : i % 5 ? 0.1 : 1),
      d: (1 / n / 2) * (i % 3 ? 0.6 : i % 5 ? 0.1 : 1),
    };

    const delta = {
      x: -24,
      y: -24,
    };

    const t = (i / n) + offset.t;

    return [
      x + Math.sin(t * 2 * PI) * (((width + delta.x) / 2) - (i % 2 ? delta.x : 0)) as number,
      y + Math.cos(t * 2 * PI) * (((height + delta.y) / 2) - (i % 2 ? delta.y : 0)) as number,
    ];
  });

  if (points.length > 0) {
    points.push({ ...points[0] });

    const d = points.map((point, i) => {
      if (i === 0) {
        return { c: 'M', x: point[0], y: point[1] };
      }

      if (i % 2 !== 0) {
        return { c: '' };
      }

      const prev = points[i - 1];

      const x1 = (prev[0]);
      const y1 = (prev[1]);

      return { c: 'C', x1, y1, x2: point[0], y2: point[1], x: point[0], y: point[1] };
    }).filter((point) => point.c);

    return {
      points,
      path: [
        ...d,
        { c: 'Z' },
      ],
    };
  }

  return {
    points,
    path: [],
  };
}

export function blob({ x, y, width, height, scale, random }: { x: any, y: any, width: number, height: number, scale: number, random: number }) {
  const n = Math.max(Math.ceil((PI * (width + height)) / scale), 4);

  const points = new Array(Math.max(n, 0)).fill(0).map((_, i) => {
    const t = i / n;

    const diff = noise(i, random * 100);

    const w = 0.4;
    const h = 0.4;

    return [
      x + Math.sin(t * 2 * PI) * ((width / 2) * ((1 - w) + w * diff)) as number,
      y + Math.cos(t * 2 * PI) * ((height / 2) * ((1 - h) + h * diff)) as number,
    ] as [number, number];
  });

  if (points.length > 0) {
    return {
      points,
      path: spline(points.map((point) => ({ x: point[0], y: point[1] })), 1, true).split('C').slice(0, -1).join('C'),
    };
  }

  return {
    points,
    path: '',
  };
}
