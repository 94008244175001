import React from 'react';

export const withCondition = <T, > (Component: React.ComponentType<T>, useStatus: () => boolean = () => true) => {
  return ({ status = true, ...props }: T & { status?: boolean }) => {
    const condition = useStatus();

    if (!condition || !status) {
      return null;
    }

    return <Component {...(props as any)} />;
  };
};
