import { useCallback, useContext } from 'react';
import { points } from 'utils/points';

import { ElementContext } from 'elements/Block/Element';

import { useProperty } from './useProperty';

export const usePoints = () => {
  const element = useContext(ElementContext);

  const updater = useProperty({});

  const update = useCallback(() => {
    updater.set({});
  }, []);

  return {
    value: updater.value,
    subscribe: (provider: string, type?: 'start' | 'middle' | 'end') => {
      points.subscribe({
        provider,
        type,
        subscriber: element.id,
        callback: update,
      });
    },
    unsubscribe: (provider: string) => {
      points.unsubscribe({
        provider,
        subscriber: element.id,
        callback: update,
      });
    },
    render: (id: string) => {
      points.render({
        id,
        callback: update,
      });
    },
    unrender: (id: string) => {
      points.unrender({
        id,
        callback: update,
      });
    },
  };
};
