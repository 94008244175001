import React, { FC } from 'react';
import { blocks } from 'blocks';

import { IElement } from 'hooks/useElement';
import { useStoreSelector } from 'hooks/useStore';

import { Cell } from 'elements/Wrap/Cell';

import { Element } from './Element';

interface IWrap {
  id: string;
  point: number[];
  element: IElement<HTMLDivElement>;
}

export const Wrap: FC<IWrap> = ({ id, element, point }) => {
  const type = useStoreSelector((state) => state.diagram.file[id]?.type);

  const Block = blocks[type]?.block;

  if (!Block) {
    return null;
  }

  return (
    <Cell element={element} point={point}>
      <Element key={id} id={id}>
        <Block id={id} />
      </Element>
    </Cell>
  );
};
