import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { IConnector } from './Cap';

export const Cap2: IConnector = {
  Start: ({ color, mask }: ICap) => (
    <path d="M9 13L1 8L9 3L7 8L9 13Z" fill={mask ? 'transparent' : color} stroke={mask ? '#fff' : color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
  End: ({ color, mask }: ICap) => (
    <path d="M15 13L23 8L15 3L17 8L15 13Z" fill={mask ? 'transparent' : color} stroke={mask ? '#fff' : color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
};
