import React from 'react';
import { blocks } from 'blocks';
import { RootState } from 'store';
import { getMemoStyle } from 'utils/store';

import { storeGraphic } from 'store/graphic';

import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

export const createTool = (tab: string, key: string, def: string | number, title: string, alt: string, icons: Record<string | number, string>, getStatus: (state: RootState) => boolean = () => true) => () => {
  const dispatch = useStoreDispatch();

  const status = useStoreSelector(getStatus);
  const active = useStoreSelector((state) => {
    return state.graphic.selection.elements.every((id) => {
      return state.graphic.options[id]?.[key] !== 'disable' && !blocks[state.diagram.file[id]?.type]?.element.constraints.disable?.[key];
    });
  });
  const element = useStoreSelector((state) => {
    if (state.graphic.selection.elements.length > 0) {
      return state.diagram.file[state.graphic.selection.elements[0]];
    }

    return {
      [key]: getMemoStyle(blocks[state.graphic.drop?.block ?? '_']?.element.memo ?? '', key),
    };
  });

  const onClick = () => {
    dispatch(storeGraphic.setComponentTab(tab));
  };

  if (!status || !active) {
    return null;
  }

  return (
    <Hint message={title} side="bottom" variant="default">
      <button type="button" className="button-icon v-1" onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icons[element[key] ?? def] ?? icons[def]} width={28} height={28} alt={alt} />
      </button>
    </Hint>
  );
};
