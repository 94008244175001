import React from 'react';
import { ITab } from 'tabs';
import { blocks } from 'blocks';
import { getMemoStyle } from 'utils/store';

import { storeGraphic } from 'store/graphic';
import { storeDiagram } from 'store/diagram';

import { useHotkey } from 'hooks/useHotkey';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';
import { useMemoPropertiesSetter } from 'hooks/useMemoPropertiesSetter';

import { Hint } from 'components/Hint';

import style from './assets/style.module.css';
import iconBack from './assets/back.svg';

export const config = {
  colors: {
    none: 'transparent',
    white: '#FFFFFF',
    dark: '#3A3D4E',
    yellow: '#FFCF35',
    orange: '#FF935C',
    red: '#FD3063',
    green: '#68CC95',
    sky: '#72A5F1',
    blue: '#5474D1',
    purple: '#AE72DE',
    grey: '#CACADE',
  } as Record<string, string>,
};

const Tab = () => {
  const dispatch = useStoreDispatch();

  const file = useStoreSelector((state) => state.diagram.file);
  const options = useStoreSelector((state) => state.graphic.options);
  const selection = useStoreSelector((state) => state.graphic.selection.elements);
  const drop = useStoreSelector((state) => state.graphic.drop?.block);
  const draw = useStoreSelector((state) => state.graphic.draw);
  const prev = useStoreSelector((state) => ((state.graphic.tabs.length < 2 || (!drop && !draw)) ? '' : state.graphic.tabs[state.graphic.tabs.length - 2]));

  const shift = useHotkey({ shift: true });
  const setMemo = useMemoPropertiesSetter();

  const colors = { ...config.colors };
  const creating = selection.length === 0 && (drop || draw);
  const selected = selection.map((id) => file[id]?.color || 'dark');

  const onConstraint = (type: string) => {
    const constraint = blocks[type]?.element.constraints.prevent?.color;

    if (constraint) {
      constraint.forEach((color: string) => {
        delete colors[color];
      });
    }
  };

  if (creating) {
    selected.push(getMemoStyle(blocks[creating]?.element.memo ?? '', 'color') || 'dark');

    onConstraint(creating);
  } else {
    selection.forEach((id) => {
      if (options[id]?.color) {
        options[id].color.forEach((color: string) => {
          delete colors[color];
        });
      }

      onConstraint(file[id]?.type);
    });
  }

  const onBack = () => {
    dispatch(storeGraphic.setComponentTab(prev));
  };

  const setColor = (color: string) => {
    if (creating) {
      setMemo(blocks[creating]?.element.memo ?? '', 'color', color);
    } else {
      selection.forEach((id) => {
        dispatch(storeDiagram.setElement({
          id,
          properties: { color },
        }));
      });
    }

    if (!shift) {
      onBack();
    }
  };

  return (
    <div className="group-icon">
      <Hint message="Back" side="bottom" variant="default">
        <button type="button" className="button-icon v-1" onClick={onBack} onMouseDown={(e) => e.preventDefault()}>
          <img src={iconBack} width={28} height={28} alt="Back" />
        </button>
      </Hint>

      <div className="icon-separator" />

      {Object.entries(colors).map(([key, color]) => (
        <button key={key} type="button" className={`button-icon v-1${selected.includes(key) ? ' active' : ''}`} onClick={() => setColor(key)} onMouseDown={(e) => e.preventDefault()}>
          <div className={`${style.icon}${key === 'white' ? ` ${style.border}` : ''}${key === 'none' ? ` ${style.none}` : ''}`} style={{ background: color }} />
        </button>
      ))}
    </div>
  );
};

export const Color: ITab = {
  key: 'color',
  tab: Tab,
};
