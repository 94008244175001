import React from 'react';

import { ICap } from 'hooks/useConnectorCap';

import { IConnector } from './Cap';

export const Cap6: IConnector = {
  Start: ({ color }: ICap) => null,
  End: ({ color, mask }: ICap) => (
    <path d="M18.5858 12.4142L23 8L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3L10 3L15 8L10 13L17.1716 13C17.702 13 18.2107 12.7893 18.5858 12.4142Z" strokeWidth="2" fill={mask ? '#fff' : color} stroke={mask ? '#fff' : color} strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100,0" />
  ),
};
