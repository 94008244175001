import React from 'react';
import { blocks } from 'blocks';
import { AppDispatch } from 'store';
import { getMemoStyle } from 'utils/store';

import { storeDiagram } from 'store/diagram';

import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';
import { IMemoPropertiesSetter, useMemoPropertiesSetter } from 'hooks/useMemoPropertiesSetter';

import { Hint } from 'components/Hint';

interface ITool<T> {
  key: string;
  def: T;
  title: string;
  alt: string;
  icon: string;
  Help?: React.ComponentType;
  allowDisabledHint?: boolean;
  getValue?: (value: T) => T | undefined;
  getActive?: (value: T) => boolean;
  getTitle?: (value: T, selection: string[]) => string | undefined;
  onClick?: (selection: string[], dispatch: AppDispatch, value: T) => void;
  getDisabled?: (selection: string[], value: T) => boolean;
  getStatus?: () => boolean;
  onMemo?: (memo: IMemoPropertiesSetter, value: T) => void;
}

export const createTool = <T, > ({ key, def, title, alt, icon, Help, allowDisabledHint = false, getTitle, getValue = (value) => (!value as unknown as T), getActive = (value) => !!value, onClick, onMemo, getStatus = () => true, getDisabled = () => false }: ITool<T>) => () => {
  const dispatch = useStoreDispatch();

  const selection = useStoreSelector((state) => state.graphic.selection.elements);
  const drop = useStoreSelector((state) => state.graphic.drop);
  const element = useStoreSelector((state) => {
    if (state.graphic.selection.elements.length > 0) {
      return state.diagram.file[state.graphic.selection.elements[0]];
    }

    return {
      [key]: getMemoStyle(blocks[state.graphic.drop?.block ?? '_']?.element.memo ?? '', key),
    };
  });

  const setMemo = useMemoPropertiesSetter();

  const value = element[key] ?? def;
  const disabled = getDisabled(selection, value);

  const message = getTitle?.(value, selection) ?? title;

  const onClickHandler = () => {
    if (disabled) {
      return;
    }

    if (onClick) {
      onClick(selection, dispatch, value);

      return;
    }

    if (selection.length === 0) {
      if (onMemo) {
        onMemo(setMemo, value);

        return;
      }

      setMemo(blocks[drop?.block ?? '_']?.element.memo ?? '', key, getValue(value));

      return;
    }

    selection.forEach((id) => {
      dispatch(storeDiagram.setElement({
        id,
        properties: { [key]: getValue(value) },
      }));
    });
  };

  if (!getStatus()) {
    return null;
  }

  return (
    <Hint message={message} Help={Help} disabled={disabled && !allowDisabledHint} side="bottom" variant="default">
      <button type="button" disabled={disabled && !allowDisabledHint} className={'button-icon v-1'.appendWhen(getActive(value), 'active').appendWhen(disabled, 'disabled')} onClick={onClickHandler} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt={alt} />
      </button>
    </Hint>
  );
};
