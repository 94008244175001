/* eslint-disable no-extend-native */

declare global {
  interface String {
    appendWhen(condition: unknown, value: string, separator?: string): string;
    append(value?: string, separator?: string): string;
  }
}

String.prototype.append = function (value?: string, separator = ' ') {
  if (value) {
    return `${this}${separator}${value}`;
  }

  return this as string;
};

String.prototype.appendWhen = function (condition: boolean, value?: string, separator = ' ') {
  if (condition) {
    return this.append(value, separator);
  }

  return this as string;
};

export const delay = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const getColor = () => hslToHex(Math.random() * 360, 65, 60);

export const getLiterals = (value: string) => {
  if (value === undefined) value = '';

  value = value.replace(/&lt;/g, '<');
  value = value.replace(/&gt;/g, '>');
  value = value.replace(/&nbsp;/g, ' ');
  value = value.replace(/&amp;/g, '&');
  value = value.replace(/&quot;/g, '"');
  value = value.replace(/&apos;/g, '\'');

  return value;
};

export const setLiterals = (value: string) => {
  if (value === undefined) value = '';

  value = value.replace(/&/g, '&amp;');
  value = value.replace(/</g, '&lt;');
  value = value.replace(/>/g, '&gt;');
  value = value.replace(/"/g, '&quot;');
  value = value.replace(/'/g, '&apos;');

  return value;
};

export const isMatch = (value: string, query: string) => {
  if (query.length === 0) {
    return true;
  }

  const test = value.toLowerCase();
  const target = query.toLowerCase().split(' ');

  return !!target.find((word) => word.length > 0 && test.includes(word));
};

export const getSearch = (keys: string[], settings: {[key: string]: string}) => {
  return keys.map((key) => settings[key] || '').join(' ');
};

export const isArrayEquals = (a: any[], b: any[]) => {
  const c = [...a];
  const d = [...b];

  const e = c.every((e) => {
    const i = d.indexOf(e);

    if (i < 0) {
      return false;
    }

    d.splice(i, 1);

    return true;
  });

  return e && d.length === 0;
};

export const validate = (items: { validate: () => boolean }[]) => {
  return items.reduce((pass, item) => item.validate() && pass, true);
};

export const getTimeDelta = (delta: number) => {
  const min = Math.floor(delta / 1000 / 60);

  if (min < 1) {
    return 'Just now';
  }

  const hour = Math.floor(min / 60);

  if (hour < 1) {
    return `${min} minute${min > 1 ? 's' : ''} ago`;
  }

  const day = Math.floor(hour / 24);

  if (day < 1) {
    return `${hour} hour${hour > 1 ? 's' : ''} ago`;
  }

  const now = new Date();
  const then = new Date(now.getTime() - delta);

  const year = now.getFullYear() - then.getFullYear();
  const mounth = year * 12 - then.getMonth() + now.getMonth();

  if (mounth < 1) {
    return `${day} day${day > 1 ? 's' : ''} ago`;
  }

  if (mounth < 12) {
    return `${mounth} mounth${mounth > 1 ? 's' : ''} ago`;
  }

  return `${mounth % 12} year${(mounth % 12) > 1 ? 's' : ''} ago`;
};

export const getEnumItems = <T extends object> (option: T, items: string[], def: number[]) => {
  if (!Array.isArray(items)) {
    return def;
  }

  const values = items.map((item) => parseInt(item, 10));

  if (values.every((item) => item in option)) {
    return values;
  }

  return def;
};

export const isChanged = (items: { isChanged: boolean }[]) => {
  return !items.every((item) => !item.isChanged);
};

export const getNextId = (file: { [key: string]: any }) => {
  return Object.keys(file).reduce((id, next) => Math.max(id, parseInt(next.replace(/\D/g, ''), 10) + 1), 0).toString();
};

export const getFileDiff = (from: { [key: string]: any }, to: { [key: string]: any }) => {
  const diff: {[key: string]: {} | 'deleted'} = {};

  Object.keys(from).forEach((id) => {
    if (!(id in to)) {
      diff[id] = 'deleted';
    }
  });

  Object.keys(to).forEach((id) => {
    const keys = new Set([...Object.keys(from[id] ?? {}), ...Object.keys(to[id] ?? {})]);

    keys.forEach((key) => {
      if (JSON.stringify(from[id]?.[key] ?? null) !== JSON.stringify(to[id]?.[key] ?? null)) {
        diff[id] = {
          ...(diff[id] || {}),
          [key]: to[id][key] === undefined ? null : to[id][key],
        };
      }
    });
  });

  if (Object.keys(diff).length > 0) {
    return diff;
  }

  return null;
};

export const setFileDiff = (cloud: { [key: string]: any }, diff: { [key: string]: {} | 'deleted' }) => {
  const file = { ...cloud };

  Object.keys(diff).forEach((element) => {
    if (diff[element] === 'deleted') {
      delete file[element];
    } else {
      file[element] = {
        ...(file[element] ?? {}),
        ...(diff[element] as {}),
      };
    }
  });

  return file;
};

export const isIntersection = (a: number[][], b: number[][]) => {
  const det = (a[1][0] - a[0][0]) * (b[1][1] - b[0][1]) - (b[1][0] - b[0][0]) * (a[1][1] - a[0][1]);

  if (det === 0) {
    return false;
  }

  const lambda = ((b[1][1] - b[0][1]) * (b[1][0] - a[0][0]) + (b[0][0] - b[1][0]) * (b[1][1] - a[0][1])) / det;
  const gamma = ((a[0][1] - a[1][1]) * (b[1][0] - a[0][0]) + (a[1][0] - a[0][0]) * (b[1][1] - a[0][1])) / det;

  return (lambda > 0 && lambda < 1) && (gamma > 0 && gamma < 1);
};

export const stripTag = (value: string) => {
  if (value === undefined) value = '';

  return value
    .replace(/(<div><br><\/div>|<div><\/div>)/gm, '<div>&nbsp;</div>')
    .replace(/(<div><div>)/gm, '<div>')
    .replace(/(<div><div>)/gm, '<div>')
    .replace(/(<\/div><\/div>)/gm, '</div>')
    .replace(/(<\/div><\/div>)/gm, '</div>')
    .replace(/<\s*(\w+).*?>/gm, '<$1>');
};

export const getQuillFormat = (quill: any) => {
  try {
    return quill?.editor?.getFormat() || {};
  } catch (e) {
    return {};
  }
};

export const getDetailedLine = (payload: string, detais?: string) => {
  return (payload || '').replace(/<p\b[^>]*>(.*?)<\/p>/gm, (value) => {
    return value.replace(/(:.+?((?= =)|$|(?=[,(){}\n:=])))/gm, (x) => `<span class="${detais}">${x}</span>`);
  });
};

export const getLinkFromHTML = (html?: string) => {
  if (!html) {
    return null;
  }

  return /<img.*?src=["|'](.*?)["|']/.exec(html)?.[1];
};

export const getBlobFromHTML = async (html?: string) => {
  const link = getLinkFromHTML(html);

  if (!link) {
    return null;
  }

  return fetch(link).then((r) => r.blob());
};

export const toFixed = (value: number, digits: number) => {
  return Number((Math.round(value * (10 ** digits)) / (10 ** digits)).toFixed(digits));
};
