import { useContext, MouseEvent } from 'react';
import { Emitter } from 'utils/events';
import { points } from 'utils/points';
import { getState } from 'utils/store';
import { blocks } from 'blocks';

import { storeDiagram } from 'store/diagram';

import { config, IModel } from 'blocks/Core/Grid';

import { CanvasDynamicContext } from 'elements/Canvas';
import { ElementContext } from 'elements/Block/Element';

import { useStoreDispatch } from './useStore';
import { useNeighborElement } from './useNeighborElement';
import { useConnectorsRebind } from './useConnectorsRebind';
import { useNeighborConnectors } from './useNeighborConnectors';

export const useDragBlock = (element?: string) => {
  const dispatch = useStoreDispatch();

  const context = useContext(ElementContext);
  const canvas = useContext(CanvasDynamicContext);

  const getNeighbor = useNeighborElement();
  const getConnectors = useNeighborConnectors();
  const rebindConnectors = useConnectorsRebind();

  const id = element ?? context.id;

  return {
    onDragUp: (e: MouseEvent, direction: number[]) => {
      const file = getState((state) => state.diagram.file);
      const roots = getState((state) => state.diagram.roots);
      const links = getState((state) => state.diagram.links);
      const copy = getState((state) => state.graphic.copy);
      const drag = getState((state) => state.graphic.drag);
      const parents = getState((state) => state.diagram.parents);
      const layout = getState((state) => state.graphic.layout.status);
      const type = getState((state) => state.diagram.file[id]?.type);
      const nest = getState((state) => state.diagram.roots[id]);

      if (!drag) {
        return;
      }

      const payload = { ...file };

      const neighbor = getNeighbor(direction);
      const connectors = getConnectors(neighbor);

      rebindConnectors((!copy && drag.element.id) || '', connectors, payload);

      const from = {
        position: [...file[id].position],
        parent: file[id].parent,
      };

      const to = {
        position: [...(file[drag.element.id]?.position || [0, 0])],
        parent: file[drag.element.id]?.parent || '',
      };

      if (nest && layout && direction[0] === 0 && direction[1] === 0) {
        Emitter.emit('drag-up', {
          id: nest,
          position: [0, 0],
          file: payload,
          event: e,
          auto: canvas.space ? false : undefined,
        });

        return;
      }

      if (direction[0] === 0 && direction[1] === 0) {
        if (!from.parent) {
          from.position[0] += (points.elements[id].size[0] - points.elements[drag.element.id].size[0]) / 2;
          from.position[1] += (points.elements[id].size[1] - points.elements[drag.element.id].size[1]) / 2;
        }

        if (!to.parent) {
          to.position[0] += (points.elements[drag.element.id].size[0] - points.elements[id].size[0]) / 2;
          to.position[1] += (points.elements[drag.element.id].size[1] - points.elements[id].size[1]) / 2;
        }

        const containersFrom = (parents[id] ?? []).map((parent) => ({
          id: parent,
          elements: (file[parent]?.elements ?? []).filter((element: string) => {
            return element !== id;
          }),
        }));

        const containersTo = (parents[drag.element.id] ?? []).map((id) => ({
          id,
          elements: (file[id]?.elements ?? []).filter((id: string) => {
            return id !== drag.element.id;
          }),
        }));

        containersFrom.forEach(({ elements }) => {
          if (!elements.includes(drag.element.id)) {
            elements.push(drag.element.id);
          }
        });

        containersTo.forEach(({ elements }) => {
          if (!elements.includes(id)) {
            elements.push(id);
          }
        });

        const containers: Record<string, Set<string>> = {};

        [...containersTo, ...containersFrom].forEach(({ id, elements }) => {
          if (!(id in containers)) {
            containers[id] = new Set();
          }

          elements.forEach((element: string) => {
            containers[id].add(element);
          });
        });

        Object.keys(containers).forEach((id) => {
          dispatch(storeDiagram.setElement({
            id,
            properties: {
              elements: Array.from(containers[id]),
            },
            prevent: id,
          }));
        });
      } else {
        const properties: IModel = {
          alignHorizontal: 'center',
          alignVertical: 'center',
          padding: 'm',
          lockKey: '',
          lock: false,
        };

        const a = blocks[type]?.element.options.includes('element-text');
        const b = blocks[drag?.element.type]?.element.options.includes('element-text');
        const c = !['top', 'bottom'].includes(file[id]?.placing) && blocks[type]?.element.options.includes('element-wrap-inline') && blocks[file[roots[id]]?.type]?.element.options.includes('element-text') && (links[id].length === 2 || (links[id].length === 3 && blocks[type]?.element.options.includes('element-wrap-grid')));
        const d = !['top', 'bottom'].includes(file[id]?.placing) && blocks[drag?.element.type]?.element.options.includes('element-wrap-inline') && blocks[file[roots[drag?.element.id]]?.type]?.element.options.includes('element-text') && (links[drag?.element.id].length === 2 || (links[drag?.element.id].length === 3 && blocks[drag?.element.type]?.element.options.includes('element-wrap-grid')));

        const position = [...from.position];

        if (!from.parent) {
          if ((a && b) || (a && d)) {
            position[0] -= 1;
            position[1] -= 5;
          } else if ((c && b) || (c && d)) {
            position[0] -= 1;
            position[1] -= 4;
          } else if (c) {
            position[0] -= 6;
            position[1] -= 9;
          } else if (a) {
            position[0] -= 6;
            position[1] -= 10;
          } else {
            position[0] -= 24;
            position[1] -= 24;
          }

          if (blocks[type]?.element.options.includes('element-wrap-text')) {
            position[1] += 3;
          }
        }

        if ((a || c) && (b || d)) {
          properties.padding = 's';
          properties.alignHorizontal = 'left';
        }

        const layout = blocks[config.type].element.onCreate({
          file: payload,
          parent: from.parent,
          auto: true,
          properties,
          position,
        });

        from.parent = layout;
        to.parent = layout;

        from.position = [Math.max(0, direction[0]), Math.max(0, direction[1])];
        to.position = [Math.max(0, -direction[0]), Math.max(0, -direction[1])];
      }

      if (copy || drag.type === 'create') {
        Emitter.emit('drag-up', {
          id: from.parent,
          position: from.position,
          file: payload,
          event: e,
          auto: canvas.space ? false : undefined,
        });
      } else {
        dispatch(storeDiagram.setElement({
          id: drag.element.id,
          properties: from,
        }));
      }

      dispatch(storeDiagram.setElement({
        id,
        properties: to,
      }));
    },
  };
};
