import { useEffect } from 'react';

export const useSessionListener = (callback: () => void) => {
  const onStorageUpdate = (e: StorageEvent) => {
    const { key } = e;

    if (key === 'session') {
      callback();
    }
  };

  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate);

    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);
};

export const useVerificationListener = (callback: () => void) => {
  const onStorageUpdate = (e: StorageEvent) => {
    const { key } = e;

    if (key === 'verified') {
      callback();
    }
  };

  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate);

    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);
};
