import React, { useContext } from 'react';
import { getPathSideCode } from 'utils/graphic';
import { getState } from 'utils/store';
import { blocks } from 'blocks';

import { GridContext } from './useGrid';

export interface INeighbor {
  id: string;
  side: 'L' | 'T' | 'R' | 'B';
}

export const useNeighborGrid = (): (position: number[], direction: number[]) => INeighbor => {
  const grid = useContext(GridContext);

  return (position: number[], direction: number[]) => {
    const drag = getState((state) => state.graphic.drag);
    const copy = getState((state) => state.graphic.copy);

    const element = {
      id: '',
      direction: [0, 0],
      side: '',
      status: true,
    };

    if (!copy && drag?.path.includes(grid.id)) {
      element.status = false;
    }

    if (Math.abs(direction[0]) === Math.abs(direction[1])) {
      element.status = false;
    }

    if (blocks[drag?.element.type ?? '_']?.element.options.includes('element-layout')) {
      element.status = false;
    }

    if (element.status && direction[0] !== 0) {
      element.id = grid.layout[position[1]]?.[position[0] + Math.min(direction[0], 0)];
      element.direction = [1, 0];

      if (!element.id || (!copy && element.id === drag?.element.id)) {
        element.id = grid.layout[position[1]]?.[position[0] + Math.min(direction[0], 0) + 1];
        element.direction = [-1, 0];
      }
    }

    if (element.status && direction[1] !== 0) {
      element.id = grid.layout[position[1] + Math.min(direction[1], 0)]?.[position[0]];
      element.direction = [0, 1];

      if (!element.id || (!copy && element.id === drag?.element.id)) {
        element.id = grid.layout[position[1] + Math.min(direction[1], 0) + 1]?.[position[0]];
        element.direction = [0, -1];
      }
    }

    return {
      id: element.id,
      side: getPathSideCode(element.direction),
    };
  };
};
