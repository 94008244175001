import React, { useEffect, useRef } from 'react';
import { Emitter } from 'utils/events';
import { ITool } from 'tools';

import { storeGraphic } from 'store/graphic';

import { useProperty } from 'hooks/useProperty';
import { useStoreDispatch, useStoreSelector } from 'hooks/useStore';

import { Hint } from 'components/Hint';

import icon from './assets/icon.svg';

const Tool = () => {
  const dispatch = useStoreDispatch();

  const selection = useStoreSelector((state) => state.graphic.selection.elements.length === 1);
  const status = useProperty((() => {
    try {
      return ((window as any).editor?.getFormat() || {}).link || '';
    } catch (e) {
      return '';
    }
  })());
  const visible = useProperty(!!(window as any).editor || selection);
  const empty = useProperty(true);

  const onClick = () => {
    if (!empty.value || status.value) {
      dispatch(storeGraphic.setComponentTab('format-link'));
    }
  };

  // Listen to Editor Change
  useEffect(() => {
    const onChange = () => {
      visible.set(!!(window as any).editor || selection);
    };

    Emitter.on('editor-change', onChange);

    return () => {
      Emitter.off('editor-change', onChange);
    };
  }, []);

  // Listen to Selection Change
  useEffect(() => {
    const onSelect = (payload: {[key: string]: string | string[]}) => {
      status.set((Array.isArray(payload.link) ? payload.link.join(', ') : payload.link) || '');
      empty.set(!(payload.length || 0));
    };

    Emitter.on('selection-change', onSelect);

    return () => {
      Emitter.off('selection-change', onSelect);
    };
  }, []);

  const link = status.value.length > 32 ? `${status.value.substr(0, 32)}...` : status.value;

  return (
    <Hint message={`Link${status.value ? ` (${link})` : empty.value ? ' (select text)' : ''}`} disabled={!visible.value || (empty.value && !status.value)} side="bottom" variant="default">
      <button type="button" className={`button-icon v-1${(!visible.value || (empty.value && !status.value)) ? ' button-disabled' : ''}`} onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        <img src={icon} width={28} height={28} alt="Link" />
      </button>
    </Hint>
  );
};

export const FormatLink: ITool = {
  key: 'format-link',
  tool: Tool,
  options: ['only-edit', 'side-right'],
};
