import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { isBlockExist } from 'utils/models';
import { addChild, removeChild, removeRoot, addRoot, removeLink, addLink, removeParent, addParent, addJoin, removeJoin } from 'utils/store';

import { skip } from 'hooks/useHistory';
import { points } from 'utils/points';

export type IRole = 'viewer' | 'editor' | 'owner';
export type IMode = 'view' | 'edit';

export interface IMember {
  id: string;
  user?: string;
  email: string;
  role: IRole;
}

export interface IDiagram {
  id: string;
  role: IRole;
  lastModified: number;
  name: string;
  ticket: string;
  owner: string;
  members: IMember[];
  favorite: boolean;
  template: boolean;
}

interface State {
  locked: {
    elements: string[];
    status: boolean;
  },
  prevent: string[];
  loading: boolean;
  failed: boolean;
  saved: boolean,
  diagram: IDiagram;
  mode: IMode;
  embed?: {
    controls: boolean;
  },
  synced: {},
  file: { [key: string]: any };
  cloud: { [key: string]: any };
  members: { [key: string]: string };
  captured: { [key: string]: string };
  childs: { [key: string]: string[] };
  links: { [key: string]: string[] };
  joins: {
    from: { [key: string]: string[] };
    to: { [key: string]: string[] };
  };
  roots: { [key: string]: string };
  parents: { [key: string]: string[] };
  hidden: { [key: string]: boolean };
  highlighted: { [key: string]: boolean };
  focused: { [key: string]: boolean };
}

export const defaultState: State = {
  locked: {
    elements: [],
    status: false,
  },
  prevent: [],
  synced: {},
  loading: true,
  failed: false,
  saved: true,
  diagram: {
    id: '',
    role: 'viewer',
    lastModified: 0,
    name: '',
    ticket: '',
    owner: '',
    favorite: false,
    members: [],
    template: false,
  },
  mode: 'view',
  file: {},
  cloud: {},
  childs: {},
  parents: {},
  members: {},
  captured: {},
  roots: {},
  links: {},
  joins: {
    from: {},
    to: {},
  },
  hidden: {},
  highlighted: {},
  focused: {},
};

const slice = createSlice({
  name: 'diagram',
  initialState: defaultState,
  reducers: {
    reset: () => defaultState,
    clear: (state) => {
      state.file = defaultState.file;
      state.captured = defaultState.captured;
      state.members = defaultState.members;
      state.cloud = defaultState.cloud;
      state.childs = defaultState.childs;
      state.parents = defaultState.parents;
      state.roots = defaultState.roots;
      state.links = defaultState.links;
      state.hidden = defaultState.hidden;
      state.highlighted = defaultState.highlighted;
      state.focused = defaultState.focused;
      state.saved = defaultState.saved;
    },
    init: (state, action: PayloadAction<{ name: string }>) => {
      state.mode = 'edit';
      state.diagram.role = 'owner';
      state.diagram.name = action.payload.name;
      state.diagram.template = false;
      state.diagram.favorite = false;
    },
    setFailed: (state) => {
      state.failed = true;
      state.loading = false;
    },
    setSaved: (state, action: PayloadAction<boolean>) => {
      state.saved = action.payload;
      state.prevent = [];
    },
    setEmbed: (state, action: PayloadAction<{ controls: boolean } | undefined>) => {
      state.embed = action.payload;
    },
    setDiagram: (state, action: PayloadAction<IDiagram>) => {
      state.diagram = action.payload;
    },
    updateDiagram: (state, action: PayloadAction<{ id: string } & any>) => {
      if (state.diagram.id !== action.payload.id) {
        return;
      }

      if ('name' in action.payload) state.diagram.name = action.payload.name;
      if ('ticket' in action.payload) state.diagram.ticket = action.payload.ticket;
      if ('favorite' in action.payload) state.diagram.favorite = action.payload.favorite;
      if ('members' in action.payload) state.diagram.members = action.payload.members;
      if ('template' in action.payload) state.diagram.template = action.payload.template;
    },
    setFile: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.file = action.payload;
      state.file = action.payload;
      state.file = action.payload;
      state.file = action.payload;

      Object.keys(state.file).forEach((id) => {
        addParent(id, state);
        addChild(id, state);
        addLink(id, state);
        addRoot(id, state);
        addJoin(id, state);
      });
    },
    syncFile: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.synced = {};

      Object.keys(action.payload).forEach((id) => {
        removeParent(id, state);
        removeChild(id, state);
        removeLink(id, state);
        removeRoot(id, state);
        removeJoin(id, state);

        skip.elements.push(id);

        if (action.payload[id] === 'deleted') {
          delete state.cloud[id];
          delete state.file[id];

          return;
        }

        if ('parent' in action.payload[id]) {
          action.payload[id].parent = action.payload[id].parent.trim();
        }

        state.cloud[id] = {
          ...state.cloud[id],
          ...action.payload[id],
        };

        state.file[id] = {
          ...state.file[id],
          ...action.payload[id],
        };

        addParent(id, state);
        addChild(id, state);
        addLink(id, state);
        addRoot(id, state);
        addJoin(id, state);
      });
    },
    syncCloud: (state, action: PayloadAction<{ [key: string]: any }>) => {
      Object.keys(action.payload).forEach((id) => {
        if (action.payload[id] === 'deleted') {
          delete state.cloud[id];

          return;
        }

        if ('parent' in action.payload[id]) {
          action.payload[id].parent = action.payload[id].parent.trim();
        }

        state.cloud[id] = {
          ...state.cloud[id],
          ...action.payload[id],
        };
      });
    },
    setCloud: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.cloud = action.payload;
    },
    setMembers: (state, action: PayloadAction<{ id: string, user: string }[]>) => {
      action.payload.forEach((member) => {
        state.members[member.id] = member.user;
      });
    },
    setCaptured: (state, action: PayloadAction<{ [key: string]: string[] }>) => {
      Object.keys(action.payload).forEach((id) => {
        action.payload[id].forEach((element) => {
          state.captured[element] = id;
        });
      });
    },
    addCaptured: (state, action: PayloadAction<{ id: string, elements: string[] }>) => {
      action.payload.elements.forEach((element) => {
        state.captured[element] = action.payload.id;
      });
    },
    removeCaptured: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((element) => {
        delete state.captured[element];
      });
    },
    addMember: (state, action: PayloadAction<{ id: string, user: string }>) => {
      state.members[action.payload.id] = action.payload.user;
    },
    removeMember: (state, action: PayloadAction<{ id: string }>) => {
      delete state.members[action.payload.id];
    },
    setChild: (state, action: PayloadAction<{ id: string; parent: string; }>) => {
      if (action.payload.parent in state.childs) {
        state.childs[action.payload.parent] = state.childs[action.payload.parent].filter((id) => id !== action.payload.id);
      }

      if (action.payload.parent) {
        if (!(action.payload.parent in state.childs)) {
          state.childs[action.payload.parent] = [];
        }

        if (!state.childs[action.payload.parent].includes(action.payload.id)) {
          state.childs[action.payload.parent].push(action.payload.id);
        }
      }
    },
    removeChild: (state, action: PayloadAction<{ id: string; parent: string; }>) => {
      if (action.payload.parent in state.childs) {
        state.childs[action.payload.parent] = state.childs[action.payload.parent].filter((id) => id !== action.payload.id);

        if (state.childs[action.payload.parent].length === 0) {
          delete state.childs[action.payload.parent];
        }
      }
    },
    addElement: (state, action: PayloadAction<{ element: {id: string, type: string, parent: string} & any, skip?: boolean}>) => {
      if (!isBlockExist(action.payload.element.type)) {
        return;
      }

      if (action.payload.skip) {
        skip.elements.push(action.payload.element.id);
      }

      removeParent(action.payload.element.id, state);
      removeChild(action.payload.element.id, state);
      removeLink(action.payload.element.id, state);
      removeRoot(action.payload.element.id, state);
      removeJoin(action.payload.element.id, state);

      const payload: {[key: string]: any} = { ...action.payload.element };

      delete payload.id;

      state.file[action.payload.element.id] = {
        ...payload,

        parent: (payload.parent || '').trim(),
        lockKey: '',
        lock: false,
      };

      addParent(action.payload.element.id, state);
      addChild(action.payload.element.id, state);
      addLink(action.payload.element.id, state);
      addRoot(action.payload.element.id, state);
      addJoin(action.payload.element.id, state);
    },
    setHidden: (state, action: PayloadAction<{ id: string; status: boolean }>) => {
      state.hidden[action.payload.id] = action.payload.status;
    },
    setHighlighted: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.highlighted = action.payload;
    },
    setFocused: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.focused = action.payload;
    },
    deleteElements: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((id) => {
        removeParent(id, state);
        removeChild(id, state);
        removeLink(id, state);
        removeRoot(id, state);
        removeJoin(id, state);

        (state.parents[id] ?? []).forEach((parent) => {
          state.file[parent].elements = (state.file[parent]?.elements ?? []).filter((child: string) => {
            return child !== id;
          });

          if (state.file[parent].elements.length === 0) {
            const position = points.elements[parent].position;
            const size = points.elements[parent].size;

            state.file[parent].position = [...position];
            state.file[parent].width = size[0];
            state.file[parent].height = size[1];
            state.file[parent].border = [
              Number.isFinite(state.file[parent].border?.[0]) ? position[0] : state.file[parent].border?.[0],
              Number.isFinite(state.file[parent].border?.[1]) ? position[1] : state.file[parent].border?.[1],
              Number.isFinite(state.file[parent].border?.[2]) ? (position[0] + size[0]) : state.file[parent].border?.[2],
              Number.isFinite(state.file[parent].border?.[3]) ? (position[1] + size[1]) : state.file[parent].border?.[3],
            ];
          }
        });

        delete state.roots[id];
        delete state.links[id];
        delete state.hidden[id];
        delete state.parents[id];
        delete state.highlighted[id];
        delete state.childs[id];
        delete state.file[id];
      });
    },
    setElement: (state, action: PayloadAction<{ id: string, forced?: boolean, prevent?: string, properties: { [key: string]: any }, skip?: boolean }>) => {
      if (state.mode !== 'edit' || (!action.payload.forced && !state.file[action.payload.id]?.type)) {
        return;
      }

      if (!action.payload.prevent && state.prevent.includes(action.payload.id)) {
        state.prevent = state.prevent.filter((id) => {
          return id !== action.payload.id;
        });

        return;
      }

      if (action.payload.prevent && !state.prevent.includes(action.payload.id)) {
        state.prevent.push(action.payload.prevent);
      }

      if (action.payload.skip) {
        skip.elements.push(action.payload.id);
      }

      if ('parent' in action.payload.properties) {
        action.payload.properties.parent = action.payload.properties.parent.trim();
      }

      removeParent(action.payload.id, state);
      removeChild(action.payload.id, state);
      removeLink(action.payload.id, state);
      removeRoot(action.payload.id, state);
      removeJoin(action.payload.id, state);

      state.file[action.payload.id] = {
        ...state.file[action.payload.id],
        ...action.payload.properties,
      };

      addParent(action.payload.id, state);
      addChild(action.payload.id, state);
      addLink(action.payload.id, state);
      addRoot(action.payload.id, state);
      addJoin(action.payload.id, state);
    },
    setComputedProperties: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((id) => {
        removeChild(id, state);
        removeLink(id, state);
        removeRoot(id, state);
        removeJoin(id, state);
      });

      action.payload.forEach((id) => {
        addChild(id, state);
        addLink(id, state);
        addRoot(id, state);
        addJoin(id, state);
      });
    },
    setLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFailedStatus: (state, action: PayloadAction<boolean>) => {
      state.failed = action.payload;
    },
    setMode: (state, action: PayloadAction<IMode>) => {
      state.mode = action.payload;
    },
    setLocked: (state, action: PayloadAction<string[] | undefined>) => {
      if (!action.payload) {
        if (state.locked.status) {
          state.locked.status = false;

          return;
        }

        state.locked.elements.forEach((id) => {
          if (id in state.file) {
            state.file[id].lock = true;
          }
        });

        state.locked.elements = [];
        state.locked.status = false;

        return;
      }

      state.locked.elements = action.payload;
      state.locked.status = true;
    },
  },
});

export const storeDiagram = {
  reset: slice.actions.reset,
  clear: slice.actions.clear,
  init: slice.actions.init,
  setFailed: slice.actions.setFailed,
  setSaved: slice.actions.setSaved,
  setDiagram: slice.actions.setDiagram,
  updateDiagram: slice.actions.updateDiagram,
  setFile: slice.actions.setFile,
  syncFile: slice.actions.syncFile,
  setCloud: slice.actions.setCloud,
  syncCloud: slice.actions.syncCloud,
  setMembers: slice.actions.setMembers,
  addMember: slice.actions.addMember,
  removeMember: slice.actions.removeMember,
  setCaptured: slice.actions.setCaptured,
  addCaptured: slice.actions.addCaptured,
  removeCaptured: slice.actions.removeCaptured,
  addElement: slice.actions.addElement,
  deleteElements: slice.actions.deleteElements,
  setElement: slice.actions.setElement,
  setLoadingStatus: slice.actions.setLoadingStatus,
  setFailedStatus: slice.actions.setFailedStatus,
  setMode: slice.actions.setMode,
  setChild: slice.actions.setChild,
  removeChild: slice.actions.removeChild,
  setHidden: slice.actions.setHidden,
  setHighlighted: slice.actions.setHighlighted,
  setFocused: slice.actions.setFocused,
  setComputedProperties: slice.actions.setComputedProperties,
  setLocked: slice.actions.setLocked,
  setEmbed: slice.actions.setEmbed,
};

export default slice.reducer;
