import { ITool } from 'tools';
import { createTool } from 'tools/ToolLink';

import icon from './assets/icon.svg';

const Tool = createTool('stereotype', 'Stereotype', 'Stereotype', icon);

export const LinkStereotype: ITool = {
  key: 'link-stereotype',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
