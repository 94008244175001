import React from 'react';
import { blocks, IBlock, IConfigBase } from 'blocks';

import { config as shape } from 'blocks/UMLActivity/ShapeDecision';

import { Button } from 'elements/Block/Button';

import icon from './assets/icon.svg';

export const config: IConfigBase = {
  title: 'Decision',
  search: 'decision uml unified modeling language',
  type: 'uml-activity-decision',
  group: ['UML'],
};

export const UMLActivityDecision : IBlock = {
  type: config.type,
  block: () => null,
  element: {
    group: config.group,
    search: config.search,
    options: [],
    styles: [],
    constraints: {},
    onCreate: ({ file, position, parent }) => {
      return blocks[shape.type].element.onCreate({
        file,
        position,
        parent,
        auto: false,
        properties: {
          width: 48,
          height: 48,
          realWidth: 48,
          realHeight: 48,
          transparent: false,
          deletable: true,
          title: '<p class="ql-align-center">Decision</p>',
        },
      });
    },
    onCopy: () => '',
    title: config.title,
    size: [48, 48],
    icon,
    class: {
      title: '',
      icon: '',
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [],
  toolbars: [],
};
