import { useContext } from 'react';
import { blocks } from 'blocks';
import { store } from 'store';

import { storeGraphic } from 'store/graphic';

import { ElementContext } from 'elements/Block/Element';

import { useStoreDispatch } from './useStore';

export const useComponentSkip = (key: string) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  return {
    next: () => {
      const state = store.getState();

      const type = state.diagram.file[element.id]?.type;
      const components = blocks[type]?.components || [];
      const index = components.indexOf(key);

      if (index >= 0) {
        if (index + 1 >= components.length) {
          dispatch(storeGraphic.setComponentKey(components[0]));
        } else {
          dispatch(storeGraphic.setComponentKey(components[index + 1]));
        }
      }
    },
    prev: () => {
      const state = store.getState();

      const type = state.diagram.file[element.id]?.type;
      const components = blocks[type]?.components || [];
      const index = components.indexOf(key);

      if (index >= 0) {
        if (index - 1 < 0) {
          dispatch(storeGraphic.setComponentKey(components[components.length - 1]));
        } else {
          dispatch(storeGraphic.setComponentKey(components[index - 1]));
        }
      }
    },
  };
};
