import { useEffect, useRef } from 'react';

import { useProperty } from './useProperty';

interface IPhase {
  handler?: () => void;
  producers?: unknown[];
}

export const usePhase = ({ handler = () => {}, producers = [] }: IPhase = {}) => {
  const status = useProperty(false);
  const listening = useProperty(false);
  const callback = useRef(handler);

  callback.current = handler;

  useEffect(() => {
    if (status.value) {
      callback.current();
    }
  }, [status.value]);

  useEffect(() => {
    if (listening.value) {
      status.set(true);
    }
  }, [producers]);

  return {
    status: status.value,
    run: () => {
      if (producers.length === 0) {
        status.set(true);
      } else {
        listening.set(true);
      }
    },
  };
};
