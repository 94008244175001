import { useContext } from 'react';
import { blocks } from 'blocks';
import { getState } from 'utils/store';

import { storeGraphic } from 'store/graphic';

import { ElementContext } from 'elements/Block/Element';

import { useStoreDispatch } from './useStore';

export const useEditLink = ({ key }: { key: string }) => {
  const dispatch = useStoreDispatch();

  const element = useContext(ElementContext);

  return () => {
    const mode = getState((state) => state.diagram.mode);
    const lock = getState((state) => !!state.diagram.captured[element.id] || state.diagram.file[element.id]?.lock || state.graphic.options[element.id]?.invisible);

    if (mode !== 'edit' || lock) {
      return;
    }

    const file = getState((state) => state.diagram.file);
    const selection = getState((state) => state.graphic.selection);

    if (!element.selected) {
      dispatch(storeGraphic.setComponentFailed(false));
      return;
    }

    if (selection.elements.length > 1) {
      const elements = selection.elements.filter((id: string) => {
        return blocks[file[id]?.type]?.tools.includes(`link-${key}`);
      });

      if (selection.elements.length !== elements.length) {
        dispatch(storeGraphic.setComponentPreventStatus());
        dispatch(storeGraphic.setSelectionElements(elements));
      }
    }

    dispatch(storeGraphic.setComponentKey(key));
  };
};
