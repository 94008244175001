import { ITool } from 'tools';
import { createTool } from 'tools/ToolBasic';

import icon from './assets/icon.svg';

const Tool = createTool({
  key: 'hidden',
  def: false,
  title: 'Hide',
  getTitle: (value) => {
    return value ? 'Show' : 'Hide';
  },
  alt: 'Visibility',
  icon,
});

export const Hide: ITool = {
  key: 'hide',
  tool: Tool,
  options: ['only-edit', 'side-left'],
};
