import React from 'react';
import { blocks, IBlock, IConfigBase } from 'blocks';

import { config as shape } from 'blocks/UMLComponent/ShapePort';

import { Button } from 'elements/Block/Button';

import icon from './assets/icon.svg';

export const config: IConfigBase = {
  title: 'Port',
  search: 'port uml unified modeling language',
  type: 'uml-component-port',
  group: ['UML'],
};

export const UMLComponentPort : IBlock = {
  type: config.type,
  block: () => null,
  element: {
    group: config.group,
    search: config.search,
    options: [],
    styles: [],
    constraints: {},
    onCreate: ({ file, position, parent }) => {
      return blocks[shape.type].element.onCreate({
        file,
        position,
        parent,
        auto: false,
        properties: {
          width: 28,
          height: 28,
          realWidth: 28,
          realHeight: 28,
          transparent: false,
          deletable: true,
          title: '<p class="ql-align-center">Port</p>',
        },
      });
    },
    onCopy: () => '',
    title: config.title,
    size: [28, 28],
    icon,
    class: {
      title: '',
      icon: '',
    },
  },
  button: {
    tool: () => <Button element={config.type} />,
    options: ['only-edit'],
    type: 'drag',
  },
  tools: [],
  toolbars: [],
};
