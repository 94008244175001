import { useContext, useLayoutEffect } from 'react';
import { getState } from 'utils/store';
import { blocks } from 'blocks';

import { ElementContext } from 'elements/Block/Element';

import { useElement } from './useElement';
import { useStoreSelector } from './useStore';

export const useWrap = () => {
  const element = useContext(ElementContext);

  const file = getState((state) => state.diagram.file);
  const child = useStoreSelector((state) => state.diagram.childs[element.id]?.[0] || '');

  const wrap = useElement<HTMLDivElement>();

  const type = file[child]?.type;
  const status = type in blocks;

  // Update Points on Child Change
  useLayoutEffect(() => {
    element.updatePoints();
  }, [status]);

  return {
    status,
    id: child,
    type,
    element: wrap.ref,
    attributes: {
      point: [0, 0],
      element: wrap,
      id: child,
    },
  };
};
