import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { storage as localStorage } from 'utils/storage';
import security from 'utils/security';

export type OptionNotifications = 'all' | 'mine';

export interface IUser {
  id: string;
  name: string;
  color: string;
  email: string;
}

export interface ISettings {
  notifications: OptionNotifications;
}

interface State {
  active: boolean;
  user: IUser;
  settings: ISettings;
}

const defaultState: State = {
  active: false,
  user: {
    id: '',
    name: '',
    color: '#ffffff',
    email: '',
  },
  settings: {
    notifications: 'all',
  },
};

const slice = createSlice({
  name: 'account',
  initialState: defaultState,
  reducers: {
    reset: () => defaultState,
    setUser: (state, action: PayloadAction<IUser & { active: boolean }>) => {
      security.setUser(action.payload);

      state.active = action.payload.active;

      state.user.id = action.payload.id;
      state.user.name = action.payload.name;
      state.user.email = action.payload.email;
      state.user.color = action.payload.color;
    },
    setSettings: (state, action: PayloadAction<ISettings>) => {
      state.settings.notifications = action.payload.notifications;
    },
    verifyUser: (state) => {
      localStorage.setItem('verified', '');

      if (state.user.id) {
        state.active = true;
      }
    },
  },
});

export const storeAccount = {
  reset: slice.actions.reset,
  setUser: slice.actions.setUser,
  setSettings: slice.actions.setSettings,
  verifyUser: slice.actions.verifyUser,
};

export default slice.reducer;
