import { useEffect, useMemo, useRef } from 'react';
import { getState } from 'utils/store';
import { lines } from 'utils/lines';
import { points } from 'utils/points';

import { useStoreSelector } from './useStore';

export const useJoinsSide = (connectors: string[]) => {
  const memo = useRef({} as Record<string, 'L' | 'T' | 'R' | 'B'>);

  const links = useStoreSelector((state) => {
    return connectors.filter((id) => {
      return (
        state.graphic.options[id]?.closed
      );
    }).join(',');
  });

  useEffect(() => {
    const file = getState((state) => state.diagram.file);
    const elements = getState((state) => state.graphic.elements);

    connectors.forEach((id) => {
      const line = file[id]?.points || ['_'];
      const parent = line[0]?.parent;

      const side = elements[parent] ? lines.elements[`${id}.1`]?.side : undefined;

      if (side) {
        memo.current[id] = side;
      }
    });
  }, [connectors]);

  return useMemo(() => {
    const file = getState((state) => state.diagram.file);
    const elements = getState((state) => state.graphic.elements);

    const items = links.split(',');

    Object.keys(memo).forEach((id) => {
      if (!items.includes(id)) {
        delete memo.current[id];
      }
    });

    const sides = items.reduce((sides, id) => {
      const line = file[id]?.points || ['_'];
      const parent = line[0]?.parent;

      const side = !elements[parent] ? memo.current[id] : (lines.elements[`${id}.1`]?.side ?? memo.current[id]);

      if (side in sides) {
        sides[side] += 1;
        memo.current[id] = side;
      }

      return sides;
    }, { L: 0, T: 0, R: 0, B: 0 });

    const [side] = Object.entries(sides).reduce((side, item) => {
      return item[1] > side[1] ? item : side;
    }, ['B', 0]);

    return side as 'L' | 'T' | 'R' | 'B';
  }, [links]);
};
